export function initBtnCopyPage (selector) {
  $(selector).on('click', function(){
    const $btn = $(this);
    const text = $btn.data('text') + "\n" + location.href;
    copyActually($btn, text);
  });
};

export function initBtnCopyText (selector) {
  $(selector).on('click', function(){
    const $btn = $(this);
    copyActually($btn, $btn.data('text'));
  });
};

function copyActually($btn, text){
  const result = execCopy(text);
  const $lbl = $btn.find('.btnLbl');
  const title = $lbl.text();
  $btn.removeClass('btn-outline-primary');
  if (result) {
    $lbl.text('クリップボードにコピーしました');
    $btn.addClass('btn-success');
  } else {
    $lbl.text('失敗しました');
    $btn.addClass('btn-danger');
  }
  setTimeout(()=>{
    $btn
      .removeClass('btn-success')
      .removeClass('btn-danger')
      .addClass('btn-outline-primary');
    $lbl.text(title);
  }, 2000);
}

function execCopy(text){
  const copyFrom = document.createElement('textarea');
  copyFrom.textContent = text;
  document.body.appendChild(copyFrom);
  copyFrom.select();
  const result = document.execCommand('copy');
  document.body.removeChild(copyFrom);

  return result;
}
