<template>
  <ul class="nav nav-tabs card-header-tabs" id="tab-selector" role="tablist">
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab0" 
        role="tab" 
        :class="{'active': isSelectedTab('tab0')}"
        aria-selected="false">
        プレビュー
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab1" 
        role="tab" 
        :class="{'active': isSelectedTab('tab1')}"
        aria-selected="true">
        格付けバージョン
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab2" 
        role="tab" 
        :class="{'active': isSelectedTab('tab2')}"
        aria-selected="true">
        ユーザー格付け
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab3" 
        role="tab" 
        :class="{'active': isSelectedTab('tab3')}"
        aria-selected="false">
        企業格付け
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab4" 
        role="tab" 
        :class="{'active': isSelectedTab('tab4')}"
        aria-selected="false">
        取引格付け
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab5" 
        role="tab" 
        :class="{'active': isSelectedTab('tab5')}"
        aria-selected="false">
        取引クラスタリング
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab6" 
        role="tab" 
        :class="{'active': isSelectedTab('tab6')}"
        aria-selected="false">
        手数料設定
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" 
        data-toggle="tab" 
        href="#tab7" 
        role="tab" 
        :class="{'active': isSelectedTab('tab7')}"
        aria-selected="false">
        手数料クラスタリング
      </a>
    </li>
  </ul>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'TabSelector',
    props: [
      'selected',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    data() {
      return {}
    },
  };
</script>
