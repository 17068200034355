var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import mainStore from '../../store/main';
var ListPaginateComponent = /** @class */ (function (_super) {
    __extends(ListPaginateComponent, _super);
    function ListPaginateComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentPage = 1;
        _this.pageLimit = 10;
        return _this;
    }
    Object.defineProperty(ListPaginateComponent.prototype, "isOnAPIRequest", {
        get: function () {
            return mainStore.isOnAPIRequest;
        },
        enumerable: false,
        configurable: true
    });
    ListPaginateComponent.prototype.isHeadOmitted = function () {
        return this.currentPage - 1 > this.pageLimit;
    };
    ListPaginateComponent.prototype.isTailOmitted = function () {
        return this.totalPages - this.currentPage > this.pageLimit;
    };
    ListPaginateComponent.prototype.getPageList = function () {
        var pageList = [];
        var begin;
        var end;
        if (this.isHeadOmitted() && this.isTailOmitted()) {
            begin = this.currentPage - 2;
            end = this.currentPage + 2;
        }
        else if (this.isTailOmitted()) {
            begin = 1;
            end = this.currentPage + 2;
        }
        else if (this.isHeadOmitted()) {
            begin = this.currentPage - 2;
            end = this.totalPages;
        }
        else {
            begin = 1;
            end = this.totalPages;
        }
        for (var i = begin; i <= end; i += 1) {
            pageList.push(i);
        }
        return pageList;
    };
    ListPaginateComponent.prototype.created = function () {
        this.paginatedData(this.invoices);
    };
    ListPaginateComponent.prototype.onInvoicesChange = function () {
        this.currentPage = 1;
        this.paginatedData(this.invoices);
    };
    ListPaginateComponent.prototype.paginatedData = function (data) {
        var start = (this.currentPage - 1) * this.itemsPerPage;
        var end = start + this.itemsPerPage;
        return data.slice(start, end);
    };
    Object.defineProperty(ListPaginateComponent.prototype, "totalPages", {
        get: function () {
            return Math.ceil(this.invoices.length / this.itemsPerPage);
        },
        enumerable: false,
        configurable: true
    });
    ListPaginateComponent.prototype.onPageClick = function (pageNumber) {
        this.currentPage = pageNumber;
        this.paginatedData(this.invoices);
    };
    ListPaginateComponent.prototype.onPrevPageClick = function () {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.paginatedData(this.invoices);
        }
    };
    ListPaginateComponent.prototype.onNextPageClick = function () {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.paginatedData(this.invoices);
        }
    };
    __decorate([
        Prop({ type: Array, required: true })
    ], ListPaginateComponent.prototype, "invoices", void 0);
    __decorate([
        Prop({ type: Number, default: 20 })
    ], ListPaginateComponent.prototype, "itemsPerPage", void 0);
    __decorate([
        Watch('invoices')
    ], ListPaginateComponent.prototype, "onInvoicesChange", null);
    __decorate([
        Emit('updateData')
    ], ListPaginateComponent.prototype, "paginatedData", null);
    ListPaginateComponent = __decorate([
        Component
    ], ListPaginateComponent);
    return ListPaginateComponent;
}(Vue));
export default ListPaginateComponent;
