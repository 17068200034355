<template>
  
  <li class="list-group-item">
    <div class="form-row">
      <div class="form-group col-6">
        <label >最小金額 :</label>
        <input 
          :name="'min_price_'+commission_range.key"
          type="text" 
          class="form-control" 
          v-model="min_price"
          v-validate="'required|min_value:0|max_value:99999999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('min_price_'+commission_range.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('min_price_'+commission_range.key)" class="invalid-feedback">
          {{ errors.first('min_price_'+commission_range.key) }}
        </p>
      </div>
      <div class="form-group col-6">
        <label >最大金額 :</label>
        <input 
          :name="'max_price_'+commission_range.key"
          type="text" 
          class="form-control" 
          v-model="max_price"
          v-validate="'required|min_value:0|max_value:99999999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('max_price_'+commission_range.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('max_price_'+commission_range.key)" class="invalid-feedback">
          {{ errors.first('max_price_'+commission_range.key) }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'CommissionRangeInput',
    props: [
      'commission_range',
    ],
    watch: {
      min_price: function(a, b) { watchCommissionRange(this, 'min_price') },
      max_price: function(a, b) { watchCommissionRange(this, 'max_price') },
    },
    computed: {
      min_price: {
        get() { return this.commission_range.min_price },
        set(value) { setCommissionRange(this, 'min_price', value) },
      },
      max_price: {
        get() { return this.commission_range.max_price },
        set(value) { setCommissionRange(this, 'max_price', value) },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        validate_item: 'この項目',
      }
    },
  };
  function setCommissionRange (that, attr, value) {
    that.$store.commit('updateCommissionRange', {
      'key' : that.commission_range.key,
      'attr' : attr,
      'value' : value,
    });
  }
  function watchCommissionRange (that, attr) {
    that.$validator.validate().then(result => {
      that.$store.commit('updateCommissionRangeIsValid', {
        'key' : that.commission_range.key,
        'attr' : attr,
        'is_valid': result
      });
    });
  }
</script>
