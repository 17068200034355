<template>
  <div>
    <div v-if="isDealNotifiedFactoring">
      <div class="mt-2 mb-2">
        <form
          target="_blank"
          method="post"
          :action="`/xpointDocument/CreateDealCreditDocumentForNotifitedFactoringDeal/${deal.ID}`">
          <button class="btn btn-link p-0">
            <span
              class="cui-external-link"
              aria-hidden="true" />&nbsp;決裁書作成
          </button>
          <span v-if="approvalDocumentApprovedDate">(承認日付：{{ approvalDocumentApprovedDate }})</span>
        </form>
      </div>
    </div>
    <label for="">審査結果：</label>
    <select
      :name="'judge_result'"
      class="form-control"
      :class="{'is-invalid': errors.has('judge_result')}"
      v-model="judge_result"
      v-validate="'required|excluded:0'"
      :disabled="!isActive(process)">
      <option
        v-for="judge_result in judge_results"
        :value="judge_result.id"
        :key="judge_result.id">
        {{ judge_result.name }}
      </option>
    </select>
    <p
      v-if="errors.has('judge_result')"
      class="invalid-feedback">
      審査結果を選択して下さい
    </p>
    <br>
    <div v-if="judge_result === 8">
      <p>申請金額の提案（円）:</p>
      <input
        type="number"
        v-model="judge_proposal_application_amount"
        step="1"
        min="0"
        name="judge_proposal_application_amount"
        class="form-control d-inline-block"
        autocomplete="off">
    </div>
    <div v-if="judge_result === 2">
      <label>謝絶理由：</label>
      <textarea
        class="form-control"
        style="height:100px;"
        v-model="judge_decline_reason"
        v-validate="'required'"
        key="judge_decline_reason"
        :class="{'is-invalid': errors.has('judge_decline_reason')}"
        :name="'judge_decline_reason'"
        :disabled="!isActive(process)" />
      <p v-if="errors.has('judge_decline_reason')" class="invalid-feedback">
        謝絶理由を記入して下さい
      </p>
      <br>
      <label>謝絶メール・プレビュー：</label>
      <pre class="bg-secondary p-3 border border-dark rounded">
{{ fullName }} 様

この度はフリーナンス即日払いにお申し込みいただきまして、
誠にありがとうございました。

お客様のお申込みを審査させていただきました結果、
以下の理由にて今回はお取引を見送らせていただくこととなりました。

-------------------------------------------

{{ judge_decline_reason }}

-------------------------------------------

お客様のご要望にお応えできませんでしたことにお詫びを申し上げます。

なお恐れ入りますが審査の基準については個別にお答えすることは
致しかねますのでご了承ください。

-------------------------------------------

クライアント様からの入金がフリーナンス口座にあった場合、
ご登録のメインバンクへ下記のスケジュールでお振替をいたします。

・メインバンクがGMOあおぞらネット銀行の場合：平日毎日
・メインバンクがそれ以外の場合：毎週金曜日と毎月第一営業日、最終営業日

なお入金がありますと与信スコアのUPに加え、次回お申し込みの際に
保証会社の審査に役立ちます。

-------------------------------------------

フリーナンス即日払いをご利用いただきありがとうございました。
      </pre>
    </div>
    <div v-if="additionalEvidenceMailDisplayable()">

      <div class="form-check mb-3">
        <label class="form-check-label">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="judge_additional_evidence_email"
            :disabled="!isActive(process) || process === 'AdditionalEvidenceRequire'"
            >
          <span>メール連絡を行う</span>
        </label>
      </div>

      <template v-if="judge_additional_evidence_email">
        <label>証憑追加取り寄せ理由：</label>
        <textarea
          class="form-control"
          style="height:100px;"
          v-model="judge_additional_evidence_reason"
          v-validate="'required'"
          key="judge_additional_evidence_reason"
          :class="{'is-invalid': errors.has('judge_additional_evidence_reason')}"
          :name="'judge_additional_evidence_reason'"
          :disabled="!isActive(process) || process === 'AdditionalEvidenceRequire'" />
        <p v-if="errors.has('judge_additional_evidence_reason')" class="invalid-feedback">
          証憑追加取り寄せ理由を記入して下さい
        </p>
        <br>
        <label>証憑追加取り寄せメール・プレビュー：</label>
        <pre class="bg-secondary p-3 border border-dark rounded">
{{ fullName }} 様

この度はフリーナンス即日払いにお申し込みいただきまして、
誠にありがとうございます。

お客様のお申込みを審査させていただきました結果、
下記の通り、追加で確認させていただきたいことがございます。

-------------------------------------------

{{ judge_additional_evidence_reason }}

-------------------------------------------

◼️修正手順

1. 請求書一覧 ({{ service_url }}/invoices) から
   該当の請求書の［修正］をクリックしてください。
2. 請求書詳細にて、請求書の修正や新しいエビデンスの提出をお願いします。
3.［保存して即日払い申込み］ボタンを押してください。
4. 申込み金額を修正または確認後、［即日払い金額確認］をクリックしてください。
5. 即日払いの諸条件と利用規約に同意後に、改めて即日払いをお申込みください。

なお、お申込みが無い場合は、3営業日後をめどに謝絶させていただきます。

◼️謝絶後の入金について
クライアント様からの入金がフリーナンス口座にあった場合、
ご登録のメインバンクへ下記のスケジュールでお振替をいたします。

・メインバンクがGMOあおぞらネット銀行の場合：平日毎日
・メインバンクがそれ以外の場合：毎週金曜日と毎月第一営業日、最終営業日

なお入金がありますと与信スコアのUPに加え、次回お申し込みの際に
保証会社の審査に役立ちます。

-------------------------------------------

フリーナンス即日払いのご利用ありがとうございます。
今後とも、どうぞよろしくお願い致します。
        </pre>
      </template>
    </div>
    <label class="mt-2">備考：</label>
    <textarea
      class="form-control"
      style="height:100px;"
      v-model="judge_note"
      :disabled="!isActive(process)" />
    <button
      class="btn btn-secondary btn-block mt-2"
      :disabled="!isActive(process) || disable_btn"
      @click="onClickJudged">
      保存
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'JudgePart',
  computed: {
    ...mapGetters([
      'isActive',
      'isDealNotifiedFactoring',
      'approvalDocumentApprovedDate',
      'isXpointAvailable',
      'fullName'
    ])
  },
  props: {
    process: String,
    judgeResults: Array,
    judgeResult: Number
  },
  data() {
    return {
      service_url: this.$store.getters.serviceUrl,
      judge_additional_evidence_reason: this.$store.getters.judgeAdditionalEvidenceReason,
      judge_additional_evidence_email: this.$store.getters.judgeAdditionalEvidenceEmail,
      judge_decline_reason: this.$store.getters.judgeDeclineReason,
      judge_result: this.judgeResult,
      judge_results: this.judgeResults,
      judge_note: this.$store.getters.judgeNote,
      judge_proposal_application_amount: this.$store.getters.currentProposalApplicationAmount,
      disable_btn: false,
      deal: this.$store.state.deal
    };
  },

  methods: {
    onClickJudged() {
      this.$validator.validate().then((result) => {
        const dueDate = moment.tz(this.deal.DueDate, 'Asia/Tokyo').format('YYYY-MM-DD');
        if (result) {
          let isValid = false;
          const message = this.judge_results.find(option => option.id === this.judge_result).comfirmMessage;
          if (confirm(message)) {
            isValid = true;
          }
          if (isValid) {
            const data = {
              process: this.process,
              method: 'judged',
              judge_result: this.judge_result,
              judge_decline_reason: this.judge_decline_reason,
              judge_additional_evidence_reason: this.judge_additional_evidence_reason,
              judge_additional_evidence_email: this.judge_additional_evidence_email,
              judge_note: this.judge_note,
              judge_proposal_application_amount: parseInt(this.judge_proposal_application_amount) || 0,
              updated_at: this.deal.UpdatedAt,
              checked_company_name: this.deal.CheckedCompanyName,
              checked_due_date: this.deal.CheckedDueDate,
              checked_incoming_amount: this.deal.CheckedIncomingAmount,
              checked_bank_name: this.deal.CheckedBankName,
              checked_bank_code: this.deal.CheckedBankCode,
              checked_branch_name: this.deal.CheckedBranchName,
              checked_branch_code: this.deal.CheckedBranchCode,
              checked_account_name: this.deal.CheckedAccountName,
              checked_account_number: this.deal.CheckedAccountNumber,
              checked_public_recepient_evidence: this.deal.CheckedPublicRecepientEvidence,
              checked_pass_book_business_evidence: this.deal.CheckedPassBookBusinessEvidence,
              checked_pass_book_company_evidence: this.deal.CheckedPassBookCompanyEvidence,
              checked_fin_statement_evidence: this.deal.CheckedFinStatementEvidence,
              checked_additional_evidence: this.deal.CheckedAdditionalEvidence,
              master_deal_industry_id: parseInt(this.deal.MasterDealIndustryID) || 0,
              due_date: dueDate,
            };
            this.disable_btn = true;
            this.$store.dispatch('updateProcess', { data, errCallback: () => this.disable_btn = false });
          }
        }
      });
    },
    additionalEvidenceMailDisplayable() {
      return (this.process === 'AdditionalEvidenceRequire' && this.judge_result === 0) || this.judge_result === 3;
    }
  }
};
</script>
