<template>
  <div class="form-group row mt-2">
    <label class="col-7 col-form-label text-right">{{user_score.title}} :</label>
    <div class="col-5">
      <input 
        :name="input_name"
        type="text" 
        class="form-control" 
        v-model="score"
        v-validate="'required|min_value:-999|max_value:999'"
        :data-vv-as="validate_title"
        :class="{'is-invalid': errors.has(input_name)}"
        :disabled="disabled"
        />
      <p v-if="errors.has(input_name)" class="invalid-feedback">
        {{ errors.first(input_name) }}
      </p>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'UserScoreInput',
    props: [
      'user_score',
    ],
    watch: {
      score: function(old_val, new_val) {
        this.$validator.validate().then(result => {
          this.$store.commit('updateUserScoreIsValid', {
            'user_score' : this.user_score,
            'is_valid': result,
            'attr': 'score',
          });
        });
      }
    },
    computed: {
      score: {
        get () {
          return this.user_score.score;
        },
        set (value) {
          value = parseInt(value, 10);
          if (isNaN(value)) {
            return;
          }
          this.$store.commit('updateUserScore', {
            'user_score' : this.user_score,
            'value' : value,
          });
        }
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        input_name: "score" + this.user_score.master_user_evidence_type_id,
        validate_title: '「' + this.user_score.title + '」 ',
      }
    },
  };
</script>
