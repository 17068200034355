export default (function () {
    Array.from(document.querySelectorAll('a.external-link'), function (a) {
        a.addEventListener('click', function (e) {
            if (!confirm('ユーザーから提供された外部URLを開こうとしています。\nよろしいですか？')) {
                e.preventDefault();
            }
        });
        return false;
    });
});
