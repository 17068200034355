import moment from 'moment'

const ON_GMOPG_CHECK = 1;
const ON_CHECK_INVOICE = 2;
const ON_OFFERING = 3;
const ON_OFFER_APPROVING = 4;
const ON_PAYING = 5;
const ON_WAITING_INCOMING = 6;
const ON_COLLECTING = 7;
const ON_UPDATING_CREDIT = 8;
const ON_DONE = 9;
const ON_DONE_LATE = 10;
const ON_BAD_DEBT = 11;
const ON_DECLINED = 12;
const ON_ADDITIONAL_EVIDENCE_REQUIRE = 13;
const ON_COLLECTED_PART = 14;
const ON_RECEPTION = 15;
const ON_ASSURANCE_CHECK = 16;
const ON_WITHDRAWN_BY_USER = 19;
const ON_CORPORATION_CHECK= 20;
const ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT= 21;
const ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT = 22;
const ON_DONE_WITH_APPLY_INSURANCE = 23;

const ON_PG_PRE_JUDGE_REQUEST    = 1;
const ON_PG_NO_JUDGE_REQUESTED   = 2;
const ON_PG_JUDGE_REQUESTING     = 3;
const ON_PG_JUDGE_REQUESTED      = 4;
const ON_PG_INSURABLE            = 5;
const ON_PG_UNINSURABLE          = 6;
const ON_PG_INSURANCE_REQUESTING = 7;
const ON_PG_INSURANCE_REQUESTED  = 8;

const JUDGED_ON_CHECK_INVOICE = 1;
const JUDGED_ON_ADDITIONAL_EVIDENCE_REQUIRE = 2;
const JUDGED_ON_ASSURANCE_CHECK = 3;

const DEFAULT_SELECT_OPTION = 0;
const APPROVED_SELECT_OPTION = 1;
const DECLINED_SELECT_OPTION = 2;
const TO_ADDITIONAL_EVIDENCE_REQUIRE_SELECT_OPTION = 3;
const TO_INVOICE_EVIDENCE_CHECK_SELECT_OPTION = 6;
const TO_ASSURANCE_CHECK_SELECT_OPTION = 7;
const ALTER_APPLICATION_AMOUNT = 8;

const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS = 10;
const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_LATE = 11;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_SUCCESS = 14;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_LATE = 15;

const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_SUCCESS = 2;
const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_LATE = 3;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_SUCCESS = 6;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_LATE = 7;

const DEFAULT_CORPORATION_JUDGE_DECLINE_REASON = 'ともだち企業により謝絶されたため';

export default {


  // Returns users list for selectbox options
  users: (state) => {
    const users = [{
      id: 0,
      name: '-',
    }];
    state.admin_users.forEach((user) => {
      users.push({
        id: user.ID,
        name: user.Name,
      });
    });
    return users;
  },

  // Return AdminUser.ID who is escalated on the process
  escalatedUser: (state) => (process) => {
    if (process === 'GMOPGCheck') {
      return state.deal.GmopgEscalatedAdminUserID;
    } else if (process === 'InvoiceEvidenceCheck') {
      return state.deal.InvoiceEvidenceCheckEscalatedAdminUserID;
    }
    return 0;
  },

  // Return escalation note of the 'process'
  escalationNote: (state) => (process) => {
    if (process === 'GMOPGCheck') {
      return state.deal.GmopgCheckNote;
    } else if (process === 'InvoiceEvidenceCheck') {
      return state.deal.InvoiceEvidenceCheckNote;
    }
    return "";
  },

  // Return escalation note of the 'process'
  escalationDone: (state, getters) => (process) => {
    let status_id = state.deal.MasterDealStatusID;
    if (process === 'GMOPGCheck') {
      return status_id > ON_GMOPG_CHECK && getters.escalatedUser != 0;
    } else if (process === 'InvoiceEvidenceCheck') {
      return status_id > ON_CHECK_INVOICE && getters.escalatedUser != 0;
    }
    return false;
  },

  // Return escalation approved or not of the 'process'
  escalationApprovedOrRejected: (state, getters) => (process) => {
    return "";
  },

  // Is the prcess escalated or not
  isEscalated: (state) => (process) => {
    if (process === 'GMOPGCheck') {
      return state.deal.GmopgEscalated;
    } else if (process === 'InvoiceEvidenceCheck') {
      return state.deal.InvoiceEvidenceCheckEscalated;
    }
    return false;
  },

  // Is the process active or not
  isActive: state => (process) => {
    const status_id = state.deal.MasterDealStatusID;
    const pg_insurance_status_id = state.deal.MasterPgInsuranceStatusID;
    switch (process) {
    case 'GMOPGCheck':
      return (status_id !== ON_WITHDRAWN_BY_USER) && (pg_insurance_status_id === ON_PG_PRE_JUDGE_REQUEST || pg_insurance_status_id === ON_PG_JUDGE_REQUESTED);
    case 'Reception':
      return status_id === ON_RECEPTION || status_id === ON_CORPORATION_CHECK;
    case 'InvoiceEvidenceCheck':
      return status_id === ON_CHECK_INVOICE
        || status_id === ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT
        || status_id === ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT;
    case 'AdditionalEvidenceRequire':
      return status_id === ON_ADDITIONAL_EVIDENCE_REQUIRE;
    case 'AssuranceCheck':
      return status_id === ON_ASSURANCE_CHECK;
    case 'ConditionCheck':
      return status_id === ON_OFFERING;
    case 'WatiingForApproval':
      return status_id === ON_OFFER_APPROVING;
    case 'WatingForPayment':
      return status_id === ON_PAYING;
    case 'Collection':
      return status_id === ON_WAITING_INCOMING || status_id === ON_COLLECTING || status_id === ON_COLLECTED_PART;
    case 'UpdatingCredit':
      return status_id === ON_UPDATING_CREDIT;
    case 'Complete':
      return status_id === ON_DONE
        || status_id === ON_DONE_LATE
        || status_id === ON_DONE_WITH_APPLY_INSURANCE
        || status_id === ON_BAD_DEBT
        || status_id === ON_DECLINED;
    default:
      return false;
    }
  },

  // Is the Invoice Content CheckBox active or not
  isContentCheckActive: (state) => {
    const judgingDealStatusID = [ON_CHECK_INVOICE, ON_ADDITIONAL_EVIDENCE_REQUIRE, ON_ASSURANCE_CHECK];
    return judgingDealStatusID.includes(state.deal.MasterDealStatusID);
  },

  // Return who did check the process
  checkedBy: state => (process) => {
    let id;
    switch (process) {
    case 'GMOPGCheck':
      id = state.deal.PgInsuranceStatusUpdatingAdminUserID;
      break;
    case 'Reception':
      id = state.deal.JudgeStartAdminUserID;
      break;
    case 'InvoiceEvidenceCheck':
    case 'AdditionalEvidenceRequire':
    case 'AssuranceCheck':
      id = state.deal.JudgeAdminUserID;
      break;
    case 'UpdatingCredit':
      id = state.deal.UpdatedCreditAdminUserID;
      break;
    case 'WatingForPayment':
      id = state.deal.Outcoming.AdminUserID;
      break;
    case 'Collection':
      id = state.deal.BadDebtJudgeAdminUserID;
      break;
    default:
      id = 0;
    }

    const found = state.admin_users.find(u => u.ID === id);
    if (found) {
      return found.Name;
    }
    return '';
  },

  // Return when did the check has been done
  checkedDate: state => (process) => {
    moment.locale('ja');
    const frmt = 'YYYY-MM-DD HH:mm:ss'; // 2018-8-27 04:48:00
    let d;
    switch (process) {
    case 'GMOPGCheck':
      d = new Date(state.deal.PgInsuranceStatusUpdatedAt);
      break;
    case 'Reception':
      d = new Date(state.deal.ReceptionDone);
      break;
    case 'InvoiceEvidenceCheck':
    case 'AssuranceCheck':
    case 'AdditionalEvidenceRequire':
      d = new Date(state.deal.JudgeDone);
      break;
    case 'UpdatingCredit':
      d = new Date(state.deal.UpdatedCreditDone);
      break;
    case 'WatingForPayment':
      d = new Date(state.deal.CreatedOutcomingDate);
      break;
    case 'Collection':
      d = new Date(state.deal.BadDebtJudgeDone);
      break;
    default:
      return '-';
    }
    return d.getFullYear() === 1 ? '-' : moment(d).format(frmt);
  },

  isJudgedByCorporation: (state) => {
    const d = moment(state.deal.CorporationJudgeDone);
    return d.format('YYYY-MM-DD') !== '0001-01-01';
  },

  judgeDeclineReason: (state, getters) => {
    let reason = state.deal.JudgeDeclineReason;
    if (!reason && getters.isJudgedByCorporation && state.deal.MasterDealStatusID == ON_DECLINED)
      reason = DEFAULT_CORPORATION_JUDGE_DECLINE_REASON;
    return reason;
  },

  corporationJudgeDeclineReason: (state) => {
    return state.deal.CorporationJudgeDeclineReason;
  },

  // Return deal.JudgeNote
  judgeNote: (state) => {
    return state.deal.JudgeNote;
  },

  // Return VirtualAccount string
  previewVirtualAccount: (state, getters) => {
    let u = state.user;
    let result = "銀行名(コード): ｼﾞ-ｴﾑｵ-ｱｵｿﾞﾗﾈﾂﾄ (310)\n"
      + "支店名(コード): " + u.MainVirtualBankAccount.BranchName   + " (" + u.MainVirtualBankAccount.BranchCode + ")\n"
      + "口座名義: " + u.MainVirtualBankAccount.AccountName + "\n"
      + "口座番号: " + u.MainVirtualBankAccount.AccountNumber;
    return result;
  },

  isDealNotifiedFactoring: state => state.deal.NotifiedFactoringID > 0,
  isXpointAvailable: state => state.isXpointAvailable,
  approvalDocumentApprovedDate: (state) => {
    if (!state.deal.ApprovalDocumentApprovedAt) {
      return null;
    }
    moment.locale('ja');
    const d = new Date(state.deal.ApprovalDocumentApprovedAt);
    return d.getFullYear() === 1 ? null : moment(d).format('YYYY/MM/DD HH:mm:ss');
  },
  // Return NotifiedFactoring.AdminNote
  noteForNotifiedFactoring: (state, getters) => {
    if (state.deal.NotifiedFactoringID > 0) {
      return state.deal.NotifiedFactoring.NoteAdmin;
    }
    return '';
  },

  dealResult: (state) => {
    let status_id = state.deal.MasterDealStatusID;
    if (status_id === ON_DONE) {
      return "正常終了";
    } else if (status_id === ON_BAD_DEBT) {
      return "不良債権";
    } else if (status_id === ON_DECLINED) {
      return "謝絶";
    } else if (status_id === ON_DONE_LATE) {
      return "遅延完了";
    } else if (status_id === ON_DONE_WITH_APPLY_INSURANCE) {
      return "保険適用";
    }
    return "進行中";
  },

  outcomingDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD HH:mm:ss';
    let d;
    d = new Date(state.deal.CreatedOutcomingDate);
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  incomingDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD';
    let d;
    d = new Date(state.deal.DueDate);
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  outcomingId : (state) => {
    return state.deal.OutcomingID;
  },

  checkedAsBadDebt : (state) => {
    return state.deal.MasterDealStatusID === ON_BAD_DEBT ;
  },

  noteOnCollection : (state) => {
    return state.deal.NoteOnCollection;
  },

  updatingCreditNote : (state) => {
    return state.deal.NoteOnUpdatingCredit;
  },

  userResponsibleForLate: (state) => {
    switch (state.user_evidence_type_id) {
    case MASTER_USER_EVIDENCE_TYPE_LT_30MAN_LATE:
    case MASTER_USER_EVIDENCE_TYPE_GT_30MAN_LATE:
      return true;
    case MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS:
    case MASTER_USER_EVIDENCE_TYPE_GT_30MAN_SUCCESS:
      return false;
    default:
      return null;
    }
  },

  corporationResponsibleForLate: (state) => {
    switch (state.corp_evidence_type_id) {
    case MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_LATE:
    case MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_LATE:
      return true;
    case MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_SUCCESS:
    case MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_SUCCESS:
      return false;
    default:
      return null;
    }
  },

  createdDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD HH:mm:ss';
    let d;
    d = new Date(state.deal.CreatedAt);
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  sendingDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD';
    let d;
    d = new Date(state.deal.SendingDate);
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  fullName : (state) => {
    return state.user.FullName;
  },

  currentProposalApplicationAmount: (state) => {
    return (state.deal.MasterDealStatusID == ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT 
      || state.deal.MasterDealStatusID == ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT) ? state.current_proposal_application_amount : '';
  },

  judgingProcessName: (state) => {
    let name = '-';
    const judgedStepID = state.deal.MasterDealJudgedStepID;
    const dealStatusID = state.deal.MasterDealStatusID;
    if (judgedStepID === JUDGED_ON_CHECK_INVOICE
      || dealStatusID === ON_CHECK_INVOICE
      || dealStatusID === ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT
      || dealStatusID === ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT) {
      name = 'InvoiceEvidenceCheck';
    } else if (judgedStepID === JUDGED_ON_ADDITIONAL_EVIDENCE_REQUIRE || dealStatusID === ON_ADDITIONAL_EVIDENCE_REQUIRE) {
      name = 'AdditionalEvidenceRequire';
    } else if (judgedStepID === JUDGED_ON_ASSURANCE_CHECK || dealStatusID === ON_ASSURANCE_CHECK) {
      name = 'AssuranceCheck';
    }
    return name;
  },

  judgingProcessTitle: (state) => {
    let title = '';
    const judgedStepID = state.deal.MasterDealJudgedStepID;
    const dealStatusID = state.deal.MasterDealStatusID;
    if (dealStatusID === ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT 
      || dealStatusID === ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT) {
      title = '（申請金額変更）';
    } else if (judgedStepID === JUDGED_ON_CHECK_INVOICE || dealStatusID === ON_CHECK_INVOICE) {
      title = '（申込内容確認）';
    } else if (judgedStepID === JUDGED_ON_ADDITIONAL_EVIDENCE_REQUIRE || dealStatusID === ON_ADDITIONAL_EVIDENCE_REQUIRE) {
      title = '（証憑追加取り寄せ）';
    } else if (judgedStepID === JUDGED_ON_ASSURANCE_CHECK || dealStatusID === ON_ASSURANCE_CHECK) {
      title = '（保証確認）';
    }
    return title;
  },

  judgedProcessResult: (state) => {
    switch (state.deal.MasterDealStatusID) {
    case ON_DECLINED:
      return DECLINED_SELECT_OPTION;
    case ON_RECEPTION:
    case ON_CORPORATION_CHECK:
    case ON_CHECK_INVOICE:
    case ON_ADDITIONAL_EVIDENCE_REQUIRE:
    case ON_ASSURANCE_CHECK:
      return DEFAULT_SELECT_OPTION;
    case ON_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT:
    case ON_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT:
      return ALTER_APPLICATION_AMOUNT;
    default:
      return APPROVED_SELECT_OPTION;
    }
  },

  lastCollection: (state) => {
    return state.deal.Collections[state.deal.Collections.length - 1];
  },
  judgeAdditionalEvidenceReason: (state) => {
    return state.deal.JudgeAdditionalEvidenceReason;
  },
  serviceUrl: (state) => {
    return `https://${state.user.MasterSkin.Domain}`;
  },
  judgeAdditionalEvidenceEmail: (state) => {
    return state.deal.JudgeAdditionalEvidenceEmail;
  },

  isUsedFrncAccount: (state) => {
    return state.deal.InvoiceVersion.IsUsedFrncAccount;
  },

  corporationJudgeDone: (state) => {
    return moment(state.deal.CorporationJudgeDone).format('YYYY-MM-DD') !== '0001-01-01' &&
      state.deal.MasterDealStatusID !== ON_DECLINED ?
      moment(state.deal.CorporationJudgeDone).format('YYYY-MM-DD HH:mm:ss') : '';
  },

  isAutoApproved : (state) => {
    return state.deal.IsAutoApproved;
  },
};
