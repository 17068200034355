import Vue from 'vue';
import VeeValidate from 'vee-validate';
import App from './components/App.vue';
import mainStore from './store/main';
Vue.use(VeeValidate);
export default function page() {
    var app = new Vue({
        el: '#collectionReminder',
        components: {
            App: App
        },
        created: function () {
            mainStore.loadData();
        }
    });
}
