<template>
  <div class="form-inline">
    有効期限：
    <select 
      data-vv-scope="expiring_date"
      :name="'select_year'"
      class="form-control" 
      :class="{'is-invalid': errors.has('expiring_date.select_year')}"
      v-model="year" 
      v-validate="'required'"
      :disabled="!active"
    >
      <option value="">-</option>
      <option 
        v-for="year in years" 
        :value="year" 
        :key="year"
      >{{ year }}</option>
    </select>
    -
    <select 
      data-vv-scope="expiring_date"
      :name="'select_month'"
      class="form-control" 
      :class="{'is-invalid': errors.has('expiring_date.select_month')}"
      v-model="month" 
      v-validate="'required'"
      :disabled="!active"
    >
      <option value="">-</option>
      <option 
        v-for="month in months" 
        :value="month" 
        :key="month"
      >{{ month }}</option>
    </select>
    -
    <select 
      data-vv-scope="expiring_date"
      :name="'select_day'"
      class="form-control" 
      :class="{'is-invalid': errors.has('expiring_date.select_day')}"
      v-model="day" 
      v-validate="'required'"
      :disabled="!active"
    >
      <option value="">-</option>
      <option 
        v-for="day in days" 
        :value="day" 
        :key="day"
      >{{ day }}</option>
    </select>
    <div v-if="errors.has('expiring_date.*')" class="invalid-feedback">
      有効期限を入力して下さい
    </div>
  </div>
</template>

<script>

  import moment from 'moment';

  export default {
    name: 'RegistrationCheck',
    props: [
      'expirationDate',
      'active',
    ],
    components: {
    },
    watch: {
      year: function (newV, oldV) {
        this.updateValue();
      },
      month: function (newV, oldV) {
        this.updateValue();
      },
      day: function (newV, oldV) {
        this.updateValue();
      },
    },
    methods: {
      updateValue () {
        if (this.year && this.month && this.day) {
          moment.locale('ja');
          let d = moment(this.year+"/"+this.month+"/"+this.day);
          if (d.format('YYYY-MM-DD') !== "0001-01-01") {
            this.$emit('change', d.format('YYYY-MM-DD'));
          }
        }
      },
      
      certExpiring (exp) {
        let d = new Date(this.expirationDate);
        if (!this.expirationDate || d.getFullYear() === 1) {
          return "";
        }
        moment.locale('ja');
        if (exp === 'year') {
          return parseInt(moment(d).format('YYYY'), 10);
        } else if (exp === 'month') {
          return parseInt(moment(d).format('MM'), 10);
        } else if (exp === 'day') {
          return parseInt(moment(d).format('DD'), 10);
        }
        return "";
      },
      
      // Return items for calendar-use selectboxies
      calendarList (exp) {
        let now = new Date();
        if (exp === 'year') {
          let years = [];
          for (let i = -5;i < 20;i++) {
            years.push(now.getFullYear() + i);
          }
          return years;
        } else if (exp === 'month') {
          let months = [];
          for (let i = 0;i < 12;i++) {
            months.push(i+1);
          }
          return months;
        } else if (exp === 'day') {
          let days = [];
          for (let i = 0;i < 31;i++) {
            days.push(i+1);
          }
          return days;
        }
        return [];
      },
    },
    data() {
      return {
        'years' : this.calendarList('year'),
        'months': this.calendarList('month'),
        'days'  : this.calendarList('day'),
        'year'  : this.certExpiring('year'),
        'month' : this.certExpiring('month'),
        'day'   : this.certExpiring('day'),
      };
    },
  };
</script>
