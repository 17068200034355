import $                    from 'jquery';
import UserScore            from './UserScore'
import CorporationScore     from './CorporationScore'
import DealRank             from './DealRank'
import DealClustering       from './DealClustering'
import Commission           from './Commission'
import CommissionRange      from './CommissionRange'
import CommissionClustering from './CommissionClustering'

var mutations = $.extend({
  // Init RankingVersion
  initRankingVersion(state, payload) {
    state.ranking_version_id = payload.id;
    state.ranking_version.id = payload.id;
    state.ranking_version.note = payload.ranking_version.Note;
    state.ranking_version.master_ranking_version_status_id = payload.ranking_version.MasterRankingVersionStatus.ID;
    state.ranking_version.master_ranking_version_status = payload.ranking_version.MasterRankingVersionStatus.Title;
    state.ranking_version.frnc_account_non_use_score = payload.ranking_version.FrncAccountNonUseScore;
    if (state.ranking_version.master_ranking_version_status_id != 1) { //  1 = DRAFT
      state.disabled = true;
    }
    state.master_user_evidence_types = payload.master_user_evidence_types;
    state.master_corporation_evidence_types = payload.master_corporation_evidence_types;
  },
  
  // Set RankingVersion value
  setRankingVersion(state, payload) {
    for (let p in payload) {
      state.ranking_version[p] = payload[p];
    }
  },
}, 
  UserScore, 
  CorporationScore,
  DealRank,
  DealClustering,
  Commission,
  CommissionRange,
  CommissionClustering
);
export default mutations;
