<template>
  <div class="card mb-1" :class="{ 'border-warning' : escalated }">
    <ToggleHeader 
      :name="no + '. 追加資料アップロード待ち'"
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-1 py-3">
        <ul class="m-0 pl-4">
          <li v-if="waitingAdditionalAccountNameFile">氏名と異なるメインバンクの口座名義を証明する資料の登録を待っています</li>
          <li v-if="waitingAdditionalAnshinAccountFile">メインバンクと異なるあんしん口座の口座名義を証明する資料の登録を待っています</li>
        </ul>
      </li>
      <li class="list-group-item ">
        <button class="btn btn-secondary w-100" :disabled="!active || disable_btn" @click="onClickSkip">スキップ</button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ToggleHeader from './parts/ToggleHeader.vue';

  export default {
    name: 'WaitingAdditionalFile',
    components: {
      ToggleHeader
    },
    props: {
      no: {
        type: Number,
        default: 1 
      }
    },
    computed: {
      ...mapGetters([
        'waitingAdditionalAccountNameFile',
        'waitingAdditionalAnshinAccountFile',
      ]),
    },
    methods: {
      onClickSkip () {
        if (confirm('ステータスを「2. お申し込み内容チェック」に変更してよろしいですか？')) {
          this.disable_btn = true;
          this.$store.dispatch('updateProcess', {
            'data' : {
              'process': this.process,
              'method': 'skip',
            },
          });
        }
      },
    },
    data() {
      const p = this.$options.name;
      const g = this.$store.getters;

      return {
        'process' :   p,
        'active' :    g.isActive(p),
        'escalated' : g.isEscalated(p),
        'disable_btn' : false,
      };
    },
  }
</script>
