<template>
  <div class="card mb-1" :class="{ 'border-warning' : isEscalated(process) }">
    <ToggleHeader
      :name="no + '. 審査 ' + process_title"
      :process="process"
      :target="'#collapseStep'+no"/>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item px-1 py-2">
        <div class="pt-0 px-2 pb-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-1">
              <p class="my-2 f16 font-weight-bold">請求内容チェック</p>
              <CheckListItem
                :disabled="!isContentCheckActive"
                :label="'会社名'" :correct="deal.ClientName" v-model="deal.CheckedCompanyName"
                :isMatched="examination_items.CheckedCompanyName"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive"
                :label="'振込期日'" :correct="dueDate" v-model="deal.CheckedDueDate"
                :isMatched="examination_items.CheckedDueDate"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive"
                :label="'入金予定額'" :correct="deal.CommaIncomingAmount + '円'" v-model="deal.CheckedIncomingAmount"
                :isMatched="examination_items.CheckedIncomingAmount"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
            </li>

            <li class="list-group-item px-1">
              <p class="my-2 f16 font-weight-bold">エビデンスチェック</p>
              <CheckListItem
                :disabled="!isContentCheckActive"
                :isCheckEvidence="true"
                :autoCheckEnabled="true"
                :isMatched="evidence_examination_items.CheckedPublicRecepientEvidence"
                :label="'住所確認が承認されていますか？'" v-model="deal.CheckedPublicRecepientEvidence" />
              <CheckListItem
                :disabled="!isContentCheckActive"
                :isCheckEvidence="true"
                :autoCheckEnabled="true"
                :isMatched="evidence_examination_items.CheckedPassBookBusinessEvidence"
                v-model="deal.CheckedPassBookBusinessEvidence"
                :label="'通帳確認（事業者としての活動履歴）が承認されていますか？'" />
              <CheckListItem
                :disabled="!isContentCheckActive"
                :isCheckEvidence="true"
                :autoCheckEnabled="true"
                :isMatched="evidence_examination_items.CheckedPassBookCompanyEvidence"
                :isLongContent="true"
                v-model="deal.CheckedPassBookCompanyEvidence"
                :label="'通帳確認（該当企業との取引履歴）が承認されていますか？'" />
              <CheckListItem
                v-if="user.Incorporated"
                :disabled="!isContentCheckActive"
                :isCheckEvidence="true"
                :autoCheckEnabled="true"
                :isMatched="evidence_examination_items.CheckedFinStatementEvidence"
                :label="'法人決算書が承認されていますか？'" v-model="deal.CheckedFinStatementEvidence" />
              <CheckListItem
                :disabled="!isContentCheckActive"
                :isCheckEvidence="true"
                :label="'追加エビデンスを確認しましたか？'" v-model="deal.CheckedAdditionalEvidence" />
              <template v-if="deal.Tax > 0">
                → または源泉徴収を引かず<span class="text-info b">{{ deal.CommaOriginalAmount }}円</span>か？
              </template>
            </li>

            <li class="list-group-item px-1">
              <p class="my-2 f16 font-weight-bold">振込先チェック</p>
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'銀行名'" :correct="'ｼﾞ-ｴﾑｵ-ｱｵｿﾞﾗﾈﾂﾄ'" v-model="deal.CheckedBankName"
                :isMatched="examination_items.CheckedBankName"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'銀行コード'" :correct="'310'" v-model="deal.CheckedBankCode"
                :isMatched="examination_items.CheckedBankCode"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'口座支店名'" :correct="user.MainVirtualBankAccount.BranchName" v-model="deal.CheckedBranchName"
                :isMatched="examination_items.CheckedBranchName"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'口座支店コード'" :correct="user.MainVirtualBankAccount.BranchCode" v-model="deal.CheckedBranchCode"
                :isMatched="examination_items.CheckedBranchCode"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID" />
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'口座番号'" :correct="user.MainVirtualBankAccount.AccountNumber" v-model="deal.CheckedAccountNumber"
                :isMatched="examination_items.CheckedAccountNumber"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID"
                :autoCheckEnabled="true" />
              <CheckListItem
                :disabled="!isContentCheckActive || !isUsedFrncAccount"
                :label="'口座名義'" :correct="user.MainVirtualBankAccount.AccountName" v-model="deal.CheckedAccountName"
                :isMatched="examination_items.CheckedAccountName"
                :masterLinkingServiceID="deal.InvoiceVersion.MasterLinkingServiceID"
                :autoCheckEnabled="true" />

              <template v-if="user.AppliedEkyc">
                <p class="mt-2 text-danger font-weight-bold">※このユーザーはeKYC方式による口座開設申込を行っております。与信タスク「公共料金領収書アップロード」などにより居住確認が取れていることを確認してください。</p>
              </template>
              <template v-if="user.IsOpluxHavingTempAccommodation">
                <p class="mt-2 text-danger font-weight-bold">※このユーザーは口座開設申込時に一時滞在先の住所を登録しています。与信タスク「公共料金領収書アップロード」などにより居住確認が取れていることを確認して下さい。</p>
              </template>
            </li>
            <li class="list-group-item px-1">
              <p class="my-2 f16 font-weight-bold">審査設定</p>
              <DueDateUpdate
                :deal="deal"
                :disabled="!isActive(process)" />
              <DealIndustrySelect
                :deal="deal"
                :disabled="!isActive(process)"
                :masterDealIndustries="masterDealIndustries"
                :masterDealIndustryDefaultId="masterDealIndustryDefaultId" />
            </li>

            <li class="list-group-item px-1">
              <RefTagList />
            </li>

            <li class="list-group-item px-1">
              <p class="my-2 f16 font-weight-bold">同一可能性ユーザーチェック</p>
              <div>
                <a v-bind:href="`/frauds/search?freenance_user_id=${user.ID}`" target="_blank" className="pl-3">確認する</a>
              </div>
            </li>
          </ul>
        </div>

        <div class="pt-0 px-2 pb-2" v-if="nfNote">
          <p class="my-2 font-weight-bold">三社間ファクタリング・プログラム備考</p>
          <pre class="bg-secondary p-2 border border-dark rounded" style="white-space:pre-wrap;" v-html="nfNote" />
        </div>
      </li>

      <li class="list-group-item p-3">
        <JudgePart
          :process="process"
          :judge-results="judge_results"
          :judge-result="judge_result" />
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';
  moment.locale('ja');

  import { mapGetters }   from 'vuex'
  import ToggleHeader     from '../../ApplicationView/components/parts/ToggleHeader.vue';
  import CheckListItem    from './CheckListItem.vue';
  import DueDateUpdate    from './DueDateUpdate.vue';
  import DealIndustrySelect    from './DealIndustrySelect.vue';
  import RefTagList    from './RefTagList.vue';
  import JudgePart from './JudgePart.vue';

  export default {
    name: 'Judging',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckListItem,
      DueDateUpdate,
      DealIndustrySelect,
      RefTagList,
      JudgePart
    },
    computed: {
      ...mapGetters([
        'isActive',
        'isContentCheckActive',
        'isEscalated',
        'previewVirtualAccount',
        'fullName',
        'isUsedFrncAccount'
      ])
    },

    data() {
      let deal = this.$store.state.deal,
        examinationItems = this.$store.state.examination_items,
        evidenceExamincationItems = this.$store.state.evidence_examination_items,
        dueDate = moment.tz(deal.DueDate, 'Asia/Tokyo').format('YYYY/MM/DD');

      const content = this.$store.getters.noteForNotifiedFactoring;
      const parser = new DOMParser();
      const dom = parser.parseFromString(`${content}`, 'text/html');
      const nfNote = dom.body ? dom.body.textContent : '';

      return {
        'process': this.$store.getters.judgingProcessName,
        'process_title': this.$store.getters.judgingProcessTitle,
        'judge_result'  : this.$store.getters.judgedProcessResult,
        'judge_results' : this.setSelectOptions(),
        'deal' : deal,
        'user' : this.$store.state.user,
        'dueDate' : dueDate,
        'disable_btn' : false,
        'examination_items': examinationItems,
        'evidence_examination_items': evidenceExamincationItems,
        'masterDealIndustries': this.$store.state.masterDealIndustries,
        'masterDealIndustryDefaultId': this.$store.state.masterDealIndustryDefaultId,
        'nfNote': nfNote
      };
    },

  methods: {
    setSelectOptions() {
      const selectOptions = [{
        id: 0,
        name: '-',
        comfirmMessage: ''
      }, {
        id: 1,
        name: '承認',
        comfirmMessage: '承認しても良いでしょうか？'
      }, {
        id: 2,
        name: '謝絶',
        comfirmMessage: '謝絶しても良いでしょうか？'
      }, {
        id: 3,
        name: '証憑追加取り寄せへ',
        comfirmMessage: '証憑追加取り寄せに変更しても良いでしょうか？'
      }, {
        id: 7,
        name: '保証確認へ',
        comfirmMessage: '保証確認に変更しても良いでしょうか？'
      }, {
        id: 6,
        name: '申込内容確認へ',
        comfirmMessage: '申込内容確認に変更しても良いでしょうか？'
      }, {
        id: 5,
        name: '担当者を自分に変更',
        comfirmMessage: '担当者を自分に変更しても良いでしょうか？'
      }, {
        id: 8,
        name: '申請金額変更',
        comfirmMessage: '申請金額を変更してユーザーに提案メールを送っても良いでしょうか？'
      }];
      const optionApproveOriginalApplicationAmount = {
        id: 9,
        name: '元の金額（' + this.$store.state.deal.CommaIncomingAmount + '円）のままで承認',
        comfirmMessage: '元の金額（' + this.$store.state.deal.CommaIncomingAmount + '円）のままで承認しても良いでしょうか？'
      };
      const removedOptionIDs = [];
      // 三者間ファクタリング取引はXpointで承認されてないと、「承認」選択肢を表示しない
      if (this.$store.getters.isXpointAvailable && this.$store.getters.isDealNotifiedFactoring && !this.$store.getters.approvalDocumentApprovedDate) {
        removedOptionIDs.push(1);
      }
      switch (this.$store.getters.judgingProcessName) {
      case 'InvoiceEvidenceCheck':
        removedOptionIDs.push(6);
        break;
      case 'AdditionalEvidenceRequire':
        removedOptionIDs.push(3);
        break;
      case 'AssuranceCheck':
        removedOptionIDs.push(7);
        break;
      default:
        return selectOptions;
      }
      // MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT
      if (this.$store.state.deal.MasterDealStatusID === 21) {
        removedOptionIDs.push(1, 3, 7);
      }
      // MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
      if (this.$store.state.deal.MasterDealStatusID === 22) {
        removedOptionIDs.push(1, 3, 7);
        selectOptions.push(optionApproveOriginalApplicationAmount);
      }

      removedOptionIDs.forEach((removedID) => {
        const index = selectOptions.map(x => x.id).indexOf(removedID);
        if (index !== -1) {
          selectOptions.splice(index, 1);
        }
      });
      return selectOptions;
    }
  }
};
</script>
