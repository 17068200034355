const fmt = new Intl.NumberFormat('ja-JP');

const MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT     = 1;
const MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT       = 19;
const MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW       = 20;
const MASTER_USER_EVIDENCE_TYPE_VIDEO_INTERVIEW     = 21;
const MASTER_USER_EVIDENCE_TYPE_TWITTER             = 2;
const MASTER_USER_EVIDENCE_TYPE_FACEBOOK            = 3;
const MASTER_USER_EVIDENCE_TYPE_GOOGLE              = 4;
const MASTER_USER_EVIDENCE_TYPE_MONEYTREE           = 5;
const MASTER_USER_EVIDENCE_TYPE_AUTO_INVOICE        = 6;
const MASTER_USER_EVIDENCE_TYPE_TAX_CERT            = 7;
const MASTER_USER_EVIDENCE_TYPE_FIN_STATEMENT       = 8;
const MASTER_USER_EVIDENCE_TYPE_PUBLIC_RECEPIENT    = 9;
const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS    = 10;
const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_LATE       = 11;
const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_INCOMPLETE = 12;
const MASTER_USER_EVIDENCE_TYPE_LT_30MAN_FAILED     = 13;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_SUCCESS    = 14;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_LATE       = 15;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_INCOMPLETE = 16;
const MASTER_USER_EVIDENCE_TYPE_GT_30MAN_FAILED     = 17;
const MASTER_USER_EVIDENCE_TYPE_PASSING_THROUGH     = 18;

const MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED          = 1;
const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_SUCCESS    = 2;
const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_LATE       = 3;
const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_INCOMPLETE = 4;
const MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_FAILED     = 5;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_SUCCESS    = 6;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_LATE       = 7;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_INCOMPLETE = 8;
const MASTER_CORPORATION_EVIDENCE_TYPE_GT_30MAN_FAILED     = 9;

export default {

  isSelectedTab: (state) => (tabName) => {
    if (!location.hash) {
      return tabName === 'tab0';
    }
    return location.hash.replace('#', '') === tabName;
  },

  // Does all 'target' form input are validated OK or not
  isFormAllValid: (state) => (target) => {
    let valid = true;
    state[target].forEach((rank) => {
      for (let key in rank.is_valid) {
        valid = rank.is_valid[key] && valid;
      }
    });
    return valid
  },

  // Return DealClutering find by user_rank_id and corporation_rank_id
  findDealClustering: (state) => (user_rank_id, corporation_rank_id) => {
    const target = state.deal_clusterings.find((d) => {
      return (
        parseInt(d.user_rank_id, 10) === parseInt(user_rank_id, 10)
        && parseInt(d.corporation_rank_id, 10) === parseInt(corporation_rank_id, 10)
      );
    });
    return target;
  },

  // Return CommissionClustering find by deal_rank_id and commission_range_id
  findCommissionClustering: (state) => (deal_rank_id, commission_range_id) => {
    const target = state.commission_clusterings.find((d) => {
      return (
        parseInt(d.deal_rank_id, 10) === parseInt(deal_rank_id, 10)
        && parseInt(d.commission_range_id, 10) === parseInt(commission_range_id, 10)
      );
    });
    return target;
  },

  // Return RankingVersion field value of 'field'
  getRankingVersionOf: (state) => (field) => {
    return state.ranking_version[field];
  },

  // Return this RankingVersion is disabled or not
  disabled: (state) => {
    return state.disabled;
  },

  loading: (state) => {
    return state.loading;
  },

  isStatus: (state) => (status) => {
    const STS_DRAFT = 1;
    const STS_LATEST = 2;
    const STS_EXPIRED = 3;
    const STS_ROLLBACKED = 4;
    let id = state.ranking_version.master_ranking_version_status_id;
    if (
      (status === "DRAFT" && id === STS_DRAFT)
      || (status === "LATEST" && id === STS_LATEST)
      || (status === "EXPIRED" && id === STS_EXPIRED)
      || (status === "ROLLBACKED" && id === STS_ROLLBACKED)
    ) {
      return true;
    }
    return false;
  },

  /* ----------------------------------------------------- */

  chartLabelsX: (state, getters) => {
    let l = state.commission_ranges.length;
    let commission_ranges = state.commission_ranges.slice(0, l); // copy array
    let result = ["0万円"];
    let lbls = commission_ranges
      .sort((a,b) => {
        if (a.min_price < b.min_price) return -1;
        if (a.min_price > b.min_price) return 1;
        return 0;
      })
      .map((cr)=>{
        return fmt.format(Math.round(cr.max_price)/10000) + "万円";
      });
    return result.concat(lbls);
  },

  _addUpUserEvidences: (state) => (conditions) => {
    let amount = 0;
    for (let i = 0; i < conditions.length; i++) {
      let userScore = state.user_scores.find((us)=>{
        if (us.master_user_evidence_type_id === conditions[i]) {
          return us;
        }
      });
      amount += !userScore ? 0 : userScore.score;
    }
    return amount;
  },

  _addUpCorporationEvidences: (state) => (conditions) => {
    let amount = 0;
    for (let i = 0; i < conditions.length; i++) {
      let corporationScore = state.corporation_scores.find((cs)=>{
        if (cs.master_corporation_evidence_type_id === conditions[i]) {
          return cs;
        }
      });
      amount += !corporationScore ? 0 : corporationScore.score;
    }
    return amount;
  },

  _findRank: (state) => (score, seqs) => {
    let rank = seqs.find((seq)=>{
      if (
        (seq.min_score <= score && score <= seq.max_score)
        || (seq.max_score == 0 && seq.min_score < score)
      ) {
        return seq;
      }
    });
    return rank;
  },

  _simuValUser: (state) => (score, rankName, limit, aveRate) => {
    let result = [{
      label:'スコア',
      value: score,
    },{
      label:'格付け',
      value:rankName,
    },{
      label:'現金化上限(総額)',
      value:fmt.format(limit) + "円",
    },{
      label:'標準手数料表示',
      value: (aveRate * 100).toFixed(1) + "%",
    }];
    return result;
  },

  _simuValCorporation: (state) => (score, rankName) => {
    let result = [{
      label:'スコア',
      value: score,
    },{
      label:'格付け',
      value:rankName,
    }];
    return result;
  },

  _simuValDealParams: (state) => (dealRank) => {
    return [{
      label: '格付け',
      value: dealRank.name,
    },{
      label: '自動取引上限',
      value: fmt.format(dealRank.auto_deal_limit) + "円",
    },{
      label: '要審査取引上限',
      value: fmt.format(dealRank.manual_deal_limit) + "円",
    }];
  },

  _findDeal: (state, getters) => (userRank, corporationRank) =>  {
    let matchedDeal = state.deal_clusterings.find((dc)=>{
      if (dc.user_rank_id === userRank.id && dc.corporation_rank_id === corporationRank.id) {
        return dc;
      }
    });
    if (!matchedDeal) {
      return;
    }
    let deal = state.deal_ranks.find((deal)=>{
      if (deal.id === matchedDeal.deal_rank_id) {
        return deal;
      }
    });
    return deal;
  },

  _findCommission : (state, getters) => (commissionId) => {
    return state.commissions.find((c)=>{
      return c.id === commissionId;
    });
  },

  _findCommissionRange : (state, getters) => (commissionRangeId) => {
    return state.commission_ranges.find((c)=>{
      return c.id === commissionRangeId;
    });
  },

  _getChartDataByDealRankId: (state, getters) => (dealRankId) => {
    // Commissions
    let commissions =
      state.commission_clusterings
        .filter(cc => cc.deal_rank_id === dealRankId)
        .map((cc) => {
          return {
            commission      : getters._findCommission(cc.commission_id),
            commissionRange : getters._findCommissionRange(cc.commission_range_id),
          };
        })
        .sort((a,b) => {
          if (a.commissionRange.min_price < b.commissionRange.min_price) return -1;
          if (a.commissionRange.min_price > b.commissionRange.min_price) return 1;
          return 0;
        })
        .map(cd => cd.commission);

    return {
      term1: commissions.map(com => com.rate_in_20_days*100).concat([0]),
      term2: commissions.map(com => com.rate_in_40_days*100).concat([0]),
      term3: commissions.map(com => com.rate_in_60_days*100).concat([0]),
    };
  },

  /* ----------------------------------------------------- */

  _getUserEvidences: (state, getters) => (evidenceIds) => {
    let totals = {};
    evidenceIds.forEach((id)=>{
      if (!totals[id]) {
        totals[id] = 0;
      }
      totals[id] += 1;
    });
    let result = [];
    for (let evId in totals) {
      let uet = state.master_user_evidence_types.find(uet => uet.ID == evId);
      let total = totals[evId];
      result.push(uet.Title + "x" + total);
    }
    return result;
  },

  _getCorporationEvidences: (state, getters) => (evidenceIds) => {
    let totals = {};
    evidenceIds.forEach((id)=>{
      if (!totals[id]) {
        totals[id] = 0;
      }
      totals[id] += 1;
    });
    let result = [];
    for (let evId in totals) {
      let cet = state.master_corporation_evidence_types.find(cet => cet.ID == evId);
      let total = totals[evId];
      result.push(cet.Title + "x" + total);
    }
    return result;
  },

  _getCaseData: (state, getters) => (userCond, corporationCond) => {
    // User
    let score1 = getters._addUpUserEvidences(userCond.evidences),
      userRank = getters._findRank(score1, state.user_ranks);

    // Corporation
    let score2 = getters._addUpCorporationEvidences(corporationCond.evidences),
      corporationRank = getters._findRank(score2, state.corporation_ranks);

    // DealRank
    let dealRank = getters._findDeal(userRank, corporationRank);

    return {
      user        : {
        title     : userCond.title,
        params    : getters._simuValUser(score1, userRank.name, userRank.deal_limit, userRank.ave_rate),
        evidences : getters._getUserEvidences(userCond.evidences),
      },
      corporation : {
        title     : corporationCond.title,
        params    : getters._simuValCorporation(score2, corporationRank.name),
        evidences : getters._getCorporationEvidences(corporationCond.evidences),
      },
      deal        : getters._simuValDealParams(dealRank),
      chart       : getters._getChartDataByDealRankId(dealRank.id),
      chartTitle  : "手数料モデル - " + dealRank.name,
    };
  },

  casesForCasePreview: (state, getters) => {
    return [{
      title     : '完全初期(電話インタビューなし)',
      caseData  : getters._getCaseData({
        title     : '完全初期(電話インタビューなし)',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '初期状態(電話インタビューあり)',
      caseData  : getters._getCaseData({
        title     : '初期状態(電話インタビューあり)',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '初期状態(ビデオインタビューあり)',
      caseData  : getters._getCaseData({
        title     : '初期状態(ビデオインタビューあり)',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_VIDEO_INTERVIEW,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '与信タスク複数登録',
      caseData  : getters._getCaseData({
        title     : '与信タスク複数登録',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
	        MASTER_USER_EVIDENCE_TYPE_FACEBOOK,
          MASTER_USER_EVIDENCE_TYPE_TAX_CERT,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : 'フリーナンス口座常用利用3ヶ月 + 取引数回程度',
      caseData  : getters._getCaseData({
        title     : 'フリーナンス口座常用利用3ヶ月 + 取引数回程度',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS,
          MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS,
          ...Array(3).fill(MASTER_USER_EVIDENCE_TYPE_PASSING_THROUGH),
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : 'ヘビーユーザー利用6ヶ月程度',
      caseData  : getters._getCaseData({
        title     : 'ヘビーユーザー利用6ヶ月程度',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_VIDEO_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_GOOGLE,
          MASTER_USER_EVIDENCE_TYPE_TAX_CERT,
          ...Array(6).fill(MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS),
          ...Array(6).fill(MASTER_USER_EVIDENCE_TYPE_PASSING_THROUGH),
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : 'ヘビーユーザー利用12ヶ月程度',
      caseData  : getters._getCaseData({
        title     : 'ヘビーユーザー利用12ヶ月程度',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_VIDEO_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_GOOGLE,
          MASTER_USER_EVIDENCE_TYPE_TAX_CERT,
          ...Array(6).fill(MASTER_USER_EVIDENCE_TYPE_LT_30MAN_SUCCESS),
          ...Array(6).fill(MASTER_USER_EVIDENCE_TYPE_GT_30MAN_SUCCESS),
          ...Array(12).fill(MASTER_USER_EVIDENCE_TYPE_PASSING_THROUGH),
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '回収遅延1回発生後',
      caseData  : getters._getCaseData({
        title     : '回収遅延1回発生後',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_LT_30MAN_LATE,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '不良債権(部分)1回発生後',
      caseData  : getters._getCaseData({
        title     : '不良債権(部分)1回発生後',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_LT_30MAN_INCOMPLETE,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '不良債権(完全)1回発生後',
      caseData  : getters._getCaseData({
        title     : '不良債権(完全)1回発生後',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
          MASTER_USER_EVIDENCE_TYPE_LT_30MAN_FAILED,
        ],
      },{
        title     : '初期登録状態',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
        ]
      }),
    },{
      title     : '(企業)回収遅延1回発生後',
      caseData  : getters._getCaseData({
        title     : '初期登録状態',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
        ],
      },{
        title     : '回収遅延1回発生後',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
          MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_LATE,
        ]
      }),
    },{
      title     : '(企業)不良債権(部分)1回発生後',
      caseData  : getters._getCaseData({
        title     : '初期登録状態',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
        ],
      },{
        title     : '不良債権(部分)1回発生後',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
          MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_INCOMPLETE,
        ]
      }),
    },{
      title     : '(企業)不良債権(完全)1回発生後',
      caseData  : getters._getCaseData({
        title     : '初期登録状態',
        evidences : [
          MASTER_USER_EVIDENCE_TYPE_OPENING_ACCOUNT,
          MASTER_USER_EVIDENCE_TYPE_PERSONAL_CERT,
          MASTER_USER_EVIDENCE_TYPE_TEL_INTERVIEW,
        ],
      },{
        title     : '不良債権(完全)1回発生後',
        evidences : [
          MASTER_CORPORATION_EVIDENCE_TYPE_REGISTERED,
          MASTER_CORPORATION_EVIDENCE_TYPE_LT_30MAN_FAILED,
        ]
      }),


    }];
  },
};
