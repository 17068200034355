<template>
  <a 
    class="d-block card-header f15" 
    role="button"
    data-toggle="collapse" 
    :data-target="target" 
    aria-expanded="true"
    v-on:click="toggleChevron"
    :class="{ 
      'bg-primary' : active, 
      'bg-success' : active && success,
      'text-light' : active,
      'font-weight-bold' : active,
    }"
  >
    <div>
      {{name}}
      <div class="card-header-actions">
        <span 
          class="card-header-action btn-minimize"
          :class="{ 
            'text-light' : active,
            'font-weight-bold' : active,
          }"
        >
           <span v-bind:class="toggle"></span>
        </span>
      </div>
    </div>
    <div>
      <small v-if="checked_by != ''" class="text-black-50">
        担当：{{ checked_by }}&emsp;
        <span
          v-if="checked_date != '-'"
          class="text-black-50">
          日時：{{ checked_date }}
        </span>
      </small>
      <div v-if="process == this.PROCESS_RECEPTION && this.corporationJudgeDone != ''">
        <small class="text-black-50">
          ともだち企業承認日時：{{ this.corporationJudgeDone }}
        </small>
      </div>
      <div v-if="process == this.PROCESS_INVOICE_EVIDENCE_CHECK && this.isAutoApproved">
        <small class="text-black-50">【自動承認】</small>
      </div>
    </div>
  </a>
</template>

<style scoped>
.card-header {
  cursor:pointer;
}
</style>

<script>
  // TODO: Rewrite with TypeScript

  const PROCESS_RECEPTION = 'Reception';
  const PROCESS_INVOICE_EVIDENCE_CHECK = 'InvoiceEvidenceCheck';

  export default {
    name: 'ToggleHeader',
    props: [
      'name',
      'process',
      'target',
      'success',
    ],
    methods: {
      'toggleChevron' : function () {
        if (this.toggle['cui-chevron-top']) {
          this.toggle['cui-chevron-top'] = false;
          this.toggle['cui-chevron-bottom'] = true;
        } else {
          this.toggle['cui-chevron-top'] = true;
          this.toggle['cui-chevron-bottom'] = false;
        }
      },
    },
    data() {
      let is_active = this.$store.getters.isActive(this.process);
      let is_escalated = this.$store.getters.isEscalated(this.process);
      let hidden = !is_active;
      const corporationJudgeDone = this.process === PROCESS_RECEPTION ? this.$store.getters.corporationJudgeDone : '';
      const isAutoApproved = this.process === PROCESS_INVOICE_EVIDENCE_CHECK ? this.$store.getters.isAutoApproved : false;

      return {
        'active' : is_active,
        'escalated' : is_escalated,
        'checked_by' : this.$store.getters.checkedBy(this.process),
        'checked_date' : this.$store.getters.checkedDate(this.process),
        'toggle' : {
          'cui-chevron-top' : hidden ? true : false,
          'cui-chevron-bottom' : hidden ? false : true,
        },
        'PROCESS_RECEPTION': PROCESS_RECEPTION,
        'PROCESS_INVOICE_EVIDENCE_CHECK': PROCESS_INVOICE_EVIDENCE_CHECK,
        'corporationJudgeDone': corporationJudgeDone,
        'isAutoApproved': isAutoApproved,
      };
    },
  };
</script>
