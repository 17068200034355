<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-6">
        <div class="card m-0">
          <div class="card-header">格付けバージョン設定</div>
          <div class="card-body">
            <ListRow
              :label="'格付けバージョンID'"
              :value="ranking_version_id" ></ListRow> <ListRow
              :label="'ステータス'"
              :value="ranking_version.master_ranking_version_status"
              ></ListRow>
            <ListRow
              label="メモ"
              name="note"
              type="textarea"
              v-model="note"
              ></ListRow>
          </div>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              style="width:200px;"
              v-on:click="saveRankingVersion"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card m-0">
          <div class="card-header">格付けバージョンのリリース</div>
          <div class="card-body">
            <template v-if="isStatus('DRAFT')">
              <h5 class="card-title f18">
                この格付けバージョンは
                <span class="f18 badge badge-primary">
                  {{ranking_version.master_ranking_version_status}}
                </span>
                です
              </h5>
              <p class="card-text">
                この格付バージョンの編集を終えた場合、以下の「リリース」を押下するとサービスに適用されます。リリースは即時適用され、リリース後に申込まれた取引に即時適用されます（リリース前に申し込まれ取引に適用されることはありません、その当時の格付バージョンが適用されたままです）。この操作は「ロールバック」により元に戻すことができますが、リリース中に申し込まれた取引に適用された条件を戻すことはできません。
              </p>
              <div class="text-center">
                <button
                  class="btn btn-primary"
                  v-on:click="release"
                  :disabled="loading"
                  >{{loading ? '送信中...' : 'リリースする' }}</button>
              </div>
            </template>
            <template v-if="isStatus('LATEST')">
              <h5 class="card-title f18">
                この格付けバージョンは
                <span class="f18 badge badge-success">
                  {{ranking_version.master_ranking_version_status}}
                </span>
                です
              </h5>
              <p class="card-text">
                現在サービス上ではこの格付バージョンが適用されています。「新しい世代の格付けバージョンを作成する」を押下すると、この格付けバージョンがコピーされ新たなドラフトが作成されます。この格付バージョンに問題がある場合は「ロールバック」することができます（以前の格付けバージョンに戻ります）。ロールバックは1世代前にのみ戻ることができます。ロールバックされた格付けバージョンは「ステータス：ロールバック済み」として今後編集不可になります（新しい格付けバージョンを作成する必要があります）。
              </p>
              <div class="text-center d-flex">
                <button
                  class="btn btn-success mr-auto"
                  v-on:click="clone"
                  :disabled="loading"
                  >{{loading ? '送信中...' : '新しい世代の格付けバージョンを作成する' }}</button>
                <button
                  class="btn btn-warning"
                  v-on:click="rollback"
                  :disabled="loading"
                  >{{loading ? '送信中...' : 'ロールバックする' }}</button>
              </div>
            </template>
            <template v-if="isStatus('EXPIRED') || isStatus('ROLLBACKED')">
              <h5 class="card-title f18">
                この格付けバージョンは
                <span class="f18 badge badge-light">
                  {{ranking_version.master_ranking_version_status}}
                </span>
                です
              </h5>
              <p class="card-text">
                この格付バージョンの提供は終了しました。
              </p>
              <div class="text-center d-flex">
                <button
                  class="btn btn-success mr-auto"
                  v-on:click="clone"
                  :disabled="loading"
                  >{{loading ? '送信中...' : '新しい世代の格付けバージョンを作成する' }}</button>
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-if="isStatus('DRAFT')">
        <div class="col-md-12">
          <div class="text-right mt-2">
              <button
                class="btn btn-sm btn-danger"
                v-on:click="deleteRankingVersion"
                :disabled="disabled"
                >削除する</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex'
  import ListRow from '../parts/ListRow';

  export default {
    name: 'TabPaneRankingVersion',
    props: [
      'no',
    ],
    components: {
      ListRow,
    },
    computed: {
      note: {
        get() { return this.$store.getters.getRankingVersionOf('note') },
        set(value) {
          this.$store.commit('setRankingVersion',{ 'note' : value });
        },
      },
      ...mapGetters([
        'isSelectedTab',
        'disabled',
        'loading',
        'isStatus',
      ]),
    },
    methods: {
      ...mapActions([
        'saveRankingVersion',
        'release',
        'clone',
        'rollback',
        'deleteRankingVersion',
      ]),
    },
    data() {
      return {
        'ranking_version_id' : this.$store.state.ranking_version_id,
        'ranking_version' : this.$store.state.ranking_version,
      }
    }
  };
</script>
