<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-12">
        <div class="card m-0">
          <div class="card-header">取引格付け設定</div>
          <ul class="list-group list-group-flush m-0">
            <DealRankInput  
              v-for="deal_rank in deal_ranks"
              :deal_rank="deal_rank" 
              :key="deal_rank.key" 
            ></DealRankInput>
          </ul>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveDealRanks"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import DealRankInput from './DealRankInput.vue';

  export default {
    name: 'TabPaneDealRanking',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      DealRankInput,
    },
    methods: {
      'saveDealRanks' : function () {
        if (!this.$store.getters.isFormAllValid('deal_ranks')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveDealRanks/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.deal_ranks,
          'redirect' : location.pathname + '#tab4',
        });
      },
    },
    data() {
      return {
        'deal_ranks': this.$store.state.deal_ranks,
      }
    }
  };
</script>
