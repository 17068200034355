var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import VeeValidate, { Validator } from 'vee-validate';
import ja from '../../../../node_modules/vee-validate/dist/locale/ja';
import ArrowDown from '../../../components/ArrowDown.vue';
import StickyScroller from '../../../components/StickyScroller.vue';
import FirstResponse from './Step1FirstResponse.vue';
import Complete from './Step2Complete.vue';
Validator.localize('ja', ja);
Vue.use(VeeValidate, { locale: ja });
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App = __decorate([
        Component({
            components: {
                ArrowDown: ArrowDown,
                StickyScroller: StickyScroller,
                FirstResponse: FirstResponse,
                Complete: Complete
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
