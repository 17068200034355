<template>
  <a 
    class="d-block card-header f15" 
    role="button"
    data-toggle="collapse" 
    :data-target="target" 
    aria-expanded="true"
    v-on:click="toggleChevron"
    :class="{ 
      'bg-primary' : active, 
      'text-light' : active,
      'font-weight-bold' : active,
    }"
  >
    <div>
      {{name}}
      <div class="card-header-actions">
        <span 
          class="card-header-action btn-minimize"
          :class="{ 
            'text-light' : active,
            'font-weight-bold' : active,
          }"
        >
           <span v-bind:class="toggle"></span>
        </span>
      </div>
    </div>
    <div>
      <small v-if="checked_by != ''" class="text-black-50">
        担当：{{checked_by}}　　日時：{{checked_date}}
      </small>
    </div>
  </a>
</template>

<style scoped>
.card-header {
  cursor:pointer;
}
</style>

<script>

  import { mainModule } from "../../store/main";
  
  export default {
    name: 'ToggleHeader',
    props: [
      'name',
      'process',
      'target',
    ],
    methods: {
      'toggleChevron' : function () {
        if (this.toggle['cui-chevron-top']) {
          this.toggle['cui-chevron-top'] = false;
          this.toggle['cui-chevron-bottom'] = true;
        } else {
          this.toggle['cui-chevron-top'] = true;
          this.toggle['cui-chevron-bottom'] = false;
        }
      },
    },
    data() {
      let is_active = mainModule.isActive(this.process);
      let is_escalated = mainModule.isEscalated(this.process);
      let hidden = !is_active;
      return {
        'active' : is_active,
        'escalated' : is_escalated,
        'checked_by' : mainModule.checkedBy(this.process),
        'checked_date' : mainModule.checkedDate(this.process),
        'toggle' : {
          'cui-chevron-top' : hidden ? true : false,
          'cui-chevron-bottom' : hidden ? false : true,
        },
      };
    },
  };
</script>
