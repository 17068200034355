import axios from 'axios';

export default {
  
  // Triggered from Tab1
  saveRankingVersion({ commit, state, dispatch }, opt) {
    dispatch('saveTblForm', {
      'url' : '/apiInternal/saveRankingVersion/' + state.ranking_version_id,
      'target' : state.ranking_version,
      'redirect' : location.pathname + '#tab1',
    });
  },
   
  // Triggered from Tab1
  release({ commit, state, dispatch }, opt) {
    if (this.state.loading) {
      return;
    }
    if (confirm('この格付けバージョンをリリースしてよろしいですか？')) {
      this.state.loading = true;
      dispatch('saveTblForm', {
        'url' : '/apiInternal/releaseRankingVersion/' + state.ranking_version_id,
        'target' : {},
        'redirect' : location.pathname + '#tab1',
      });
    }
  },
  
  // Triggered from Tab1
  clone({ commit, state, dispatch }, opt) {
    if (this.state.loading) {
      return;
    }
    this.state.loading = true;
    dispatch('saveTblForm', {
      'url' : '/apiInternal/cloneRankingVersion/' + state.ranking_version_id,
      'target' : {},
      'onSuccess' : (res) => {
        if (res.data.status == 200) {
          location.href = "/rankingVersions/edit/" + res.data.result.id;
        } else {
          alert('エラーが発生しました:' + res.data.message);
        }
      }
    });
  },
  
  // Triggered from Tab1
  rollback({ commit, state, dispatch }, opt) {
    if (this.state.loading) {
      return;
    }
    if (confirm('この格付けバージョンをロールバックしてよろしいですか？')) {
      this.state.loading = true;
      dispatch('saveTblForm', {
        'url' : '/apiInternal/rollbackRankingVersion/' + state.ranking_version_id,
        'target' : {},
        'redirect' : location.pathname + '#tab1',
        'onSuccess' : (res) => {
          if (res.data.status == 200) {
            location.pathname + '#tab1';
            location.reload();
          } else {
            alert('エラーが発生しました:' + res.data.message);
          }
        },
      });
    }
  },
  
  // Triggered from Tab1
  deleteRankingVersion({ commit, state, dispatch }, opt) {
    if (confirm('この格付けバージョンを削除してよろしいですか？')) {
      dispatch('saveTblForm', {
        'url' : '/apiInternal/deleteRankingVersion/' + state.ranking_version_id,
        'target' : {},
        'onSuccess' : (res) => {
          if (res.data.status == 200) {
            location.href = '/rankingVersions/search';
          } else {
            location.pathname + '#tab1';
            location.reload();
          }
        }
      });
    }
  },
  
  // Ajax request of serialized table-jorm params
  saveTblForm({ commit, state }, opt) {
    function serialize (data) {
      const params = new URLSearchParams();
      params.append('data', JSON.stringify(data));
      return params;
    }

    function onSuccess (res, redirect) {
      if (opt.onSuccess) {
        opt.onSuccess(res);
        return;
      }
      location.href = redirect;
      location.reload();
    }

    function onError (err) {
      if (opt.onError) {
        opt.onError(err);
        return;
      }
      console.error(err);
      alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
    }
    
    axios.post(opt.url, serialize(opt.target))
      .then((res) => onSuccess(res, opt.redirect))
      .catch(onError); 
  },
};

