<template>
  <td class="align-middle" v-bind:style="{'background-color': bg_color}">
    <select class="form-control" v-model="selected">
      <option 
        v-for="deal_rank in deal_ranks" 
        :value="deal_rank.id" 
        :key="deal_rank.key"
        :disabled="disabled"
        >{{ deal_rank.name }}</option>
    </select>
  </td>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'DealClusteringCell',
    props: [
      'user_rank',
      'corporation_rank',
      'deal_ranks',
      'deal_clustering',
    ],
    watch: {
      selected: function(a, b) { 
        this.bg_color = this.$store.state.deal_color_map[a]
      },
    },
    computed: {
      selected: {
        get() { return this.deal_clustering.deal_rank_id },
        set(value) { 
          this.$store.commit('updateDealClustering', {
            'key': this.deal_clustering.key,
            'deal_rank_id': value,
          });
        },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      let deal_rank_id = this.deal_clustering.deal_rank_id;
      let deal_color_map = this.$store.state.deal_color_map;
      return { 
        'bg_color' : deal_color_map[deal_rank_id],
      }
    },
  };
</script>
