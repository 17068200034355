import moment from 'moment'
import fmt from '../util.js'

export default {
  // Returns transactions list
  transactions: (state) => {
    let trans = state.transactions;
    // 発生タイプをセット
    setTransactionTypes(state, trans);
    // 期日到来回収予定をセット
    setCollectionable(state.constMasterTransactionTypes.MASTER_TRANSACTION_TYPE_COMMISSION_CASH_BACK, trans);
    return trans;
  },
  
  transaction:(state) => (id) => {
    return state.transactions.find((t) => t.ID === id);
  },
 
  calcTransactionAmount: (state) => (tran) => {
    let recds = tran.TransactionRecords;
    let amount = 0;
    for (let i = 0; i < recds.length; i++) {
      if (recds[i].isChecked && recds[i]._shown) {
        amount += recds[i].Amount;
      }
    }
    return amount;
  },
   
  calcCollectionAmount: (state) => (tran) => {
    let colls = tran.Collections;
    let amount = 0;
    for (let i = 0; i < colls.length; i++) {
      amount += colls[i].Amount;
    }
    return amount;
  },
  
  optTRTypes: (state) => (tran) => {
    let mtt = state.constMasterTransactionTypes;
    let ts = state.masterTransactionRecordTypes;
    let t1 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_COLLECT_ALL);
    let t2 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_COLLECT_PART);
    let t3 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_STOP);
    let t4 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_COLLECT_ETC);
    let t5 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_BRINGING_BACK);
    let t6 = ts.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_COLLECT_INSURANCE);
    let collectable = tran.Collections.length > 0;
    t1.disabled = !collectable;
    t2.disabled = !collectable;
    t3.disabled = false;
    t4.disabled = false;
    return [t1, t2, t3, t4, t5, t6];
  },
  
  optTranCollectionables: (state) => (tran) => {
    let alreadyCollecting = [];
    tran.TransactionRecords.forEach((tr) => {
      if (tr._collectionID) {
        alreadyCollecting.push(tr._collectionID);
      }
    });
    let options = [];
    tran.Collections.forEach((coll) => {
      let lbl = '回収予定ID:' + coll.ID 
        + ' 回収予定日:' + fmt.date(coll.Deadline) 
        + ' - ' +  coll.Title;
      options.push({
        id : coll.ID,
        name : lbl,
        disabled : alreadyCollecting.indexOf(coll.ID) >= 0 || (state.isGroupSupportAdmin && coll.Deal.NotifiedFactoringID != 0),
      });
    });
    return options;
  },
  
  masterTransactionRecordType: (state) => (id) => {
    return state
      .masterTransactionRecordTypes
      .find((t) => t.ID === id);
  },
  
  constMasterTRTypes: (state) => {
    return state.constMasterTransactionTypes;
  },
  
  cntCheckedTransactions: (state) => {
    let cnt = 0;
    state.transactions.forEach((tran)=>{
      cnt += tran._checked ? 1 : 0;
    });
    return cnt;
  },
  
  cntSelectedTransactions: (state) => {
    let cnt = 0;
    state.transactions.forEach((tran)=>{
      cnt += tran._checked || tran._targeted ? 1 : 0;
    });
    return cnt;
  },
  cntTargeted: (state) => {
    let targets = state.transactions.filter(t => t._targeted);
    return targets.length;
  },
  isCollectTargeted: (state) => {
    const transactionsTargets = state.transactions.filter(t => t._targeted);
    const collectMasterTransactionRecords = [
      state.constMasterTransactionRecords.MASTER_TRANSACTION_RECORD_TYPE_COLLECT_ALL,
      state.constMasterTransactionRecords.MASTER_TRANSACTION_RECORD_TYPE_COLLECT_PART,
      state.constMasterTransactionRecords.MASTER_TRANSACTION_RECORD_TYPE_TERMINATE,
      state.constMasterTransactionRecords.MASTER_TRANSACTION_RECORD_TYPE_COLLECT_ETC,
      state.constMasterTransactionRecords.MASTER_TRANSACTION_RECORD_TYPE_COLLECT_INSURANCE
    ];
    return transactionsTargets.some(
      transaction => transaction.TransactionRecords
                && transaction.TransactionRecords.some(record => collectMasterTransactionRecords.includes(record.MasterTransactionRecordTypeID))
    );
  },
  amountTargeted: (state, getters) => {
    let amount = 0;
    state.transactions.forEach((tran)=>{
      if (tran._targeted) {
        amount += getters.calcTransactionAmount(tran);
      }
    });
    return amount;
  },

  getSendingValue: (state, getters) => {
    let sendingTrans = []; 
    state.transactions.forEach((tran)=>{
      let sendingTranRecords = [];
      tran.TransactionRecords.forEach((tRecord)=>{
        if (tRecord._newRecord && tRecord._shown) {
          sendingTranRecords.push({
            master_transaction_record_type_id   : tRecord.MasterTransactionRecordTypeID,
            title                               : tRecord.Title,
            amount                              : tRecord.Amount,
            collection_id                       : tRecord._collectionID,
          });
        }
      });
      if (tran._targeted) {
        sendingTrans.push({
          id: tran.ID,
          targeted_amount: getters.calcTransactionAmount(tran),
          excluded_incoming_ids: tran.excludedIncomingIds,
          new_transaction_records: sendingTranRecords
        });
      }
    });
    return sendingTrans;
  },
  
  isAddMode: (state) => {
    return state.mode === "add";
  },
   
  isViewMode: (state) => {
    return state.mode === "view";
  },
  
  containsInstantTransferRecords: (state) => {
    return state.containsInstantTransferRecords;
  },
  
  isTransactionRecordCheckAll: (state) => {
    return state.checkedAllTransactionRecords;
  },
};

function setTransactionTypes(state, trans) {
  let mtt = state.constMasterTransactionTypes;
  trans.forEach((tran, i)=>{
    tran.Types = [];
    let recds = tran.TransactionRecords;
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_INCOMING)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_INCOMING,
        Type: "入金",
        LabelClass: "badge-primary",
      });
    }
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_QUICKPAY)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_QUICKPAY,
        Type: "即日払い",
        LabelClass: "badge-warning",
      });
    }
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_RETRY_FAIL)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_RETRY_FAIL,
        Type: "送金失敗分再送金",
        LabelClass: "badge-secondary",
      });
    }
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_RETRY_STOP)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_RETRY_STOP,
        Type: "凍結分再送金",
        LabelClass: "badge-secondary",
      });
    }
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE,
        Type: "今すぐ振替手数料",
        LabelClass: "badge-secondary",
      });
    }
    if (hasState(recds, mtt.MASTER_TRANSACTION_TYPE_COMMISSION_CASH_BACK)) {
      tran.Types.push({
        ID: mtt.MASTER_TRANSACTION_TYPE_COMMISSION_CASH_BACK,
        Type: "手数料キャッシュバック",
        LabelClass: "badge-success",
      });
    }
  });
}

function setCollectionable(masterTransactionTypeCashBack, transactions) {
  moment.locale('ja');
  const today = moment(new Date());
  const later15Days = moment(new Date()).add(15, 'days');

  transactions.forEach((transaction) => {
    const collections = transaction.Collections;
    const tran = transaction;
    let isCollectionableDeadline = false;
    let isCollectionable15DaysDeadline = false;

    tran.Collectionable = false;
    const hasCashBack = transaction.TransactionRecords
      .find(el => el.MasterTransactionRecordTypeID === masterTransactionTypeCashBack);
    if (collections.length > 0 && !hasCashBack) {
      tran.Collectionable = true;
    }

    collections.forEach((collection) => {
      if (moment(collection.Deadline).isSameOrBefore(today, 'day')) {
        isCollectionableDeadline = true;
      } else if (moment(collection.Deadline).isSameOrBefore(later15Days, 'day')) {
        isCollectionable15DaysDeadline = true;
      }
    });

    if (isCollectionableDeadline) {
      tran.CollectionableDeadline = true;
    } else if (isCollectionable15DaysDeadline) {
      tran.Collectionable15DaysDeadline = true;
    }
  });
}

function hasState(tranRecords, id) {
  let result = false;
  for (let i = 0; i < tranRecords.length; i++) {
    if (tranRecords[i].MasterTransactionRecordTypeID == id) {
      result = true;
      break;
    }
  }
  return result;
}
