<template>
  <div class="card mb-1" :class="{ 'border-warning' : escalated }">
    <ToggleHeader 
      :name="no + '. 信用調査（反社チェック）'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>信用調査サービスに照会し問題がないか？</li>
        </ul>
      </li>
      <CheckAndEscalate
        :no="no"
        :process="process"
        v-on:onClickChecked="onClickChecked"
        v-on:onClickEscalation="onClickEscalation"
      ></CheckAndEscalate>
    </ul>
  </div>
</template>

<script>

  import ToggleHeader  from './parts/ToggleHeader.vue';
  import CheckAndEscalate  from './parts/CheckAndEscalate.vue';

  export default {
    name: 'AntiSocialCheck',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    methods: {
      onClickChecked () {
        this.$store.dispatch('updateProcess', {
          'data' : {
            'process': this.process,
            'method': 'checked',
            'updated_at':this.$store.state.application.UpdatedAt,
          },
        });
      },
      onClickEscalation (data) {
        this.$store.dispatch('updateProcess', data);
      },
    },
    data() {
      let p = this.$options.name;
      return {
        'process' : p,
        'active' : this.$store.getters.isActive(p),
        'escalated' : this.$store.getters.isEscalated(p),
      };
    },
  };
</script>
