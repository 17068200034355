var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import mainStoreModule from '../../store/main';
var CheckAndEscalate = /** @class */ (function (_super) {
    __extends(CheckAndEscalate, _super);
    function CheckAndEscalate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.all_valid = true;
        _this.disabled = !mainStoreModule.isActive(_this.process);
        return _this;
    }
    Object.defineProperty(CheckAndEscalate.prototype, "users", {
        get: function () {
            return mainStoreModule.userList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CheckAndEscalate.prototype, "isCheckFinishable", {
        get: function () {
            return !this.disabled;
        },
        enumerable: false,
        configurable: true
    });
    CheckAndEscalate.prototype.onClickChecked = function () {
        if (confirm(this.lbl_check + "\u3068\u3057\u3066\u4FDD\u5B58\u3057\u3066\u3088\u308D\u3057\u3044\u3067\u3057\u3087\u3046\u304B\uFF1F")) {
            this.disabled = true;
            this.$emit('onClickChecked', this);
        }
    };
    __decorate([
        Prop(Number)
    ], CheckAndEscalate.prototype, "no", void 0);
    __decorate([
        Prop(String)
    ], CheckAndEscalate.prototype, "process", void 0);
    __decorate([
        Prop(String)
    ], CheckAndEscalate.prototype, "lbl_check", void 0);
    CheckAndEscalate = __decorate([
        Component
    ], CheckAndEscalate);
    return CheckAndEscalate;
}(Vue));
export default CheckAndEscalate;
