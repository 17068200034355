import moment from 'moment';
export default {
    namespaced: true,
    state: {
        termConfigs: [{
                id: 1,
                name: '前々々月以前',
                from: moment('2018/10/16').startOf('day'),
                to: moment().subtract(2, 'months').startOf('month').startOf('day'),
            }, {
                id: 2,
                name: '前々月',
                from: moment().subtract(2, 'months').startOf('month').startOf('day'),
                to: moment().subtract(1, 'months').startOf('month').startOf('day'),
            }, {
                id: 3,
                name: '前月',
                from: moment().subtract(1, 'months').startOf('month').startOf('day'),
                to: moment().startOf('month').startOf('day'),
            }, {
                id: 4,
                name: '当月（期限切れ）',
                from: moment().startOf('month').startOf('day'),
                to: moment().startOf('day'),
            }, {
                id: 5,
                name: '本日',
                from: moment().startOf('day'),
                to: moment().add(1, 'days').startOf('day'),
            }, {
                id: 6,
                name: '〜3日以内',
                from: moment().add(1, 'days').startOf('day'),
                to: moment().add(4, 'days').startOf('day'),
            }, {
                id: 7,
                name: '〜1週間以内',
                from: moment().add(4, 'days').startOf('day'),
                to: moment().add(1, 'weeks').add(1, 'days').startOf('day'),
            }, {
                id: 8,
                name: '〜3週間以内',
                from: moment().add(1, 'weeks').add(1, 'days').startOf('day'),
                to: moment().add(3, 'weeks').add(1, 'days').startOf('day'),
            }],
    },
    mutations: {},
    actions: {},
    getters: {
        termConfigs: function (state) { return state.termConfigs; },
    },
};
