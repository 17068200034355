<template>
  <div class="card border-primary mb-0" :class="{ 'border-primary' : !tran._targeted, 'border-success' : tran._targeted}">
    <div class="card-header d-flex bg-primary" :class="{ 'bg-primary' : !tran._targeted, 'bg-success' : tran._targeted}">
      <h6 class="mb-0">
        <span class="cui-yen font-weight-bold">
          送金合計の計算根拠
        </span>
      </h6>
      <template v-if="isAddMode">
        <div class="ml-auto" v-if="addRecordValid">
          <a class="badge badge-light" @click="onClickAddRecord" href="#">
            <span
              :class="{ 'cui-chevron-bottom' : !showSection, 'cui-chevron-top' : showSection }"
            ></span>
            {{!showSection ? "レコードの追加" : "キャンセル"}}
          </a>
        </div>
      </template>
    </div>
    <AddRecordSection @close="onClose" :displaySection="showSection" :tran="tran"></AddRecordSection>
    <table class="table tblInner mb-0">
      <tr class="bg-light">
        <th scope="col">
          <input
            v-if="isAddMode"
            type="checkbox"
            :disabled="tranRecordCheckAllDisabled"
            @click.stop.prevent="onAllTranRecordIdChanged()"
            :checked="isTransactionRecordCheckAll">
        </th>
        <th scope="col">発生日時</th>
        <th scope="col">発生タイプ</th>
        <th scope="col">内容</th>
        <th scope="col">金額</th>
      </tr>
      <tr
        v-for="(tRecord, i) in tranRecordFiltered"
        :key="i">
        <td>
          <input
            v-if="isAddMode"
            type="checkbox"
            :disabled="tranRecordCheckDisabled(tRecord)"
            @click.stop.prevent="onTranRecordIdClicked(tRecord)"
            :checked="tRecord.isChecked">
        </td>
        <td>{{fmt.datetime(tRecord.CreatedAt)}}</td>
        <td>
          {{tRecord.MasterTransactionRecordType.Title}}
          <template v-if="tRecord._collectionID > 0">
            <span class="badge badge-light">(充当先回収予定ID：{{tRecord._collectionID}})</span>
          </template>
        </td>
        <td :class="{ 'text-danger' : tRecord.isSendingFincodeBank}">
          {{tRecord.Title}}
          <template v-if="tRecord.MasterTransactionRecordTypeID == constMasterTRTypes.MASTER_TRANSACTION_TYPE_CHARGE">
            <br>フリーナンス口座：
            <template v-if="tRecord.Deal.InvoiceVersion.NotifiedFactoringID > 0 && !tRecord.Deal.InvoiceVersion.NotifiedFactoring.IsChoosableFrncAccount">
              -
            </template>
            <template v-else-if="tRecord.Deal.InvoiceVersion.IsUsedFrncAccount">
              <span class="badge badge-warning">使用</span>
            </template>
            <template v-else>
              <span class="badge badge-secondary">不使用</span>
            </template>
          </template>
          <template v-else-if="(tRecord.MasterTransactionRecordTypeID == constMasterTRTypes.MASTER_TRANSACTION_TYPE_INCOMING && tRecord.isSendingFincodeBank)">
               (fincode)
          </template>
        </td>
        <td class="d-flex justify-content-between" :class="{ 'text-danger' : tRecord.Amount < 0}">
          {{fmt.currency(tRecord.Amount)}}
          <template v-if="tRecord._newRecord && !tran._targeted">
            <a @click="onClickRemoveRecord(i, tRecord, $event)" href="#">x</a>
          </template>
          <template v-if="(tRecord.MasterTransactionRecordTypeID === constMasterTRTypes.MASTER_TRANSACTION_TYPE_INCOMING &&
                           hasCollectionOfMS(tran.Collections) && $store.getters.isAddMode)">
            <a @click="onClickCopyDealOfMS(tran, tRecord, $event)" :transaction_record_id="tRecord.ID" class="btn btn-sm btn-outline-info">
                <span class="cui-copy" aria-hidden="true"></span>
                <span class="btnLbl"></span>
            </a>
          </template>
        </td>
      </tr>
    </table>
    <div class="card-footer text-right bg-light rounded-bottom">
      <span class="font-weight-bold">送金合計：{{fmt.currency(calcTransactionAmount(tran))}}</span>
    </div>
  </div>
</template>

<style scoped>
.tblInner th {
  font-size:.8rem;
  padding-top:5px;
  padding-bottom:5px;
  border-top:none;
}
.tblInner td {
  vertical-align: middle;
}
</style>

<script>
import { mapGetters } from 'vuex';
import fmt from '../../util';
import AddRecordSection from './AddRecordSection';
import { copyActually } from '../../../../CopyToClipboard';

export default {
  name: 'TblTransactionRecords',
  props: [
    'tran',
  ],
  watch: {
    'tran._targeted': function () {
      this.showSection = false;
    }
  },
  computed: {
    ...mapGetters([
      'calcTransactionAmount',
      'isAddMode',
      'isTransactionRecordCheckAll',
      'constMasterTRTypes'
    ]),
    tranRecordCheckAllDisabled() {
      if (!this.tran.isValid) {
        return true;
      }
      return this.tran._targeted;
    },
    tranRecordFiltered() {
      return this.tran.TransactionRecords.filter(tranRecord => tranRecord._shown);
    },
    addRecordValid() {
      return this.calcTransactionAmount(this.tran) > 0 && !this.tran._targeted && this.tran.isValid;
    }
  },
  components: {
    AddRecordSection
  },
  methods: {
    tranRecordCheckDisabled(tRecord) {
      if(this.tran.User.AccountNo.length !== 7) {
        return true;
      }
      return tRecord.MasterTransactionRecordType.ID !== this.constMasterTRTypes.MASTER_TRANSACTION_TYPE_INCOMING || this.tran._targeted;
    },

    onClickAddRecord(e) {
      e.preventDefault();
      this.showSection = !this.showSection;
    },

    validateTransaction(tranRecords) {
      const currentAmount = this.calcTransactionAmount(this.tran);
      const amountToUpdate = tranRecords.reduce((amountSum, tranRecord) => {
        let amountChange = 0;
        if (tranRecord.MasterTransactionRecordTypeID === this.constMasterTRTypes.MASTER_TRANSACTION_TYPE_INCOMING) {
          // Only incoming transaction record can be unchecked or checked
          // The changed amount is negative or positve based on check status
          amountChange = tranRecord.isChecked ? -1 * tranRecord.Amount : tranRecord.Amount;
        } else {
          // Handles cases when transaction record is newly added by JS and is removed
          amountChange = -1 * tranRecord.Amount;
        }

        return amountSum + amountChange;
      }, 0);

      if (currentAmount + amountToUpdate < 0) {
        alert('送金合計が0円を下回るためチェックを外せません');
        return false;
      }

      return true;
    },
    hasCollectionOfMS(collections) {
      const result = collections.filter(coll => coll.Deal.OauthConsumerID === document.GMO_MS_OAUTH_CONSUMER_ID);
      return result.length > 0;
    },
    getSubTitle(title, regex) {
      const found = title.match(regex)
      return found != null ? found[0].replace(/\s+/g, "") : "";
    },
    onClickCopyDealOfMS(tran, tranRecord, e) {
      e.preventDefault();
      const lineBreak = '\n';
      const horizontalLine = 'ーーーーーーーーーーーー';
      const space = '  ';
      let subTitle = this.getSubTitle(tranRecord.Title, /\S+(?=様からの入金|への着金)/)

      let copyText = tran.User.FullName + lineBreak +
                     "→" + subTitle + "(" + fmt.currency(tranRecord.Amount).replace("￥", "") + "円)がありました" + lineBreak +
                     horizontalLine + lineBreak;
      tran.Collections.forEach((coll) => {
        if (coll.Deal.OauthConsumerID === document.GMO_MS_OAUTH_CONSUMER_ID) {
          const line = coll.DealID + space + fmt.date(coll.Deadline) + space + fmt.currency(coll.Amount) + space + "振替 or 回収" + lineBreak;
          copyText = copyText + line;
        }
      });

      copyText = copyText + horizontalLine;
      copyActually($(`[transaction_record_id=${tranRecord.ID}]`), copyText);
    },
    onClickRemoveRecord(removedIndex, tranRecord, e) {
      e.preventDefault();
      if (this.validateTransaction([tranRecord])) {
        this.tran.TransactionRecords = this.tran.TransactionRecords.filter((currentVal, currentIndex) => currentIndex !== removedIndex);
      }
    },

    onClose() {
      this.showSection = false;
    },

    onTranRecordIdClicked(tranRecord) {
      if (this.validateTransaction([tranRecord])) {
        setTimeout(() => {
          tranRecord.isChecked = !tranRecord.isChecked;
          const isCheckedAll = this.tran.TransactionRecords.every(trRecord => trRecord.isChecked);
          this.updateCheckAll(isCheckedAll);
        });
      }
    },

    onAllTranRecordIdChanged() {
      const newAllStatus = !this.isTransactionRecordCheckAll;
      const changedRecords = this.tran.TransactionRecords.reduce((recordArr, tranRecord) => {
        // We get all transaction records that will have status changed when the master checkbox is checked
        // (only incoming transaction record can get checked/unchecked)
        if ((tranRecord.MasterTransactionRecordTypeID === this.constMasterTRTypes.MASTER_TRANSACTION_TYPE_INCOMING)
          && tranRecord.isChecked !== newAllStatus) {
          recordArr.push(tranRecord);
        }

        return recordArr;
      }, []);

      if (this.validateTransaction(changedRecords)) {
        setTimeout(() => {
          this.updateCheckAll(newAllStatus);
          changedRecords.forEach((tranRecord) => {
            tranRecord.isChecked = newAllStatus;
          });
        });
      }
    },
    updateCheckAll(status) {
      this.$store.commit('updateCheckAllTransactionRecord', { checkedAll: status });
    }
  },
  updated() {
    if (!this.tran.isValid) {
      return;
    }
    // Only when there is at least on transaction record checked the create outcoming button is enabled
    this.tran.targetable = this.tran.TransactionRecords.some(tRecord => tRecord.isChecked);
    // Remove the transaction from outcoming target if it is not valid
    if (!this.tran.targetable) {
      this.tran._checked = false;
    }
  },
  data() {
    return {
      fmt,
      showSection: false,
      errMsg: null
    };
  }
};
</script>
