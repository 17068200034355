import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from '../api/actions';

Vue.use(Vuex);

const state = {
  transactions: [],
  masterTransactionRecordTypes: [],
  filters: {
    checkedCondTargeted: false,
    checkedCondUntargeted: false,
    checkedCondIncoming: false,
    checkedCondQuickPay: false,
    checkedCondRetryFail: false,
    checkedCondRetryStop: false,
    checkedCondInstantTransferFee: false,
    checkedCondDeadlineHasCome: false,
    checkedCondDeadline15Days: false,
    checkedCondDeadlineIsComming: false,
    checkedCondDeadlineNone: false,
    checkedCondCommissionCashBack: false,
    checkedCondIncomingAmountMoreThanTwoMillionYen: false,
    checkedCondIncomingAmountLessThanTwoMillionYen: false
  },
  checkedBankAccountFilters: [],
  corporationBankAccountFilters: [],
  constMasterTransactionTypes: {
    MASTER_TRANSACTION_TYPE_INCOMING: 1,
    MASTER_TRANSACTION_TYPE_QUICKPAY: 2,
    MASTER_TRANSACTION_TYPE_CHARGE: 3,
    MASTER_TRANSACTION_TYPE_BANK_FEE: 4,
    MASTER_TRANSACTION_TYPE_COLLECT_ALL: 5,
    MASTER_TRANSACTION_TYPE_COLLECT_PART: 6,
    MASTER_TRANSACTION_TYPE_STOP: 7,
    MASTER_TRANSACTION_TYPE_RETRY_FAIL: 8,
    MASTER_TRANSACTION_TYPE_RETRY_STOP: 9,
    MASTER_TRANSACTION_TYPE_COLLECT_ETC: 10,
    MASTER_TRANSACTION_TYPE_BRINGING_BACK: 11,
    MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE: 12,
    MASTER_TRANSACTION_TYPE_COMMISSION_CASH_BACK: 13,
    MASTER_TRANSACTION_TYPE_COLLECT_INSURANCE: 14
  },
  constMasterTransactionStatuses: {
    MASTER_TRANSACTION_STATUS_OPEN: 1,
    MASTER_TRANSACTION_STATUS_SENDING: 6,
    MASTER_TRANSACTION_STATUS_SENT: 2,
    MASTER_TRANSACTION_STATUS_SENT_FAILED: 4
  },
  constMasterTransactionRecords: {
    MASTER_TRANSACTION_RECORD_TYPE_COLLECT_ALL: 5,
    MASTER_TRANSACTION_RECORD_TYPE_COLLECT_PART: 6,
    MASTER_TRANSACTION_RECORD_TYPE_TERMINATE: 7,
    MASTER_TRANSACTION_RECORD_TYPE_COLLECT_ETC: 10,
    MASTER_TRANSACTION_RECORD_TYPE_COLLECT_INSURANCE: 14
  },
  constGanbBankCode: '0310',
  constFincodeBankName: 'ｼﾞ-ｴﾑｵ-ｱｵｿﾞﾗﾈﾂﾄ',
  constFincodeBankBranchName: 'ﾎｳｼﾞﾝﾀﾞｲﾆ',
  constFincodeBankAccountName: 'ｼﾞｰｴﾑｵｰｲﾌﾟｼﾛﾝ(ｶ',
  isMasterOutcomingStatusFixed: false,
  outcoming: {},
  containsInstantTransferRecords: false,
  isGanbAPIConnected: false,
  masterGanbTransferStatus: {},
  checkedAllTransactionRecords: true,
  isProcessingApiRequest: false
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
});
