export default {
    namespaced: true,
    state: {
        selectedTermConfig: {},
    },
    mutations: {
        setState: function (state, newState) {
            state.selectedTermConfig = newState;
        },
    },
    actions: {
        changeTermConfig: function (_a, selectedId) {
            var commit = _a.commit, rootGetters = _a.rootGetters;
            commit('setState', rootGetters['termConfigs/termConfigs'].find(function (config) { return selectedId === config.id; }));
        },
    },
    getters: {
        selectedTermConfig: function (state) { return state.selectedTermConfig; },
    },
};
