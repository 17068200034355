<template>
  <div class="card mb-1">
    <ToggleHeader
      :name="no + '. 与信評価待ち'"
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>何らかの理由で回収が複数回に渡った場合、または遅延した場合、手動で取引結果の更新を実行</li>
          <li>ユーザー・企業、それぞれの与信評価を選択する</li>
          <li>回収遅延について帰責性がない場合プラス与信材料が、帰責性がある場合マイナス与信材料が追加される</li>
          <li>両者帰責性あり（なし）、または片方のみ帰責性あり（なし）として設定可能</li>
        </ul>
      </li>
      <li class="list-group-item p-3">
        <label for="">ユーザー与信評価：</label>
        <select
          :name="'user_responsible_for_late'"
          class="form-control"
          :class="{'is-invalid': errors.has('user_responsible_for_late')}"
          v-model="user_responsible_for_late"
          v-validate="'required|included:0,1'"
          :disabled="!isActive(process)"
        >
          <option :value="null">-</option>
          <option :value="false">プラス評価（遅延の帰責性なし）</option>
          <option :value="true">マイナス評価（遅延の帰責性あり）</option>
        </select>
        <p
          v-if="errors.has('user_responsible_for_late')"
          class="invalid-feedback">
          ユーザー与信評価を選択して下さい
        </p>
        <label class="mt-2">企業与信評価：</label>
        <select
          :name="'corporation_responsible_for_late'"
          class="form-control"
          :class="{'is-invalid': errors.has('corporation_responsible_for_late')}"
          v-model="corporation_responsible_for_late"
          v-validate="'required|included:0,1'"
          :disabled="!isActive(process)"
        >
          <option :value="null">-</option>
          <option :value="false">プラス評価（遅延の帰責性なし）</option>
          <option :value="true">マイナス評価（遅延の帰責性あり）</option>
        </select>
        <p
          v-if="errors.has('corporation_responsible_for_late')"
          class="invalid-feedback">
          企業与信評価を選択して下さい
        </p>
        <label class="mt-2">備考：</label>
        <textarea
          class="form-control"
          style="height:100px;"
          v-model="credit_note"
          :disabled="!isActive(process)"
        ></textarea>
        <button
          class="btn btn-secondary btn-block mt-2"
          :disabled="!isActive(process) || disable_btn"
          @click="onClickUpdateCredit"
        >更新</button>
      </li>
    </ul>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ToggleHeader from '../../ApplicationView/components/parts/ToggleHeader.vue';

const ON_DONE = 9;
const ON_DONE_LATE = 10;
const EVALUTION_STATUS_DONE_LATE = 2;

export default {
  name: 'UpdatingCredit',
  props: [
    'no',
  ],
  components: {
    ToggleHeader,
  },
  computed: {
    ...mapGetters([
      'isActive',
    ]),
  },
  methods: {
    onClickUpdateCredit(e) {
      this.$validator.validate().then((result) => {
        if (result) {
          if (confirm('与信評価を更新して取引を終了してよろしいでしょうか？')) {
            this.disable_btn = true;
            this.$store.dispatch('updateProcess', {
              'data': {
                'process': this.process,
                'method' : 'update',
                'user_responsible_for_late': this.user_responsible_for_late,
                'corporation_responsible_for_late': this.corporation_responsible_for_late,
                'credit_note': this.credit_note,
                'updated_at': this.$store.state.deal.UpdatedAt,
              }
            });
          }
        }
      });
    },
  },

  data() {
    return {
      'process'                           : this.$options.name,
      'user_responsible_for_late'         : this.$store.getters.userResponsibleForLate,
      'corporation_responsible_for_late'  : this.$store.getters.corporationResponsibleForLate,
      'credit_note'                       : this.$store.getters.updatingCreditNote,
      'disable_btn'                       : false,
    };
  },
};
</script>
