<template>
  <div style="margin-bottom:80px;">
    <h4 class="border-bottom border-secondary pb-2 mb-4 b f18">{{no + '. ' + title}}</h4>
    <div class="row">
      <div class="col-5">
        <div class="row">
          <div class="col-12">
            <IndicatorBox 
              :title="caseData.user.title"
              :color="'primary'"
              :icon="'user'"
              :params="caseData.user.params"
              :evidences="caseData.user.evidences"
              ></IndicatorBox>
          </div>
          <div class="col-12 text-center pt-2 pb-2">
            <span class="text-secondary cuis-x font-2xl" aria-hidden="true"></span>
          </div>
          <div class="col-12">
            <IndicatorBox 
              :title="caseData.corporation.title"
              :color="'warning'"
              :icon="'building'"
              :params="caseData.corporation.params"
              :evidences="caseData.corporation.evidences"
              ></IndicatorBox>
          </div>
          <div class="col-12 text-center pt-2 pb-2">
            <span class="text-secondary cuis-caret-bottom font-2xl" aria-hidden="true"></span>
          </div>
          <div class="col-12">
            <IndicatorBox 
             :title="'取引'"
             :color="'success'"
             :icon="'handshake'"
             :params="caseData.deal"
             ></IndicatorBox>
          </div>
        </div>
      </div>
      <div class="col-7">
        <StepChart 
          :data="caseData.chart"
          :labels="chartLabelsX"
          :title="caseData.chartTitle"
        ></StepChart>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import IndicatorBox   from './IndicatorBox.vue';
  import StepChart      from './StepChart.vue'

  export default {
    name: 'CaseBox',
    props: [
      'no',
      'title',
      'caseData',
    ],
    computed: {
      ...mapGetters([
        'chartLabelsX',
      ])
    },
    components: {
      IndicatorBox,
      StepChart,
    },
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>
