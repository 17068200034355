var initFincodeErrLogTooltip = function () {
    var elm = document.querySelectorAll('[data-toggle="fincode-error-log-tooltip"]');
    $(elm).tooltip({
        html: true,
        template: '<div class="fincode-error-log-tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    });
};
export default (function () {
    initFincodeErrLogTooltip();
});
