<template>
  <div class="col-12 boxDetail" v-if="openDetail">
    <div class="row">
      <div class="col-12">
        <TblTransactionRecords :tran="tran"></TblTransactionRecords>
        <div class="text-center mt-2">
          <div class="alert alert-danger" v-if="!tran.isValid">
            このユーザーは送金対象に指定出来ません。ユーザー編集より口座情報の修正を行ってください。
          </div>
          <div class="alert alert-danger" v-if="tran.HasBankAccountWarning">
            口座名義に誤りがある可能性があります。
          </div>
          <template v-if="isAddMode">
            <template v-if="!tran._targeted">
              <button class="btn btn-primary btn-lg" :disabled="!tran.isValid || !tran.targetable || calcTransactionAmount(tran) < 0 || calcTransactionAmount(tran) < 0 || (tran.User.IsDisabledOutcoming && calcTransactionAmount(tran) > 0)" @click="onClickTargeted">
                <template v-if="calcTransactionAmount(tran) === 0">
                  回収後送金額0円で伝票を締める
                </template>
                <template v-else>
                  送金合計{{fmt.currency(calcTransactionAmount(tran))}}
                  で処理対象化
                </template>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-secondary btn-lg" @click="onClickTargeted">
                対象から外す
              </button>
            </template>
          </template>
          <template v-else-if="CanRequestGanbTransferForOutcomingRecord()">
            <span class="text-muted b">送金予定日：</span>
            <Datepicker
              v-model="sendingDate"
              :format="confDatePicker.format"
              :language="confDatePicker.language"
              name="datepicker" />
            <button
              class="btn btn-primary btn-lg"
              @click="onClickRequestGanbTransferForOutcomingRecord"
              :disabled="disableRequestGanbTransferForOutcomingRecordBtn">
              振込再依頼
            </button>
            または
            <button class="btn btn-secondary btn-lg" @click="onClickMarkAsSucceeded">
              送金手続き済みとして記録する
            </button>
          </template>
          <template v-else-if="canMarkGanbTransferFailed()">
            <button
              class="btn btn-danger btn-lg"
              @click="onClickGanbTransferFailed"
              :disabled="disableGanbTransferFailedBtn">
              振込エラーとして記録する
            </button>
          </template>
        </div>
      </div>

      <template v-if="$store.getters.isAddMode">
        <div class="col-12 mt-3">
          <TblCollections :tran="tran"></TblCollections>
        </div>
      </template>

      <div class="col-12 mt-3">
        <div class="card">
          <div class="mb-0 card-header">
            <div class="row">
              <div class="col-md-9">
                <h6 class="pt-2">
                  <span class="cui-user font-weight-bold">
                    ユーザー情報
                  </span>
                </h6>
              </div>
              <div class="col-md-3 text-center">
                <a :href="'/users/view/' + tran.User.ID" target="_blank" class="btn btn-sm btn-secondary w-100">
                  <span class="cui-external-link" aria-hidden="true"></span>
                  ユーザー詳細へ
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <table class="col-md-6 tblUserInfo">
                <tr>
                  <th>氏名：</th>
                  <td>{{tran.User.FullName}}</td>
                </tr>
                <template v-if="!tran.User.Incorporated">
                  <tr>
                    <th>性別：</th>
                    <td>{{tran.User.MasterGender ? tran.User.MasterGender.Title : '-'}}</td>
                  </tr>
                  <tr>
                    <th>生年月日：</th>
                    <td>{{tran.User.StrBirthDay}}</td>
                  </tr>
                  <tr>
                    <th>屋号：</th>
                    <td>{{tran.User.BusinessName || '-'}}</td>
                  </tr>
                  <tr>
                    <th>ペンネームなど通り名：</th>
                    <td>{{tran.User.PenName || '-'}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <th>会社名：</th>
                    <td>{{tran.User.CompanyName}}</td>
                  </tr>
                  <tr>
                    <th>設立年：</th>
                    <td>{{tran.User.EstablishedYear}}年</td>
                  </tr>
                  <tr>
                    <th>資本金：</th>
                    <td>¥{{tran.User.CommaCapital || '-'}}</td>
                  </tr>
                </template>
                <tr>
                  <th>メールアドレス：</th>
                  <td>{{tran.User.Email}}</td>
                </tr>
                <tr>
                  <th>TEL：</th>
                  <td>{{tran.User.Tel}}</td>
                </tr>
              </table>
              <table class="col-md-6 tblUserInfo">
                <tr>
                  <th>〒：</th>
                  <td>
                    {{tran.User.Zipcode}}
                  </td>
                </tr>
                <tr>
                  <th>住所：</th>
                  <td>
                    {{tran.User.MasterPrefecture ? tran.User.MasterPrefecture.Title : ''}}
                    {{tran.User.City}}
                    {{tran.User.Address}}
                    {{tran.User.Building}}
                  </td>
                </tr>
                <tr>
                  <th>業種：</th>
                  <td>{{tran.User.IndustryList}}</td>
                </tr>
                <tr>
                  <th>業歴：</th>
                  <td>{{tran.User.IndustryYears}}年</td>
                </tr>
                <tr>
                  <th>請求書1枚の平均請求額※：</th>
                  <td>¥{{tran.User.CommaAveInvoiceSales}}</td>
                </tr>
                <tr>
                  <th>月間売上平均※：</th>
                  <td>¥{{tran.User.CommaAveMonthlySales}}</td>
                </tr>
                <tr>
                  <th>メインバンク：</th>
                  <td>{{tran.User.MainBank}}</td>
                </tr>
              </table>
              <div class="col-md-12 text-right py-2 text-muted">
                ※会員登録時申告値です。現在の詳細は「ユーザー詳細へ」から確認して下さい。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tblUserInfo th {
  color:#73818f!important;
  text-align:right;
  font-weight:bold;
  padding:5px 0;
}
.boxDetail {
  margin-top:10px;
  margin-left:0;
  margin-right:0;
}
.vdp-datepicker, .vdp-datepicker div {
  display:inline-block;
}
</style>

<script>
  import moment from 'moment';
  moment.locale('ja');
  import Datepicker from 'vuejs-datepicker';
  import TblTransactionRecords  from './TblTransactionRecords/Table'
  import TblCollections         from './TblCollections/Table'
  import { mapGetters }         from 'vuex'
  import fmt from '../util.js'
  export default {
    name: 'RowDetail',
    props: [
      'tran',
      'openDetail',
    ],
    computed: {
      ...mapGetters([
        'calcTransactionAmount',
        'isAddMode',
        'isViewMode',
        'transactions'
      ]),
    },
    components: {
      TblTransactionRecords,
      TblCollections,
      Datepicker
    },
    methods: {
      CanRequestGanbTransferForOutcomingRecord() {
        return this.isViewMode
          && !this.outcomingRecord.Failed
          && (this.outcomingRecord.MasterGanbTransferStatusID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_API_ERROR
              || this.outcomingRecord.MasterGanbTransferStatusID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_WHITE_LIST_ERROR
              || this.outcomingRecord.MasterGanbTransferStatusID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_FAILED)
          && this.isAdminValidForGanbTransfer;
      },
      canMarkGanbTransferFailed() {
        return this.isViewMode
          && this.outcomingRecord.MasterGanbTransferStatusID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFERED
          && this.isAdminValidForGanbTransfer;
      },
      onClickTargeted() {
        if (!this.tran._targeted === true) {
          const currTargeted = this.transactions.filter(tranVal => tranVal._targeted);
          if (currTargeted.length >= document.ACCOUNT_NUMBER_PER_REQUEST) {
            alert(`${document.ACCOUNT_NUMBER_PER_REQUEST}件までしか処理できません`);
            return;
          }
          
        }

        this.tran._targeted = !this.tran._targeted;
      },
      onClickMarkAsSucceeded() {
        if (confirm("このデータを送金手続き済みとして記録してもよろしいでしょうか？オンラインバンキングなどからすでに振込が完了している前提となります。\n※この操作は元に戻すことができません\n※この操作でユーザーに送金完了メールは送信されません")) {
          this.$store.dispatch('saveAsTransferSucceeded', {
            id : this.tran.ID,
          });
        }
      },
      onClickRequestGanbTransferForOutcomingRecord() {
        const today = new Date();
        if (moment(this.sendingDate).add('days', 1).isBefore(today)) {
          alert('送金予定日が不適切です、再度ご確認下さい');
          return false;
        }
        if (confirm("このデータを振込再依頼してよろしいでしょうか？\n※この操作は元に戻すことができません")) {
          this.disableRequestGanbTransferForOutcomingRecordBtn = true;
          this.$store.dispatch('saveRequestGanbTransferForOutcomingRecord', {
            id : this.tran.OutcomingRecordID,
            data : {
              sending_date: moment(this.sendingDate).format('YYYY-MM-DD')
            },
            onSuccess: () => {
              this.disableRequestGanbTransferForOutcomingRecordBtn = false;
            },
            onError: () => {
              this.disableRequestGanbTransferForOutcomingRecordBtn = false;
            }
          });
        }
      },
      onClickGanbTransferFailed() {
        if (confirm("このデータを振込エラーとして記録してよろしいでしょうか？\n※この操作は元に戻すことができません")) {
          this.disableGanbTransferFailedBtn = true;
          this.$store.dispatch('saveMarkAsGanbTransferFailed', {
            id : this.tran.OutcomingRecordID,
            onSuccess: () => {
              this.disableGanbTransferFailedBtn = false;
            },
            onError: () => {
              this.disableGanbTransferFailedBtn = false;
            }
          });
        }
      }
    },
    created() {
      if (this.isViewMode && this.$store.state.outcoming) {
        this.outcomingRecord = this.$store.state.outcoming.OutcomingRecords.find(OutRec => OutRec.ID === this.tran.OutcomingRecordID);
        this.sendingDate = moment(this.outcomingRecord.SendingDate).format('YYYY/MM/DD');
        this.isAdminValidForGanbTransfer = this.$store.state.isAdminValidForGanbTransfer;
      }
    },

    data() {
      return {
        masterGanbTransferStatus: this.$store.state.masterGanbTransferStatus,
        outcoming: this.$store.state.outcoming,
        outcomingRecord: null,
        disableRequestGanbTransferForOutcomingRecordBtn: false,
        disableGanbTransferFailedBtn: false,
        confDatePicker: fmt.confDatePicker,
        sendingDate: null,
        fmt: fmt,
        isAdminValidForGanbTransfer: false,
      };
    },
  };
</script>
