var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Module, Mutation, Action, VuexModule, getModule } from 'vuex-module-decorators';
import axios from 'axios';
import moment from 'moment';
import store from './store';
var MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_WAITING_JUDGE = 1;
var MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_COMPLETED = 2;
var MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_REJECTED = 3;
export var JudgeOptionIds;
(function (JudgeOptionIds) {
    JudgeOptionIds[JudgeOptionIds["Empty"] = 0] = "Empty";
    JudgeOptionIds[JudgeOptionIds["Approving"] = 1] = "Approving";
    JudgeOptionIds[JudgeOptionIds["Rejecting"] = 2] = "Rejecting";
})(JudgeOptionIds || (JudgeOptionIds = {}));
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.requestData = {
            requestID: 0,
            createdAt: '',
            judgeDeclineReason: '',
            judgeNote: '',
            fullname: '',
            masterRequestStatusID: 0,
            masterRequestStatusTitle: ''
        };
        _this.virtualBankAccount = {
            branchName: '',
            branchCode: '',
            accountName: '',
            accountNumber: ''
        };
        _this.approved = false;
        _this.currentJudgeOptionId = 0;
        return _this;
    }
    Object.defineProperty(Main.prototype, "isActive", {
        get: function () {
            var _this = this;
            return function (process) {
                var statusId = _this.requestData.masterRequestStatusID;
                if (process === 'CheckContent') {
                    return statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_WAITING_JUDGE;
                }
                if (process === 'Complete') {
                    return statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_COMPLETED || statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_REJECTED;
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "createdDate", {
        get: function () {
            moment.locale('ja');
            var frmt = 'YYYY/MM/DD HH:mm:ss';
            var d = new Date(this.requestData.createdAt);
            if (d.toString() === 'Invalid Date') {
                return '-';
            }
            return d.getFullYear() === 1 ? '-' : moment(d).format(frmt);
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.setApproval = function (value) {
        this.approved = value;
    };
    Main.prototype.loadData = function (payload) {
        this.requestData = payload.requestData;
        this.virtualBankAccount = payload.virtualBankAccount;
        var statusId = this.requestData.masterRequestStatusID;
        if (statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_WAITING_JUDGE) {
            this.currentJudgeOptionId = JudgeOptionIds.Empty;
        }
        if (statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_COMPLETED) {
            this.currentJudgeOptionId = JudgeOptionIds.Approving;
        }
        if (statusId === MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_REJECTED) {
            this.currentJudgeOptionId = JudgeOptionIds.Rejecting;
        }
        if (statusId !== MASTER_REQUEST_CHANGING_VIRTUAL_BANK_ACCOUNT_NAME_STATUS_COMPLETED) {
            this.virtualBankAccount.branchName = '新しい支店名';
            this.virtualBankAccount.branchCode = '新しい支店コード';
            this.virtualBankAccount.accountNumber = '新しい口座番号';
        }
    };
    Main.prototype.setCurrentJudgeOptionId = function (value) {
        this.currentJudgeOptionId = value;
    };
    Main.prototype.updateRequestChangingVirtualBankAccountNameProcess = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, data, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.setApproval(this.currentJudgeOptionId === JudgeOptionIds.Approving);
                        params = new URLSearchParams();
                        data = {
                            approved: this.approved,
                            judge_decline_reason: this.requestData.judgeDeclineReason,
                            judge_note: this.requestData.judgeNote
                        };
                        params.append('data', JSON.stringify(data));
                        return [4 /*yield*/, axios.post("/apiInternal/UpdateRequestChangingVirtualAccountNameProcess/" + this.requestData.requestID, params)];
                    case 1:
                        res = _a.sent();
                        if (res.data.status === 200)
                            location.reload();
                        else
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "setApproval", null);
    __decorate([
        Mutation
    ], Main.prototype, "loadData", null);
    __decorate([
        Mutation
    ], Main.prototype, "setCurrentJudgeOptionId", null);
    __decorate([
        Action
    ], Main.prototype, "updateRequestChangingVirtualBankAccountNameProcess", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export var mainModule = getModule(Main);
