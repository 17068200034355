import { generateKey } from './Util'

export default {
  // Set Commission from global to store
  initCommission(state, payload) {
    // Construct user_ranks initial data
    let commissions = [];
    payload.ranking_version.Commissions.forEach((rank, i) => {
      commissions.push({
        key:                              generateKey(),
        id:                               rank.ID,
        name:                             rank.Name,
        description:                      rank.Description,
        rate_in_20_days:                  rank.RateIn20Days,
        rate_in_40_days:                  rank.RateIn40Days,
        rate_in_60_days:                  rank.RateIn60Days,
        master_delivery_evidence_type_id: rank.MasterDeliveryEvidenceTypeID,
        is_valid: {},
      });
    });
    state.commissions = commissions;
    state.master_delivery_evidence_types = payload.master_delivery_evidence_types;
  },
   
  // Update individual deal rank
  updateCommission(state, payload) {
    state.commissions.forEach((rank, i) => {
      if (rank.key == payload.key) {
        let val = payload.value;
        if (
          payload.attr === 'rate_in_20_days' 
          || payload.attr === 'rate_in_40_days'
          || payload.attr === 'rate_in_60_days'
        ) {
          val = parseFloat(val);
          if (isNaN(val) || val === 0) {
            val = payload.value;
          }
        }
        state.commissions[i][payload.attr] = val;
      }
    });
  },
  
  // Update validation status of individual deal rank
  updateCommissionIsValid(state, payload) {
    state.commissions.forEach((rank, i) => {
      if (rank.key == payload.key) {
        state.commissions[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },

};
