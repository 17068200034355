<template>
  <div
    v-if="isEkyc"
    class="card mb-1">
    <ToggleHeader
      :name="no + '. 自動審査'"
      :process="process"
      :target="'#collapseStep'+no" />
    <ul
      :id="'collapseStep'+no"
      class="list-group list-group-flush collapse"
      :class="{ 'show':isActive(process) || isActive('JudgeTrustdock') }">
      <li class="list-group-item">
        <h6 class="font-weight-bold">
          OPLUX
          <template v-if="application.HasOpluxInfo">
            <span class="badge badge-danger">情報あり</span>
          </template>
          <template v-else>
            <span class="badge badge-success">情報なし</span>
          </template>
        </h6>
        <template v-if="application.HasOpluxInfo">
          <div class="card bg-light mb-0">
            <div class="card-body">
              <pre class="m-0">{{ opluxResult.result }}</pre>
            </div>
          </div>
        </template>
      </li>
      <li class="list-group-item">
        <h6 class="font-weight-bold">
          TRUSTDOCK 身元確認
          <span v-if="isCheckedEkycForHo">（犯罪収益移転禁止法[ホ]）</span>
          <template v-if="trustDockPersonal.result == '承認'">
            <span class="badge badge-success">承認</span>
          </template>
          <template v-else-if="trustDockPersonal.result == '非承認'">
            <span class="badge badge-danger">非承認</span>
          </template>
          <template v-else>
            <span class="badge badge-info">審査中</span>
          </template>
        </h6>
        <template v-if="trustDockPersonal.result == '非承認'">
          <span>非承認理由:</span>
          <div class="card bg-light mb-1">
            <div class="card-body">
              <template v-if="trustDockPersonal.deniedReasons !== ''">
                <pre class="m-0">{{ trustDockPersonal.deniedReasons }}</pre>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </div>
        </template>
        <ul class="m-0 pl-3">
          <li>ID: {{ trustDockPersonal.checkID }}</li>
          <li>依頼日時: {{ trustDockPersonal.requestAt }}</li>
          <li>完了日時: {{ trustDockPersonal.completeAt }}</li>
        </ul>
      </li>
      <li class="list-group-item">
        <h6 class="font-weight-bold">
          TRUSTDOCK AMLチェック
          <template v-if="trustDockAml.result == '承認'">
            <span class="badge badge-success">承認</span>
          </template>
          <template v-else-if="trustDockAml.result == '非承認'">
            <span class="badge badge-danger">非承認</span>
          </template>
          <template v-else>
            <span class="badge badge-info">審査中</span>
          </template>
        </h6>
        <template v-if="trustDockAml.result == '非承認'">
          <span>非承認理由:</span>
          <div class="card bg-light mb-1">
            <div class="card-body">
              <template v-if="trustDockAml.deniedReasons !== ''">
                <ul class="pl-3">
                  <li v-if="trustDockAml.resultType !== ''"><pre class="m-0">{{ trustDockAml.resultType }}</pre></li>
                  <li v-if="trustDockAml.resultMatches !== ''"><pre class="m-0">{{ trustDockAml.resultMatches }}</pre></li>
                  <li><pre class="m-0">{{ trustDockAml.deniedReasons }}</pre></li>
                </ul>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </div>
        </template>
        <ul class="m-0 pl-3">
          <li>ID: {{ trustDockAml.checkID }}</li>
          <li>依頼日時: {{ trustDockAml.requestAt }}</li>
          <li>完了時間: {{ trustDockAml.completeAt }}</li>
        </ul>
      </li>
      <li class="list-group-item">
        <h6 class="font-weight-bold">
          同一可能性主体チェック
          <template v-if="isCheckedApplicationSamePossibility">
            <span class="badge badge-success">OK</span>
          </template>
          <template v-else>
            <span class="badge badge-danger">NG</span>
          </template>
        </h6>
        <template v-if="!isCheckedApplicationSamePossibility">
          <span>同一可能性主体理由:</span>
          <div class="card bg-light mb-1">
            <div class="card-body">
              <ul class="pl-3">
                <li v-for="(app, index) in applicationSamePossibilityReasons" :key="'application_same_possibility_reason'+index">
                  {{app}}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </li>
      <li class="list-group-item p-3" v-if="!isCheckedApplicationSamePossibility">
        <label>同一可能性ユーザーチェック</label>
        <div>
          <a v-bind:href="`/frauds/search?freenance_user_id=${this.$store.state.application.user_id}`" target="_blank" className="pl-3">開く</a>
        </div>
      </li>
      <li class="list-group-item ">
        <button
          class="btn btn-secondary w-100"
          :disabled="!isActive(process)"
          @click="skipTrustDock">
          スキップ（補助審査へ）
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToggleHeader from './parts/ToggleHeader.vue';

export default {
  name: 'TrustDockCheck',
  components: {
    ToggleHeader
  },
  props: {
    no: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'isEkyc',
      'isActive',
      'trustDockPersonal',
      'trustDockAml',
      'opluxResult',
      'application',
      'isCheckedEkycForHo',
      'isCheckedApplicationSamePossibility',
      'applicationSamePossibilityReasons'
    ])
  },
  data() {
    const process = this.$options.name;
    return {
      process
    };
  },
  methods: {
    skipTrustDock() {
      if (confirm('委託審査をスキップして、補助審査へ移行してよろしいですか？')) {
        this.$store.dispatch('updateProcess', {
          data: {
            process: this.process,
            method: 'skip'
          }
        });
      }
    }
  }
};
</script>
