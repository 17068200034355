import Vue    from 'vue';
import App    from './components/App.vue';
import store  from './store'
export default function page () {
  new Vue({
    el: '#app',
    store: store,
    components: {
      'App': App
    },
    created () {
      this.$store.commit('loadData', {
        transactions                      : document.TRANSACTIONS,
        masterTransactionRecordTypes      : document.MASTER_TRANSACTION_RECORD_TYPES,
        mode                              : document.MODE,
        isMasterOutcomingStatusFixed      : document.IS_MASTER_OUTCOMING_STATUS_FIXED,
        outcoming                         : document.OUTCOMING,
        isGanbAPIConnected                : document.IS_GANB_API_CONNECTED,
        masterGanbTransferStatus          : document.MASTER_GANB_TRANSFER_STATUS,
        isMasterOutcomingStatusTransfered : document.IS_MASTER_OUTCOMING_STATUS_TRANSFERED,
        isAllowTransferRequestOutcoming   : document.IS_ALLOW_TRANSFER_REQUEST_OUTCOMING,
        isAdminValidForGanbTransfer       : document.IS_ADMIN_VALID_FOR_GANB_TRANSFER,
        isGroupSupportAdmin               : document.IS_GROUP_SUPPORT_ADMIN,
        filterCorporationBankAccounts     : document.CORPORATION_FILTER_BANK_ACCOUNTS,
      });
    },
  });
};
