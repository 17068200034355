import TableSort from 'tablesort';
var parseDate = function (date) {
    return new Date(date).getTime() || -1;
};
var parseScore = function (score) {
    if (score.match(/-/g))
        return parseInt(score.replace(/-/g, ''), 10) * (-1);
    if (score.match(/\+/g))
        return parseInt(score.replace(/\+/g, ''), 10);
    return 0;
};
export default (function (className) {
    TableSort.extend('datetime', function (item) {
        if (item.search(/\d{4}[\/]\d{1,2}[\/]\d{2}[ ]\d{2}[:]\d{2}[:]\d{2}/))
            return true;
        return false;
    }, function (a, b) {
        return parseDate(b) - parseDate(a);
    });
    TableSort.extend('score', function (item) {
        if (item.match(/^[-+]?(\d)+$/))
            return true;
        return false;
    }, function (a, b) {
        return parseScore(b) - parseScore(a);
    });
    TableSort.extend('stringNumber', function (item) {
        if (item.match(/^\d+$/))
            return true;
        return false;
    }, function (a, b) {
        return parseInt(b, 10) - parseInt(a, 10);
    });
    var tables = Array.from(document.getElementsByClassName(className));
    tables.forEach(function (table) {
        TableSort(table, { descending: true });
    });
});
