<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
  >
    <CaseBox
      v-for="c, key in casesForCasePreview"
      :key="key"
      :no="key+1"
      :title="c.title" 
      :caseData="c.caseData"
    ></CaseBox>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import CaseBox        from './CaseBox.vue';

  export default {
    name: 'TabPaneSimulation',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'casesForCasePreview',
      ])
    },
    components: {
      CaseBox,
    },
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>
