export function initBtnCopyPage(selector) {
    $(selector).on("click", function () {
        var $btn = $(this);
        var text = $btn.data("text") + "\n" + location.href;
        copyActually($btn, text);
    });
}
export function initBtnCopyText(selector) {
    $(selector).on("click", function () {
        var $btn = $(this);
        copyActually($btn, $btn.data("text"));
    });
}
export function copyActually($btn, text) {
    var result = execCopy(text);
    var $lbl = $btn.find(".btnLbl");
    var title = $lbl.text();
    $btn.removeClass("btn-outline-primary");
    if (result) {
        $lbl.text("クリップボードにコピーしました");
        $btn.addClass("btn-success");
    }
    else {
        $lbl.text("失敗しました");
        $btn.addClass("btn-danger");
    }
    setTimeout(function () {
        $btn
            .removeClass("btn-success")
            .removeClass("btn-danger")
            .addClass("btn-outline-primary");
        $lbl.text(title);
    }, 2000);
}
function execCopy(text) {
    var copyFrom = document.createElement("textarea");
    copyFrom.textContent = text;
    document.body.appendChild(copyFrom);
    copyFrom.select();
    var result = document.execCommand("copy");
    document.body.removeChild(copyFrom);
    return result;
}
