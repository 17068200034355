import './scss/style.scss';
import './scss/coreui-icons/scss/coreui-icons-linear.scss';
import './scss/coreui-icons/scss/coreui-icons-solid.scss';

/* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

import PDFObject from 'pdfobject';
import $ from 'jquery';
import 'bootstrap';
import '@coreui/coreui/js/dist/ajax-load';
import '@coreui/coreui/js/dist/sidebar';
import 'ion-rangeslider';

import ApplicationView from './js/app/ApplicationView/main';
import RankingVersionEditor from './js/app/RankingVersionEditor/main';
import DealView from './js/app/DealView/main';
import UserEvidenceView from './js/app/UserEvidenceView/main';
import RequestChangingVirtualAccountNameView from './js/app/RequestChangingVirtualBankAccountNameView/main';
import ContactView from './js/app/ContactView/main';
import OutcomingsAdd from './js/app/OutcomingsAdd/main';
import OutcomingsView from './js/app/OutcomingsView/main';
import CollectingDealsTable from './js/app/CollectingDealsTable/main';
import CollectingDealsTodayTable from './js/app/CollectingDealsTodayTable/main';
import URLLoader from './js/utils/URLLoader';
import initHeaderSearch from './js/utils/HeaderSearch';
import dateRangePicker from './js/utils/InputDateRangePicker';
import dateRangeSlider from './js/utils/DateRangeSlider';
import { initBtnCopyPage, initBtnCopyText } from './js/utils/CopyToClipboard';
import { initBtnJudge, initBtnInsurance } from './js/PgInsurance';
import initNewsEditor from './js/NewsEditor';
import processingInput from './js/app/NotifiedFactoring/processingInput';
import processClickItem from './js/app/DealSearch/processClickItem';
import processingInvitingPhrases from './js/app/InvitingPhrases/processingInvitingPhrases';
import MemoInit from './js/app/MemoSection/main';
import CorpIndustrySection from './js/app/CorpIndustrySection/main';
import DealIndustrySection from './js/app/DealIndustrySection/main';
import UserLinkingServices from './js/app/UserLinkingServices/main';
import setConfirmationToExternalLinks from './js/utils/SetConfirmationToExternalLinks';
import collectionReminders from './js/utils/collectionReminders';
import makeTableSortable from './js/utils/TableSort';
import DealEvidence from './js/app/DealEvidence/main';
import CollectionReminder from './js/app/CollectionReminder/main';
import processingAdminGroup from './js/app/AdminUser/processingAdminGroup';
import corporationDeal from './js/utils/corporationDeal';
import fincodeErrLogTooltip from './js/utils/fincodeErrLogTooltip';
import client from './js/utils/client';
import SpotEdit from './js/app/Spot/main';
import Campaign from './js/app/Campaign/main';
import JudgeCampaign from './js/app/Campaign/judgeCampaign';
import common from './js/common';

$(() => {
  common();

  // exec page function
  URLLoader([{
    pattern: /\/rankingVersions\/edit\/.*/,
    page: RankingVersionEditor
  }, {
    pattern: /\/applications\/view\/.*/,
    page: ApplicationView
  }, {
    pattern: /\/requestChangingVirtualAccountNames\/view\/.*/,
    page: RequestChangingVirtualAccountNameView
  }, {
    pattern: /\/deals\/view\/.*/,
    page() {
      if (document.IS_ADMIN_VALID_FOR_JUDGE_DEAL || document.IS_GROUP_SUPPORT_ADMIN || document.IS_GROUP_FINANCE_ADMIN) {
        DealView();
      }
      DealEvidence();
      dateRangeSlider();
      MemoInit('userMemoSection');
      MemoInit('corpMemoSection');
      CorpIndustrySection('coporationIndustrySection');
      DealIndustrySection('dealIndustrySection');
    }
  }, {
    pattern: /\/deals\/search($|\?.*)/,
    page: processClickItem
  }, {
    pattern: /\/userEvidences\/view\/.*/,
    page: UserEvidenceView
  }, {
    pattern: /\/contacts\/view\/.*/,
    page: ContactView
  }, {
    pattern: /\/corporationDeals\/search($|\?.*)/,
    page: corporationDeal
  }, {
    pattern: /\/clients\/view\/.*/,
    page: client
  }, {
    pattern: /\/outcomings\/add/,
    page: OutcomingsAdd
  }, {
    pattern: /\/outcomings\/view\/.*/,
    page: () => {
      OutcomingsAdd();
      OutcomingsView();
    },
  }, {
    pattern: /\/notifiedFactorings\/(edit|add|view).*/,
    page: processingInput
  }, {
    pattern: /\/collectionDeals\/search($|\?.*)/,
    page() {
      CollectingDealsTable();
    }
  }, {
    pattern: /\/invitingPhrases\/(edit|add).*/,
    page: processingInvitingPhrases
  }, {
    pattern: /\/news\/[add|edit\/.*]/,
    page: initNewsEditor
  }, {
    pattern: /\/adminUsers\/(edit|add).*/,
    page: processingAdminGroup
  }, {
    pattern: /\/home.*/,
    page() {
      CollectingDealsTodayTable();
      // Would be added another RIA apps maybe..
    }
  }, {
    pattern: /\/users\/view\/.*/,
    page() {
      MemoInit('memoSection');
      UserLinkingServices('freeeInvoiceTable');
      UserLinkingServices('MisocaInvoiceTable');
      UserLinkingServices('MfCloudInvoiceTable');
      fincodeErrLogTooltip();
    }
  }, {
    pattern: /\/corporations\/view\/.*/,
    page() {
      MemoInit('memoSection');
      CorpIndustrySection('coporationIndustrySection');
    }
  }, {
    pattern: /\/collectionReminders\/search/,
    page: collectionReminders
  }, {
    pattern: /\/collectionReminders\/view\/.*/,
    page() {
      CollectionReminder();
    }
  }, {
    pattern: /\/spots\/edit\/.*/,
    page: SpotEdit
  }, {
    pattern: /\/judgeCampaignDeals\/index.*/,
    page: JudgeCampaign
  }, {
    pattern: /\/campaigns\/(edit|add).*/,
    page: Campaign
  }, {
    pattern: /\/planCampaign\/(add).*/,
    page: Campaign
  }
  ]);

  // Activate PDFObject if it exists in the document
  $('.boxPDF').each(function(){
    PDFObject.embed($(this).data('pdf'), this);
  });

  // Activate search box on header
  initHeaderSearch();

  // Make .btnCopyToClipboard to copy text
  initBtnCopyPage('.btnCopyPage');
  initBtnCopyText('.btnCopyText');

  initBtnJudge('.btnPGJudge');
  initBtnInsurance('.btnPGInsurance');

  // Bootstrap initializations
  $('[data-toggle="tooltip"]').tooltip({ html: true });

  setConfirmationToExternalLinks();

  // Bootstrap Date Range Picker
  dateRangePicker();

  makeTableSortable('table-sort');
});
