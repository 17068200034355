var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import ToggleHeader from './parts/ToggleHeader.vue';
import mainStore from '../store/main';
var ResultCheck = /** @class */ (function (_super) {
    __extends(ResultCheck, _super);
    function ResultCheck() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'ResultCheck';
        _this.active = mainStore.isActive(_this.name);
        _this.reminder = mainStore.collectionReminder;
        _this.statusOptions = mainStore.statusOptions;
        _this.extendedDayOptions = mainStore.extendedDayOptions;
        _this.adminNote = mainStore.collectionReminder.AdminNote;
        _this.adminID = mainStore.loginUserID;
        _this.updatedAdminName = mainStore.updatedAdminName;
        _this.checkedDate = mainStore.checkedDate;
        _this.statusID = mainStore.isCompletedStatus ? mainStore.collectionReminder.MasterCollectionReminderStatusID : 0;
        _this.extendedDays = mainStore.collectionReminder.AdminExtendedDayValue;
        return _this;
    }
    Object.defineProperty(ResultCheck.prototype, "isExtendedDeadline", {
        get: function () {
            return this.statusID === mainStore.extendedWithin5daysStatus;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResultCheck.prototype, "isDisabled", {
        get: function () {
            return !this.active || mainStore.isOnAPIProcess;
        },
        enumerable: false,
        configurable: true
    });
    ResultCheck.prototype.completeReminder = function () {
        var _this = this;
        this.$validator.validate().then(function (result) {
            if (result) {
                if (confirm('保存してもよろしいでしょうか？')) {
                    var params = {
                        master_collection_reminder_status_id: _this.statusID,
                        responsible_admin_user_id: _this.adminID,
                        admin_note: _this.adminNote,
                        admin_extended_day_value: _this.extendedDays
                    };
                    mainStore.completeReminder(params);
                }
            }
        });
    };
    ResultCheck = __decorate([
        Component({
            components: {
                ToggleHeader: ToggleHeader
            }
        })
    ], ResultCheck);
    return ResultCheck;
}(Vue));
export default ResultCheck;
