var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { Vue, Component, Prop } from 'vue-property-decorator';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
var DealIndustry = /** @class */ (function (_super) {
    __extends(DealIndustry, _super);
    function DealIndustry() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.message = '';
        _this.isError = false;
        _this.isEditingMode = false;
        _this.selectedIndustryID = _this.getInitialSelectedIndustryId();
        _this.selectedIndustryTitle = _this.getSelectedIndustryTitle(_this.deal.MasterDealIndustry.ID);
        return _this;
    }
    DealIndustry.prototype.getInitialSelectedIndustryId = function () {
        return this.deal.MasterDealIndustry.ID ? this.deal.MasterDealIndustry.ID : this.masterDealIndustryDefaultId;
    };
    DealIndustry.prototype.getSelectedIndustryTitle = function (newID) {
        var selectedIndustry = this.masterDealIndustries.find(function (industry) { return industry.ID === newID; });
        return selectedIndustry ? selectedIndustry.Title : '';
    };
    DealIndustry.prototype.startEditingMode = function () {
        this.isEditingMode = true;
        this.message = '';
    };
    DealIndustry.prototype.cancelEditingMode = function () {
        this.isEditingMode = false;
        this.message = '';
        this.resetUnsavedChanges();
    };
    DealIndustry.prototype.resetUnsavedChanges = function () {
        this.selectedIndustryTitle = this.getSelectedIndustryTitle(this.deal.MasterDealIndustry.ID);
        this.selectedIndustryID = this.getInitialSelectedIndustryId();
    };
    DealIndustry.prototype.UpdateDealIndustries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        params = new URLSearchParams();
                        params.append('data', JSON.stringify({
                            deal_id: this.deal.ID,
                            master_deal_industry_id: this.selectedIndustryID
                        }));
                        return [4 /*yield*/, axios.post('/apiInternal/UpdateDealIndustry', params)];
                    case 1:
                        res = _a.sent();
                        if (res.data.status !== 200) {
                            this.resetUnsavedChanges();
                            throw new Error('エラーが発生しました。もう一度やり直してください。');
                        }
                        else {
                            this.isEditingMode = false;
                            this.deal.MasterDealIndustry.ID = this.selectedIndustryID;
                            this.deal.MasterDealIndustryID = this.selectedIndustryID;
                            this.selectedIndustryTitle = this.getSelectedIndustryTitle(this.selectedIndustryID);
                            this.updateMsg('業種が更新されました。');
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        alert(err_1.message);
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DealIndustry.prototype.updateMsg = function (msg) {
        var _this = this;
        this.message = msg;
        setTimeout(function () { _this.message = ''; }, 5000);
    };
    __decorate([
        Prop(Object)
    ], DealIndustry.prototype, "deal", void 0);
    __decorate([
        Prop(Number)
    ], DealIndustry.prototype, "masterDealIndustryDefaultId", void 0);
    __decorate([
        Prop(Array)
    ], DealIndustry.prototype, "masterDealIndustries", void 0);
    DealIndustry = __decorate([
        Component({
            components: {
                vSelect: vSelect
            }
        })
    ], DealIndustry);
    return DealIndustry;
}(Vue));
export default DealIndustry;
