import Vue    from 'vue';
import App    from './components/App.vue';
import store  from './store'
export default function page () {
  new Vue({
    el: '#app',
    store: store,
    components: {
      'App': App
    },
  });
};
