export default {
  // Init Application
  initApplication(state, payload) {
    state.application = payload.application;
    state.user = payload.user;
    state.admin_users = payload.admin_users;
    state.login_user_id = payload.login_user_id;
    state.application_same_possibilities = payload.application_same_possibilities;
  },
};
