<template>
  <div class="card-body border-bottom" v-if="displaySection">
    <h6 class="font-weight-bold">新しいレコードを追加する</h6>
    <div class="row align-items-center font-weight-bold">
      <div class="col-2">
        <label class="form-label text-right">日付 :</label>
        <div class="font-weight-bold">{{newRecord.CreatedAt}}</div>
      </div>
      <div class="col-2">
        <label class="form-label text-right">発生タイプ :</label>
        <select 
          :name="'tranRecType'"
          v-validate="'required'"
          @change="onChangeType" 
          v-model="newRecord.MasterTransactionRecordTypeID"
          class="form-control form-control-sm" 
          :class="{'is-invalid': errors.has('tranRecType')}"
        >  
          <option value="">-</option>
          <option v-for="t in optTRTypes(tran)" :disabled="t.disabled" :value="t.ID">{{t.Title}}</option>
        </select>
        <div v-if="errors.has('tranRecType')" class="invalid-feedback">
          必須入力です
        </div>
      </div>
      <div class="col-5">
        <label class="form-label text-right">内容 :</label>
        <input 
          :name="'tranRecTtile'"
          v-validate="'required'"
          v-model="newRecord.Title" 
          class="form-control" 
          :class="{'is-invalid': errors.has('tranRecTtile')}"
          :disabled="isOnCollectAll" 
        />
        <div v-if="errors.has('tranRecTtile')" class="invalid-feedback">
          必須入力です
        </div>
      </div>
      <div class="col-3">
        <label class="form-label text-right">
          {{isPositiveRecord ? '加算額 (+):' : '減算額 (-):' }}
        </label>
        <input 
          :name="'tranRecAmount'"
          v-validate="'required|min_value:1'"
          type="number" 
          v-model="newRecord.Amount" 
          class="form-control" 
          :class="{'is-invalid': errors.has('tranRecAmount')}"
          :disabled="isOnCollectAll" 
        />
        <div v-if="errors.has('tranRecAmount')" class="invalid-feedback">
          必須入力です
        </div>
      </div>
    </div>
    <div class="row align-items-center font-weight-bold mt-2" v-if="showCollectionables">
      <div class="col-7 offset-2">
        <label class="form-label text-right">充当先:</label>
        <select 
          @change="onChangeCollectionable" 
          class="form-control form-control-sm" 
          v-model="newRecord._collectionID"
          :name="'tranCollectionable'"
          :class="{'is-invalid': errors.has('tranCollectionable')}"
          v-validate="'required'"
        >
          <option value="">-</option>
          <option v-for="t in optTranCollectionables(tran)" :disabled="t.disabled" v-bind:value="t.id">{{t.name}}</option>
        </select>
        <div v-if="errors.has('tranCollectionable')" class="invalid-feedback">
          必須入力です
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center mt-3">
        <button class="btn btn-primary" @click="onClickAddNewRecord">追加</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import fmt from '../../util.js'
  import ListRow from '../parts/ListRow'
  export default {
    name: 'AddRecordSection',
    props: [
      'tran',
      'displaySection',
    ],
    watch : {
      displaySection(newVal) {
        // when opening adding new record
        if (newVal) {
          // reset form
          let data = initNewRecord();
          data.TransactionID = this.tran.ID;
          this.newRecord = data;
          this.showCollectionables = false;
          this.isOnCollectAll = false; 
        }
      },
    },
    computed: {
      ...mapGetters([
        'optTRTypes',
        'optTranCollectionables',
        'calcTransactionAmount',
        'constMasterTRTypes',
        'masterTransactionRecordType',
      ]),
      // Wheither selected 「発生タイプ」 is positive or not
      // == MASTER_TRANSACTION_TYPE_BRINGING_BACK
      isPositiveRecord() {
        return this.newRecord.MasterTransactionRecordTypeID 
          === this.constMasterTRTypes.MASTER_TRANSACTION_TYPE_BRINGING_BACK;
      },
    },
    components: {
      ListRow,
    },
    methods: {
      onClickAddNewRecord() {
        this.$validator
          .validate("*").then(valid => {
            // check Vee-Validate result
            if (!valid) {
              return;
            }

            let m = this.constMasterTRTypes;
            let typeId = this.newRecord.MasterTransactionRecordTypeID;

            // 運営者負担金の場合: instead of subtracting, the amount will be added to the total amount
            // so the value will be positive instead of negative one 
            const signedNewAmount = this.isPositiveRecord ? this.newRecord.Amount : this.newRecord.Amount * -1;
            // check the sending amount is not minus
            if (this.calcTransactionAmount(this.tran) + signedNewAmount < 0) {
              alert("送金合計が0円を下回るためレコードを追加できません")
              return;
            }
            
            // check less than collection amount
            if (typeId === m.MASTER_TRANSACTION_TYPE_COLLECT_PART) {
              let coll = this.tran.Collections.find((coll) => coll.ID == this.newRecord._collectionID);
              if (coll.Amount <= this.newRecord.Amount) {
                alert("部分回収は回収予定額と同額、またはそれを上回る金額を充当することはできません")
                return;
              }
            }
            
            // Fetch MasterTransactionRecordType
            this.newRecord.MasterTransactionRecordType 
              = this.masterTransactionRecordType(typeId);
            
            // Unset CollecitonID for some transaction types
            // or having "" in this.newRecord._collectionID,
            // consequently Go response unmarshal error
            if (
              typeId === m.MASTER_TRANSACTION_TYPE_STOP 
              || typeId === m.MASTER_TRANSACTION_TYPE_COLLECT_ETC
              || typeId === m.MASTER_TRANSACTION_TYPE_BRINGING_BACK
            ) {
              this.newRecord._collectionID = 0;
            }
            
            // Turn 'Amount' into negative if it's isPositiveRecord
            // and would be turned it againg in the 'addNewTransactionRecord' mutation
            if (this.isPositiveRecord) {
              this.newRecord.Amount = -this.newRecord.Amount;
            }
            
            // Add newRecord to the store
            this.$store.commit('addNewTransactionRecord', this.newRecord);

            // notify event
            this.$emit('close')
          });
      },
      onChangeType() {
        let m = this.$store.getters.constMasterTRTypes;
        let id = this.newRecord.MasterTransactionRecordTypeID;
        this.isOnCollectAll = id === m.MASTER_TRANSACTION_TYPE_COLLECT_ALL || id === m.MASTER_TRANSACTION_TYPE_COLLECT_INSURANCE;
        this.showCollectionables = 
          id === m.MASTER_TRANSACTION_TYPE_COLLECT_ALL 
          || id === m.MASTER_TRANSACTION_TYPE_COLLECT_PART
          || id === m.MASTER_TRANSACTION_TYPE_COLLECT_INSURANCE;
        if (this.isOnCollectAll) {
          let coll = getCollection(this.tran, this.newRecord._collectionID) 
          this.newRecord.Title = coll ? coll.Title : '';
          this.newRecord.Amount = coll ? coll.Amount : '';
        }
      },
      
      onChangeCollectionable () {
        let m = this.$store.getters.constMasterTRTypes;
        let id = this.newRecord.MasterTransactionRecordTypeID;
        if (id === m.MASTER_TRANSACTION_TYPE_COLLECT_ALL || id === m.MASTER_TRANSACTION_TYPE_COLLECT_INSURANCE) {
          let coll = getCollection(this.tran, this.newRecord._collectionID) 
          this.newRecord.Title = coll ? coll.Title : '';
          this.newRecord.Amount = coll ? coll.Amount : '';
        }
      }
    },
    data() {
      let data = initNewRecord();
      data.TransactionID = this.tran.ID;
      return {
        showCollectionables: false,
        isOnCollectAll: false,
        fmt: fmt,
        newRecord : data,
      };
    },
  };
function initNewRecord () {
  return{
    CreatedAt: fmt.date(new Date()),
    Title: '',
    MasterTransactionRecordTypeID: '',
    Amount: '',
    _collectionID: '',
  };
}
function getCollection (tran, id) {
    return tran.Collections.find((t) => t.ID === id);
}
</script>
