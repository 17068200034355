<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-12">
        <div class="card m-0">
          <div class="card-header">取引クラスタリング設定</div>
          <div class="table-responsive">
            <table class="table m-0">
              <tr class="table-secondary">
                <th style="border:none;"></th>
                <th style="border:none;"></th>
                <th class="text-center"  style="border:none;" :colspan="cols.length">企業格付け</th>
              </tr>
              <tr class="table-secondary">
                <th class="border-top-0 align-middle" :rowspan="rows.length+1" style="width:70px;position:relative;">
                  <div style="position:absolute;left:-30px;top:50%;transform:rotate(-90deg); width:120px;">
                    ユーザー格付け
                  </div>
                </th>
                <th class="border-top-0"></th>
                <th v-for="col in cols" class="border-top-0 text-center">{{ col.name }}</th>
              </tr>
              <tr v-for="row in rows">
                <th class="table-secondary align-middle">
                  {{ row.col_name }}
                  <strong data-toggle="tooltip" data-placement="right" :title="row.tooltip">
                    <span class="cui-info"></span>
                  </strong>
                </th>
                <DealClusteringCell
                  v-for="cell in row.cells"
                  :key="cell.corporation_rank.key"
                  :user_rank="row.user_rank"
                  :corporation_rank="cell.corporation_rank"
                  :deal_ranks="deal_ranks"
                  :deal_clustering="cell.deal_clustering"
                  >
                </DealClusteringCell>
              </tr>
            </table>
          </div>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveDealClustering"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import DealClusteringCell from './DealClusteringCell.vue';

  export default {
    name: 'TabPaneDealClustering',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      DealClusteringCell,
    },
    data() {
      const user_ranks = this.$store.state.user_ranks;
      const corporation_ranks = this.$store.state.corporation_ranks;
      const deal_ranks = this.$store.state.deal_ranks;
      
      let cols = [];
      corporation_ranks.forEach((corporation, i) => {
        cols.push(corporation)
      });
      
      let rows = [];
      user_ranks.forEach((user, i) => {
        let row = {
          col_name : user.name,
          user_rank: user,
          cells: [],
          tooltip : "標準手数料：" + parseInt(user.ave_rate * 100, 10) + '%' 
          + '<br />' + "現金化上限(総額)：" + user.deal_limit.toLocaleString() + '円',
        };
        corporation_ranks.forEach((corporation, i) => {
          row.cells.push({
            corporation_rank: corporation,
            deal_clustering: this.$store.getters.findDealClustering(user.id, corporation.id),
          })
        });
        rows.push(row);
      });
            
      return {
        cols: cols,
        rows: rows,
        deal_ranks: deal_ranks,
      }
    },
    methods: {
      'saveDealClustering' : function () {
        if (!this.$store.getters.isFormAllValid('deal_clusterings')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveDealClusterings/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.deal_clusterings,
          'redirect' : location.pathname + '#tab5',
        });
      },
    },
  };
</script>
