var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"d-block card-header f15",class:{
    'bg-primary' : _vm.isActive,
    'text-light' : _vm.isActive,
    'font-weight-bold' : _vm.isActive,
  },attrs:{"role":"button","data-toggle":"collapse","data-target":_vm.target,"aria-expanded":"true"},on:{":click":_vm.toggleChevron}},[_c('div',[_vm._v("\n    "+_vm._s(_vm.name)+"\n    "),_c('div',{staticClass:"card-header-actions"},[_c('span',{staticClass:"card-header-action btn-minimize",class:{
          'text-light' : _vm.isActive,
          'font-weight-bold' : _vm.isActive,
        }},[_c('span',{class:_vm.toggle})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }