import Vue        from 'vue';
import Vuex       from 'vuex'
import mutations  from './mutations/index'
import getters    from './getters'
import actions    from './actions'

Vue.use(Vuex);

const state = {
  disabled: false, // true when not DRAFT
  loading: false,
  ranking_version_id: null,
  ranking_version: {},
  user_scores: [],
  user_ranks: [],
  corporation_scores: [],
  corporation_ranks: [],
  deal_ranks: [],
  deal_clusterings: [],
  commissions: [],
  commission_ranges: [],
  commission_clusterings: [],
  
  deal_color_map: {},
  commission_color_map: {},
  master_delivery_evidence_types: [],
  master_user_evidence_types: [],
  master_corporation_evidence_types: [],
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
});
