export default function initHeaderSearch () {
  let $form = $('#headerSearch');
  $('.btnUser').on('click', function(){
    $form.attr('action', '/users/search').submit();
  });
  $('.btnApplication').on('click', function(){
    $form.attr('action', '/applications/search').submit();
  });
  $('.btnCorporation').on('click', function(){
    $form.attr('action', '/corporations/search').submit();
  });
};
