import axios from 'axios';

export default function processClickItem() {
  function serialize (data) {
    const params = new URLSearchParams();
    params.append('data', JSON.stringify(data));
    return params;
  }

  async function updateDealProcess(dealId, data)
  {
    try {
      await axios.post('/apiInternal/updateDealProcess/' + dealId,
        serialize(data)
      );
      return true;
    } catch (err) {
      alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
      throw err;
    }
  }
  function processDealAssignMyself()
  {
    const dealEls = document.querySelectorAll('.assign-myself');
    dealEls.forEach((el) => {
      el.addEventListener('click', async function(event) {
        event.preventDefault();
        if (el.disabled) return;
        el.disabled = true;

        const currentRow = event.target.closest('tr.deal-item');
        const dealId = parseInt(el.getAttribute('data-dealId'));
        const data = {
          process: 'Reception',
          method: 'checked',
        };

        const processSuccess = await updateDealProcess(dealId, data);

        if (processSuccess) {
          const statusEl = currentRow.querySelector('.status');
          const examinerEl = currentRow.querySelector('.examiner');
          const adminName = el.getAttribute('data-adminName');
          const declinedBtnEl = currentRow.querySelector('.declined');
          statusEl.innerText = '申込内容を確認';
          examinerEl.innerText = adminName;
          if (declinedBtnEl) declinedBtnEl.remove();
        } else {
          el.disabled = false;
        }
      });
    });
  }

  function processDealDeclined()
  {
    const dealEls = document.querySelectorAll('.declined');
    dealEls.forEach((el) => {
      el.addEventListener('click', async function(event) {
        event.preventDefault();
        if (el.disabled) return;
        el.disabled = true;

        const currentRow = event.target.closest('tr.deal-item');
        const dealId = parseInt(el.getAttribute('data-dealId'));
        const data = {
          process: 'InvoiceEvidenceCheck',
          method: 'judged',
          judge_result: 2,
          judge_decline_reason: '保証会社による与信審査の結果、\n該当のお取引に関し保証がおりませんでした。'
        };
        const processSuccess = await updateDealProcess(dealId, data);
        if (processSuccess) {
          const statusEl = currentRow.querySelector('.status');
          const examinerEl = currentRow.querySelector('.examiner');
          const adminName = el.getAttribute('data-adminName');
          statusEl.innerText = '謝絶';
          examinerEl.innerText = adminName;
          el.remove();
        } else {
          el.disabled = false;
        }
      });
    });
  }

  function init() {
    processDealAssignMyself();
    processDealDeclined();
  }
  init();
}
