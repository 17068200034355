/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint arrow-parens: ["error", "always"] */

import axios from 'axios';

function onError(res, options) {
  if (options && typeof options.onError === 'function') {
    options.onError(res);
  } else {
    alert(`エラーが発生しました、恐れ入りますが以下のメッセージかスクリーンショットを開発者にお送り下さい\n${res.data.message}`);
  }
}

function serialize(data) {
  const params = new URLSearchParams();
  params.append('data', JSON.stringify(data));
  return params;
}

export default {
  // Ajax request of serialized table-jorm params
  save({ commit, getters }, opt) {
    commit('setUncheckedTranRecordIds');
    const transactions = getters.getSendingValue;
    const options = opt || {};

    if (transactions.length > document.ACCOUNT_NUMBER_PER_REQUEST) {
      alert(`一度に送金できるユーザは${document.ACCOUNT_NUMBER_PER_REQUEST}件までです。`);
      return;
    }

    if (transactions.some((transaction) => transaction.targeted_amount < 0)) {
      alert('金額が0円以下の送金対象が存在してます。');
      return;
    }

    function onSuccess(res) {
      if (res.data.status !== 200) {
        onError(res, options);
        commit('setProcessingApiRequest', false);
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(res);
      }
      commit('setProcessingApiRequest', false);
      location.href = `/outcomings/view/${res.data.result}`;
    }

    axios.post('/apiInternal/createOutcoming', serialize({ ...opt.data, transactions, addingMode: document.ADDING_MODE }))
      .then((res) => onSuccess(res))
      .catch((res) => {
        onError(res, options);
        commit('setProcessingApiRequest', false);
      });
  },
  saveAsTransferSucceeded({ commit, getters }, opt) {
    const options = opt || {};
    function onSuccess(res) {
      if (res.data.status !== 200) {
        onError(res, options);
        commit('setProcessingApiRequest', false);
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(res);
      }
      commit('setProcessingApiRequest', false);
      location.reload();
    }

    axios.post(`/apiInternal/markTransactionAsSucceeded/${opt.id}`, {})
      .then((res) => onSuccess(res))
      .catch((error) => {
        onError(error, options);
        commit('setProcessingApiRequest', false);
      });
  },
  updateOutcomingInfo({ commit, getters }, opt) {
    const options = opt || {};
    function onSuccess(res) {
      if (res.data.status !== 200) {
        onError(res, options);
        commit('setProcessingApiRequest', false);
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(res);
      }
      commit('setProcessingApiRequest', false);
    }
    axios.post(`/apiInternal/updateOutcomingInfo/${opt.id}`, serialize(opt.data))
      .then((res) => onSuccess(res))
      .catch(onError);
  },

  saveRequestGanbTransferForOutcomingRecord({ commit, getters }, opt) {
    const options = opt || {};
    function onSuccess(res) {
      if (res.data.status !== 200) {
        onError(res, options);
        commit('setProcessingApiRequest', false);
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(res);
      }
      commit('setProcessingApiRequest', false);
      location.reload();
    }

    axios.post(`/apiInternal/requestGanbTransferForOutcomingRecord/${opt.id}`, serialize(opt.data))
      .then((res) => onSuccess(res))
      .catch((res) => {
        onError(res, options);
        commit('setProcessingApiRequest', false);
      });
  },

  saveMarkAsGanbTransferFailed({ commit, getters }, opt) {
    const options = opt || {};
    function onSuccess(res) {
      if (res.data.status !== 200) {
        onError(res, options);
        commit('setProcessingApiRequest', false);
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(res);
      }
      commit('setProcessingApiRequest', false);
      location.reload();
    }

    axios.post(`/apiInternal/markAsGanbTransferFailed/${opt.id}`, {})
      .then((res) => onSuccess(res))
      .catch((res) => {
        onError(res, options);
        commit('setProcessingApiRequest', false);
      });
  }
};
