<template>
  <StickyScroller>
    <GMOPGCheck :no="0"></GMOPGCheck></br>
    <Milestone :color="'info'" :label="'申込日時: ' + createdDate"></Milestone>
    <ArrowDown></ArrowDown>
    <Reception :no="1"></Reception>
    <ArrowDown></ArrowDown>
    <Judging :no="2"></Judging>
    <ArrowDown></ArrowDown>
    <ConditionCheck :no="3"></ConditionCheck>
    <ArrowDown></ArrowDown>
    <WatingForApproval :no="4"></WatingForApproval>
    <ArrowDown></ArrowDown>
    <Milestone :color="'info'" :label="'出金予定日: ' + sendingDate"></Milestone>
    <ArrowDown></ArrowDown>
    <WatingForPayment :no="5"></WatingForPayment>
    <ArrowDown></ArrowDown>
    <Milestone :color="'info'" :label="'入金期限: ' + incomingDate"></Milestone>
    <ArrowDown></ArrowDown>
    <Collection :no="6"></Collection>
    <ArrowDown></ArrowDown>
    <UpdatingCredit :no="7"></UpdatingCredit>
    <ArrowDown></ArrowDown>
    <Complete :no="8"></Complete>
  </StickyScroller>
</template>

<script>

  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import VeeValidate, { Validator } from 'vee-validate'
  import ja from '../../../../node_modules/vee-validate/dist/locale/ja'

  Validator.localize('ja', ja);
  Vue.use(VeeValidate, { locale: ja });

  import Milestone                  from '../../../components/Milestone.vue';
  import ArrowDown                  from '../../../components/ArrowDown.vue';
  import StickyScroller             from '../../../components/StickyScroller.vue';
  import GMOPGCheck                 from './Step1GMOPGCheck.vue';
  import Reception                  from './Step2ReceptionCheck.vue';
  import Judging                    from './Step3Judging.vue';
  import ConditionCheck             from './Step4ConditionCheck.vue';
  import WatingForApproval          from './Step5WatingForApproval.vue';
  import WatingForPayment           from './Step6WatingForPayment.vue';
  import Collection                 from './Step7Collection.vue';
  import UpdatingCredit             from './Step8UpdatingCredit.vue';
  import Complete                   from './Step9Complete.vue';

  export default  {
    name: 'App',
    computed: {
      ...mapGetters([
        'isActive',
        'isEscalated',
        'createdDate',
        'sendingDate',
        'incomingDate',
      ]),
    },
    components: {
      Complete,
      ArrowDown,
      StickyScroller,
      GMOPGCheck,
      Judging,
      ConditionCheck,
      WatingForApproval,
      WatingForPayment,
      Collection,
      UpdatingCredit,
      Milestone,
      Reception
    },
    created: function () {
      this.$store.commit('loadData', {
        user: document.USER,
        deal: document.DEAL,
        current_proposal_application_amount: document.CURRENT_PROPOSAL_APPLICATION_AMOUNT,
        admin_users: document.ADMIN_USERS,
        login_user_id: document.LOGIN_USER_ID,
        examination_items: document.EXAMINATION_ITEMS,
        evidence_examination_items: document.EVIDENCE_EXAMINATION_ITEMS,
        corp_evidence_type_id: document.CORP_EVIDENCE_TYPE_ID,
        user_evidence_type_id: document.USER_EVIDENCE_TYPE_ID,
        isXpointAvailable: document.IS_XPOINT_AVAILABLE,
        masterDealIndustries: document.MASTER_DEAL_INDUSTRIES,
        masterDealIndustryDefaultId: document.MASTER_DEAL_INDUSTRY_DEFAULT_ID,
      });
    }
  };
</script>
