import { generateKey } from './Util'

export default {
  // Set CommissionRange from global to store
  initCommissionRange(state, payload) {
    // Construct user_ranks initial data
    let commission_ranges = [];
    payload.ranking_version.CommissionRanges.forEach((rank, i) => {
      commission_ranges.push({
        key:            generateKey(),
        id:             rank.ID,
        min_price:      rank.MinPrice,
        max_price:      rank.MaxPrice,
        is_valid: {},
      });
    });
    state.commission_ranges = commission_ranges;
  },
   
  // Update individual commission range
  updateCommissionRange(state, payload) {
    state.commission_ranges.forEach((rank, i) => {
      if (rank.key == payload.key) {
        let val = payload.value;
        if (
          payload.attr === 'min_price' 
          || payload.attr === 'max_price'
        ) {
          val = parseInt(val, 10);
          if (isNaN(val)) {
            val = '';
          }
        }
        state.commission_ranges[i][payload.attr] = val;
      }
    });
  },
  
  // Update validation status of individual commission range
  updateCommissionRangeIsValid(state, payload) {
    state.commission_ranges.forEach((rank, i) => {
      if (rank.key == payload.key) {
        state.commission_ranges[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },
};
