import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
// @ts-ignore: implicitly has an 'any' type.
import VeeValidateJa from 'modules/vee-validate/dist/locale/ja.js';
import App from './components/App.vue';
import { mainModule } from './store/main';
Validator.localize('ja', VeeValidateJa);
Vue.use(VeeValidate);
export default function page() {
    var app = new Vue({
        el: 'app',
        components: {
            App: App
        },
        created: function () {
            var data = document.REQUEST_CHANGING_VIRTUAL_ACCOUNT_NAMES;
            mainModule.loadData({
                requestData: {
                    requestID: data.id,
                    createdAt: data.CreatedAt,
                    judgeDeclineReason: data.judge_decline_reason,
                    judgeNote: data.judge_note,
                    fullname: data.User.FullName,
                    masterRequestStatusID: data.MasterRequestChangeVirtualBankAccountNameStatus.ID,
                    masterRequestStatusTitle: data.MasterRequestChangeVirtualBankAccountNameStatus.Title
                },
                virtualBankAccount: {
                    branchName: data.VirtualBankAccount.BranchName,
                    branchCode: data.VirtualBankAccount.BranchCode,
                    accountName: data.new_account_name,
                    accountNumber: data.VirtualBankAccount.AccountNumber
                }
            });
        }
    });
}
