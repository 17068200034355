<template>
  <li class="list-group-item p-3">
    <button 
      class="btn btn-block mb-4"
      :disabled="disabled || disable_btn"
      :class="{ 
        'btn-primary' : !disabled, 
        'btn-secondary' : disabled, 
        'd-none' : escalated,
      }"
      @click="onClickChecked"
      v-if="judge_type == 1"
    >{{lbl_check}}</button>
    <div class="custom-control p-0 mt-2 mb-3" v-if="judge_type == 2" :class="{ 'd-none' : escalated }">
      <label for="">審査結果：</label>
      <select 
        :name="select_escalation_done"
        class="form-control" 
        :class="{'is-invalid': errors.has(select_escalation_done)}"
        v-model="escalation_approved_or_rejected" 
        v-validate="'required'"
        :disabled="disabled"
      >
        <option value="">-</option>
        <option value="approved">プロセスを「承認」として完了</option>
        <option value="rejected">プロセスを「非承認」として完了</option>
      </select>
      <p v-if="errors.has(select_escalation_done)" class="invalid-feedback">
        審査結果を選択して下さい
      </p>
    </div>
    <button 
      class="btn btn-block mb-4"
      :disabled="disabled || disable_btn"
      :class="{ 
        'btn-primary' : !disabled, 
        'btn-secondary' : disabled, 
        'd-none' : escalated,
      }"
      @click="onClickChecked"
      v-if="judge_type == 2"
    >審査</button>
   
    <div class="custom-control custom-checkbox mt-2" v-if="can_escalate">
      <input 
        type="checkbox" 
        class="custom-control-input" 
        :id="checkbox_escalated" 
        value="1" 
        v-model="escalated"
        :disabled="disabled"
        />
      <label class="custom-control-label" :for="checkbox_escalated">
        問題あり・エスカレーション
      </label>
    </div>
    <div :class="{ 'd-none' : !escalated }">
      <p class="mt-2">「担当者」に確認依頼者を選択して「備考欄」に理由を記述し「エスカレーション」を押下して下さい</p>
      <label>担当者：</label>
      <select 
        :name="escalated_user_input_name"
        class="form-control" 
        :class="{'is-invalid': errors.has('escalation.'+escalated_user_input_name)}"
        v-model="escalated_user_id"
        v-validate="'required|excluded:0'"
        data-vv-scope="escalation"
        :disabled="disabled"
      >
        <option 
          v-for="user in users" 
          :value="user.id" 
          :key="user.id"
          >{{ user.name }}</option>
      </select>
      <p v-if="errors.has('escalation.'+escalated_user_input_name)" class="invalid-feedback">
        担当者を選択して下さい
      </p>
      <br />
      <label>備考欄：</label>
      <textarea 
        :name="escalated_note_input_name"
        class="form-control" 
        :class="{'is-invalid': errors.has('escalation.'+escalated_note_input_name)}"
        style="height:100px;"
        v-model="escalation_note"
        v-validate="'required'"
        data-vv-scope="escalation"
        :disabled="disabled"
      ></textarea>
      <p v-if="errors.has('escalation.'+escalated_note_input_name)" class="invalid-feedback">
        備考欄を空欄にすることはできません
      </p>
      <div class="custom-control custom-checkbox mt-2" v-if="judge_type == 1">
        <input 
          type="checkbox" 
          class="custom-control-input" 
          :id="checkbox_escalation_done" 
          value="1" 
          v-model="escalation_done"
          :disabled="disabled"
          />
        <label class="custom-control-label" :for="checkbox_escalation_done">
          プロセスを完了して次へ
        </label>
      </div>
      <div class="custom-control p-0 mt-2" v-if="judge_type == 2">
        <label for="">審査結果：</label>
        <select 
          :name="select_escalation_done"
          class="form-control" 
          v-model="escalation_approved_or_rejected" 
          :disabled="disabled"
        >
          <option value="">-</option>
          <option value="approved">プロセスを「承認」として完了</option>
          <option value="rejected">プロセスを「非承認」として完了</option>
        </select>
      </div>
      <button 
        class="btn btn-secondary btn-block mt-2"
        :disabled="disabled || disable_btn"
        @click="onClickEscalation"
      >エスカレーション情報を保存</button>
    </div>
  </li>
</template>

<script>
  // TODO: Rewrite with TypeScript

  import { mapGetters } from 'vuex'
  import { mainModule } from "../../store/main";

  export default {
    name: 'CheckAndEscalate',
    props: {
      no: {
        type: Number,
        default: 0,
      },
      process: {
        type: String,
        default: "",
      },
      judge_type: {
        type: Number,
        default: 1,
      },
      can_escalate: {
        type: Boolean,
        default: true,
      },
      lbl_check: {
        type: String,
        default: "チェック完了",
      },
      skip_confirmation: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onClickChecked (e) {
        if (this.skip_confirmation) {
          this.disable_btn = true;
          this.$emit('onClickChecked', this);
        } else {
          let valid = true;
          if (this.judge_type === 2) {
            this.$validator.validate(this.select_escalation_done).then(result => {
              valid = result;
            });
          }
          if (valid && confirm(this.lbl_check + 'として保存してよろしいでしょうか？')) {
            this.disable_btn = true;
            this.$emit('onClickChecked', this);
          }
        }
      },
      onClickEscalation (e) {
        this.$validator
          .validate("escalation.*").then(result => {
            if (result) {
              this.disable_btn = true;
              this.$emit('onClickEscalation', {
                'data' : {
                  'process' :             this.process,
                  'method' :              'escalation',
                  'escalated_user_id' :   this.escalated_user_id,
                  'escalated_note' :      this.escalation_note,
                  'escalation_done' :     this.escalation_done,
                  'approved_or_rejected': this.escalation_approved_or_rejected,
                }
              });
            }
          });
      },
    },
    data() {
      let getters = mainModule;
      return {
        'users' : getters.users,
        'all_valid':                      true,
        'disabled' :                      !getters.isActive(this.process),
        'escalated' :                     getters.isEscalated(this.process),
        'escalated_user_id' :             getters.escalatedUser(this.process),
        'escalated_user_input_name':      this.process + '_escalated_user',
        'escalation_note' :               getters.escalationNote(this.process),
        'escalated_note_input_name':      this.process + '_escalated_note',
        'escalation_done' :               getters.escalationDone(this.process),
        'checkbox_escalated' :            'step'+this.no+'Escalation',
        'checkbox_escalation_done' :      'doneStep'+this.no+'Escalation',
        'select_escalation_done' :        'selectDoneStep'+this.no+'Escalation',
        'escalation_approved_or_rejected': getters.escalationApprovedOrRejected(this.process),
        'disable_btn' : false,
      };
    },
  };
</script>
