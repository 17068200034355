<template>
  <div class="card mb-1" :class="{ 'border-warning' : isEscalated(process) }">
    <ToggleHeader 
      :name="no + '. 代替条件のご案内'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>審査の結果、代替条件での取引をご案内する場合、その代替条件を設定</li>
        </ul>
      </li>
      <li class="list-group-item p-3">
        <ul class="pl-4 mb-3">
          <li>この操作を行うとユーザーにメールが送信されます</li>
          <li>一度確定した買取金額・手数料は変更できません</li>
        </ul>
        <form action="">
          <label for="">手数料利率（設定可能範囲：0.00% ~ 0.00%）：</label>
          <input type="text" class="form-control" disabled="disabled" />
          <div for="" class="mt-2 form-check form-check-inline">
            <input id="special_rate" type="checkbox" class="form-check-input" disabled="disabled" />
            <label for="special_rate" class="form-check-label">特別手数料を適用</label>
          </div>
          <div class="text-center">
            <button class="btn btn-primary btn-block" disabled="disabled">取引条件をご案内</button>
          </div>
        </form>
      </li>
      <CheckAndEscalate
        :no="no"
        :process="process"
        v-on:onClickChecked="onClickChecked"
        v-on:onClickEscalation="onClickEscalation"
      ></CheckAndEscalate>
    </ul>
 </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader      from '../../ApplicationView/components/parts/ToggleHeader.vue';
  import CheckAndEscalate  from '../../ApplicationView/components/parts/CheckAndEscalate.vue';

  export default {
    name: 'ConditionCheck',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    computed: {
      ...mapGetters([
        'isActive',
        'isEscalated',
      ]),
    },
    methods: {
      onClickChecked () {
        this.$store.dispatch('updateProcess', {
          'data' : {
            'process': this.process,
            'method': 'checked',
          },
        });
      },
      onClickEscalation (data) {
        this.$store.dispatch('updateProcess', data);
      },
    },
    data() {
      return {
        'process' : this.$options.name,
      };
    },
  };
</script>
