<template>
  <div class="card mb-1">
    <ToggleHeader 
      :name="no + '. 審査'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-3">
        <p class="mt-0">1~3のチェックを行い審査実行</p>
        <label for="">審査結果：</label>
        <select 
          :name="judge_result_input_name"
          class="form-control" 
          :class="{'is-invalid': errors.has(judge_result_input_name)}"
          v-model="judge_result" 
          v-validate="'required|excluded:0'"
          :disabled="disabled"
        >
          <option 
            v-for="judge_result in judge_results" 
            :value="judge_result.id" 
            :key="judge_result.id"
          >{{ judge_result.name }}</option>
        </select>
        <p v-if="errors.has(judge_result_input_name)" class="invalid-feedback">
          審査結果を選択して下さい
        </p>
        <br />
        <div v-if="judge_result === 2">
          <label>謝絶理由：</label>          
          <textarea 
            class="form-control" 
            style="height:100px;" 
            v-model="judge_decline_reason"
            v-validate="'required'"
            :class="{'is-invalid': errors.has('judge_decline_reason')}"
            :name="'judge_decline_reason'"
            :disabled="disabled"
          ></textarea>
          <p v-if="errors.has('judge_decline_reason')" class="invalid-feedback">
            謝絶理由を記入して下さい
          </p>
          <br />
          <label>謝絶メール・プレビュー：</label>
<pre class="bg-secondary p-3 border border-dark rounded">
{{ fullName }} 様

いつもフリーナンスをご利用いただき、誠にありがとうございます。
この度はフリーナンスの会員登録にお申し込みいただきまして、
誠にありがとうございました。

お客様のお申込みを審査させていただきました結果、
以下の理由により会員登録を見送らせていただくこととなりました。

-------------------------------------------

{{ judge_decline_reason }}

-------------------------------------------

お客様のご要望にお応えできませんでしたことにお詫びを申し上げます。
なお恐れ入りますが審査の基準については個別にお答えすることは
致しかねますのでご了承ください。

フリーナンスをご利用いただきありがとうございました。
お客様の今後の活躍をお祈りしております。
</pre>
          <br />
        </div>
        <label>備考欄：</label>
        <textarea 
          class="form-control" 
          style="height:100px;" 
          v-model="judge_note"
          :disabled="disabled"
        ></textarea>
        <p class="mt-2">「審査」の押下によりあんしん確認コードを郵送</p>
        <button 
          class="btn btn-secondary btn-block mt-2"
          :disabled="disabled"
          @click="onClickJudged"
        >審査</button>
      </li>
    </ul>
  </div>
</template>

<script>

  import ToggleHeader  from './parts/ToggleHeader.vue';
  import { mapGetters } from 'vuex'

  export default {
    name: 'Judge',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'judgeResults',
        'fullName',
      ]),
    },
    components: {
      ToggleHeader,
    },
    methods: {
      onClickJudged (e) {
        this.$validator.validate().then(result => {
          if (result) {
            if (confirm('審査完了として保存してよろしいでしょうか？')) {
              this.disabled = true;
              this.$store.dispatch('updateProcess', {
                'data' : {
                  'process':                this.process,
                  'method':                 'judged',
                  'approved':               this.judge_result === 1,
                  'judge_note':             this.judge_note,
                  'judge_decline_reason':   this.judge_decline_reason,
                  'updated_at':             this.$store.state.application.UpdatedAt,
                },
              });
            }
          }
        });
      },
    },
    data() {
      let p = this.$options.name;
      const judge_results = [{
        id: 0,
        name: '-',
      },{
        id: 1,
        name: '承認',
      },{
        id: 2,
        name: '謝絶',
      }];
      return {
        'process' :                 p,
        'disabled' :                !this.$store.getters.isActive(p),
        'active' :                  this.$store.getters.isActive(p),
        'judge_results':            judge_results,
        'judge_result':             this.$store.getters.judgeResult,
        'judge_decline_reason':     this.$store.getters.judgeDeclineReason,
        'judge_note':               this.$store.getters.judgeNote,
        'judge_result_input_name':  this.process + '_judge_result',
      };
    },
  };
</script>
