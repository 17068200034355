// ---------------------------------
// Resolve dependencies
// ---------------------------------
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// Vue / Vuex
import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "./store";
// Axios
import * as Axios from 'axios';
var AXIOS = Axios.default.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
    },
});
// Moment.js
import moment from 'moment';
moment.locale('ja');
// ---------------------------------
// Type definitions
// ---------------------------------
var ON_UPLOADED_CHECK = 1;
var ON_COMPLETE = 2;
var ON_REJECTED = 3;
var ON_UNNECESSARY = 4;
var ON_CANCELED = 8;
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        // ----------------------------------------
        // state
        // ----------------------------------------
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.statusId = 0;
        _this.user_evidence = {};
        _this.admin_users = [];
        _this.login_user_id = 0;
        return _this;
    }
    Object.defineProperty(Main.prototype, "users", {
        // ----------------------------------------
        // getters
        // ----------------------------------------
        // Returns users list for selectbox options
        get: function () {
            var users = [{
                    id: 0,
                    name: '-',
                }];
            this.admin_users.forEach(function (user) {
                users.push({
                    id: user.ID,
                    name: user.Name,
                });
            });
            return users;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "escalatedUser", {
        // Return AdminUser.ID who is escalated on the process
        get: function () {
            var _this = this;
            return function (process) {
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return _this.user_evidence.UploadedCheckEscalatedAdminUserID;
                }
                return 0;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "escalationNote", {
        // Return escalation note of the 'process'
        get: function () {
            var _this = this;
            return function (process) {
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return _this.user_evidence.UploadedCheckNote;
                }
                return "";
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "escalationDone", {
        // Return escalation has been done or not
        get: function () {
            var _this = this;
            return function (process) {
                var status_id = _this.user_evidence.MasterUserEvidenceStatusID;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return status_id > ON_UPLOADED_CHECK && _this.escalatedUser(process) != 0;
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "escalationApprovedOrRejected", {
        // Return escalation approved or not of the 'process'
        get: function () {
            var _this = this;
            return function (process) {
                var status_id = _this.user_evidence.MasterUserEvidenceStatusID;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    if (status_id === ON_COMPLETE) {
                        return "approved";
                    }
                    else if (status_id === ON_REJECTED) {
                        return "rejected";
                    }
                    else {
                        return "";
                    }
                }
                return "";
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "escalationRejected", {
        // Return escalation rejected or not of the 'process'
        get: function () {
            var _this = this;
            return function (process) {
                var status_id = _this.user_evidence.MasterUserEvidenceStatusID;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return status_id === ON_REJECTED;
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isActive", {
        // Is the prcess active or not
        get: function () {
            var _this = this;
            return function (process) {
                var status_id = _this.user_evidence.MasterUserEvidenceStatusID;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return status_id === ON_UPLOADED_CHECK;
                }
                else if (process === 'TelInterview') {
                    return status_id === ON_UPLOADED_CHECK;
                }
                else if (process === 'Complete') {
                    return status_id === ON_COMPLETE || status_id === ON_REJECTED || status_id === ON_UNNECESSARY || status_id == ON_CANCELED;
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isEscalated", {
        // Is the prcess escalated or not
        get: function () {
            var _this = this;
            return function (process) {
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    return _this.user_evidence.UploadedCheckEscalated;
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "checkedBy", {
        // Return who did check the process
        get: function () {
            var _this = this;
            return function (process) {
                var id = 0;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    id = _this.user_evidence.UploadedCheckAdminUserID;
                }
                if (process === 'TelInterview') {
                    id = _this.user_evidence.TelInterviewAdminUserID;
                }
                if (process === 'Complete') {
                    id = _this.user_evidence.CanceledCheckAdminUserID;
                }
                var found = _this.admin_users.find(function (u) { return u.ID === id; });
                if (found) {
                    return found.Name;
                }
                return "";
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "checkedDate", {
        // Return whed did the check has been done
        get: function () {
            var _this = this;
            return function (process) {
                moment.locale('ja');
                var frmt = 'YYYY-MM-DD HH:mm:ss'; // 2018-8-27 04:48:00
                var d;
                if (process === 'UploadedCheck' || process === 'UploadedUserEvidenceCheck') {
                    d = new Date(_this.user_evidence.UploadedCheckDone);
                }
                else if (process === 'TelInterview') {
                    d = new Date(_this.user_evidence.TelInterviewDone);
                }
                else if (process === 'Complete') {
                    d = new Date(_this.user_evidence.CanceledCheckDone);
                }
                if (!d) {
                    return '-';
                }
                return d.getFullYear() === 1 ? '-' : moment(d).format(frmt);
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "judgeResult", {
        // Return judge result id
        get: function () {
            var status_id = this.user_evidence.MasterUserEvidenceStatusID;
            if (status_id === ON_REJECTED) {
                return 2;
            }
            if (status_id === ON_COMPLETE) {
                return 1;
            }
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "judgeDeclineReason", {
        // Return user_evidence.JudgeDeclineReason
        get: function () {
            return this.user_evidence.JudgeDeclineReason;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "judgeNote", {
        // Return user_evidence.JudgeNote
        get: function () {
            return this.user_evidence.JudgeNote;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "openingAccountStatus", {
        // Return the account opening process has been on process, sucusessfully done, or declined
        get: function () {
            var status_id = this.user_evidence.MasterUserEvidenceStatusID;
            if (status_id === ON_COMPLETE) {
                return '承認済み';
            }
            else if (status_id === ON_REJECTED) {
                return '非承認済み';
            }
            else if (status_id === ON_UNNECESSARY) {
                return '審査不要';
            }
            else if (status_id === ON_CANCELED) {
                return 'キャンセル済み';
            }
            else {
                return '審査中';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "completeTitle", {
        get: function () {
            return this.user_evidence.MasterUserEvidenceStatusID == ON_CANCELED ? "与信材料キャンセル済み" : "チェック完了";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "expirationDate", {
        get: function () {
            moment.locale('ja');
            var d = moment(this.user_evidence.ExpiringDate);
            if (d.format('YYYY-MM-DD') !== "0001-01-01") {
                return d.format('YYYY-MM-DD');
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isEvidenceTypeOf", {
        // Checkk UserEvidence.MasterUserEvidenceTypeID
        get: function () {
            var _this = this;
            return function (name) {
                var id = _this.user_evidence.MasterUserEvidenceTypeID;
                /*
                CHECK_CERTIFICATE type is use for expiring date manual judge
                if (name == "CHECK_CERTIFICATE") {
                }
                */
                if (name == "TEL_INTERVIEW") {
                    return id == 20 || id == 21;
                }
                else if (name == "NOT_CREDITED_EVIDENCE") {
                    return id == 22 || id == 23 || id == 7 || id == 8 || id == 9;
                }
                else {
                    return false;
                }
            };
        },
        enumerable: false,
        configurable: true
    });
    // ----------------------------------------
    // mutation
    // ----------------------------------------
    Main.prototype.loadData = function (payload) {
        this.user_evidence = payload.user_evidence;
        this.admin_users = payload.admin_users;
        this.login_user_id = payload.login_user_id;
    };
    // ----------------------------------------
    // actions
    // ----------------------------------------
    Main.prototype.update = function (opt) {
        function serialize(data) {
            var params = new URLSearchParams();
            params.append('data', JSON.stringify(data));
            return params;
        }
        AXIOS.post('/apiInternal/updateUserEvidenceProcess/' + this.user_evidence.ID, serialize(opt.data))
            .then(function (res) {
            if (res.data.status == 400) {
                alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい' + '\n' + res.data.message);
            }
            if (opt.onSuccess) {
                opt.onSuccess(res);
                return;
            }
            location.reload();
        })
            .catch(function (error) {
            alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
            console.log(error);
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadData", null);
    __decorate([
        Action({})
    ], Main.prototype, "update", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: "main", namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export var mainModule = getModule(Main);
