import { datadogRum } from '@datadog/browser-rum';
export default function common() {
    // Instrument Datadog Real User Monitoring
    (function () {
        if (process.env.DD_RUM_ENV) {
            datadogRum.init({
                applicationId: '613f46d7-ed64-4b7e-af6d-eb01790d8232',
                clientToken: 'pubd2d6f6b3c84b2a1ab9df3c93c1f94d01',
                site: 'ap1.datadoghq.com',
                service: 'frnc-backend',
                env: process.env.DD_RUM_ENV,
                // Specify a version number to identify the deployed version of your application in Datadog 
                version: process.env.DD_VERSION,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask',
                beforeSend: function (event) {
                    var _a, _b;
                    if (event.type === 'action') {
                        if ((_b = (_a = event.action) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.name) {
                            // actionのターゲット名に機密情報が含まれる場合があるため、ターゲット名をマスクする
                            var maskedTargetName = ''.padStart(event.action.target.name.length, '☓');
                            event.action.target.name = maskedTargetName;
                        }
                    }
                    return true;
                }
            });
            // ログイン中のユーザーID取得
            var usrId = document.LOGGED_IN_USER_ID;
            if (usrId) {
                // ログイン済の場合はRUMにユーザーIDを送信
                datadogRum.setUser({
                    id: usrId
                });
            }
        }
    })();
}
