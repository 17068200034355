var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
var APPROVED = 1;
var DECLINED = 2;
var APPROVED_BTN_ID = 'corpDealApprovedBtn';
var DECLINED_BTN_ID = 'corpDealDeclinedBtn';
var INCOMING_BTN_ID = 'clearing_deal_btn';
var inputSelector = 'input[type=number].corporation-deal-collecting-amount';
var cbxSelector = 'input[type=checkbox].cbx-corp-deal';
var checkedCond = ':checked';
var enabledCond = ':not(:disabled)';
var initUserInfoTooltip = function () {
    var elm = document.querySelectorAll('[data-toggle="user-info-tooltip"]');
    $(elm).tooltip({
        html: true,
        template: '<div class="user-info-tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    });
};
var initToggleCtrlBtns = function () {
    var isDisabled = document.querySelectorAll("" + cbxSelector + checkedCond + enabledCond).length == 0;
    Array.from(document.getElementsByClassName('btn-corp-deal-ctrl')).forEach(function (e) {
        e.disabled = isDisabled;
    });
    var elm = document.getElementById('corpDealCbxAll');
    if (!elm)
        return;
    if (isDisabled) {
        elm.checked = !isDisabled;
    }
};
var initToggleCheckboxes = function () {
    var elm = document.getElementById('corpDealCbxAll');
    if (!elm)
        return;
    elm.addEventListener('change', function () {
        document.querySelectorAll("" + cbxSelector + enabledCond).forEach(function (el) {
            el.checked = elm.checked;
            el.dispatchEvent(new Event('change'));
        });
    });
};
var initIncomingAmountInput = function () {
    document.querySelectorAll("" + inputSelector + enabledCond).forEach(function (el) {
        el.addEventListener('change', function () {
            countIncomingAmountTotal();
        });
    });
};
var getCheckedDealIDs = function () {
    var checkedElements = Array.from(document.querySelectorAll("" + cbxSelector + checkedCond));
    var dealIds = checkedElements.map(function (el) { return parseInt(el.value, 10); });
    return dealIds;
};
var isValidIncomingAmount = function () {
    var isValid = true;
    document.querySelectorAll("" + cbxSelector + checkedCond).forEach(function (el) {
        var amountElement = el.parentElement.parentElement.querySelector("" + inputSelector + enabledCond);
        if (!amountElement.value || Number(amountElement.value) <= 0) {
            isValid = false;
            amountElement.classList.add('is-invalid');
        }
    });
    return isValid;
};
var getIncomingData = function () {
    var data = [];
    document.querySelectorAll("" + cbxSelector + checkedCond).forEach(function (el) {
        var amountElement = el.parentElement.parentElement.querySelector("" + inputSelector + enabledCond);
        data.push({
            deal_id: parseInt(el.value, 10),
            amount: parseInt(amountElement.value, 10),
            updated_at: el.dataset.updatedAt
        });
    });
    return data;
};
var countIncomingAmountTotal = function () {
    var totalAmount = 0;
    document.querySelectorAll("" + cbxSelector + checkedCond + enabledCond).forEach(function (element) {
        var trEl = element.parentElement.parentElement;
        if (trEl) {
            var inputEl = trEl.querySelector("" + inputSelector + enabledCond);
            totalAmount += inputEl ? Number(inputEl.value) || 0 : 0;
        }
    });
    document.querySelectorAll('[data-deal="incoming-amount-total"]').forEach(function (e) {
        e.innerHTML = totalAmount ? totalAmount.toLocaleString() : '0';
    });
};
var addEventForCtrlBtn = function (btnID) {
    var btnEl = document.getElementById(btnID);
    if (!btnEl)
        return;
    btnEl.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, submitCorpDeals(btnID)];
    }); }); });
};
var addEventForCbxes = function () {
    document.querySelectorAll("" + cbxSelector).forEach(function (element) {
        element.addEventListener('change', function () {
            initToggleCtrlBtns();
            countIncomingAmountTotal();
        });
    });
};
var submitCorpDeals = function (btnID) { return __awaiter(void 0, void 0, void 0, function () {
    var btnEl, checkedDealIDs, params, data, url, reasonTxt, errMsgEl, isValid, checkedDealData, res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                btnEl = document.getElementById(btnID);
                if (!btnEl)
                    return [2 /*return*/];
                checkedDealIDs = getCheckedDealIDs();
                if (checkedDealIDs.length === 0)
                    return [2 /*return*/];
                params = new URLSearchParams();
                data = {};
                url = '/apiInternal/processCorporationDealsCheck';
                switch (btnID) {
                    case APPROVED_BTN_ID:
                        data.judge_result = APPROVED;
                        data.deal_ids = checkedDealIDs;
                        break;
                    case DECLINED_BTN_ID:
                        reasonTxt = document.getElementById('corpJudgeDeclineReasonTxt').value;
                        errMsgEl = document.getElementById('declineReasonErrMsg');
                        if (!reasonTxt) {
                            errMsgEl.classList.add('text-danger');
                            errMsgEl.classList.remove('d-none');
                            return [2 /*return*/];
                        }
                        data.judge_result = DECLINED;
                        data.deal_ids = checkedDealIDs;
                        data.decline_reason = reasonTxt;
                        break;
                    case INCOMING_BTN_ID:
                        isValid = isValidIncomingAmount();
                        if (!isValid) {
                            alert('データをチェックしてください。');
                            return [2 /*return*/];
                        }
                        checkedDealData = getIncomingData();
                        if (checkedDealData.length === 0)
                            return [2 /*return*/];
                        data.arbitrary_incomings = checkedDealData;
                        url = '/apiInternal/makeArbitraryIncomings';
                        break;
                }
                params.append('data', JSON.stringify(data));
                if ((btnID == APPROVED_BTN_ID || btnID == DECLINED_BTN_ID) && !confirm('データを一括更新します、よろしいですか？'))
                    return [2 /*return*/];
                btnEl.disabled = true;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post(url, params)];
            case 2:
                res = _a.sent();
                if (res.data.status === 200) {
                    location.reload();
                    window.scrollTo(0, 0);
                    return [2 /*return*/];
                }
                btnEl.disabled = false;
                throw new Error(res.data.message);
            case 3:
                err_1 = _a.sent();
                alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                btnEl.disabled = false;
                throw err_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export default (function () {
    initUserInfoTooltip();
    addEventForCbxes();
    initToggleCheckboxes();
    initIncomingAmountInput();
    countIncomingAmountTotal();
    addEventForCtrlBtn(APPROVED_BTN_ID);
    addEventForCtrlBtn(DECLINED_BTN_ID);
    addEventForCtrlBtn(INCOMING_BTN_ID);
});
