var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
var MessageDisplayType;
(function (MessageDisplayType) {
    MessageDisplayType[MessageDisplayType["HIDDEN_MESSAGE"] = 0] = "HIDDEN_MESSAGE";
    MessageDisplayType[MessageDisplayType["SUCCESS_MESSAGE"] = 1] = "SUCCESS_MESSAGE";
    MessageDisplayType[MessageDisplayType["FAILED_MESSAGE"] = 2] = "FAILED_MESSAGE";
    MessageDisplayType[MessageDisplayType["RELOAD_MESSAGE"] = 3] = "RELOAD_MESSAGE";
})(MessageDisplayType || (MessageDisplayType = {}));
var Memo = /** @class */ (function (_super) {
    __extends(Memo, _super);
    function Memo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lastMemoValue = _this.memoData.memo;
        _this.lastMemoUpdatedAt = _this.memoData.memoUpdatedAt;
        _this.messageType = MessageDisplayType.HIDDEN_MESSAGE;
        _this.isEditing = false;
        _this.isErrorMessage = false;
        _this.flashMessage = '';
        return _this;
    }
    Memo.prototype.onMessageType = function () {
        switch (this.messageType) {
            case MessageDisplayType.HIDDEN_MESSAGE:
                this.flashMessage = '';
                this.isErrorMessage = false;
                break;
            case MessageDisplayType.SUCCESS_MESSAGE:
                this.flashMessage = 'メモを変更しました';
                this.isErrorMessage = false;
                break;
            case MessageDisplayType.RELOAD_MESSAGE:
                this.flashMessage = '最新のメモを取得しました';
                this.isErrorMessage = false;
                break;
            default:
                this.flashMessage = 'メモを変更することができません';
                this.isErrorMessage = true;
                break;
        }
    };
    Memo.prototype.updateMemoData = function (data, messageType) {
        this.memoData.memo = data.memo;
        this.memoData.memoUpdatedAt = data.memoUpdatedAt;
        this.messageType = messageType;
    };
    Memo.prototype.updateLastMemo = function (data) {
        this.lastMemoValue = data.memo;
        this.lastMemoUpdatedAt = data.memoUpdatedAt;
    };
    Memo.prototype.editMode = function () {
        this.isEditing = true;
        this.messageType = MessageDisplayType.HIDDEN_MESSAGE;
    };
    Memo.prototype.cancel = function () {
        this.memoData.memo = this.lastMemoValue;
        this.memoData.memoUpdatedAt = this.lastMemoUpdatedAt;
        this.isEditing = false;
        this.messageType = MessageDisplayType.HIDDEN_MESSAGE;
    };
    Memo.prototype.updateMemo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, newMemoData, res, canLoadNewMemo, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        params = new URLSearchParams();
                        newMemoData = {
                            id: this.memoData.id,
                            memo: this.memoData.memo,
                            memoUpdatedAt: this.memoData.memoUpdatedAt,
                            type: this.memoData.type
                        };
                        params.append('data', JSON.stringify(newMemoData));
                        return [4 /*yield*/, axios.post('/apiInternal/UpdateMemo', params)];
                    case 1:
                        res = _a.sent();
                        this.isEditing = false;
                        if (res.data.status === 200) {
                            this.updateMemoData(res.data.result, MessageDisplayType.SUCCESS_MESSAGE);
                            this.updateLastMemo(res.data.result);
                            return [2 /*return*/];
                        }
                        canLoadNewMemo = confirm('他のユーザがメモを変更したため、変更をすることができません。最新のメモを取得しますか？');
                        if (canLoadNewMemo) {
                            this.updateMemoData(res.data.result, MessageDisplayType.RELOAD_MESSAGE);
                        }
                        else {
                            this.isEditing = true;
                        }
                        this.updateLastMemo(res.data.result);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.messageType = MessageDisplayType.FAILED_MESSAGE;
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください'); // TODO: Fix text.
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop(Object)
    ], Memo.prototype, "memoData", void 0);
    __decorate([
        Watch('messageType')
    ], Memo.prototype, "onMessageType", null);
    Memo = __decorate([
        Component
    ], Memo);
    return Memo;
}(Vue));
export default Memo;
