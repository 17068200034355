<script>
  // MonthlyIncome.vue
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    props: ['data', 'labels', 'title'],
    mounted () {
      this.renderChart({
        labels: this.labels,
        datasets: [{
          label: '回収予定（31日以内）',
          borderColor: '#fb740d',
          backgroundColor: '#ffeee1',
          steppedLine: true,
          data: this.data.term1,
        },{
          label: '回収予定（62日以内）',
          borderColor: '#fb0d1d',
          backgroundColor: '#ffe1e3',
          steppedLine: true,
          data: this.data.term2,
        },{
          label: '回収予定（105日以内）',
          borderColor: '#e90487',
          backgroundColor: '#fedaef',
          steppedLine: true,
          data: this.data.term3,
        }],
      },{
        title: {
            display: true,
            text: this.title,
            fontSize: 15,
        },
        responsive: true, 
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              max: 11,
              min: 0,
              stepSize:0.5, 
              callback: function(value, index, values) {
                return value + '%';
              }
            }
          }],
          xAxes: [{
            ticks: {
              max: this.labels.length,
              min: 0,
              stepSize:1, 
            }
          }],
        },
      });
    }
  };
</script>
