<template>
  <div class="card mb-0" :class="['border-'+color, cardClass]">
    <div class="card-header b f12 p-2" :class="['bg-'+color]">
      {{title}}
    </div>
    <div class="card-body p-0 d-flex align-items-center pr-3">
      <span class="p-4 font-2xl" :class="['bg-'+color, 'cui-'+icon]" aria-hidden="true"></span> 
      <div class="ml-3" v-for="param in params">
        <div class="text-value-sm" :class="'text-'+color">{{param.value}}</div>
        <div class="text-muted text-uppercase font-weight-bold small">{{param.label}}</div>
      </div>
    </div>
    <template v-if="evidences">
      <div class="card-footer p-1">
        <span class="badge mr-1" :class="'badge-'+color" v-for="ev in evidences">
          {{ev}}
        </span>
      </div>
    </template>
  </div>
 </template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'IndicatorBox',
    props: [
      'cardClass',
      'title',
      'color',
      'icon',
      'params',
      'evidences',
    ],
    computed: {
      ...mapGetters([
      ])
    },
    data() {
      return {
      }
    },
    methods: {
    },
  };
</script>
