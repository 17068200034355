var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators';
import store from '.';
import axios from 'axios';
import { FREEE_SERVICE, MFCLOUD_SERVICE, MISOCA_SERVICE } from '../config';
import Vue from 'vue';
export var LIMIT_PAGE = 20;
function isArrayEmpty(variable) {
    return Array.isArray(variable) && variable.length === 0;
}
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = LIMIT_PAGE;
        _this.userID = 0;
        _this.isFreeeReset = false;
        _this.freeeCompanies = [];
        _this.freeeCompanyInvoices = [];
        _this.freeeInvoiceDetails = {};
        _this.currentFreeeCompanyID = 0;
        _this.freeeInvoices = _this.defaultFreeeInvoice;
        _this.misocaInvoices = _this.defaultLinkingInvoice;
        _this.mfCloudInvoices = _this.defaultLinkingInvoice;
        _this.isOnAPIRequest = false;
        return _this;
    }
    Object.defineProperty(Main.prototype, "defaultFreeeInvoice", {
        get: function () {
            return {
                company_id: 0,
                invoices: [],
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "defaultLinkingInvoice", {
        get: function () {
            return {
                total: 0,
                total_pages: 0,
                limit: LIMIT_PAGE,
                currentPage: 1,
                next_page: null,
                prev_page: null,
                invoices: [],
            };
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.setIsOnAPIRequest = function (flag) {
        this.isOnAPIRequest = flag;
    };
    Main.prototype.setIsFreeeReset = function (flag) {
        this.isFreeeReset = flag;
    };
    Main.prototype.setUserId = function (id) {
        this.userID = id;
    };
    Main.prototype.setFreeeCompanies = function (companies) {
        this.freeeCompanies = companies;
    };
    Main.prototype.setCurrentFreeeCompanyID = function (companyID) {
        this.currentFreeeCompanyID = companyID;
    };
    Main.prototype.setFreeeInvoices = function (invoices) {
        this.freeeInvoices = invoices;
    };
    Main.prototype.setFreeeCategoryInvoices = function (invoices) {
        this.freeeCompanyInvoices.push(invoices);
    };
    Main.prototype.setMisocaInvoices = function (invoices) {
        this.misocaInvoices = invoices;
    };
    Main.prototype.setMfCloudInvoices = function (invoices) {
        this.mfCloudInvoices = invoices;
    };
    Main.prototype.loadInitialData = function (serviceID) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, userID, invoices, companies, freeeInvoice, company_id;
            return __generator(this, function (_a) {
                doc = document;
                userID = doc.USER_ID;
                if (!userID) {
                    return [2 /*return*/];
                }
                this.setUserId(userID);
                invoices = [];
                switch (serviceID) {
                    case 'freeeInvoiceTable':
                        invoices = doc.FREEE_INVOICES;
                        if (!isArrayEmpty(invoices)) {
                            companies = invoices.companies, freeeInvoice = __rest(invoices, ["companies"]);
                            company_id = freeeInvoice.company_id;
                            this.setFreeeCompanies(companies);
                            this.setCurrentFreeeCompanyID(company_id);
                            this.setFreeeInvoices(freeeInvoice);
                            this.setFreeeCategoryInvoices(freeeInvoice);
                        }
                        break;
                    case 'MisocaInvoiceTable':
                        invoices = doc.MISOCA_INVOICES;
                        if (!isArrayEmpty(invoices)) {
                            this.setMisocaInvoices(invoices);
                        }
                        break;
                    case 'MfCloudInvoiceTable':
                        invoices = doc.MFCLOUD_INVOICES;
                        if (!isArrayEmpty(invoices)) {
                            this.setMfCloudInvoices(invoices);
                        }
                        break;
                    default:
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    Main.prototype.getFreeeInvoiceByCompany = function (companyID) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.freeeCompanyInvoices.find(function (data) { return data.company_id === companyID; })];
            });
        });
    };
    Main.prototype.getFreeeInvoices = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var invoices, params, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFreeeInvoiceByCompany(this.currentFreeeCompanyID)];
                    case 1:
                        invoices = _a.sent();
                        if (invoices != undefined) {
                            this.setFreeeInvoices(invoices);
                            return [2 /*return*/];
                        }
                        params = {
                            page: page,
                            company_id: this.currentFreeeCompanyID,
                            service: FREEE_SERVICE
                        };
                        return [4 /*yield*/, this.callLinkingInvoices(params)];
                    case 2:
                        response = _a.sent();
                        if (!isArrayEmpty(response)) {
                            this.setFreeeInvoices(response);
                            this.setFreeeCategoryInvoices(response);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.getMisocaInvoices = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var params, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            page: page,
                            service: MISOCA_SERVICE
                        };
                        return [4 /*yield*/, this.callLinkingInvoices(params)];
                    case 1:
                        response = _a.sent();
                        if (!isArrayEmpty(response)) {
                            this.setMisocaInvoices(response);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.getMfCloudInvoices = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var params, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            page: page,
                            service: MFCLOUD_SERVICE
                        };
                        return [4 /*yield*/, this.callLinkingInvoices(params)];
                    case 1:
                        response = _a.sent();
                        if (!isArrayEmpty(response)) {
                            this.setMfCloudInvoices(response);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.callLinkingInvoices = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isOnAPIRequest)
                            return [2 /*return*/];
                        this.setIsOnAPIRequest(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.get('/apiInternal/getPaginateLinkingInvoices/' + this.userID, { params: params })];
                    case 2:
                        res = _a.sent();
                        this.setIsOnAPIRequest(false);
                        if (res.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            return [2 /*return*/];
                        }
                        return [2 /*return*/, res.data.result];
                    case 3:
                        err_1 = _a.sent();
                        alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.getFreeeInvoiceDetail = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isOnAPIRequest)
                            return [2 /*return*/];
                        this.setIsOnAPIRequest(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.get('/apiInternal/getFreeeInvoiceDetail/' + this.userID, { params: params })];
                    case 2:
                        res = _a.sent();
                        this.setIsOnAPIRequest(false);
                        if (res.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            return [2 /*return*/];
                        }
                        if (res.data != undefined && res.data.result != undefined && res.data.result.length > 0) {
                            res.data.result.forEach(function (invoice) {
                                Vue.set(_this.freeeInvoiceDetails, invoice.id, invoice);
                            });
                        }
                        return [2 /*return*/, res.data.result];
                    case 3:
                        err_2 = _a.sent();
                        alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                        throw err_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "setIsOnAPIRequest", null);
    __decorate([
        Mutation
    ], Main.prototype, "setIsFreeeReset", null);
    __decorate([
        Mutation
    ], Main.prototype, "setUserId", null);
    __decorate([
        Mutation
    ], Main.prototype, "setFreeeCompanies", null);
    __decorate([
        Mutation
    ], Main.prototype, "setCurrentFreeeCompanyID", null);
    __decorate([
        Mutation
    ], Main.prototype, "setFreeeInvoices", null);
    __decorate([
        Mutation
    ], Main.prototype, "setFreeeCategoryInvoices", null);
    __decorate([
        Mutation
    ], Main.prototype, "setMisocaInvoices", null);
    __decorate([
        Mutation
    ], Main.prototype, "setMfCloudInvoices", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "getFreeeInvoiceByCompany", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "getFreeeInvoices", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "getMisocaInvoices", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "getMfCloudInvoices", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "callLinkingInvoices", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "getFreeeInvoiceDetail", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main' })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);
