import moment from 'moment';

const ON_WAITING_ADDITIONAL_FILE = 8;
const ON_TRUSTDOCK_CHECKING = 10;
const ON_TRUSTDOCK_CHECK_NG = 11;
const ON_REGISTRATION_CHECK = 1;
const ON_ANTI_SOCIAL_CHECK = 2;
// 電話インタビューは削除により欠番
const ON_JUDGE = 4;
const ON_POSTAL = 5;
const ON_COMPLETE = 6;
const ON_DECLINED = 7;
const ON_EXPIRED_IN_UNCONFIRMED = 9;

// TODO: Fix text
const TRUSTDOCK_AML_RESULT_TYPE = {
  'general': '一般記事がヒット',
  'antisocial_forces': '反社会的勢力記事がヒット'
};
const TRUSTDOCK_AML_RESULT_MATCHES = {
  'name': '名前のみマッチ',
  'name_and_birth': '名前と年齢がマッチ'
};

export default {


  // Returns users list for selectbox options
  users: (state) => {
    const users = [{
      id: 0,
      name: '-',
    }];
    state.admin_users.forEach((user) => {
      users.push({
        id: user.ID,
        name: user.Name,
      });
    });
    return users;
  },

  // Return AdminUser.ID who is escalated on the process
  escalatedUser: (state) => (process) => {
    if (process === 'RegistrationCheck') {
      return state.application.RegistrationCheckEscalatedAdminUserID;
    } else if (process === 'AntiSocialCheck') {
      return state.application.AntiSocialCheckEscalatedAdminUserID;
    }
    return 0;
  },

  // Return escalation note of the 'process'
  escalationNote: (state) => (process) => {
    if (process === 'RegistrationCheck') {
      return state.application.RegistrationCheckNote;
    } else if (process === 'AntiSocialCheck') {
      return state.application.AntiSocialCheckNote;
    }
    return "";
  },

  // Return escalation note of the 'process'
  escalationDone: (state, getters) => (process) => {
    let status_id = state.application.MasterApplicationStatusID;
    if (process === 'RegistrationCheck') {
      return status_id > ON_REGISTRATION_CHECK && getters.escalatedUser != 0;
    } else if (process === 'AntiSocialCheck') {
      return status_id > ON_ANTI_SOCIAL_CHECK && getters.escalatedUser != 0;
    }
    return false;
  },

  // Return escalation approved or not of the 'process'
  escalationApprovedOrRejected: (state, getters) => (process) => {
    return "";
  },

  // Is the prcess active or not
  isActive: state => (process) => {
    const status_id = state.application.MasterApplicationStatusID;
    const isEkyc = state.application.IsEkyc;
    const isCorporated = state.application.incorporated;
    if (process === 'TrustDockCheck') {
      return status_id === ON_TRUSTDOCK_CHECKING;
    }
    if (process === 'WaitingAdditionalFile') {
      return status_id === ON_WAITING_ADDITIONAL_FILE;
    }
    if (process === 'RegistrationCheck') {
      return status_id === ON_REGISTRATION_CHECK;
    }
    if (process === 'AntiSocialCheck') {
      return status_id === ON_ANTI_SOCIAL_CHECK;
    }
    if (process === 'Judge') {
      return status_id === ON_JUDGE;
    }
    if (process === 'Postal') {
      return status_id === ON_POSTAL;
    }
    if (process === 'Complete') {
      return status_id === ON_COMPLETE
        || status_id === ON_DECLINED
        || status_id === ON_EXPIRED_IN_UNCONFIRMED;
    }
    if (process === 'JudgeTrustdock') {
      return isEkyc && status_id === ON_TRUSTDOCK_CHECK_NG;
    }

    return false;
  },

  // Is the prcess escalated or not
  isEscalated: (state) => (process) => {
    if (process === 'RegistrationCheck') {
      return state.application.RegistrationCheckEscalated;
    } else if (process === 'AntiSocialCheck') {
      return state.application.AntiSocialCheckEscalated;
    }
    return false;
  },

  // Return who did check the process
  checkedBy: (state) => (process) => {
    let id = 0;
    if (process === 'RegistrationCheck') {
      id = state.application.RegistrationCheckAdminUserID;
    } else if (process === 'AntiSocialCheck') {
      id = state.application.AntiSocialCheckAdminUserID;
    } else if (process === 'Judge') {
      id = state.application.JudgeAdminUserID;
    }
    let found = state.admin_users.find((u) => u.ID === id);
    if (found) {
      return found.Name;
    }
    return "";
  },

  // Return whed did the check has been done
  checkedDate: (state) => (process) => {
    moment.locale('ja');
    let frmt = 'YYYY-MM-DD HH:mm:ss' // 2018-8-27 04:48:00
    let d;
    if (process === 'RegistrationCheck') {
      d = new Date(state.application.RegistrationCheckDone);
    } else if (process === 'AntiSocialCheck') {
      d = new Date(state.application.AntiSocialCheckDone);
    } else if (process === 'Judge') {
      d = new Date(state.application.JudgeDone);
    }
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  // Return judge result id
  judgeResult: (state) => {
    let status_id = state.application.MasterApplicationStatusID;
    if (status_id === ON_DECLINED) {
      return 2;
    }
    // eKYC補助審査のデフォルト値
    if (status_id === ON_TRUSTDOCK_CHECK_NG) {
      return 0;
    }
    if (status_id > ON_JUDGE) {
      return 1;
    }
    return 0;
  },

  // Return Application.JudgeDeclineReason
  judgeDeclineReason: (state) => {
    return state.application.JudgeDeclineReason;
  },

  // Return Application.JudgeNote
  judgeNote: (state) => {
    return state.application.ManualTrustdockJudgeNote;
  },

  // Handle format and default value for Application.JudgeAdditionalExpiredDate
  judgeAdditionalExpiredDate: (state) => {
    const judgeAdditionalExpiredDate = moment(state.application.JudgeAdditionalExpiredDate)
      .tz('Asia/Tokyo').format('YYYY-MM-DD');

    if (judgeAdditionalExpiredDate !== '0001-01-01') {
      return judgeAdditionalExpiredDate;
    }
    return moment(state.application.DefaultJudgeAdditionalExpiredDate)
      .tz('Asia/Tokyo').format('YYYY-MM-DD');
  },
  judgeAdditionalReason: state => state.application.JudgeAdditionalReason,
  serviceUrl: state => `https://${state.application.MasterSkin.Domain}`,
  // Return the user's confirmation code can be resent or not
  canResendConfirmationCode: (state, getters) => {
    let dateInfo = getters.confirmationCodeDateInfo();
    return dateInfo.confirmationCodeExpiredDate !== null
           && dateInfo.confirmationCodeReSentDate === null ;
  },

  // Return the user's confirmation code has been requested to be re-sent
  resendRequested: (state, getters) => {
    return state.user.ReSendingConfirmationCode;
  },

  // Return date & datetime data of 'Application
  confirmationCodeDateInfo: (state) => () => {
    let d1 = new Date(state.user.ConfirmationCodeSentDate);
    let d2 = new Date(state.user.ConfirmationCodeExpiredDate);
    let d3 = new Date(state.user.ConfirmationCodeInputDate);
    let d4 = new Date(state.user.ConfirmationCodeReSentDate);
    let d5 = new Date(state.user.ConfirmationCodeReExpiredDate);
    let d6 = new Date(state.user.ConfirmationCodeReInputDate);

    moment.locale('ja');
    let fmt = 'YYYY-MM-DD ' // 2018-8-27
    return {
      confirmationCodeSentDate :       d1.getFullYear() === 1 ? null :  moment(d1).format(fmt),
      confirmationCodeExpiredDate:     d2.getFullYear() === 1 ? null :  moment(d2).format(fmt),
      confirmationCodeInputDate :      d3.getFullYear() === 1 ? null :  moment(d3).format(fmt),
      confirmationCodeReSentDate :     d4.getFullYear() === 1 ? null :  moment(d4).format(fmt),
      confirmationCodeReExpiredDate :  d5.getFullYear() === 1 ? null :  moment(d5).format(fmt),
      confirmationCodeReInputDate :    d6.getFullYear() === 1 ? null :  moment(d6).format(fmt),
      confirmationCode            :    state.user.ConfirmationCode,
    };
  },

  // Return the account opening process has been on process, sucusessfully done, or declined
  openingAccountStatus: (state) => {
    let status_id = state.application.MasterApplicationStatusID;
    if (status_id === ON_COMPLETE) {
      return '正常完了';
    } else if (status_id === ON_DECLINED) {
      return '謝絶';
    } else if (status_id === ON_EXPIRED_IN_UNCONFIRMED) {
      moment.locale('ja');
      let d = new Date(state.application.ExpiredInUnconfirmed);
      return '確認コード未入力で終了 (' + moment(d).format('YYYY/MM/DD HH:mm:ss') + ')';
    } else {
      return '審査進行中';
    }
  },

  // Return is declined or not
  declined: (state) => {
    return state.application.MasterApplicationStatusID === ON_DECLINED;
  },

  // Return is declined or not
  expiredInUnconfirmed: (state) => {
    return state.application.MasterApplicationStatusID === ON_EXPIRED_IN_UNCONFIRMED;
  },

  // Return items for calendar-use selectboxies
  calendarList: (state) => (exp) => {
    let now = new Date();
    if (exp === 'year') {
      let years = [];
      for (let i = 0;i < 20;i++) {
        years.push(now.getFullYear() + i);
      }
      return years;
    } else if (exp === 'month') {
      let months = [];
      for (let i = 0;i < 12;i++) {
        months.push(i+1);
      }
      return months;
    } else if (exp === 'day') {
      let days = [];
      for (let i = 0;i < 31;i++) {
        days.push(i+1);
      }
      return days;
    }
    return [];
  },

  // Return Application.RegistrationCertExpiring
  certExpiring: (state) => (exp) => {
    let d = new Date(state.application.RegistrationCertExpiring);
    if (d.getFullYear() === 1) {
      return "";
    }
    moment.locale('ja');
    if (exp === 'year') {
      return parseInt(moment(d).format('YYYY'), 10);
    } else if (exp === 'month') {
      return parseInt(moment(d).format('MM'), 10);
    } else if (exp === 'day') {
      return parseInt(moment(d).format('DD'), 10);
    }
    return "";
  },

  // Return Application.RegistrationCertExpiring
  certNumberExpiring: (state) => (exp) => {
    let d = new Date(state.application.RegistrationCertExpiring);
    if (d.getFullYear() === 1) {
      return "";
    }
    moment.locale('ja');
    if (exp === 'year') {
      return parseInt(moment(d).format('YYYY'), 10);
    } else if (exp === 'month') {
      return parseInt(moment(d).format('MM'), 10);
    } else if (exp === 'day') {
      return parseInt(moment(d).format('DD'), 10);
    }
    return "";
  },

  fullName: (state) => {
    if (state.application.incorporated) {
      return state.application.represent_family_name + state.application.represent_first_name;
    }
    return state.application.family_name + state.application.first_name;
  },

  createdDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD HH:mm:ss';
    let d;
    d = new Date(state.application.CreatedAt);
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  activatedDate : (state) => {
    moment.locale('ja');
    let frmt = 'YYYY/MM/DD';
    let d;
    let d1 = new Date(state.user.ConfirmationCodeInputDate);
    let d2 = new Date(state.user.ConfirmationCodeReInputDate);
    if (d1.getFullYear() !== 1) {
      d = d1;
    }
    if (d2.getFullYear() !== 1) {
      d = d2;
    }
    if (!d) {
      return '-';
    }
    return d.getFullYear() === 1 ? '-' :  moment(d).format(frmt);
  },

  alternatedAnshinAccountName: state => {
    return state.application.alternated_anshin_account_name;
  },

  alternatedBankAccountName: state => {
    return state.application.alternated_bank_account_name;
  },

  waitingAdditionalAnshinAccountFile: state => {
    return state.application.waiting_additional_anshin_account_file;
  },

  waitingAdditionalAccountNameFile: state => {
    return state.application.waiting_additional_account_name_file;
  },

  opluxResult: (state) => {
    return {
      result: state.application.oplux_result,
      resultXml: state.application.oplux_result_xml
    };
  },

  isTrustdockPassed: state => state.application.TrustdockCheckPassed,
  isEkyc: state => state.application.IsEkyc,

  trustDockPersonal: (state) => {
    const fmt = 'YYYY/MM/DD HH:mm:ss';
    const requestAtDate = new Date(state.application.trustdock_personal_check_requested_at);
    const completeAtDate = new Date(state.application.trustdock_personal_check_completed_at);
    const deniedReasons = state.application.trustdock_personal_check_denied_reasons;
    let requestAt = moment(requestAtDate).format(fmt);
    let completeAt = moment(completeAtDate).format(fmt);
    let result = '-';
    let passed = state.application.trustdock_personal_check_passed;

    if (requestAtDate.getFullYear() === 1) {
      requestAt = '-';
    }

    if (completeAtDate.getFullYear() === 1) {
      completeAt = '-';
    }

    if (completeAt !== '-' && passed) {
      result = '承認';
    } else if (completeAt !== '-' && (deniedReasons !== '' || !passed)) {
      result = '非承認';
    }

    return {
      checkID: state.application.trustdock_personal_check_id,
      requestAt,
      completeAt,
      result,
      deniedReasons
    };
  },

  trustDockAml: (state) => {
    const fmt = 'YYYY/MM/DD HH:mm:ss';
    const requestAtDate = new Date(state.application.trustdock_aml_check_requested_at);
    const completeAtDate = new Date(state.application.trustdock_aml_check_completed_at);
    const deniedReasons = state.application.trustdock_aml_check_denied_reasons;
    let requestAt = moment(requestAtDate).format(fmt);
    let completeAt = moment(completeAtDate).format(fmt);
    let result = '-';
    let resultType = '';
    let resultMatches = '';
    let passed = state.application.trustdock_aml_check_passed;

    if (requestAtDate.getFullYear() === 1) {
      requestAt = '-';
    }

    if (completeAtDate.getFullYear() === 1) {
      completeAt = '-';
    }

    if (completeAt !== '-' && passed) {
      result = '承認';
    } else if (completeAt !== '-' && (deniedReasons !== '' || !passed)) {
      result = '非承認';
      try {
        const jsonResp = JSON.parse(state.application.trustdock_aml_check_json_response);
        resultType = jsonResp.result_type ? TRUSTDOCK_AML_RESULT_TYPE[jsonResp.result_type] : resultType;
        resultMatches = jsonResp.result_matches ? TRUSTDOCK_AML_RESULT_MATCHES[jsonResp.result_matches] : resultMatches;
      } catch(e) {
        const tMatcher = state.application.trustdock_aml_check_json_response.match( /"(result_type)":"((\\"|[^"])*)"/i );
        const mMatcher = state.application.trustdock_aml_check_json_response.match( /"(result_matches)":"((\\"|[^"])*)"/i );
        resultType = tMatcher.length ? TRUSTDOCK_AML_RESULT_TYPE[tMatcher[2]] : resultType;
        resultMatches = mMatcher.length ? TRUSTDOCK_AML_RESULT_MATCHES[mMatcher[2]] : resultMatches;
      }
    }

    return {
      checkID: state.application.trustdock_aml_check_id,
      requestAt,
      completeAt,
      result,
      resultType,
      resultMatches,
      deniedReasons
    };
  },

  // Is trustdock check NG
  isNgTrustdock: (state) => {
    const status_id = state.application.MasterApplicationStatusID;
    return status_id === ON_TRUSTDOCK_CHECK_NG;
  },

  // Is corporated
  isCorporated: (state) => {
    return state.application.incorporated;
  },

  application: (state) => {
    return state.application;
  },

  duplicateMainbankUserID: (state) => {
    return state.application.DuplicateMainbankUserID;
  },

  isCheckedEkycForHo: (state) => {
    return state.user.TrustdockPersonalCheckPublicID !== '';
  },

  isCheckedApplicationSamePossibility: (state) => {
    return state.application_same_possibilities.length === 0;
  },

  applicationSamePossibilityReasons: (state) => {
    const reasons = [];
    state.application_same_possibilities.forEach(val => {
      let reason = "";
      if (val.ByName) {
        reason += "氏名 ";
      }
      if (val.ByBirthDay) {
        reason += "生年月日 ";
      }
      if (val.ByTel) {
        reason += "電話番号 ";
      }
      if (val.ByAddress) {
        reason += "住所 ";
      }
      reason += val.Count + "件";
      reasons.push(reason);
    })
    return reasons;
  }
};
