import axios from 'axios';

function serialize (data) {
  const params = new URLSearchParams();
  params.append('data', JSON.stringify(data));
  return params;
}

function onSuccess (opt, res) {
  if (opt.onSuccess) {
    opt.onSuccess(res);
    return;
  }
  if (res.data.status !== 200) {
    onError(opt, res);
    return;
  }
  location.reload();
}

function onError (opt, err) {
  if (opt.onError) {
    opt.onError(err);
    return;
  }

  if (err.data && err.data.message) {
    alert(err.data.message);
  } else {
    alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
  }
  if (opt.errCallback) opt.errCallback();
}

export default {
  // Ajax request of serialized table-jorm params
  updateProcess({ commit, state }, opt) {
    axios.post('/apiInternal/updateDealProcess/' + state.deal.ID, serialize(opt.data))
      .then((res) => onSuccess(opt, res))
      .catch((err) => onError(opt, err));
  },

  updateCollectionReminderForCollection(_, { collectionId, reminderDisabled }) {
    const params = new URLSearchParams();
    params.append('data', JSON.stringify(reminderDisabled));

    return axios.post(`/apiInternal/updateCollectionReminderForCollection/${collectionId}`, params);
  },

  makeArbitraryIncoming({ commit, state }, opt) {
    axios.post('/apiInternal/makeArbitraryIncoming', serialize(opt.data))
      .then((res) => onSuccess(opt, res))
      .catch((err) => onError(opt, err));
  },
};
