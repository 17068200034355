<template>
  <div class="card mb-1">
    <ToggleHeader 
      :name="no + '. 会員登録プロセス完了'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <div :id="'collapseStep'+no" class="card-body collapse" :class="{ 'show' : active }">
      <p class="mt-2">{{openingAccountStatus}}</p>
      <div class="mt-2" v-if="declined || expiredInUnconfirmed">
        ※申込者様から再度会員登録申込を行いたい旨のご連絡を頂いた場合は以下のユーザー詳細画面より当該ユーザーを削除して下さい（同一のメールアドレスで再申込が出来ないため）
        <p class="text-center mt-2">
          <a :href="'/users/view/'+userId" class="btn btn-primary btn-sm">ユーザー詳細へ</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader  from './parts/ToggleHeader.vue';

  export default {
    name: 'Complete',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'declined',
        'expiredInUnconfirmed',
        'openingAccountStatus',
      ]),
    },
    components: {
      ToggleHeader,
    },
    data() {
      let p = this.$options.name;
      return {
        'process' : p,
        'active' : this.$store.getters.isActive(p),
        'userId' : this.$store.state.application.user_id,
      };
    },
  };
</script>
