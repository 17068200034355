import moment from 'moment'
moment.locale('ja');

const date = function(val) {
  return moment(val).format('YYYY-MM-DD');
};

function dayDiff(from, to) {
  let fromMoment = moment(new Date(from));
  let nowMoment = moment(new Date(to));
  return -fromMoment.diff(nowMoment, 'days');
}

function dayAdd(from, val) {
  return moment(from).add('days', val).format('YYYY-MM-DD');
}

export default function dateRangeSlider () {
  let $d    = $('.dateRangeSlider'),
    created = $d.data('created'),
    issued  = $d.data('issued'),
    sending = $d.data('sending'),
    judged  = $d.data('judged'),
    due     = $d.data('due'),
    from    = dayDiff(created, (judged === "0001/01/01" ? sending : judged)),
    to      = dayDiff(created, due),
    max     = 105,
    values  = [],
    i = 0;

  for (;i<max;i++) {
    let value = dayAdd(created, i);
    if (i === 0 ) {
      value = ((created == judged) ? "申込&審査日:" : "申込日:") + value;
    } else if (i === from) {
      value = (judged === "0001/01/01" ? "出金予定日" : "審査日") + ":" + value;
    } else if (i === to) {
      value = "入金予定日:" + value;
    } else if (i === max-1) {
      value = value + "<br />(申込日から105日)";
    }
    values.push(value);
  }
  
  $d.ionRangeSlider({
    type: 'double',
    grid: true,
    min: 0,
    max: max,
    from_fixed: true,
    from: from,
    to: to,
    to_fixed: true,
    values: values,
  });
};
