var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import axios from 'axios';
import { Vue, Component, Prop } from 'vue-property-decorator';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
var CorporationIndustry = /** @class */ (function (_super) {
    __extends(CorporationIndustry, _super);
    function CorporationIndustry() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.message = '';
        _this.isError = false;
        _this.isEditingMode = false;
        _this.selectedCategory = null;
        _this.categoryOptions = _this.getIndustryCategories();
        _this.industryOptions = _this.getIndustries();
        _this.currentCorpIndustries = _this.getCurrentCorpIndustries();
        _this.selectedIndustries = __spreadArrays(_this.currentCorpIndustries);
        _this.isFullOptions = _this.page !== 'Deal';
        return _this;
    }
    CorporationIndustry.prototype.startEditingMode = function () {
        this.isEditingMode = true;
        this.message = '';
    };
    CorporationIndustry.prototype.cancelEditingMode = function () {
        this.isEditingMode = false;
        this.message = '';
        this.resetUnsavedChanges();
    };
    CorporationIndustry.prototype.getIndustryCategories = function () {
        var list = [];
        this.masterCorpIndustries.forEach(function (item) {
            if (item.Code.length === 1) {
                list.push({
                    ID: item.ID,
                    Code: item.Code,
                    Name: item.Name,
                    CodeWithName: item.Code + " " + item.Name
                });
            }
        });
        return list;
    };
    CorporationIndustry.prototype.changeIndustyOptions = function () {
        this.industryOptions = this.getIndustries();
    };
    CorporationIndustry.prototype.getIndustries = function () {
        var _this = this;
        var list = [];
        this.masterCorpIndustries.forEach(function (item) {
            if (_this.selectedCategory) {
                // get industries by selected category
                if (item.Code.startsWith(_this.selectedCategory.Code)) {
                    list.push({
                        ID: item.ID,
                        Code: item.Code,
                        Name: item.Name,
                        CodeWithName: item.Code + " " + item.Name
                    });
                }
            }
            else {
                // get all industries
                list.push({
                    ID: item.ID,
                    Code: item.Code,
                    Name: item.Name,
                    CodeWithName: item.Code + " " + item.Name
                });
            }
        });
        return list;
    };
    CorporationIndustry.prototype.getCurrentCorpIndustries = function () {
        var list = [];
        this.corpIndustries.forEach(function (item) {
            list.push({
                ID: item.ID,
                Code: item.Code,
                Name: item.Name,
                CodeWithName: item.Code + " " + item.Name
            });
        });
        return list;
    };
    CorporationIndustry.prototype.removeCorpIndustry = function (id) {
        var indexToRemove = this.selectedIndustries.findIndex(function (obj) { return obj.ID === id; });
        if (indexToRemove !== -1) {
            this.selectedIndustries.splice(indexToRemove, 1);
        }
    };
    CorporationIndustry.prototype.resetUnsavedChanges = function () {
        this.selectedIndustries = __spreadArrays(this.currentCorpIndustries);
    };
    CorporationIndustry.prototype.UpdateCorpIndustries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var selectedIds, savedIds_1, params, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        selectedIds = this.selectedIndustries.map(function (item) { return item.ID; });
                        savedIds_1 = this.currentCorpIndustries.map(function (item) { return item.ID; });
                        if (selectedIds.length === savedIds_1.length && selectedIds.every(function (value) { return savedIds_1.includes(value); })) {
                            return [2 /*return*/];
                        }
                        params = new URLSearchParams();
                        params.append('data', JSON.stringify({
                            corporation_id: this.corpId,
                            master_corporation_industry_ids: selectedIds
                        }));
                        return [4 /*yield*/, axios.post('/apiInternal/UpdateCorpIndustries', params)];
                    case 1:
                        res = _a.sent();
                        if (res.data.status !== 200) {
                            throw new Error('エラーが発生しました。もう一度やり直してください。');
                        }
                        else {
                            this.isEditingMode = false;
                            this.currentCorpIndustries = __spreadArrays(this.selectedIndustries);
                            this.updateMsg('業種が更新されました。');
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        alert(err_1.message);
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CorporationIndustry.prototype.updateMsg = function (msg) {
        var _this = this;
        this.message = msg;
        setTimeout(function () { _this.message = ''; }, 5000);
    };
    __decorate([
        Prop(String)
    ], CorporationIndustry.prototype, "page", void 0);
    __decorate([
        Prop(Number)
    ], CorporationIndustry.prototype, "corpId", void 0);
    __decorate([
        Prop(Array)
    ], CorporationIndustry.prototype, "corpIndustries", void 0);
    __decorate([
        Prop(Array)
    ], CorporationIndustry.prototype, "masterCorpIndustries", void 0);
    CorporationIndustry = __decorate([
        Component({
            components: {
                vSelect: vSelect
            }
        })
    ], CorporationIndustry);
    return CorporationIndustry;
}(Vue));
export default CorporationIndustry;
