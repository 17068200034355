<template>
  <div class="card mb-1" :class="{ 'border-warning' : escalated }">
    <ToggleHeader 
      :name="no + '. お申し込み内容チェック'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <div class="m-0 pl-4">
          <div class="custom-control custom-checkbox mt-2" v-for="item in checkList" :key="`check-${item.id}`">
            <input class="custom-control-input" type="checkbox" :value="item.id" :disabled="!active" :id="`check-${item.id}`" v-model="checkedItems">
            <label class="custom-control-label" :for="`check-${item.id}`">
              {{ item.content }}
            </label>
            <span
              v-if="active && item.id == 'mainbankAccountName' && duplicateMainbankUserID != 0"
              class="badge badge-danger"
              id="duplicateMainbankFlg"
            >
              同一のメインバンク口座が登録済み(<a :href="'/users/view/'+duplicateMainbankUserID">{{duplicateMainbankUserID}}</a>)
            </span>
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <label>身分証明書</label>
        <div class="form-inline">
          有効期限：
          <select 
            :name="'select_year'"
            class="form-control" 
            :class="{'is-invalid': !inputDateValid}"
            v-model="year" 
            :disabled="!active"
          >
            <option value="">-</option>
            <option 
              v-for="year in years" 
              :value="year" 
              :key="year"
            >{{ year }}</option>
          </select>
          -
          <select 
            :name="'select_month'"
            class="form-control" 
            :class="{'is-invalid': !inputDateValid}"
            v-model="month" 
            :disabled="!active"
          >
            <option value="">-</option>
            <option 
              v-for="month in months" 
              :value="month" 
              :key="month"
            >{{ month }}</option>
          </select>
          -
          <select 
            :name="'select_day'"
            class="form-control" 
            :class="{'is-invalid': !inputDateValid }"
            v-model="day" 
            :disabled="!active"
          >
            <option value="">-</option>
            <option 
              v-for="day in days" 
              :value="day" 
              :key="day"
            >{{ day }}</option>
          </select>
          <div v-if="!inputDateValid" class="invalid-feedback">
            正しい身分証明書の有効期限を入力して下さい
          </div>
        </div>
      </li>
      <CheckAndEscalate
        :no="no"
        :process="process"
        :skip_confirmation="true"
        :isCheckReady="isCheckReady"
        v-on:onClickChecked="onClickChecked"
        v-on:onClickEscalation="onClickEscalation"
      ></CheckAndEscalate>
    </ul>
  </div>
</template>

<style scoped>
#duplicateMainbankFlg {
  display: table;
  padding: 3px 4px;
  margin-top: 2px;
}
</style>

<script>

  import ToggleHeader     from './parts//ToggleHeader.vue';
  import CheckAndEscalate from './parts/CheckAndEscalate.vue';
  import { mapGetters } from 'vuex';
  import moment from 'moment'

  export default {
    name: 'RegistrationCheck',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    methods: {
      onClickChecked () {
        if (this.isValidExpireDate(this.expireDate) && confirm('チェック完了として保存してよろしいでしょうか？')) {
          this.$store.dispatch('updateProcess', {
            'data' : {
              'process': this.process,
              'method': 'checked',
              'expiring':(this.year && this.month && this.day) ? this.year + '-' + this.month + '-' + this.day : '',
              'updated_at':this.$store.state.application.UpdatedAt,
            },
          });
        }
      },
      onClickEscalation (data) {
        data.expiring_year = this.year || 0;
        data.expiring_month = this.month || 0;
        data.expiring_day = this.day || 0;
        this.$store.dispatch('updateProcess', data);
      },
      getCheckedItems (active) {
        // Pre check all the items if this step is already completed
        // Or precheck all items that completed by OCR
        let checkedItems = active ? document.CHECKED_ITEMS : document.CHECK_LIST.map(item => item.id);
        return checkedItems;
      },
      isValidExpireDate (date) {
        // Check valid date and must be after today
        return date.isValid() && date.isAfter(moment());
      }
    },
    computed: {
      ...mapGetters([
        'duplicateMainbankUserID'
      ]),
      expireDate () {
        return moment(this.year + '-' + this.month + '-' + this.day, 'YYYY-M-D', true);
      },
      allChecked () {
        // All items checked
        return this.checkedItems.length === this.checkList.length;
      },
      isCheckReady () {
        return this.isValidExpireDate(this.expireDate) && this.allChecked;
      }
    },
    watch: {
      expireDate(value) {
        // Check the input date, must be after the current date
        this.inputDateValid = this.isValidExpireDate(value);
      }
    },
    data () {
      let p = this.$options.name;
      let g = this.$store.getters;
      let active = g.isActive(p); // If this check is active or not
      let currentUser = this.$store.state.user;

      return {
        'process' :   p,
        'active' :    active,
        'escalated' : g.isEscalated(p),
        'years' :     g.calendarList('year'),
        'months' :    g.calendarList('month'),
        'days' :      g.calendarList('day'),
        'year' :      g.certExpiring('year'),
        'month' :     g.certExpiring('month'),
        'day' :       g.certExpiring('day'),
        'inputDateValid':  true,
        'checkList': document.CHECK_LIST,
        'checkedItems': this.getCheckedItems(active)
      };
    },
  };
</script>
