import Vue from 'vue';
import App from './components/App.vue';
import $ from "jquery";

import VeeValidate, { Validator } from "vee-validate";
// @ts-ignore: implicitly has an 'any' type.
import VeeValidateJa from "modules/vee-validate/dist/locale/ja.js";
Validator.localize("ja", VeeValidateJa);
Vue.use(VeeValidate);

import { mainModule } from "./store/main";
import { telInterviewModule } from "./store/tel_interview";

export default function page () {
    new Vue({
        el: '#app',
        components: {
            'App': App 
        },
        created () {
          mainModule.loadData({
            user_evidence: document.USER_EVIDENCE,
            admin_users: document.ADMIN_USERS,
            login_user_id: document.LOGIN_USER_ID,
          });
          telInterviewModule.loadData(document.USER_EVIDENCE);
        },
    }); 
};
