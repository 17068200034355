<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-4">
        <div class="card m-0">
          <div class="card-header">手数料レンジ設定</div>
          <ul class="list-group list-group-flush m-0">
            <CommissionRangeInput  
              v-for="commission_range in commission_ranges"
              :commission_range="commission_range" 
              :key="commission_range.key"
            ></CommissionRangeInput>
          </ul>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveCommissionRanges"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card m-0">
          <div class="card-header">手数料設定</div>
          <ul class="list-group list-group-flush m-0">
            <CommissionInput  
              v-for="commission in commissions"
              :commission="commission" 
              :key="commission.key" 
            ></CommissionInput>
          </ul>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveCommissions"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import CommissionRangeInput from './CommissionRangeInput.vue';
  import CommissionInput      from './CommissionInput.vue';

  export default {
    name: 'TabPaneCommission',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      CommissionRangeInput,
      CommissionInput,
    },
    methods: {
      'saveCommissionRanges' : function () {
        if (!this.$store.getters.isFormAllValid('commission_ranges')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveCommissionRanges/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.commission_ranges,
          'redirect' : location.pathname + '#tab6',
        });
      },
      
      'saveCommissions' : function () {
        if (!this.$store.getters.isFormAllValid('commissions')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.state.commissions.map((c, i)=>{
          this.$store.state.commissions[i].rate_in_20_days = 
            parseFloat(this.$store.state.commissions[i].rate_in_20_days);
          this.$store.state.commissions[i].rate_in_40_days = 
            parseFloat(this.$store.state.commissions[i].rate_in_40_days);
          this.$store.state.commissions[i].rate_in_60_days = 
            parseFloat(this.$store.state.commissions[i].rate_in_60_days);
        });
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveCommissions/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.commissions,
          'redirect' : location.pathname + '#tab6',
        });
      },
    },
    data() {
      return {
        'commission_ranges': this.$store.state.commission_ranges,
        'commissions': this.$store.state.commissions,
      }
    }
  };
</script>
