var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import mainStoreModule from '../store/main';
import ToggleHeader from './parts/ToggleHeader.vue';
import CheckAndEscalate from './parts/CheckAndEscalate.vue';
var FirstResponse = /** @class */ (function (_super) {
    __extends(FirstResponse, _super);
    function FirstResponse() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.actionName = 'FirstResponse';
        return _this;
    }
    Object.defineProperty(FirstResponse.prototype, "process", {
        get: function () {
            return this.actionName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FirstResponse.prototype, "isActive", {
        get: function () {
            return mainStoreModule.isActive(this.actionName);
        },
        enumerable: false,
        configurable: true
    });
    FirstResponse.prototype.onClickChecked = function () {
        mainStoreModule.updateProcess({
            data: {
                process: this.process,
                method: 'checked'
            }
        });
    };
    __decorate([
        Prop(Number)
    ], FirstResponse.prototype, "no", void 0);
    FirstResponse = __decorate([
        Component({
            components: {
                ToggleHeader: ToggleHeader,
                CheckAndEscalate: CheckAndEscalate
            }
        })
    ], FirstResponse);
    return FirstResponse;
}(Vue));
export default FirstResponse;
