<template>
  <div class="card mb-1">
    <ToggleHeader 
      :name="no + '. 完了'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <div :id="'collapseStep'+no" class="card-body collapse" :class="{ 'show' : isActive(process) }">
      <p class="mt-2">{{dealResult}}</p>
      <p
        v-if="corporationJudgeDeclineReason"
        class="mt-2">
        ※ともだち企業により「{{ corporationJudgeDeclineReason }}」の理由で謝絶されました。
      </p>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader      from '../../ApplicationView/components/parts/ToggleHeader.vue';

  export default {
    name: 'Complete',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
    },
    computed: {
      ...mapGetters([
        'isActive',
        'dealResult',
        'corporationJudgeDeclineReason',
      ]),
    },
    data() {
      return {
        'process' : this.$options.name,
      };
    },
  };
</script>
