import Vue from 'vue';
import App from './components/App.vue';
export default function page(elementID) {
    var element = "#" + elementID;
    var app = new Vue({
        el: element,
        components: {
            App: App
        }
    });
}
