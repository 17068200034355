var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { SortType, Order, sortActual } from '../../../../utils/SortLinkingService';
var LinkingServiceInvoiceMixin = /** @class */ (function (_super) {
    __extends(LinkingServiceInvoiceMixin, _super);
    function LinkingServiceInvoiceMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentPageInvoices = [];
        _this.displayInvoices = [];
        _this.sortType = SortType;
        return _this;
    }
    LinkingServiceInvoiceMixin.prototype.setCurrentInvoices = function (data) {
        this.currentPageInvoices = data;
    };
    LinkingServiceInvoiceMixin.prototype.setDisplayInvoices = function (data) {
        this.displayInvoices = data;
    };
    LinkingServiceInvoiceMixin.prototype.sort = function (params, event) {
        var elems = document.getElementsByClassName('font-weight-bold');
        Array.from(elems).forEach(function (element) {
            element.classList.remove('cui-arrow-bottom');
            element.classList.remove('cui-arrow-top');
        });
        var target = event.target;
        if (target.tagName !== 'SPAN')
            return;
        var sortOrder = Number(target.getAttribute('aria-sort'));
        if (sortOrder === Order.asc) {
            target.classList.add('cui-arrow-top');
        }
        else if (sortOrder === Order.dsc) {
            target.classList.add('cui-arrow-bottom');
        }
        else {
            sortOrder = Order.dsc;
            target.classList.add('cui-arrow-bottom');
        }
        target.setAttribute('aria-sort', -sortOrder);
        this.displayInvoices = sortActual(__assign(__assign({}, params), { data: this.displayInvoices, order: sortOrder }));
    };
    LinkingServiceInvoiceMixin = __decorate([
        Component
    ], LinkingServiceInvoiceMixin);
    return LinkingServiceInvoiceMixin;
}(Vue));
export default LinkingServiceInvoiceMixin;
