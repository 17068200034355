<template>
  <div class="d-industry-select">
    <label>業種:</label>
    <select
      class="form-control"
      v-model="deal.MasterDealIndustryID"
      :disabled="disabled">
      <option v-for="option in masterDealIndustyOptions" :key="option.ID" :value="option.ID">
        {{ option.Title }}
      </option>
    </select>
    <div v-if="masterDealIndustries.length === 0">取引業種マスタが設定されません</div>
  </div>
</template>
<script>

export default {
  name: 'DealIndustrySelect',
  props: {
    deal: Object,
    disabled: Boolean,
    masterDealIndustries: Array,
    masterDealIndustryDefaultId: Number,
  },
  created() {
    if (!this.deal.MasterDealIndustryID) {
      this.$set(this.deal, 'MasterDealIndustryID', this.masterDealIndustryDefaultId);
    }
  },
  data() {
    // 業種設定に「未設定」選択肢を追加する
    const masterDealIndustyOptions = [...[{ ID: '0', Title: '未設定' }], ...this.masterDealIndustries];
    return {
      masterDealIndustyOptions
    };
  }
};
</script>
