import CodeMirror from 'codemirror/lib/codemirror.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/xq-light.css';
import 'codemirror/mode/htmlmixed/htmlmixed.js'

export default function initNewsEditor () {
  var ta = document.getElementById("newsEditor"),
    editor = CodeMirror.fromTextArea(ta, {
      mode: "htmlmixed",
      theme: "xq-light",
      value: ta.value,
    });
};
