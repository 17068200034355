<template>
  <div>
    <label>{{ heading }}</label>
    <pre class="bg-secondary p-3 border border-dark rounded">
{{ fullName }} 様

FREENANCE（フリーナンス）審査担当です。
このたびは、ご登録いただきありがとうございます。

現在、審査プロセスが保留となっております。

保留理由------------------------------------

{{ content }}

---------------------------------------------

**&lt;画像・情報アップロード方法&gt;**

マイページへログインした状態で以下のURLをクリックしてください。
お問合せ内容は「登録審査書類の提出」を選択し、情報または画像をご用意の上、アップロードください。

[《アップロードはこちらをクリック》]({{ serviceUrl }}/contacts?human_support=application_judge_additional)

特にご連絡がない場合は、本日から５営業日程度を目処に
一旦、お申込みを謝絶させていただきます。

お手数ではございますがよろしくお願いいたします。
    </pre>
    <br />
  </div>
</template>

<script>
export default {
  name: 'EmailPreviewTemplateForEmailDelivery',
  props: [
    'fullName',
    'content',
    'heading',
    'serviceUrl',
  ],
};
</script>
