<template>
  <div class="card mb-1">
    <ToggleHeader
      :name="no + '. 入金待ち'"
      :process="process"
      :target="'#collapseStep'+no" />
    <ul
      :id="'collapseStep'+no"
      class="list-group list-group-flush collapse"
      :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-3">
        <div class="row">
          <div class="col text-right">
            入金期限:
          </div>
          <div class="col">
            {{ incomingDate }}
          </div>
        </div>
      </li>
      <li
        v-if="lastCollection"
        class="list-group-item p-3">
        <div class="text-center">
          <div v-if="!this.lastCollection.ReminderDisabled">
            <p class="mb-3">
              督促架電が予定されています（
              <a :href="'/collectionReminders/search?user_id=' + userId">
                このユーザーの架電予定一覧
              </a>
              ）
            </p>
            <button
              @click="updateCollectionReminderStatus(true)"
              :disabled="disabledCollReminderBtn"
              class="btn btn-outline-danger btn-sm">
              架電の非対象とする
            </button>
          </div>
          <div v-else>
            <p class="mb-3">
              督促架電は予定されていません（
              <a :href="'/collectionReminders/search?user_id=' + userId">
                このユーザーの架電予定一覧
              </a>
              ）
            </p>
            <button
              @click="updateCollectionReminderStatus(false)"
              :disabled="disabledCollReminderBtn"
              class="btn btn-outline-success">
              再度架電対象とする
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>振込期限までに回収予定額の完全回収がされた場合、ステータスは自動で「8. 完了」になり終了する</li>
          <li>振込期限を過ぎた完全回収、複数回に渡る回収による完全回収の場合、ステータスは自動で「7. 評価待ち」へ</li>
          <li>振込期限を過ぎても完全回収を迎えない場ユーザーへ直接連絡、遅延・追加入金予定がある場合はその予定日後に再度確認</li>
          <li>回収目処が立たない場合は「不良債権として終了」を押下</li>
        </ul>
      </li>
      <li class="list-group-item p-3">
        <div>
          <label>備考：</label>
          <textarea
            class="form-control"
            style="height:200px;"
            v-model="noteOnCollection"
            :disabled="!isActive(process)" />
          <button
            class="btn btn-secondary btn-block mt-2"
            @click="onClieckSaveNote"
            :disabled="!isActive(process) || disableBtn">
            保存
          </button>
        </div>
      </li>
      <li class="list-group-item p-3">
        <div class="custom-control custom-checkbox mt-2">
          <input
            type="checkbox"
            class="custom-control-input"
            id="checkCollectedAlready"
            value="1"
            v-model="checkedAsCollectedAlready"
            :disabled="!isActive(process) || collectingLimit === 0">
          <label
            class="custom-control-label"
            for="checkCollectedAlready">
            フリーナンス口座外での着金を記録
          </label>
          <div
            v-if="checkedAsCollectedAlready"
            class="py-2">
            <p>通常はフリーナンス口座で債務者から入金を受け回収しますが、ユーザーのフリーナンス口座以外の口座で入金があった場合（= フリーナンス口座への入金が発生しない場合）などはここで入金レコードを発生させます。</p>
            <p>具体的には以下のケースなどで必要になります。</p>
            <ul class="m-0 mb-3 pl-4">
              <li>ともだち企業からの支払いがフリーナンス口座でなく代表口座に複数取引の合算金額で実行される場合</li>
              <li>GMO-CN自身が「ともだち」となる三者間ファクタリング</li>
              <li>ユーザーからの返金がフリーナンス口座外で発生した場合</li>
            </ul>
            <p>入金レコードを発生させただけではこの取引は回収済み扱いにはなりません。通常の出金データ作成処理で回収を行って下さい。</p>
            <label for="inpCollecting">着金額（円）</label>
            <input
              type="text"
              id="inpCollecting"
              :name="'inpCollecting'"
              class="form-control input-sm"
              :class="{'is-invalid': errors.has('inpCollecting')}"
              v-model="collectingAmount"
              v-validate="'required|min_value:1|max_value:' + incomingAmount">
            <p
              v-if="errors.has('inpCollecting')"
              class="invalid-feedback">
              有効な金額を入力してください
            </p>
            <br>
            <label for="calculateDate">勘定日</label>
            <input
              type="date"
              id="calculateDate"
              :name="'calculateDate'"
              class="form-control input-sm"
              :class="{'is-invalid': errors.has('calculateDate')}"
              v-model="calculateDate">
            <p
              v-if="errors.has('calculateDate')"
              class="invalid-feedback">
              {{ errors.first('calculateDate') }}
            </p>
            <button
              class="btn btn-primary btn-block mt-2"
              @click="onClickMakeArbitraryIncoming"
              :disabled="!isActive(process) || disableBtn">
              入金レコードを発生させる
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <div class="custom-control custom-checkbox mt-2">
          <input
            type="checkbox"
            class="custom-control-input"
            id="checkBadDebt"
            value="1"
            v-model="checkedAsBadDebt"
            :disabled="!isActive(process)">
          <label
            class="custom-control-label"
            for="checkBadDebt">
            不良債権として終了
          </label>
          <div v-if="checkedAsBadDebt">
            <button
              class="btn btn-warning btn-block mt-2"
              @click="onClieckSaveAsBadDebt"
              :disabled="!isActive(process) || disableBtn">
              不良債権として取引を終了する
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

/* eslint-disable indent */
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import ToggleHeader from '../../ApplicationView/components/parts/ToggleHeader.vue';

  export default {
    name: 'Collection',
    props: [
      'no'
    ],
    components: {
      ToggleHeader
    },
    computed: {
      ...mapGetters([
        'isActive',
        'incomingDate',
        'lastCollection'
      ])
    },
    methods: {
      onClieckSaveNote() {
        this.disableBtn = true;
        this.$store.dispatch('updateProcess', {
          data: {
            process: this.process,
            method: 'saveNote',
            note_on_collection: this.noteOnCollection,
            updated_at: this.$store.state.deal.UpdatedAt
          }
        });
      },
      onClieckSaveAsBadDebt() {
        if (confirm('この取引を不良債権として終了してよろしいでしょうか？\n※この操作は元に戻すことができません')) {
          this.disableBtn = true;
          this.$store.dispatch('updateProcess', {
            data: {
              process: this.process,
              method: 'saveAsBadDebt',
              updated_at: this.$store.state.deal.UpdatedAt
            }
          });
        }
      },
      onClickMakeArbitraryIncoming() {
        this.$validator.validate().then((result) => {
          if (!result) {
            return;
          }
          // validate 勘定日
          this.$validator.errors.remove('calculateDate');
          const calculateDate = moment(this.calculateDate);
          if (!calculateDate.isValid()) {
            this.$validator.errors.add({
              field: 'calculateDate',
              msg: '有効な日付を入力してください'
            });
            return;
          }
          if (confirm('入金レコードを登録してもよろしいでしょうか？\n※この操作は元に戻すことができません')) {
            this.disableBtn = true;
            this.$store.dispatch('makeArbitraryIncoming', {
              data: {
                deal_id: this.$store.state.deal.ID,
                updated_at: this.$store.state.deal.UpdatedAt,
                amount: parseInt(this.collectingAmount, 10),
                calculateDate: moment(this.calculateDate).format('YYYY-MM-DD')
              }
            });
          }
        });
      },
      async updateCollectionReminderStatus(isReminderDisabled) {
        // 当面「再対象化」をできなくした（コメントアウトした）ので
        // 戻せない操作のためconfirmを入れる
        if (!confirm('督促架電の予定を変更してよろしいですか？')) {
          return;
        }
        this.disabledCollReminderBtn = true;
        try {
          const res = await this.$store.dispatch('updateCollectionReminderForCollection', {
            collectionId: this.lastCollection.ID,
            reminderDisabled: isReminderDisabled
          });

          if (res.data.status !== 200) {
            this.disabledCollReminderBtn = false;
            alert(res.data.message);
            return;
          }

          location.reload();
        } catch (err) {
          this.disabledCollReminderBtn = false;
          alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
        }
      }
    },
    data() {
      const {
        lastCollection,
        checkedAsBadDebt,
        noteOnCollection
      } = this.$store.getters;
      let disabledCollReminderBtn = false;

      if (lastCollection) {
        disabledCollReminderBtn = lastCollection.ReminderLimitExceeded || lastCollection.ReminderFinished
          || !this.$store.getters.isActive(this.$options.name);
      }

      // 「フリーナンス口座外での回収を記録」関連のローカル・ステート
      const checkedAsCollectedAlready = false;
      const curCols = this.$store.state.deal.Collections
        .filter(cl => cl.MasterCollectionStatusID === 1);
      const collectingLimit = curCols.length === 1 ? curCols[0].Amount : 0;
      const collectingAmount = collectingLimit;

      return {
        process: this.$options.name,
        checkedAsBadDebt,
        collectingAmount,
        collectingLimit,
        checkedAsCollectedAlready,
        noteOnCollection,
        disableBtn: false,
        disabledCollReminderBtn,
        userId: this.$store.state.deal.UserID,
        incomingAmount: this.$store.state.deal.IncomingAmount,
        calculateDate: moment().format('YYYY-MM-DD')
      };
    }
  };
</script>
