var JudgeableEvidenceConfig = {
    MASTER_USER_EVIDENCE_STATUS: {
        CHECKING: 1,
        APPROVED: 2,
        REJECTED: 3,
        UNNECESSARY: 6,
        REQUIRED: 7
    }
};
export default JudgeableEvidenceConfig;
