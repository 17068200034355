<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">ユーザー取引スコアリング</div>
          <div class="card-body">
            <div class="form-group row mt-2">
              <label class="col-7 col-form-label text-right">フリーナンス口座不使用:</label>
              <div class="col-5">
                <input
                  type="text"
                  v-model.number="frnc_account_non_use_score"
                  :disabled="disabled"
                  class="form-control">
              </div>
            </div>
          </div>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary save_btn"
              v-on:click="saveUserDealScoring"
              :disabled="disabled">保存
            </button>
          </div>
        </div>
        <div class="card m-0">
          <div class="card-header">ユーザー与信材料スコアリング</div>
          <div class="card-body">
            <UserScoreInput  
              v-for="user_score in user_scores"
              :user_score="user_score" 
              :key="user_score.master_user_evidence_type_id"
            ></UserScoreInput>
          </div>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary save_btn"
              v-on:click="saveUserScores"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card m-0">
          <div class="card-header">ユーザー格付け閾値設定</div>
          <ul class="list-group list-group-flush m-0">
            <UserRankInput  
              v-for="user_rank in user_ranks"
              :user_rank="user_rank" 
              :key="user_rank.key" 
            ></UserRankInput>
          </ul>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary save_btn"
              v-on:click="saveUserRanks"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import UserScoreInput from './UserScoreInput.vue';
  import UserRankInput  from './UserRankInput.vue';

  export default {
    name: 'TabPaneUserScoring',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      UserScoreInput,
      UserRankInput,
    },
    methods: {
      
      'saveUserScores' : function () {
        if (!this.$store.getters.isFormAllValid('user_scores')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveUserScores/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.user_scores,
          'redirect' : location.pathname + '#tab2',
        });
      },
      
      'saveUserRanks' : function () {
        if (!this.$store.getters.isFormAllValid('user_ranks')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveUserRanks/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.user_ranks,
          'redirect' : location.pathname + '#tab2',
        });
      },

      'saveUserDealScoring' : function() {
        if (isNaN(parseInt(this.frnc_account_non_use_score))) {
          alert("入力内容をご確認下さい");
          return;
        }

        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveUserDealScoring/' + this.$store.state.ranking_version_id,
          'target' : parseInt(this.frnc_account_non_use_score),
          'redirect' : location.pathname + '#tab2',
        });
      },
    },
    data() {
      return {
        'user_scores': this.$store.state.user_scores,
        'user_ranks': this.$store.state.user_ranks,
        'frnc_account_non_use_score': this.$store.state.ranking_version.frnc_account_non_use_score,
      }
    }
  };
</script>

<style lang="scss" scoped>
.save_btn {
  width: 200px;
}
</style>
