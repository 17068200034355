var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import mainStore from '../../store/main';
var statusMap = {
    unsent: "送付待ち",
    sent: "送付済み",
    unsettled: "入金待ち",
    settled: "入金済み",
    canceled: "該当する",
    uncanceled: "該当しない",
    registered: "登録済み",
    unregistered: "登録待ち",
    in: "内税",
    out: "外税",
    transfer: "振込",
    direct_debit: "振替",
};
var prefectureNames = {
    "-1": "設定しない",
    0: "北海道",
    1: "青森",
    2: "岩手",
    3: "宮城",
    4: "秋田",
    5: "山形",
    6: "福島",
    7: "茨城",
    8: "栃木",
    9: "群馬",
    10: "埼玉",
    11: "千葉",
    12: "東京",
    13: "神奈川",
    14: "新潟",
    15: "富山",
    16: "石川",
    17: "福井",
    18: "山梨",
    19: "長野",
    20: "岐阜",
    21: "静岡",
    22: "愛知",
    23: "三重",
    24: "滋賀",
    25: "京都",
    26: "大阪",
    27: "兵庫",
    28: "奈良",
    29: "和歌山",
    30: "鳥取",
    31: "島根",
    32: "岡山",
    33: "広島",
    34: "山口",
    35: "徳島",
    36: "香川",
    37: "愛媛",
    38: "高知",
    39: "福岡",
    40: "佐賀",
    41: "長崎",
    42: "熊本",
    43: "大分",
    44: "宮崎",
    45: "鹿児島",
    46: "沖縄",
};
var lineTypes = {
    item: "品目行",
    text: "テキスト行",
};
var FreeeComponent = /** @class */ (function (_super) {
    __extends(FreeeComponent, _super);
    function FreeeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FreeeComponent.prototype, "isOnAPIRequest", {
        get: function () {
            return mainStore.isOnAPIRequest;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FreeeComponent.prototype, "invoiceDetails", {
        get: function () {
            return mainStore.freeeInvoiceDetails;
        },
        enumerable: false,
        configurable: true
    });
    FreeeComponent.prototype.getStatus = function (status) {
        var found = Object.keys(statusMap).find(function (key) { return status === key; });
        if (found)
            return statusMap[found];
        return "-";
    };
    FreeeComponent.prototype.getPrefectureName = function (prefectureId) {
        var found = Object.keys(prefectureNames).find(function (key) { return prefectureId == key; });
        if (found)
            return prefectureNames[found];
        return "-";
    };
    FreeeComponent.prototype.getLineTypes = function (type) {
        var found = Object.keys(lineTypes).find(function (key) { return type === key; });
        if (found)
            return lineTypes[found];
        return "-";
    };
    __decorate([
        Prop({ type: Object })
    ], FreeeComponent.prototype, "invoice", void 0);
    FreeeComponent = __decorate([
        Component
    ], FreeeComponent);
    return FreeeComponent;
}(Vue));
export default FreeeComponent;
