import { generateKey } from './Util'

export default {
  // Set CorporationScores and CorporationRanks from global to store
  initCorporationScoreAndRank(state, payload) {
    // Construct corporation_scores initial data
    let corporation_scores = [];
    payload.master_corporation_evidence_types.forEach((type, i) => {
      let corporation_score = {
        id: null,
        title: type.Title,
        master_corporation_evidence_type_id: type.ID,
        score: 0,
        is_valid: {},
      };
      payload.ranking_version.CorporationScores.forEach((us, j) => {
        if (us.MasterCorporationEvidenceTypeID == type.ID) {
          corporation_score.id = us.ID;
          corporation_score.score = us.Score;
        }
      });
      corporation_scores.push(corporation_score);
    });
    state.corporation_scores = corporation_scores;
    
    // Construct corporation_ranks initial data
    let corporation_ranks = [];
    let sorted = payload.ranking_version.CorporationRanks.sort((a,b) => a.MinScore < b.MinScore);
    sorted.forEach((rank, i) => {
      corporation_ranks.push({
        key:          generateKey(),
        id:           rank.ID,
        name:         rank.Name,
        description:  rank.Description,
        min_score:    rank.MinScore,
        max_score:    rank.MaxScore,
        is_valid: {},
      });
    });
    state.corporation_ranks = corporation_ranks;
  },
 
  // Update individual corporation score
  updateCorporationScore(state, payload) {
    state.corporation_scores.forEach((us, i) => {
      if (us.master_corporation_evidence_type_id == payload.corporation_score.master_corporation_evidence_type_id) {
        state.corporation_scores[i].score = payload.value;
      }
    });
  },
  
  // Update validation status of individual corporation score
  updateCorporationScoreIsValid(state, payload) {
    state.corporation_scores.forEach((us, i) => {
      if (us.master_corporation_evidence_type_id == payload.corporation_score.master_corporation_evidence_type_id) {
        state.corporation_scores[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },
   
  // Update individual corporation rank
  updateCorporationRank(state, payload) {
    state.corporation_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        let val = payload.value;
        if (
          payload.attr === 'min_score' 
          || payload.attr === 'max_score'
        ) {
          val = parseInt(val, 10);
          if (isNaN(val)) {
            val = '';
          }
        }
        state.corporation_ranks[i][payload.attr] = val;
      }
    });
  },
  
  // Update validation status of individual corporation rank
  updateCorporationRankIsValid(state, payload) {
    state.corporation_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        state.corporation_ranks[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },
};
