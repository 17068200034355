var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default {
    namespaced: true,
    state: {
        deals: [],
    },
    mutations: {
        setState: function (state, newState) {
            state.deals = newState;
        },
    },
    actions: {
        loadData: function (_a, payload) {
            var commit = _a.commit, rootGetters = _a.rootGetters;
            commit('setState', payload.collecting_deals);
        },
    },
    getters: {
        filteredDeals: function (state) {
            return state.deals;
        },
        dealsByUsers: function (state, getters) {
            var deals = getters.filteredDeals;
            var userDict = {};
            // group by user
            deals.forEach(function (deal) {
                var ud = userDict[deal.UserID];
                if (!ud) {
                    userDict[deal.UserID] = {
                        user: deal.User,
                        deals: [],
                    };
                }
                userDict[deal.UserID].deals.push(__assign(__assign({}, deal), { _rowspan: 0 }));
                userDict[deal.UserID].deals[0]._rowspan += 1;
            });
            return userDict;
        },
    },
};
