var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
var StickyScroller = /** @class */ (function (_super) {
    __extends(StickyScroller, _super);
    function StickyScroller() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sticky = {};
        _this.stickyHolder = {};
        _this.originalHeight = 0;
        return _this;
    }
    StickyScroller.prototype.mounted = function () {
        window.addEventListener('scroll', this.handleScroll);
        var isElement = function (x) { return x instanceof Element; };
        var stickyWrapper = this.$refs.stickyWrapper;
        if (!isElement(stickyWrapper))
            return;
        this.originalHeight = stickyWrapper.clientHeight + 300;
    };
    StickyScroller.prototype.handleScroll = function () {
        if (window.scrollY > 90) {
            var height = 90;
            // cals scrollBar's height to show 取り下げ btn
            var dealWithdrawalBtn = document.getElementById('dealWithdrawal-btn');
            if (dealWithdrawalBtn) {
                var diff = window.scrollY + window.innerHeight - (document.body.clientHeight - 80);
                if (diff >= 0) {
                    var minH = 0.05 * (window.innerHeight) + 35;
                    height -= diff / 4;
                    height = height < minH ? minH : height;
                }
            }
            this.stickyHolder = { height: this.originalHeight + "px" };
            this.sticky = {
                position: 'fixed',
                top: '70px',
                width: '26%',
                height: height + "vh",
                overflow: 'scroll',
            };
        }
        else {
            this.stickyHolder = { height: '0px' };
            this.sticky = {
                position: 'relative',
                top: '0px',
                width: '100%',
                height: 'auto',
                overflow: 'visible',
            };
        }
    };
    StickyScroller = __decorate([
        Component
    ], StickyScroller);
    return StickyScroller;
}(Vue));
export default StickyScroller;
