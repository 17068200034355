/* global $ */
import trumbowyg from 'trumbowyg/dist/trumbowyg.min.js';
import trumbowygJa from 'trumbowyg/dist/langs/ja.min.js';
import trumbowygColor from 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css';
import iconSvg from 'trumbowyg/dist/ui/icons.svg';

export default function processingInvitingPhrases() {
  const iconSvgPath = `/public/${iconSvg}`;

  const invitingPhraseEditorConfig = {
    lang: 'ja',
    svgPath: iconSvgPath,
    semantic: false,
    tagsToRemove: ['script', 'style'],
    tagsToKeep: ['label'],
    btns: [
      ['viewHTML'],
      ['formatting'],
      ['strong', 'em', 'underline', 'del'],
      ['foreColor', 'backColor'],
      ['link'],
      ['insertImage'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['unorderedList', 'orderedList'],
      ['horizontalRule'],
      ['removeformat'],
      ['guide'],
      ['fullscreen']]
  };

  function init() {
    const invitingPhraseElm = document.getElementsByName('inviting_phrase')[0];
    if (invitingPhraseElm) {
      $(invitingPhraseElm).trumbowyg(invitingPhraseEditorConfig);
    }
  }

  init();
}
