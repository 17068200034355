<template>
  <div class="form-group row m-0">
    <label class="col-sm-5 col-form-label text-right">{{label}} :</label>
    <div class="col-sm-7 col-form-label">
      <template v-if="name">
        <template v-if="type == 'textarea'">
          <textarea 
            class="form-control w-100" 
            style="height:180px;" 
            :name="name"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"   
            :disabled="disabled"
            ></textarea>
        </template>
      </template>
      <template v-else>
        {{value}}
      </template>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'ListRow',
    props: [
      'label',
      'value',
      'name',
      'type',
    ],
    computed: {
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
      }
    },
  };
</script>
