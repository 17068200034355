<template>
  
  <li class="list-group-item">
    <div class="form-row">
      <div class="form-group col-2">
        <label >格付け名称 :</label>
        <input 
          :name="'name_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="name"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('name_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('name_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('name_'+user_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label>説明 :</label>
        <input 
          :name="'description_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="description"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('description_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('description_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('description_'+user_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >スコア下限 :</label>
        <input 
          :name="'min_score_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="min_score"
          v-validate="'required|min_value:-9999|max_value:9999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('min_score_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('min_score_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('min_score_'+user_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >スコア上限 :</label>
        <input 
          :name="'max_score_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="max_score"
          v-validate="'required|min_value:-9999|max_value:9999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('max_score_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('max_score_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('max_score_'+user_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >標準手数料 :</label>
        <input 
          :name="'ave_rate_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="ave_rate"
          v-validate="'required|min_value:0|max_value:1.0'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('ave_rate_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('ave_rate_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('ave_rate_'+user_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label>現金化上限(総額) :</label>
        <input 
          :name="'deal_limit_'+user_rank.key"
          type="text" 
          class="form-control" 
          v-model="deal_limit"
          v-validate="'required|min_value:0|max_value:99999999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('deal_limit_'+user_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('deal_limit_'+user_rank.key)" class="invalid-feedback">
          {{ errors.first('deal_limit_'+user_rank.key) }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'UserRankInput',
    props: [
      'user_rank',
    ],
    watch: {
      name: function(a, b) { watchUserRank(this, 'name') },
      min_score: function(a, b) { watchUserRank(this, 'min_score') },
      max_score: function(a, b) { watchUserRank(this, 'max_score') },
      ave_rate: function(a, b) { watchUserRank(this, 'ave_rate') },
      deal_limit: function(a, b) { watchUserRank(this, 'deal_limit') },
      description: function(a, b) { watchUserRank(this, 'description') },
    },
    computed: {
      name: {
        get() { return this.user_rank.name },
        set(value) { setUserRank(this, 'name', value) },
      },
      min_score: {
        get() { return this.user_rank.min_score },
        set(value) { setUserRank(this, 'min_score', value) },
      },
      max_score: {
        get() { return this.user_rank.max_score },
        set(value) { setUserRank(this, 'max_score', value) },
      },
      ave_rate: {
        get() { return this.user_rank.ave_rate },
        set(value) { setUserRank(this, 'ave_rate', value) },
      },
      deal_limit: {
        get() { return this.user_rank.deal_limit },
        set(value) { setUserRank(this, 'deal_limit', value) },
      },
      description: {
        get() { return this.user_rank.description },
        set(value) { setUserRank(this, 'description', value) },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        validate_item: 'この項目',
        validate_title: '「' + this.user_rank.name + '」 ',
      }
    },
  };
  function setUserRank (that, attr, value) {
    that.$store.commit('updateUserRank', {
      'key' : that.user_rank.key,
      'attr' : attr,
      'value' : value,
    });
  }
  function watchUserRank (that, attr) {
    that.$validator.validate().then(result => {
      that.$store.commit('updateUserRankIsValid', {
        'key' : that.user_rank.key,
        'attr' : attr,
        'is_valid': result
      });
    });
  }
</script>
