<template>
  <div class="d-tags">
    <template v-for="(tag, key) in searchTags">
      <a
        :key="key"
        target="_blank"
        class="d-tag-item"
        :href="tag.link">
        <span
          class="cui-magnifying-glass"
          aria-hidden="true" />
        {{ tag.label }}
        <br>
      </a>
    </template>
  </div>
</template>
<script>
import Constants from '../../../utils/Constants';

export default {
  name: 'RefTagList',
  data() {
    return {
      searchTags: this.getSearchTagList()
    };
  },
  methods: {
    generateGoogleSearchTagList(tagConfig) {
      const linkPrefix = 'https://www.google.com/search?q=';
      const fixedKeywords = Constants.DEAL_CREDIT_JUDGE_SEARCH_KEYWORD.join(' OR ');
      return [
        {
          label: tagConfig.creditLabelName,
          link: `${linkPrefix}"${tagConfig.searchTargetName}" (${fixedKeywords})`
        },
        {
          label: tagConfig.appendCorporationLabelName,
          link: `${linkPrefix}${tagConfig.searchCorporationName} "${tagConfig.searchTargetName}"`
        },
        {
          label: tagConfig.targetLabelName,
          link: `${linkPrefix}"${tagConfig.searchTargetName}"`
        }
      ];
    },
    getSearchTagList() {
      const { user, deal } = this.$store.state;
      const corpName = deal.Corporation && deal.Corporation.Name ? deal.Corporation.Name : '';
      if (user.Incorporated) {
        // Incorporated = true場合、user.FullNameは法人代表名
        const corpRepresentTagList = this.generateGoogleSearchTagList({
          searchTargetName: user.FullName.replace(/\s/g, ''),
          searchCorporationName: corpName,
          creditLabelName: '信用検索',
          appendCorporationLabelName: '+企業名',
          targetLabelName: '法人代表者名'
        });
        const corpTagList = this.generateGoogleSearchTagList({
          searchTargetName: user.CompanyName.replace(/\s/g, ''),
          searchCorporationName: corpName,
          creditLabelName: '信用検索(法人名)',
          appendCorporationLabelName: '+企業名(法人名)',
          targetLabelName: '法人名'
        });
        return [...corpRepresentTagList, ...corpTagList];
      }
      // Incorporated = false場合、FullNameはユーザー名
      return this.generateGoogleSearchTagList({
        searchTargetName: user.FullName.replace(/\s/g, ''),
        searchCorporationName: corpName,
        creditLabelName: '信用検索',
        appendCorporationLabelName: '+企業名',
        targetLabelName: 'ユーザー名'
      });
    }
  }
};
</script>
<style lang="scss">
.d-tags {
  position: relative;
  .d-tag-item {
    display: inline-block;
    border: 2px solid #89ddf4;
    margin: 5px 5px 0px 0px;
    padding: 2px 5px;
    border-radius: 8px;
    color: #20a8d8;
  }
}
</style>
