<template>
  
  <li class="list-group-item">
    <div class="form-row">
      <div class="form-group col-2">
        <label >格付け名称 :</label>
        <input 
          :name="'name_'+corporation_rank.key"
          type="text" 
          class="form-control" 
          v-model="name"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('name_'+corporation_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('name_'+corporation_rank.key)" class="invalid-feedback">
          {{ errors.first('name_'+corporation_rank.key) }}
        </p>
      </div>
      <div class="form-group col-6">
        <label>説明 :</label>
        <input 
          :name="'description_'+corporation_rank.key"
          type="text" 
          class="form-control" 
          v-model="description"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('description_'+corporation_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('description_'+corporation_rank.key)" class="invalid-feedback">
          {{ errors.first('description_'+corporation_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >スコア下限 :</label>
        <input 
          :name="'min_score_'+corporation_rank.key"
          type="text" 
          class="form-control" 
          v-model="min_score"
          v-validate="'required|min_value:-9999|max_value:9999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('min_score_'+corporation_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('min_score_'+corporation_rank.key)" class="invalid-feedback">
          {{ errors.first('min_score_'+corporation_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >スコア上限 :</label>
        <input 
          :name="'max_score_'+corporation_rank.key"
          type="text" 
          class="form-control" 
          v-model="max_score"
          v-validate="'required|min_value:-9999|max_value:9999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('max_score_'+corporation_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('max_score_'+corporation_rank.key)" class="invalid-feedback">
          {{ errors.first('max_score_'+corporation_rank.key) }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'CorporationRankInput',
    props: [
      'corporation_rank',
    ],
    watch: {
      name: function(a, b) { watchCorporationRank(this, 'name') },
      min_score: function(a, b) { watchCorporationRank(this, 'min_score') },
      max_score: function(a, b) { watchCorporationRank(this, 'max_score') },
      description: function(a, b) { watchCorporationRank(this, 'description') },
    },
    computed: {
      name: {
        get() { return this.corporation_rank.name },
        set(value) { setCorporationRank(this, 'name', value) },
      },
      min_score: {
        get() { return this.corporation_rank.min_score },
        set(value) { setCorporationRank(this, 'min_score', value) },
      },
      max_score: {
        get() { return this.corporation_rank.max_score },
        set(value) { setCorporationRank(this, 'max_score', value) },
      },
      description: {
        get() { return this.corporation_rank.description },
        set(value) { setCorporationRank(this, 'description', value) },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        validate_item: 'この項目',
        validate_title: '「' + this.corporation_rank.name + '」 ',
      }
    },
  };
  function setCorporationRank (that, attr, value) {
    that.$store.commit('updateCorporationRank', {
      'key' : that.corporation_rank.key,
      'attr' : attr,
      'value' : value,
    });
  }
  function watchCorporationRank (that, attr) {
    that.$validator.validate().then(result => {
      that.$store.commit('updateCorporationRankIsValid', {
        'key' : that.corporation_rank.key,
        'attr' : attr,
        'is_valid': result
      });
    });
  }
</script>
