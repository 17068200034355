<template>
  <div class="card">
    <h6 class="mb-0 card-header bg-light">
      <span class="cui-calendar font-weight-bold">
        即日支払いサービス利用に伴う回収予定
      </span>
    </h6>
    <table class="table tblInner mb-0">
      <tr class="bg-light">
        <th scope="col">回収予定ID</th>
        <th scope="col">取引ID</th>
        <th scope="col">発生日</th>
        <th scope="col">回収予定日</th>
        <th scope="col">内容</th>
        <th scope="col">三社間コード</th>
        <th scope="col">即日払い種類</th>
        <th scope="col">金額</th>
      </tr>
      <template v-if="isEmptyCollections">
        <tr>
          <td class="text-center" colspan="7">データがありません</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="coll in tran.Collections">
          <td>{{coll.ID}}</td>
          <td><a :href="'/deals/view/' + coll.DealID" target="_blank">{{coll.DealID}}</a></td>
          <td>{{fmt.date(coll.CreatedAt)}}</td>
          <td>{{fmt.date(coll.Deadline)}}</td>
          <td>{{coll.Title}}</td>
          <td v-if="coll.Deal.NotifiedFactoringID > 0">
            <a
              :href="'/notifiedFactorings/view/' + coll.Deal.NotifiedFactoringID"
              target="_blank">
              {{ coll.Deal.NotifiedFactoring.Code }}
            </a>
          </td>
          <td v-else>-</td>
          <td>
            フリーナンス口座：
            <span v-if="coll.InvoiceVersion && coll.InvoiceVersion.IsUsedFrncAccount" class="badge badge-warning">使用</span>
            <span v-else class="badge badge-secondary">不使用</span>
          </td>
          <td>{{fmt.currency(coll.Amount)}}</td>
        </tr>
      </template>
    </table>
    <div class="card-footer text-right bg-light">
      <span class="font-weight-bold">回収予定合計：{{fmt.currency(calcCollectionAmount(tran))}}</span>
    </div>
  </div>
</template>

<style scoped>
.tblInner th {
  font-size:.8rem;
  padding-top:5px;
  padding-bottom:5px;
  border-top:none;
}
.tblInner td {
  vertical-align: middle;
}
</style>

<script>
  import { mapGetters } from 'vuex'
  import fmt from '../../util.js'
  export default {
    name: 'TblCollections',
    props: [
      'tran',
    ],
    computed: {
      ...mapGetters([
        'calcCollectionAmount',
      ]),
      isEmptyCollections () {
        return this.tran.Collections.length === 0;
      },
    },
    components: {
    },
    methods: {
    },
    data() {
      return {
        fmt: fmt,
      };
    },
  };
</script>
