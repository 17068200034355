<template>
  <div class="card mb-1">
    <ToggleHeader
      :name="no + '. 確認コード受領待ち'"
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>確認コードがユーザーによって入力されると「6.会員登録プロセス完了」へ</li>
          <li>確認コードの有効期限が切れた場合（有効期限内でも再郵送を希望される場合）、電話連絡などで再郵送について同意を得た場合「確認コード再郵送」を実行</li>
        </ul>
      </li>
      <li class="list-group-item p-3">
        <div class="row">
          <div class="col text-right">
            確認コード送信依頼日：
          </div>
          <div class="col">
            {{confirmationCodeSentDate || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            確認コード有効期限：
          </div>
          <div class="col">
            {{confirmationCodeExpiredDate || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            確認コード入力日時：
          </div>
          <div class="col">
            {{confirmationCodeInputDate || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            確認コード再送信日時：
          </div>
          <div class="col">
            {{confirmationCodeReSentDate || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            再送信確認コード有効期限：
          </div>
          <div class="col">
            {{confirmationCodeReExpiredDate || '-'}}
            <button
              v-if="confirmationCodeReExpiredDate"
              v-on:click="onClickClear"
              class="btn btn-sm btn-outline-info"
              :disabled="!active || disable_btn"
              >クリア</button>
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            再送信確認コード入力日時：
          </div>
          <div class="col">
            {{confirmationCodeReInputDate || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="f15 col text-center font-weight-bold mt-3 mb-3">
            確認コード {{confirmationCode || '-'}}
            <button
              class="btn btn-secondary btn-sm"
              v-on:click="onClickTrackNum"
              :disabled="!active || disable_btn"
              >郵送状況を追跡</button>
          </div>
          <div class="col-12" v-if="postal_status !== ''">
            <div class="alert alert-info">
              {{postal_status}}
            </div>
          </div>
        </div>
        <div class="custom-control custom-checkbox mt-2">
          <input
            type="checkbox"
            class="custom-control-input"
            id="confirm"
            v-model="checkedSendingPostal"
            :disabled="!active || !canResendConfirmationCode"
          />
          <template v-if="!resendRequested">
            <label class="custom-control-label" for="confirm">
              確認コードの再郵送について電話確認済み
            </label>
          </template>
        </div>
        <button
          class="btn btn-secondary btn-block"
          v-on:click="onClickResendPostal"
          :disabled="!active || !checkedSendingPostal || resendRequested || disable_btn"
          >{{resendRequested ? '確認コード再郵送リクエスト済み' : '確認コード再郵送'}}</button>
        <button
          class="btn btn-outline-danger btn-block"
          v-on:click="onClickExpiredInUnconfirmed"
          :disabled="!active || disable_btn"
          >確認コード未登録のまま終了</button>
      </li>
    </ul>
  </div>
</template>

<script>

  import ToggleHeader   from './parts/ToggleHeader.vue';

  export default {
    name: 'Postal',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
    },
    methods: {
      onClickResendPostal (e) {
        this.$validator.validate().then(result => {
          if (result) {
            if (confirm('確認コードを再送信してよろしいでしょうか？')) {
              this.disable_btn = true;
              this.$store.dispatch('updateProcess', {
                'data' : {
                  'process': this.process,
                  'method': 'resend',
                  'updated_at': this.$store.state.application.UpdatedAt,
                },
              });
            }
          }
        });
      },
      onClickExpiredInUnconfirmed (e) {
        if (confirm("この開設申込を「確認コード未登録のまま終了」としてよろしいでしょうか？")) {
          this.disable_btn = true;
          this.$store.dispatch('updateProcess', {
            'data' : {
              'process': this.process,
              'method': 'terminate',
              'updated_at': this.$store.state.application.UpdatedAt,
            },
          });
        }
      },
      onClickClear (e) {
        if (confirm(
          "2度目の郵送を取り消して再度(3度目~)再郵送リクエストができるようになります。"
          + "\n・この操作により2度目の確認コードは無効となります"
          + "\n・この操作は3度目以降の郵送のために行います"
          + "\n・2度目で受領されない場合、通常は「確認コード未登録のまま終了」とします"
        )) {
          this.disable_btn = true;
          this.$store.dispatch('updateProcess', {
            'data' : {
              'process': this.process,
              'method': 'clear_second',
              'updated_at': this.$store.state.application.UpdatedAt,
            },
          });
        }
      },
      onClickTrackNum (e) {
        this.disable_btn = true;
        this.$store.dispatch('trackNexwayPostalStatus', (result)=>{
          this.postal_status = result;
          this.disable_btn = false;
        });
      },
    },
    data() {
      let p = this.$options.name;
      return {
        'process'                   : p,
        'checkedSendingPostal'      : false,
        'canResendConfirmationCode' : this.$store.getters.canResendConfirmationCode,
        'resendRequested'           : this.$store.getters.resendRequested,
        'active'                    : this.$store.getters.isActive(p),
        'disable_btn'               : false,
        ...this.$store.getters.confirmationCodeDateInfo(),
        'postal_status'             : '',
      };
    },
  };
</script>
