import { generateKey } from './Util'

export default {
  // Set UserScores and UserRanks from global to store
  initUserScoreAndRank(state, payload) {
    // Construct user_scores initial data
    let user_scores = [];
    payload.master_user_evidence_types.forEach((type, i) => {
      let user_score = {
        id: null,
        title: type.Title,
        master_user_evidence_type_id: type.ID,
        score: 0,
        is_valid: {},
      };
      payload.ranking_version.UserScores.forEach((us, j) => {
        if (us.MasterUserEvidenceTypeID == type.ID) {
          user_score.id = us.ID;
          user_score.score = us.Score;
        }
      });
      user_scores.push(user_score);
    });
    state.user_scores = user_scores;
    
    // Construct user_ranks initial data
    let user_ranks = [];
    let sorted = payload.ranking_version.UserRanks.sort((a,b) => a.MinScore < b.MinScore);
    sorted.forEach((rank, i) => {
      user_ranks.push({
        key:          generateKey(),
        id:           rank.ID,
        name:         rank.Name,
        description:  rank.Description,
        min_score:    rank.MinScore,
        max_score:    rank.MaxScore,
        ave_rate:     rank.AveRate,
        deal_limit:   rank.DealLimit,
        is_valid: {},
      });
    });
    state.user_ranks = user_ranks;
  },
   
  // Update individual user score
  updateUserScore(state, payload) {
    state.user_scores.forEach((us, i) => {
      if (us.master_user_evidence_type_id == payload.user_score.master_user_evidence_type_id) {
        state.user_scores[i].score = payload.value;
      }
    });
  },
  
  // Update validation status of individual user score
  updateUserScoreIsValid(state, payload) {
    state.user_scores.forEach((us, i) => {
      if (us.master_user_evidence_type_id == payload.user_score.master_user_evidence_type_id) {
        state.user_scores[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },
   
  // Update individual user rank
  updateUserRank(state, payload) {
    state.user_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        let val = payload.value;
        if (
          payload.attr === 'min_score' 
          || payload.attr === 'max_score'
          || payload.attr === 'deal_limit'
        ) {
          val = parseInt(val, 10);
          if (isNaN(val)) {
            val = '';
          }
        } else if (payload.attr === 'ave_rate') {
          val = parseFloat(val);
          if (isNaN(val)) {
            val = '';
          }
        }
        state.user_ranks[i][payload.attr] = val;
      }
    });
  },
    
  // Update validation status of individual user rank
  updateUserRankIsValid(state, payload) {
    state.user_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        state.user_ranks[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },
};
