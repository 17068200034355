import Vue from 'vue';
import App from './components/App.vue';
import mainStoreModule from './store/main';
export default function page() {
    var app = new Vue({
        el: '#app',
        components: {
            App: App
        },
        created: function () {
            mainStoreModule.loadData({
                contact: document.CONTACT,
                adminUsers: document.ADMIN_USERS,
                loginUserId: document.LOGIN_USER_ID
            });
        }
    });
}
