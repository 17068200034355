import Vue from 'vue';
import App from './components/App.vue';
import store from './store';
export default (function () {
    new Vue({
        el: '#collectingDealsTable',
        store: store,
        components: {
            App: App,
        },
    });
});
