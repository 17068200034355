export var SortType;
(function (SortType) {
    SortType[SortType["byNumber"] = 0] = "byNumber";
    SortType[SortType["byDate"] = 1] = "byDate";
    SortType[SortType["byString"] = 2] = "byString";
})(SortType || (SortType = {}));
export var Order = {
    asc: 1,
    dsc: -1
};
export var sortDate = function (order, fieldName) { return function (a, b) {
    var val1 = a[fieldName];
    var val2 = b[fieldName];
    if (fieldName.includes('.')) {
        var fieldNameArr = fieldName.split('.');
        val1 = a[fieldNameArr[0]][fieldNameArr[1]];
        val2 = b[fieldNameArr[0]][fieldNameArr[1]];
    }
    return order * (new Date(val1).getTime() - new Date(val2).getTime());
}; };
export var sortString = function (order, fieldName) { return function (a, b) {
    var val1 = a[fieldName];
    var val2 = b[fieldName];
    if (fieldName.includes('.')) {
        var fieldNameArr = fieldName.split('.');
        val1 = a[fieldNameArr[0]][fieldNameArr[1]];
        val2 = b[fieldNameArr[0]][fieldNameArr[1]];
    }
    if (val1 === val2)
        return 0;
    return ((val1 > val2) ? order : -order);
}; };
export var sortNumber = function (order, fieldName) { return function (a, b) {
    var val1 = a[fieldName];
    var val2 = b[fieldName];
    if (fieldName.includes('.')) {
        var fieldNameArr = fieldName.split('.');
        val1 = a[fieldNameArr[0]][fieldNameArr[1]];
        val2 = b[fieldNameArr[0]][fieldNameArr[1]];
    }
    return order * (val1 - val2);
}; };
export var sortActual = function (params) {
    var result = params.data;
    switch (params.type) {
        case SortType.byNumber:
            result.sort(sortNumber(params.order, params.fieldName));
            break;
        case SortType.byDate:
            result.sort(sortDate(params.order, params.fieldName));
            break;
        case SortType.byString:
            result.sort(sortString(params.order, params.fieldName));
            break;
        default:
            break;
    }
    return result;
};
