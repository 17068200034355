<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-4">
        <div class="card m-0">
          <div class="card-header">企業与信材料スコアリング</div>
          <div class="card-body">
            <CorporationScoreInput  
              v-for="corporation_score in corporation_scores"
              :corporation_score="corporation_score" 
              :key="corporation_score.master_corporation_evidence_type_id"
            ></CorporationScoreInput>
          </div>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveCorporationScores"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card m-0">
          <div class="card-header">企業格付け閾値設定</div>
          <ul class="list-group list-group-flush m-0">
            <CorporationRankInput  
              v-for="corporation_rank in corporation_ranks"
              :corporation_rank="corporation_rank" 
              :key="corporation_rank.key" 
            ></CorporationRankInput>
          </ul>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveCorporationRanks"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import CorporationScoreInput from './CorporationScoreInput.vue';
  import CorporationRankInput  from './CorporationRankInput.vue';

  export default {
    name: 'TabPaneCorporationScoring',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      CorporationScoreInput,
      CorporationRankInput,
    },
    methods: {
      'saveCorporationScores' : function () {
        if (!this.$store.getters.isFormAllValid('corporation_scores')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveCorporationScores/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.corporation_scores,
          'redirect' : location.pathname + '#tab3',
        });
      },
      'saveCorporationRanks' : function () {
        if (!this.$store.getters.isFormAllValid('corporation_ranks')) {
          alert("入力内容をご確認下さい");
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveCorporationRanks/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.corporation_ranks,
          'redirect' : location.pathname + '#tab3',
        });
      },
    },
    data() {
      return {
        'corporation_scores': this.$store.state.corporation_scores,
        'corporation_ranks': this.$store.state.corporation_ranks,
      }
    }
  };
</script>
