import XLSX from 'xlsx';
import inputTags from '../../utils/InputTags';
var FILE_SIZE_OF_UP_USER_ID_CAMPAIGN = 10 * 1024 * 1024;
var currUserIDs = [];
var tagsOpj;
var loadCurrUserIDs = function () {
    if (currUserIDs.length === 0)
        return;
    var lockedUserIDs = [];
    currUserIDs.forEach(function (userID) {
        lockedUserIDs.push({
            value: userID,
            readonly: true
        });
    });
    if (lockedUserIDs.length > 0) {
        tagsOpj.addTags(lockedUserIDs);
    }
};
function refreshUploader() {
    var uploadUserId = document.querySelector('#userIDsUploader');
    uploadUserId.value = '';
}
function processFromFile(content) {
    var rows = content.split(/\r\n|\n/);
    if (rows.length === 0) {
        refreshUploader();
        alert('有効なユーザーIDが見つかりません');
        return;
    }
    var userIDs = [];
    var colUserIDIdx = 0;
    rows.every(function (row, numRow) {
        if (row.trim() === '')
            return true;
        var cols = row.split(',');
        if (numRow === 0) {
            var isValid_1 = false;
            var idColName_1 = 'ユーザーID';
            cols.forEach(function (col, numCol) {
                if (col === idColName_1) {
                    colUserIDIdx = numCol;
                    isValid_1 = true;
                }
            });
            if (!isValid_1) {
                alert("\u300C" + idColName_1 + "\u300D\u306E\u30AB\u30E9\u30E0\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093");
                return false;
            }
        }
        var userID = parseInt(cols[colUserIDIdx], 10);
        if (userID && userID > 0 && !userIDs.includes(userID)) {
            userIDs.push(userID);
        }
        return true;
    });
    // add userIDs into input form
    if (userIDs.length > 0) {
        tagsOpj.removeAllTags();
        loadCurrUserIDs();
        tagsOpj.addTags(userIDs);
    }
    else {
        alert('有効なユーザーIDが見つかりません');
    }
    refreshUploader();
}
function getUserIDs() {
    return tagsOpj.value.map(function (item) { return item.value; }).join(',');
}
function processInputTags() {
    var userIdForm = document.querySelector('input[name=user_ids]');
    if (userIdForm) {
        userIdForm.value = getUserIDs();
    }
}
function processUpload($event) {
    var reader = new FileReader();
    var file = $event.target.files[0] || $event.dataTransfer.files[0];
    if (file.size > FILE_SIZE_OF_UP_USER_ID_CAMPAIGN) {
        refreshUploader();
        alert('ファイルのサイズが不正です');
        return;
    }
    var fileType = '';
    var fileName = file.name;
    if (file.name.includes('.')) {
        fileType = fileName.split('.').pop().toLowerCase();
    }
    switch (fileType) {
        case 'csv':
            reader.readAsText(file);
            reader.onload = function ($e) {
                if ($e.target) {
                    var content = $e.target.result;
                    processFromFile(content);
                }
            };
            break;
        case 'xls':
        case 'xlsx':
            reader.readAsArrayBuffer(file);
            reader.onload = function (e) {
                if (e.target) {
                    var f = e.target.result;
                    var workbook = XLSX.read(f, { type: 'buffer' });
                    var content = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
                    processFromFile(content);
                }
            };
            break;
        default:
            refreshUploader();
            alert('ファイル形式が無効になりました');
    }
}
var initInputTags = function () {
    var elmInputUserIDs = document.querySelector('input[id=userIDs]');
    if (!elmInputUserIDs) {
        return;
    }
    var configs = {
        delimiters: ',| |\n',
        duplicates: true
    };
    elmInputUserIDs.addEventListener('change', processInputTags);
    return inputTags(elmInputUserIDs, configs);
};
var initUploader = function () {
    var uploadUserIdElm = document.querySelector('#userIDsUploader');
    if (uploadUserIdElm) {
        uploadUserIdElm.addEventListener('change', processUpload);
    }
};
export default (function () {
    var tmpList = document.CURRENT_USER_ID_LIST;
    if (tmpList)
        currUserIDs = tmpList.trim().split(',').map(Number);
    tagsOpj = initInputTags();
    tagsOpj.removeAllTags();
    loadCurrUserIDs();
    initUploader();
});
