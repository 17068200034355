var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ToggleHeader from './parts/ToggleHeader.vue';
import CheckAndEscalate from './parts/CheckAndEscalate.vue';
import ExpirationDateInput from './parts/ExpirationDateInput.vue';
import { mainModule } from '../store/main';
import { telInterviewModule } from '../store/tel_interview';
var TelInterview = /** @class */ (function (_super) {
    __extends(TelInterview, _super);
    function TelInterview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processId = 'TelInterview';
        _this.disable_btn = false;
        return _this;
    }
    TelInterview.prototype.onClickUpdate = function () {
        var _this = this;
        this.$validator
            .validate('interview.*').then(function (result) {
            if (result) {
                _this.disable_btn = true;
                telInterviewModule.update();
            }
        });
    };
    Object.defineProperty(TelInterview.prototype, "bookedDatetime", {
        get: function () {
            return telInterviewModule.bookedDatetime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "interviewType", {
        get: function () {
            return telInterviewModule.interviewType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "approvedOrRejected", {
        get: function () {
            return telInterviewModule.approvedOrRejected;
        },
        set: function (judge) {
            telInterviewModule.setApprovedOrRejected(judge);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "adminUserId", {
        get: function () {
            return telInterviewModule.adminUserId;
        },
        set: function (id) {
            telInterviewModule.setAdminUserId(id);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "judgeNote", {
        get: function () {
            return telInterviewModule.judgeNote;
        },
        set: function (note) {
            telInterviewModule.setJudgeNote(note);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "users", {
        get: function () {
            return mainModule.users;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "disabled", {
        get: function () {
            return !mainModule.isActive(this.process);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "process", {
        get: function () {
            return this.processId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelInterview.prototype, "active", {
        get: function () {
            return mainModule.isActive(this.processId);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], TelInterview.prototype, "no", void 0);
    TelInterview = __decorate([
        Component({
            components: {
                ToggleHeader: ToggleHeader,
                CheckAndEscalate: CheckAndEscalate,
                ExpirationDateInput: ExpirationDateInput,
            },
            $_veeValidate: { validator: 'new' },
        })
    ], TelInterview);
    return TelInterview;
}(Vue));
export default TelInterview;
