export default {
  // Loading data from inlined global json vars
  loadData(state, payload) {
    state.user = payload.user;
    state.deal = payload.deal;
    state.current_proposal_application_amount = payload.current_proposal_application_amount;
    state.admin_users = payload.admin_users;
    state.login_user_id = payload.login_user_id;
    state.examination_items = payload.examination_items;
    state.evidence_examination_items = payload.evidence_examination_items;
    state.corp_evidence_type_id = payload.corp_evidence_type_id;
    state.user_evidence_type_id = payload.user_evidence_type_id;
    state.isXpointAvailable = payload.isXpointAvailable;
    state.masterDealIndustries = payload.masterDealIndustries;
    state.masterDealIndustryDefaultId = payload.masterDealIndustryDefaultId;
  },
};
