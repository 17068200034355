import { render, staticRenderFns } from "./DealsTable.vue?vue&type=template&id=e83a00f6&scoped=true"
import script from "./DealsTable.vue?vue&type=script&lang=ts"
export * from "./DealsTable.vue?vue&type=script&lang=ts"
import style0 from "./DealsTable.vue?vue&type=style&index=0&lang=css"
import style1 from "./DealsTable.vue?vue&type=style&index=1&id=e83a00f6&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e83a00f6",
  null
  
)

export default component.exports