import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import deals from './modules/deals';
moment.locale('ja');
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        deals: deals
    }
});
