/* global $ */
import trumbowyg from 'trumbowyg/dist/trumbowyg.min.js';
import trumbowygJa from 'trumbowyg/dist/langs/ja.min.js';
import trumbowygColor from 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css';
import iconSvg from 'trumbowyg/dist/ui/icons.svg';

export default function processingInput() {
  const iconSvgPath = `/public/${iconSvg}`;

  const editNoteUserConfig = {
    lang: 'ja',
    svgPath: iconSvgPath,
    semantic: false,
    tagsToRemove: ['script', 'style'],
    tagsToKeep: ['label'],
    btnsDef: {
      guide: {
        fn: () => { $('#guideModal').modal(); },
        title: 'チェックボックス',
        class: 'cui-document trumbowyg-not-disable',
        hasIcon: false
      }
    },
    btns: [
      ['viewHTML'],
      ['formatting'],
      ['strong', 'em', 'underline', 'del'],
      ['foreColor', 'backColor'],
      ['link'],
      ['insertImage'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['unorderedList', 'orderedList'],
      ['horizontalRule'],
      ['removeformat'],
      ['guide'],
      ['fullscreen']]
  };

  let editNoteAdminConfig = JSON.parse(JSON.stringify(editNoteUserConfig));
  delete editNoteAdminConfig.btnsDef;

  const viewConfig = {
    lang: 'ja',
    svgPath: iconSvgPath,
    disabled: true,
    tagsToKeep: ['label'],
    btns: [['viewHTML'], ['fullscreen']]
  };

  /* Toggle disable inputText when required checkbox is changed */
  function toggleSwitchCkb(ckbEl) {
    // For 取引の審査を許可する checkbox
    if (ckbEl.id === "is_judgeable") {
      const inputEl = document.getElementsByName("contact_email")[0];
      inputEl.disabled = !ckbEl.checked;
      if (inputEl.disabled) {
        inputEl.value = '';
      }

      return
    }

    // For 請求金額ノート表示が必要 checkbox
    if (ckbEl.id === "is_displayable_amount_note") {
      const inputEl = document.getElementsByName("amount_note")[0];
      inputEl.disabled = !ckbEl.checked;
      if (inputEl.disabled) {
        inputEl.value = '';
      }

      return
    }

    // For 源泉徴収ノート表示が必要 checkbox
    if (ckbEl.id === "is_displayable_tax_note") {
      const inputEl = document.getElementsByName("tax_note")[0];
      inputEl.disabled = !ckbEl.checked;
      if (inputEl.disabled) {
        inputEl.value = '';
      }

      return
    }

    // For 即日払い申込金額ノート表示が必要 checkbox
    if (ckbEl.id === "is_displayable_application_amount_note") {
      const inputEl = document.getElementsByName("application_amount_note")[0];
      inputEl.disabled = !ckbEl.checked;
      if (inputEl.disabled) {
        inputEl.value = '';
      }

      return
    }

    // For メモノート表示が必要 checkbox
    if (ckbEl.id === "is_displayable_memo_note") {
      const inputEl = document.getElementsByName("memo_note")[0];
      inputEl.disabled = !ckbEl.checked;
      if (inputEl.disabled) {
        inputEl.value = '';
      }

      return
    }

    if (ckbEl.id === 'is_auto_calculate_due_date') {
      const elmDefaultIssuedDate = document.getElementById('is_default_issued_date_to_today');
      const elmDisableIssuedDate = document.getElementById('is_disabled_issued_date');
      const elmDueDateMonthRule = document.getElementById('due_date_month_rule');
      const elmDueDateDayRule = document.getElementById('due_date_day_rule');
      elmDefaultIssuedDate.disabled = !ckbEl.checked;
      elmDueDateMonthRule.disabled = !ckbEl.checked;
      elmDueDateDayRule.disabled = !ckbEl.checked;
      if (!ckbEl.checked) {
        elmDefaultIssuedDate.checked = false;
        elmDisableIssuedDate.checked = false;
        elmDisableIssuedDate.disabled = true;
        elmDueDateMonthRule.selectedIndex = 1; // index -> 0: 当月、1: 翌月、2: 翌々月、3: 翌翌々月 
        elmDueDateDayRule.selectedIndex   = 0; // index -> 0: 末日
      }

      return
    }
    if (ckbEl.id === 'is_default_issued_date_to_today') {
      const elmDisableIssuedDate = document.getElementById('is_disabled_issued_date');
      elmDisableIssuedDate.disabled = !ckbEl.checked;
      if (!ckbEl.checked) {
        elmDisableIssuedDate.checked = false;
      }

      return
    }
    

    const label = document.getElementsByName(ckbEl.id.replace('required', 'label'))[0];
    label.disabled = !ckbEl.checked;
    if (label.disabled) {
      label.value = '';
    }
    if (ckbEl.id === 'file_upload_required') {
      const fileUploadNoteEl = document.getElementsByName('file_upload_note')[0];
      fileUploadNoteEl.disabled = !ckbEl.checked;
      if (fileUploadNoteEl.disabled) {
        fileUploadNoteEl.value = '';
      }

      const ckbEls = document.querySelectorAll('[name*=allowed_upload_file]');
      ckbEls.forEach((el) => {
        el.disabled = !ckbEl.checked;
        if (el.disabled) {
          el.checked = false;
        }
      });
    }
  }

  // when 買取限度額 change, set max of 申請金額の下限 by 買取限度額 value
  const limitEl = document.getElementById('limit');
  const minimumApplicationAmountEl = document.getElementById('minimum-application-amount');

  if (limitEl) {
    limitEl.addEventListener('change', ($event) => {
      const limit = parseInt($event.target.value, 10);

      if (minimumApplicationAmountEl && limit) {
        minimumApplicationAmountEl.setAttribute('max', limit);
      }
    });
    const eventChange = new Event('change');
    limitEl.dispatchEvent(eventChange);
  }

  function initCheckbox(elmID) {
    const elm = document.getElementById(elmID);
    if (!elm) return;
    toggleSwitchCkb(elm);
    elm.onchange = () => {
      toggleSwitchCkb(elm);
    };
  }

  function init() {
    /* Create editor for view page */
    $(document.querySelectorAll('[name*=view_nf_note_]')).trumbowyg(viewConfig);

    /* Create editor for add, edit page */
    const noteAdminElm = document.getElementsByName('note_admin')[0];
    $(noteAdminElm).trumbowyg(editNoteAdminConfig);

    const noteUserElm = document.getElementsByName('note_user')[0];
    $(noteUserElm).trumbowyg(editNoteUserConfig);

    /* Create event for required checkboxes */
    const ckbReqEls = document.querySelectorAll('[id*=required]');
    ckbReqEls.forEach((el) => {
      toggleSwitchCkb(el);
      el.onchange = () => {
        toggleSwitchCkb(el);
      };
    });

    initCheckbox('is_judgeable'); // 取引の審査を許可する checkbox
    initCheckbox('is_displayable_amount_note'); // 請求金額ノート表示が必要 checkbox
    initCheckbox('is_displayable_tax_note'); // 源泉徴収ノート表示が必要 checkbox
    initCheckbox('is_displayable_application_amount_note'); // 即日払い申込金額ノート表示が必要 checkbox
    initCheckbox('is_displayable_memo_note'); // メモノート表示が必要 checkbox
    initCheckbox('is_auto_calculate_due_date'); // 支払いルール設定 checkbox
    initCheckbox('is_default_issued_date_to_today'); // 支払いルール設定 checkbox
  }

  init();
}
