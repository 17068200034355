export default {
  // Loading data from inlined global json vars
  loadData(state, payload) {
    let trs = payload.transactions;
    let l = trs.length;
    const mtt = state.constMasterTransactionTypes;
    for (let i = 0; i < l; i++) {
      let failedId = state.constMasterTransactionStatuses.MASTER_TRANSACTION_STATUS_SENT_FAILED;
      trs[i]._failed = trs[i].MasterTransactionStatusID == failedId; // 送金失敗したかか否か
      trs[i]._targeted = false; // 送金対象/対象外
      trs[i]._checked = false; // チェックされているか否か
      trs[i]._shown = true; // 表示するか否か
      trs[i].isValid = trs[i].User.BankCode.length === 4
        && trs[i].User.BranchCode.length === 3
        && trs[i].User.AccountNo.length === 7;
      trs[i].targetable = true;

      trs[i].TransactionRecords.forEach((tr) => {
        tr.isChecked = true;
        tr._shown = true; // 表示するか否か
        if (tr.MasterTransactionRecordTypeID == mtt.MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE) {
          trs[i]._instantTransferAppliedAt = tr.CreatedAt;
        }

        if (tr.MasterTransactionRecordTypeID == mtt.MASTER_TRANSACTION_TYPE_INCOMING) {
          tr.isSendingFincodeBank = tr.IncomingRecord
            && tr.IncomingRecord.SendingBankName == state.constFincodeBankName
            && tr.IncomingRecord.SendingBranchName == state.constFincodeBankBranchName
            && tr.IncomingRecord.SendingName == state.constFincodeBankAccountName;
        }
      });
    }

    state.transactions = trs;
    state.masterTransactionRecordTypes = payload.masterTransactionRecordTypes;
    state.mode = payload.mode;
    state.isMasterOutcomingStatusFixed = payload.isMasterOutcomingStatusFixed;
    state.outcoming = payload.outcoming || {};
    state.containsInstantTransferRecords =
      state.transactions.filter(
        (t) =>
          t.TransactionRecords.filter(
            (tr) =>
              tr.MasterTransactionRecordTypeID == mtt.MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE
          ).length > 0
        ).length > 0;
    state.isGanbAPIConnected = payload.isGanbAPIConnected;
    state.masterGanbTransferStatus = payload.masterGanbTransferStatus;
    state.isMasterOutcomingStatusTransfered = payload.isMasterOutcomingStatusTransfered;
    state.isAllowTransferRequestOutcoming = payload.isAllowTransferRequestOutcoming;
    state.isAdminValidForGanbTransfer = payload.isAdminValidForGanbTransfer;
    state.isGroupSupportAdmin = payload.isGroupSupportAdmin;

    if (payload.filterCorporationBankAccounts) {
      // 送金元の口座をフィルタするための初期値を設定する
      payload.filterCorporationBankAccounts.forEach((corporationBankAccount) => {
        // チェックボックスの初期値を設定する
        state.checkedBankAccountFilters.push(false);
        // 銀行口座情報を設定する
        state.corporationBankAccountFilters.push(corporationBankAccount);
      });
      // 個人口座フィルタを追加する
      state.checkedBankAccountFilters.push(false);
      state.corporationBankAccountFilters.push({
        ID: 0,
        Corporation: {
          Name: '個人口座'
        }
      });
    }
  },

  addNewTransactionRecord(state, payload) {
    let tran = state.transactions.find((t) => t.ID === payload.TransactionID);
    tran.TransactionRecords.push({
      _shown                        : true,
      _newRecord                    : true,
      _collectionID                 : payload._collectionID,
      isChecked                     : true,
      MasterTransactionRecordTypeID : parseInt(payload.MasterTransactionRecordTypeID, 10),
      Amount                        : -parseInt(payload.Amount, 10),
      Title                         : payload.Title,
      MasterTransactionRecordType   : payload.MasterTransactionRecordType,
    });
  },

  toggleOpenDetail(state, tranID) {
    let tran = state.transactions.find((t) => t.ID === tranID);
    tran._openDetail = tran._openDetail ? false: true;
  },

  checkByMaxRecords(state, payload) {
    let checkedCount = state.transactions.filter((tran) => tran._checked).length + 1;
    state.transactions.forEach((tran)=>{
      if (tran._shown && tran.isValid && checkedCount <= payload.maxRecords && !tran.User.IsDisabledOutcoming) {
        tran._checked = true;
        checkedCount++;
      }
    });
  },

  uncheckAll(state) {
    state.transactions.forEach((tran)=>{
      tran._checked = false;
    });
  },

  setProcessingApiRequest(state, payload) {
    state.isProcessingApiRequest = payload.isProcessingApiRequest;
  },

  updateCheckAllTransactionRecord(state, payload) {
    state.checkedAllTransactionRecords = payload.checkedAll;
  },

  makeSelectionsTargeted(state, payload) {
    const targetedCountMax = document.ACCOUNT_NUMBER_PER_REQUEST;
    const newTargetedCount = state.transactions.filter(tranVal => tranVal._checked || tranVal._targeted).length;
    if (newTargetedCount > targetedCountMax) {
      alert(`${targetedCountMax}件までしか処理できません`);
      return;
    }

    // Validate disabled outcoming users
    var invalidUsers = [];
    state.transactions.filter(tranVal => tranVal._checked && tranVal.User.IsDisabledOutcoming).forEach(filteredTran => {
      if (payload.getters.calcTransactionAmount(filteredTran) != 0) {
        invalidUsers.push(filteredTran.User.FullName);
      }
    });
    if (invalidUsers.length > 0) {
      alert(`振込停止ユーザが存在してます。\n${invalidUsers.join('\n')}`);
      return;
    }

    state.transactions.forEach((tran)=>{
      if (tran._checked) {
        tran._targeted = true;
      }
      // uncheck all
      tran._checked = false;
    });
  },

  makeSelectionsUntargeted(state) {
    state.transactions.forEach((tran)=>{
      if (tran._checked) {
        tran._targeted = false;
      }
      // uncheck all
      tran._checked = false;
    });
  },

  applyFilter(state) {
    const mtt = state.constMasterTransactionTypes;
    const f = state.filters;
    const checkedBankAccounts = state.checkedBankAccountFilters;
    const corporationBankAccounts = state.corporationBankAccountFilters;
    state.transactions.forEach((tran) => {
      let shown = true;
      tran.TransactionRecords.forEach((tr) => {
        tr._shown = true;
      });
      if (f.checkedCondTargeted) {
        shown = shown && tran._targeted;
      }
      if (f.checkedCondUntargeted) {
        shown = shown && !tran._targeted;
      }
      if (f.checkedCondIncoming) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_INCOMING);
        shown = shown && hasType;
      }

      if (f.checkedCondQuickPay) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_QUICKPAY);
        shown = shown && hasType;
      }
      if (f.checkedCondRetryFail) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_RETRY_FAIL);
        shown = shown && hasType;
      }
      if (f.checkedCondRetryStop) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_RETRY_STOP);
        shown = shown && hasType;
      }
      if (f.checkedCondInstantTransferFee) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_INSTANT_TRANSFER_FEE);
        shown = shown && hasType;
      }
      if (f.checkedCondDeadlineHasCome) {
        shown = shown && tran.CollectionableDeadline;
      }
      if (f.checkedCondDeadline15Days) {
        shown = shown && tran.Collectionable15DaysDeadline;
      }
      if (f.checkedCondDeadlineIsComming) {
        shown = shown && tran.Collectionable && !(tran.CollectionableDeadline || tran.Collectionable15DaysDeadline);
      }
      if (f.checkedCondDeadlineNone) {
        shown = shown && !tran.Collectionable;
      }

      if (f.checkedCondCommissionCashBack) {
        let hasType = tran.Types.find((t) => t.ID === mtt.MASTER_TRANSACTION_TYPE_COMMISSION_CASH_BACK);
        shown = shown && hasType;
      }

      // (個別) 200万円〜、(個別) 0〜200万円金額のどちらかフィルタを選択
      if ((f.checkedCondIncomingAmountMoreThanTwoMillionYen || f.checkedCondIncomingAmountLessThanTwoMillionYen)
        && !(f.checkedCondIncomingAmountMoreThanTwoMillionYen && f.checkedCondIncomingAmountLessThanTwoMillionYen)) {
        tran.TransactionRecords.forEach((tr) => {
          if (tr.MasterTransactionRecordType.ID !== mtt.MASTER_TRANSACTION_TYPE_INCOMING) {
            return;
          }

          // (個別)200万円〜ではない場合、非表示にする
          // (個別)0~200万円金額ではない場合、非表示にする
          if ((f.checkedCondIncomingAmountMoreThanTwoMillionYen && tr.Amount < 2000000)
          || (f.checkedCondIncomingAmountLessThanTwoMillionYen && (tr.Amount < 0 || tr.Amount >= 2000000))) {
            tr._shown = false;
          }
        });
      }
      // 送金元の銀行口座をフィルタする
      if (checkedBankAccounts.some(v => v === true)) {
        const transactionRecordBankAccountFilterArray = new Array(tran.TransactionRecords.length).fill(false);
        for (let i = 0; i < checkedBankAccounts.length; i++) {
          if (checkedBankAccounts[i]) {
            tran.TransactionRecords.forEach((tr, index) => {
              if (tr.MasterTransactionRecordType.ID !== mtt.MASTER_TRANSACTION_TYPE_INCOMING) {
                return;
              }

              let isExistParentheses = false;
              let isMatchedBankAccount = false;
              if (corporationBankAccounts[i].ID === 0) {
              // 個人口座場合
                isExistParentheses = !/\(|\)/.test(tr.IncomingRecord.SendingName);
              } else {
                // 送金元の口座は、いずれか該当口座に一致するか
                isMatchedBankAccount = tr.IncomingRecord.SendingBankName === corporationBankAccounts[i].BankName && tr.IncomingRecord.SendingBranchName === corporationBankAccounts[i].BranchName && tr.IncomingRecord.SendingName === corporationBankAccounts[i].AccountName;
              }
              // Orでフィルタするために、一度でもtrueになったらtrueにする
              transactionRecordBankAccountFilterArray[index] = transactionRecordBankAccountFilterArray[index] || isExistParentheses || isMatchedBankAccount;
            });
          }
        }
        tran.TransactionRecords.forEach((tr, index) => {
          tr._shown = tr._shown && transactionRecordBankAccountFilterArray[index];
        });
      }

      // exist at least one transaction record is showned in transaction
      const filteredTransactionRecordLength = tran.TransactionRecords.filter(tr => tr._shown).length;
      tran._shown = shown && filteredTransactionRecordLength > 0;
    });
  },

  resetFilter(state) {
    for (let key in state.filters) {
      state.filters[key]  = false;
    }

    for (let key in state.checkedBankAccountFilters) {
      state.checkedBankAccountFilters[key] = false;
    }
    state.transactions.forEach((tran) => {
      tran.TransactionRecords.forEach((tr) => {
        tr._shown = true;
        tr.isChecked = true;
      });
      tran._shown = true;
    });
  },

  setUncheckedTranRecordIds(state) {
    const mtt = state.constMasterTransactionTypes;
    state.transactions.forEach((tran) => {
      tran.excludedIncomingIds = [];
      tran.TransactionRecords.forEach((tranRecord) => {
        if ((!tranRecord.isChecked || !tranRecord._shown) && tranRecord.MasterTransactionRecordTypeID === mtt.MASTER_TRANSACTION_TYPE_INCOMING) {
          tran.excludedIncomingIds.push(tranRecord.ID);
        }
      });
    });
  }
};
