import { generateKey } from './Util'
import chroma from 'chroma-js'

export default {
  // Set CommissionClustering from global to store
  initCommissionClustering(state, payload) {
    // Create color table
    const sorted_commissions = state.commissions.sort((a, b) => { return a.id - b.id });
    const blue = chroma('blue').hex();
    const red = chroma('red').darken().desaturate(1).hex();
    const scale = chroma.scale([blue, "#328332", red]);
    const color_map = {};
    const l = sorted_commissions.length;
    sorted_commissions.forEach((r, i) => {
      if (i+1 === l) {
        color_map[r.id] = '#ddd';
      } else {
        color_map[r.id] = scale((i + 1) / sorted_commissions.length).hex();
      }
    });
    state.commission_color_map = color_map;
    
    // Construct commission_clusterings initial data
    const commission_clusterings = [];
    payload.ranking_version.CommissionClusterings.forEach((cluster, i) => {
      commission_clusterings.push({
        key:                  generateKey(),
        id:                   cluster.ID,
        deal_rank_id:         cluster.DealRankID,
        commission_range_id:  cluster.CommissionRangeID,
        commission_id:        cluster.CommissionID,
        is_valid:             {},
      });
    });
    state.commission_clusterings = commission_clusterings;
  },
   
  // Update individual commission clustering
  updateCommissionClustering(state, payload) {
    state.commission_clusterings.forEach((cluetering, i) => {
      if (cluetering.key == payload.key) {
        state.commission_clusterings[i].commission_id = payload.commission_id;
      }
    });
  },
};
