<template>
  
  <li class="list-group-item">
    <div class="form-row">
      <div class="form-group col-2">
        <label >格付け名称 :</label>
        <input 
          :name="'name_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="name"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('name_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('name_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('name_'+deal_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label>説明 :</label>
        <input 
          :name="'description_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="description"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('description_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('description_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('description_'+deal_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >自動取引上限 :</label>
        <input 
          :name="'auto_deal_limit_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="auto_deal_limit"
          v-validate="'required|min_value:0|max_value:99999999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('auto_deal_limit_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('auto_deal_limit_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('auto_deal_limit_'+deal_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >要審査取引上限 :</label>
        <input 
          :name="'manual_deal_limit_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="manual_deal_limit"
          v-validate="'required|min_value:0|max_value:99999999'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('manual_deal_limit_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('manual_deal_limit_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('manual_deal_limit_'+deal_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >1ランクアップ取引回数:</label>
        <input 
          :name="'rank1_up_cnt_of_deal_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="rank1_up_cnt_of_deal"
          v-validate="'required|min_value:0|max_value:99'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('rank1_up_cnt_of_deal_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('rank1_up_cnt_of_deal_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('rank1_up_cnt_of_deal_'+deal_rank.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >2ランクアップ取引回数:</label>
        <input 
          :name="'rank2_up_cnt_of_deal_'+deal_rank.key"
          type="text" 
          class="form-control" 
          v-model="rank2_up_cnt_of_deal"
          v-validate="'required|min_value:0|max_value:99'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('rank2_up_cnt_of_deal_'+deal_rank.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('rank2_up_cnt_of_deal_'+deal_rank.key)" class="invalid-feedback">
          {{ errors.first('rank2_up_cnt_of_deal_'+deal_rank.key) }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'DealRankInput',
    props: [
      'deal_rank',
    ],
    watch: {
      name: function(a, b) { watchDealRank(this, 'name') },
      description: function(a, b) { watchDealRank(this, 'description') },
      auto_deal_limit: function(a, b) { watchDealRank(this, 'auto_deal_limit') },
      manual_deal_limit: function(a, b) { watchDealRank(this, 'manual_deal_limit') },
      rank1_up_cnt_of_deal: function(a, b) { watchDealRank(this, 'rank1_up_cnt_of_deal') },
      rank2_up_cnt_of_deal: function(a, b) { watchDealRank(this, 'rank2_up_cnt_of_deal') },
    },
    computed: {
      name: {
        get() { return this.deal_rank.name },
        set(value) { setDealRank(this, 'name', value) },
      },
      description: {
        get() { return this.deal_rank.description },
        set(value) { setDealRank(this, 'description', value) },
      },
      auto_deal_limit: {
        get() { return this.deal_rank.auto_deal_limit },
        set(value) { setDealRank(this, 'auto_deal_limit', value) },
      },
      manual_deal_limit: {
        get() { return this.deal_rank.manual_deal_limit },
        set(value) { setDealRank(this, 'manual_deal_limit', value) },
      },
      rank1_up_cnt_of_deal: {
        get() { return this.deal_rank.rank1_up_cnt_of_deal },
        set(value) { setDealRank(this, 'rank1_up_cnt_of_deal', value) },
      },
      rank2_up_cnt_of_deal: {
        get() { return this.deal_rank.rank2_up_cnt_of_deal },
        set(value) { setDealRank(this, 'rank2_up_cnt_of_deal', value) },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        validate_item: 'この項目',
        validate_title: '「' + this.deal_rank.name + '」 ',
      }
    },
  };
  function setDealRank (that, attr, value) {
    that.$store.commit('updateDealRank', {
      'key' : that.deal_rank.key,
      'attr' : attr,
      'value' : value,
    });
  }
  function watchDealRank (that, attr) {
    that.$validator.validate().then(result => {
      that.$store.commit('updateDealRankIsValid', {
        'key' : that.deal_rank.key,
        'attr' : attr,
        'is_valid': result
      });
    });
  }
</script>
