import { generateKey } from './Util'
import chroma from 'chroma-js'

export default {
  // Set DealClustering from global to store
  initDealClustering(state, payload) {
    // Create color table
    const sorted_deal_ranks = state.deal_ranks.sort((a, b) => { return a.id - b.id });
    const blue = chroma('blue').hex();
    const red = chroma('red').darken().desaturate(1).hex();
    const scale = chroma.scale([blue, "#328332", red]);
    const color_map = {};
    const l = sorted_deal_ranks.length;
    sorted_deal_ranks.forEach((r, i) => {
      if (i+1 === l) {
        color_map[r.id] = '#ddd';
      } else {
        color_map[r.id] = scale((i + 1) / sorted_deal_ranks.length).hex();
      }
    });
    state.deal_color_map = color_map;
    
    // Construct deal_clusterings initial data
    const deal_clusterings = [];
    payload.ranking_version.DealClusterings.forEach((cluster, i) => {
      deal_clusterings.push({
        key:                  generateKey(),
        id:                   cluster.ID,
        user_rank_id:         cluster.UserRankID,
        corporation_rank_id:  cluster.CorporationRankID,
        deal_rank_id:         cluster.DealRankID,
        is_valid:             {},
      });
    });
    state.deal_clusterings = deal_clusterings;
  },
   
  // Update individual deal clustering
  updateDealClustering(state, payload) {
    state.deal_clusterings.forEach((cluetering, i) => {
      if (cluetering.key == payload.key) {
        state.deal_clusterings[i].deal_rank_id = payload.deal_rank_id;
      }
    });
  },
};
