<template>
  
  <li class="list-group-item">
    <div class="form-row">
      <div class="form-group col-2">
        <label >格付け名称 :</label>
        <input 
          :name="'name_'+commission.key"
          type="text" 
          class="form-control" 
          v-model="name"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('name_'+commission.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('name_'+commission.key)" class="invalid-feedback">
          {{ errors.first('name_'+commission.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label>説明 :</label>
        <input 
          :name="'description_'+commission.key"
          type="text" 
          class="form-control" 
          v-model="description"
          v-validate="'required|max:255'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('description_'+commission.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('description_'+commission.key)" class="invalid-feedback">
          {{ errors.first('description_'+commission.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >回収期間31日以内手数料 :</label>
        <input 
          :name="'rate_in_20_days_'+commission.key"
          type="text" 
          class="form-control" 
          v-model="rate_in_20_days"
          v-validate="'required|min_value:0|max_value:0.1|decimal:3'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('rate_in_20_days_'+commission.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('rate_in_20_days_'+commission.key)" class="invalid-feedback">
          {{ errors.first('rate_in_20_days_'+commission.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >回収期間62日以内手数料 :</label>
        <input 
          :name="'rate_in_40_days_'+commission.key"
          type="text" 
          class="form-control" 
          v-model="rate_in_40_days"
          v-validate="'required|min_value:0|max_value:0.1|decimal:3'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('rate_in_40_days_'+commission.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('rate_in_40_days_'+commission.key)" class="invalid-feedback">
          {{ errors.first('rate_in_40_days_'+commission.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label >回収期間105日以内手数料 :</label>
        <input 
          :name="'rate_in_60_days_'+commission.key"
          type="text" 
          class="form-control" 
          v-model="rate_in_60_days"
          v-validate="'required|min_value:0|max_value:0.1|decimal:3'"
          :data-vv-as="validate_item"
          :class="{'is-invalid': errors.has('rate_in_60_days_'+commission.key)}"
          :disabled="disabled"
          />
        <p v-if="errors.has('rate_in_60_days_'+commission.key)" class="invalid-feedback">
          {{ errors.first('rate_in_60_days_'+commission.key) }}
        </p>
      </div>
      <div class="form-group col-2">
        <label>納品エビデンス :</label>
        <select class="form-control" v-model="master_delivery_evidence_type_id">
          <option 
           :name="'master_delivery_evidence_type_id_'+commission.key"
           v-for="master_delivery_evidence_type in master_delivery_evidence_types" 
           :value="master_delivery_evidence_type.ID" 
           :key="master_delivery_evidence_type.ID"
           :disabled="disabled"
           >{{ master_delivery_evidence_type.Title }}</option>
        </select>
      </div>
    </div>
  </li>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'CommissionInput',
    props: [
      'commission',
    ],
    computed: {
      name: {
        get() { return this.commission.name },
        set(value) { 
          setCommission(this, 'name', value);
          watchCommission(this, 'name');
        },
      },
      description: {
        get() { return this.commission.description },
        set(value) { 
          setCommission(this, 'description', value);
          watchCommission(this, 'description');
        },
      },
      rate_in_20_days: {
        get() { return this.commission.rate_in_20_days },
        set(value) { 
          setCommission(this, 'rate_in_20_days', value);
          watchCommission(this, 'rate_in_20_days');
        },
      },
      rate_in_40_days: {
        get() { return this.commission.rate_in_40_days },
        set(value) {
          setCommission(this, 'rate_in_40_days', value);
          watchCommission(this, 'rate_in_40_days');
        },
      },
      rate_in_60_days: {
        get() { return this.commission.rate_in_60_days },
        set(value) {
          setCommission(this, 'rate_in_60_days', value);
          watchCommission(this, 'rate_in_60_days');
        },
      },
      master_delivery_evidence_type_id: {
        get() { return this.commission.master_delivery_evidence_type_id },
        set(value) { 
          setCommission(this, 'master_delivery_evidence_type_id', value);
          watchCommission(this, 'master_delivery_evidence_type_id');
        },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      return {
        master_delivery_evidence_types: this.$store.state.master_delivery_evidence_types,
        validate_item: 'この項目',
      }
    },
  };
  function setCommission (that, attr, value) {
    that.$store.commit('updateCommission', {
      'key' : that.commission.key,
      'attr' : attr,
      'value' : value,
    });
  }
  function watchCommission (that, attr) {
    let name = attr + '_' + that.commission.key;
    that.$validator.validate(name).then(result => {
      that.$store.commit('updateCommissionIsValid', {
        'key' : that.commission.key,
        'attr' : attr,
        'is_valid': result
      });
    });
  }
</script>
