import Vue from 'vue';
import App from './components/App.vue';
import store from './store';

export default function page() {
  const app = new Vue({
    el: '#app',
    store,
    components: {
      App
    }
  });
}
