<template>
  <div id="app">
    <Ace
      :content="body"
      @content-changed="onChange"
      style="height: 60rem"
    />
    <input
      v-model="body"
      type="hidden"
      name="body"
    />
  </div>
</template>

<script>
import Ace from './Ace.vue'
export default {
  name: 'App',
  components: {
    Ace
  },
  data() {
    return {
      body: document.SPOT_BODY
    }
  },
  methods: {
    onChange(data) {
      this.body = data;
    },
  }
}
</script>
