<template>
  <div class="card mb-1">
    <ToggleHeader
      :name="no + '. 補助審査'"
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : active }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <div class="m-0 pl-4">
          <div class="custom-control custom-checkbox mt-2" v-for="item in checkList" :key="`check-${item.id}`">
            <input class="custom-control-input" type="checkbox" :value="item.id" :disabled="!active" :id="`check-${item.id}`" v-model="checkedItems">
            <label class="custom-control-label" :for="`check-${item.id}`">
              {{ item.content }}
            </label>
            <span
              v-if="active && item.id == 'mainbankAccountName' && duplicateMainbankUserID != 0"
              class="badge badge-danger"
              id="duplicateMainbankFlg"
            >
              同一のメインバンク口座が登録済み(<a :href="'/users/view/'+duplicateMainbankUserID">{{duplicateMainbankUserID}}</a>)
            </span>
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <label>身分証明書</label>
        <div class="form-inline">
          有効期限：
          <select
            :name="'select_year'"
            class="form-control"
            :class="{'is-invalid': !inputDateValid}"
            v-model="year"
            :disabled="!active"
          >
            <option value="">-</option>
            <option
              v-for="year in years"
              :value="year"
              :key="year"
            >{{ year }}</option>
          </select>
          -
          <select
            :name="'select_month'"
            class="form-control"
            :class="{'is-invalid': !inputDateValid}"
            v-model="month"
            :disabled="!active"
          >
            <option value="">-</option>
            <option
              v-for="month in months"
              :value="month"
              :key="month"
            >{{ month }}</option>
          </select>
          -
          <select
            :name="'select_day'"
            class="form-control"
            :class="{'is-invalid': !inputDateValid }"
            v-model="day"
            :disabled="!active"
          >
            <option value="">-</option>
            <option
              v-for="day in days"
              :value="day"
              :key="day"
            >{{ day }}</option>
          </select>
          <div v-if="!inputDateValid" class="invalid-feedback">
            正しい身分証明書の有効期限を入力して下さい
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <div class="form-group row mb-0">
          <label
            for="judge_additional_expired_date"
            class="col-auto col-form-label">謝絶期限：</label>
          <div class="col">
            <input
              type="date"
              class="form-control"
              name="judge_additional_expired_date"
              :disabled="disabled"
              :class="{'is-invalid': errors.has('judge_additional_expired_date')}"
              v-model="judge_additional_expired_date">
          </div>
        </div>
        <p v-if="errors.has('judge_additional_expired_date')" class="has-error invalid-feedback">
          {{ errors.first('judge_additional_expired_date') }}
        </p>
      </li>
      <li class="list-group-item p-3">
        <p class="mt-0">チェックを行い審査実行</p>
        <label for="">審査結果：</label>
        <select
          :name="judge_result_input_name"
          class="form-control"
          :class="{'is-invalid': errors.has(judge_result_input_name)}"
          v-model="judge_result"
          v-validate="'required'"
          :disabled="disabled">
          <option
            v-for="judge_result in judge_results"
            :value="judge_result.id"
            :key="judge_result.id">
            {{ judge_result.name }}
          </option>
        </select>
        <p
          v-if="errors.has(judge_result_input_name)"
          class="invalid-feedback">
          審査結果を選択して下さい
        </p>
        <div v-if="is_additional_reason_updated || judge_result === 3">
          <label>追加情報依頼理由：</label>
          <textarea
            class="form-control"
            style="height:100px;"
            v-model="judge_additional_reason"
            v-validate="'required'"
            :class="{'is-invalid': errors.has('judge_additional_reason')}"
            :name="'judge_additional_reason'"
            :disabled="disabled || is_additional_reason_updated" />
          <p
            v-if="errors.has('judge_additional_reason') && !judge_additional_reason"
            class="invalid-feedback">
            追加情報依頼理由を記入ください
          </p>
          <br/>
        </div>
        <EmailPreviewTemplateForJudgeAdditional
          v-if="judge_result === 3"
          :heading="'追加情報依頼メール・プレビュー：'"
          :full-name="fullName"
          :content="judge_additional_reason"
          :service-url="service_url" />
        <div v-if="judge_result === 2">
          <label>謝絶理由：</label>
          <textarea
            class="form-control"
            style="height:100px;"
            v-model="judge_decline_reason"
            v-validate="'required'"
            :class="{'is-invalid': errors.has('judge_decline_reason')}"
            :name="'judge_decline_reason'"
            :disabled="disabled"
          ></textarea>
          <p v-if="errors.has('judge_decline_reason')" class="invalid-feedback">
            謝絶理由を記入して下さい
          </p>
          <br />
          <EmailPreviewTemplateForDeclined
            :heading="'謝絶メール・プレビュー：'"
            :full-name="fullName"
            :content="judge_decline_reason" />
        </div>
        <label class="mt-2">備考欄：</label>
        <textarea
          class="form-control"
          style="height:150px;"
          v-model="judge_note"
          :disabled="disabled"
        ></textarea>
        <button
          class="btn btn-secondary btn-block mt-2"
          :disabled="!canSave"
          @click="onClickJudgedTrustdock">
          保存
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped>
#duplicateMainbankFlg {
  display: table;
  padding: 3px 4px;
  margin-top: 2px;
}
.has-error.invalid-feedback {
  display: block;
  text-align: right;
  margin-top: 10px 0 0 0;
}
</style>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'
import ToggleHeader  from './parts/ToggleHeader.vue';
import EmailPreviewTemplateForDeclined  from './parts/EmailPreviewTemplateForDeclined.vue';
import EmailPreviewTemplateForJudgeAdditional  from './parts/EmailPreviewTemplateForJudgeAdditional.vue';


  const JUDGING = 0;  // 審査前
  const APPROVED = 1; // 承認
  const DECLINED = 2; // 謝絶
  const JUDGE_ADDITIONAL_EMAIL_DELIVERY = 3; // 保存してメール送信

  export default {
    name: 'JudgeTrustdock',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'judgeResults',
        'fullName',
        'duplicateMainbankUserID'
      ]),
    canSave() {
      return (this.judge_result === APPROVED && this.checkedItems.length === this.checkList.length
      && this.isValidExpireDate(this.expireDate))
        || this.judge_result === JUDGING
        || this.judge_result === DECLINED
        || this.judge_result === JUDGE_ADDITIONAL_EMAIL_DELIVERY;
    },
      expireDate () {
        return moment(this.year + '-' + this.month + '-' + this.day, 'YYYY-M-D', true);
      },
    },
    components: {
      ToggleHeader,
      EmailPreviewTemplateForDeclined,
      EmailPreviewTemplateForJudgeAdditional,
    },
  methods: {
    onClickJudgedTrustdock() {
      this.$validator.validate().then((result) => {
        let judgeAdditionalExpiredDate = '';
        if (result) {
          console.log(this.judge_additional_expired_date);
          if (this.judge_result === JUDGING || this.judge_result === JUDGE_ADDITIONAL_EMAIL_DELIVERY) {
            this.$validator.errors.remove('judge_additional_expired_date');
            if (!this.judge_additional_expired_date) {
              this.$validator.errors.add({
                field: 'judge_additional_expired_date',
                msg: '補助期限日を入力ください。'
              });
              return;
            }

            judgeAdditionalExpiredDate = moment(this.judge_additional_expired_date);
            if (!judgeAdditionalExpiredDate.isValid()) {
              this.$validator.errors.add({
                field: 'judge_additional_expired_date',
                msg: '有効な日付を入力してください。'
              });
              return;
            }
            if (judgeAdditionalExpiredDate.isBefore(moment().startOf('day'))) {
              this.$validator.errors.add({
                field: 'judge_additional_expired_date',
                msg: '謝絶期限が過去日入力しないでください'
              });
              return;
            }
          }
          const updateProcessData = {
            process: this.process,
            method: this.getJudgeMethod(this.judge_result),
            approved: this.judge_result === APPROVED,
            judge_note: this.judge_note,
            judge_decline_reason: this.judge_decline_reason,
            judge_additional_reason: this.judge_additional_reason,
            updated_at: this.$store.state.application.UpdatedAt,
            expiring: (this.year && this.month && this.day) ? this.year + '-' + this.month + '-' + this.day : '',
          };

          if (judgeAdditionalExpiredDate) {
            updateProcessData.judge_additional_expired_date = judgeAdditionalExpiredDate.format('YYYY-MM-DD');
          }
          if (confirm('保存してもよろしいでしょうか？')) {
            this.disabled = true;
            this.$store.dispatch('updateProcess', {
              data: updateProcessData
            });
          }
        }
      });
    },
    getJudgeMethod(judgeResult) {
      if (judgeResult === JUDGING) {
        return 'saved';
      }

      if (judgeResult === JUDGE_ADDITIONAL_EMAIL_DELIVERY) {
        return 'judge_additional_email_delivery';
      }

      return 'judged';
    },
    isValidExpireDate (date) {
      // Check valid date and must be after today
      return date.isValid() && date.isAfter(moment());
    }
  },
  data() {
    let p = this.$options.name;
    let g = this.$store.getters;
    const isAdditionalReasonUpdated = this.$store.getters.judgeAdditionalReason !== '';
    const judge_results = [{
      id: 0,
      name: '保存',
    }];
    if (!isAdditionalReasonUpdated) {
      judge_results.push({
        id: 3,
        name: '保存してメール送信',
      })
    }
    judge_results.push( {
      id: 1,
      name: '承認',
    },
    {
      id: 2,
      name: '謝絶',
    });
     
    return {
      process :                      p,
      disabled :                     !this.$store.getters.isActive(p),
      active :                       this.$store.getters.isActive(p),
      judge_results:                 judge_results,
      judge_result:                  this.$store.getters.judgeResult,
      judge_decline_reason:          this.$store.getters.judgeDeclineReason,
      judge_note:                    this.$store.getters.judgeNote,
      judge_result_input_name:       this.process + '_judge_result',
      judge_additional_expired_date: this.$store.getters.judgeAdditionalExpiredDate,
      judge_additional_reason:       this.$store.getters.judgeAdditionalReason,
      is_additional_reason_updated:  isAdditionalReasonUpdated,
      service_url: this.$store.getters.serviceUrl,
      checkList: document.CHECK_LIST,
      checkedItems: document.CHECKED_ITEMS,
      inputDateValid:  true,
      years :     g.calendarList('year'),
      months :    g.calendarList('month'),
      days :      g.calendarList('day'),
      year :      g.certNumberExpiring('year'),
      month :     g.certNumberExpiring('month'),
      day :       g.certNumberExpiring('day'),
    };
  },
};
</script>
