var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"d-block card-header f15",class:{
    'bg-primary' : _vm.active,
    'bg-success' : _vm.active,
    'text-light' : _vm.active,
    'font-weight-bold' : _vm.active,
  },attrs:{"role":"button","data-toggle":"collapse","data-target":_vm.target,"aria-expanded":"true"},on:{"click":_vm.toggleChevron}},[_c('div',[_vm._v("\n    "+_vm._s(_vm.name)+"\n    "),_c('div',{staticClass:"card-header-actions"},[_c('span',{staticClass:"card-header-action btn-minimize",class:{
          'text-light' : _vm.active,
          'font-weight-bold' : _vm.active,
        }},[_c('span',{class:_vm.toggle})])])]),_vm._v(" "),_c('div',[(_vm.checked_by != '')?_c('small',{staticClass:"text-black-50"},[_vm._v("\n      担当："+_vm._s(_vm.checked_by)+"  日時："+_vm._s(_vm.checked_date)+"\n    ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }