export default function page () {
  const $f = $('#formMarkAsTransfered');
  $f.on('submit', (e) => {
    if (confirm('「送金手続き済み」にしてよろしいですか？（メールが送信されます）')) {
      $f.find('button').attr('disabled', true).text("送信中...");
      return true;
    }
    e.preventDefault();
    return false;
  });

  const reqTransferForm = document.getElementById('formMarkAsRequestTransfer');
  if (reqTransferForm) {
    reqTransferForm.addEventListener('submit', (e) => {
      if (confirm('振込依頼してよろしいですか？')) {
        const btn = reqTransferForm.querySelector('button');
        if (btn) {
          btn.setAttribute('disabled', true);
          btn.innerHTML = '送信中...';
        }
        return true;
      }
      e.preventDefault();
      return false;
    });
  }
};
