var GROUP_CORPORATION = 7;
export default (function () {
    var selectGroupId = document.querySelector('select[name=group_id]');
    if (selectGroupId) {
        selectGroupId.addEventListener('change', function ($event) {
            var companyNoInput = document.querySelector('#companyNo');
            var companyNoContainerElement = document.querySelector('#container-company-no');
            var groupId = Number($event.target.value);
            if (groupId === GROUP_CORPORATION) {
                companyNoContainerElement.style.display = 'block';
                companyNoInput.required = true;
            }
            else {
                companyNoContainerElement.style.display = 'none';
                companyNoInput.value = '';
                companyNoInput.required = false;
            }
        });
        var eventChange = new Event('change');
        selectGroupId.dispatchEvent(eventChange);
    }
});
