import { generateKey } from './Util'

export default {
  // Set DealRank from global to store
  initDealRank(state, payload) {
    // Construct user_ranks initial data
    let deal_ranks = [];
    payload.ranking_version.DealRanks.forEach((rank, i) => {
      deal_ranks.push({
        key:                  generateKey(),
        id:                   rank.ID,
        name:                 rank.Name,
        description:          rank.Description,
        auto_deal_limit:      rank.AutoDealLimit,
        manual_deal_limit:    rank.ManualDealLimit,
        rank1_up_cnt_of_deal: rank.Rank1UpCntOfDeal,
        rank2_up_cnt_of_deal: rank.Rank2UpCntOfDeal,
        is_valid: {},
      });
    });
    state.deal_ranks = deal_ranks;
  },
   
  // Update individual deal rank
  updateDealRank(state, payload) {
    state.deal_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        let val = payload.value;
        if (
          payload.attr === 'auto_deal_limit' 
          || payload.attr === 'manual_deal_limit'
          || payload.attr === 'rank1_up_cnt_of_deal'
          || payload.attr === 'rank2_up_cnt_of_deal'
        ) {
          val = parseInt(val, 10);
          if (isNaN(val)) {
            val = '';
          }
        }
        state.deal_ranks[i][payload.attr] = val;
      }
    });
  },
  
  // Update validation status of individual deal rank
  updateDealRankIsValid(state, payload) {
    state.deal_ranks.forEach((rank, i) => {
      if (rank.key == payload.key) {
        state.deal_ranks[i].is_valid[payload.attr] = payload.is_valid;
      }
    });
  },

};
