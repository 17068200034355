import Vue        from 'vue';
import Vuex       from 'vuex'
import mutations  from './mutations'
import getters    from './getters'
import actions    from './actions'

Vue.use(Vuex);

const state = {
  application: {},
  user: {},
  admin_users: [],
  login_user_id : 0,
  application_same_possibilities: [],
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
});
