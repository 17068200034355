var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators';
import axios from 'axios';
import moment from 'moment';
import store from '.';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contactInfo = {
            contact: {},
            adminUsers: [],
            loginUserId: 0
        };
        return _this;
    }
    Object.defineProperty(Main.prototype, "userList", {
        // Returns users list for selectbox options
        get: function () {
            var users = [];
            this.contactInfo.adminUsers.forEach(function (_a) {
                var ID = _a.ID, Name = _a.Name;
                users.push({
                    id: ID,
                    name: Name
                });
            });
            return users;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isActive", {
        // Get Statuc of process
        get: function () {
            var _this = this;
            return function (process) {
                if (process) {
                    var responsed = _this.contactInfo.contact.Responsed;
                    if (process === 'FirstResponse') {
                        return !responsed;
                    }
                    if (process === 'Complete') {
                        return responsed;
                    }
                }
                return false;
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "checkedBy", {
        // Return who did check the process
        get: function () {
            var _this = this;
            return function (process) {
                if (process === 'FirstResponse') {
                    var id_1 = _this.contactInfo.contact.ResponsedAdminUserID;
                    var found = _this.contactInfo.adminUsers.find(function (adminUser) { return adminUser.ID === id_1; });
                    if (found) {
                        return found.Name;
                    }
                }
                return '';
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "checkedDate", {
        // Return whed did the check has been done
        get: function () {
            var _this = this;
            return function (process) {
                moment.locale('ja');
                var frmt = 'YYYY-MM-DD HH:mm:ss'; // 2018-8-27 04:48:00
                if (process === 'FirstResponse') {
                    var responseDone = new Date(_this.contactInfo.contact.ResponsedDone);
                    if (responseDone) {
                        return responseDone.getFullYear() === 1 ? '-' : moment(responseDone).format(frmt);
                    }
                }
                return '-';
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "openingAccountStatus", {
        // Return the account opening process has been on process, sucusessfully done, or declined
        get: function () {
            var responsed = this.contactInfo.contact.Responsed;
            if (responsed) {
                return '対応済み';
            }
            return '対応中';
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.loadData = function (data) {
        this.contactInfo.contact = data.contact;
        this.contactInfo.adminUsers = data.adminUsers;
        this.contactInfo.loginUserId = data.loginUserId;
    };
    Main.prototype.updateProcess = function (_a) {
        var data = _a.data;
        return __awaiter(this, void 0, void 0, function () {
            var params, res, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = new URLSearchParams();
                        params.append('data', JSON.stringify(data));
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post("/apiInternal/updateContactProcess/" + this.contactInfo.contact.ID, params)];
                    case 2:
                        res = _b.sent();
                        if (res) {
                            location.reload();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadData", null);
    __decorate([
        Action
    ], Main.prototype, "updateProcess", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);
