<template>
  <div>
    <div class="d-due-date-update">
      <label>入金予定日:</label>
      <input
        type="date"
        class="form-control"
        v-model="dueDate"
        :disabled="disabled"
        :class="{'is-invalid': !isDueDateInvalid}"/>
      <p
        v-if="!isDueDateInvalid"
        class="invalid-feedback">
        入金予定日を正しく入力してください。
      </p>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DueDateUpdate',
  props: {
    deal: Object,
    disabled: Boolean
  },
  data() {
    const dueDate = moment.tz(this.deal.DueDate, 'Asia/Tokyo').format('YYYY-MM-DD');
    return {
      dueDate,
      isDueDateInvalid: true
    };
  },
  watch: {
    dueDate(newDate) {
      this.isDueDateInvalid = true;
      const newDueDate = moment(newDate, 'YYYY-MM-DD');
      if (!newDueDate.isValid()) {
        this.isDueDateInvalid = false;
      }
      this.$set(this.deal, 'DueDate', newDate);
    }
  }
};
</script>
