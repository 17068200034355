import axios from 'axios';

export default {
  // Ajax request of serialized table-jorm params
  save({ commit, getters }, opt) {
    opt = opt || {};
    commit('setUncheckedTranRecordIds');
    const transactions = getters.getSendingValue;

    if (transactions.length > document.ACCOUNT_NUMBER_PER_REQUEST) {
      alert(`一度に送金できるユーザは${document.ACCOUNT_NUMBER_PER_REQUEST}件までです。`);
      return;
    }

    for (const transaction in transactions) {
      if (transaction.targeted_amount < 0) {
        alert('金額が0円以下の送金対象が存在してます。');
        return;
      }
    }

    function onSuccess (res) {
      if (opt.onSuccess) {
        opt.onSuccess(res);
        return;
      }
      if (res.data.status !== 200) {
        onError(res);
        return;
      }
      location.href = '/outcomings/view/' + res.data.result;
    }

    axios.post('/apiInternal/createOutcoming', serialize({ ...opt.data, transactions, addingMode: document.ADDING_MODE }))
      .then((res) => onSuccess(res))
      .catch(onError);
  },

  saveAsTransferSucceeded({}, opt) {

    function onSuccess (res) {
      if (opt.onSuccess) {
        opt.onSuccess(res);
        return;
      }
      if (res.data.status !== 200) {
        onError(res);
        return;
      }
      location.reload();
    }

    axios.post('/apiInternal/markTransactionAsSucceeded/'+opt.id, {})
      .then((res) => onSuccess(res))
      .catch(onError);
  },
  updateOutcomingInfo({}, opt) {
    function onSuccess (res) {
      if (opt.onSuccess) {
        opt.onSuccess(res);
        return;
      }
      if (res.data.status !== 200) {
        onError(res);
        return;
      }
    }
    axios.post('/apiInternal/updateOutcomingInfo/'+opt.id, serialize(opt.data))
      .then((res) => onSuccess(res))
      .catch(onError);
  },

  saveRequestGanbTransferForOutcomingRecord({}, opt) {
    function onSuccess(res) {
      if (opt.onSuccess) {
        opt.onSuccess();
      }

      if (res.data.status !== 200) {
        opt.onError();
        onError(res);
        return;
      }
      location.reload();
    }

    axios.post('/apiInternal/requestGanbTransferForOutcomingRecord/'+opt.id, serialize(opt.data))
      .then((res) => onSuccess(res))
      .catch(() => {
        opt.onError();
        onError();
      });
  },

  saveMarkAsGanbTransferFailed({}, opt) {
    function onSuccess(res) {
      if (opt.onSuccess) {
        opt.onSuccess();
      }

      if (res.data.status !== 200) {
        opt.onError();
        onError(res);
        return;
      }
      location.reload();
    }

    axios.post('/apiInternal/markAsGanbTransferFailed/'+opt.id, {})
      .then((res) => onSuccess(res))
      .catch(() => {
        opt.onError();
        onError(res);
      });
  },
};

function serialize (data) {
  const params = new URLSearchParams();
  params.append('data', JSON.stringify(data));
  return params;
}

function onError (res) {
  console.error(res);
  alert("'エラーが発生しました、恐れ入りますが以下のメッセージかスクリーンショットを開発者にお送り下さい\n" + res.data.message);
}
