import moment from 'moment'
moment.locale('ja');

const currency = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY'
});

const date = function(val) {
  return moment(val).format('YYYY-MM-DD');
};

const datetime = function(val) {
  return moment(val).format('YYYY-MM-DD HH:mm:ss');
};

const dayDiff = function(from) {
  let fromMoment = moment(from);
  let nowMoment = moment(new Date());
  return -fromMoment.diff(nowMoment, 'days');
};

const hourDiff = function(from) {
  let fromMoment = moment(from);
  let nowMoment = moment(new Date());
  return -fromMoment.diff(nowMoment, 'hours');
};

const confDatePicker = {
  format: 'yyyy-MM-dd',
  language: {
    language: 'Japanese',
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    days: ['日', '月', '火', '水', '木', '金', '土'],
    rtl: false,
    ymd: 'yyyy-MM-dd',
    yearSuffix: '年'
  }
};

export default {
  currency: currency.format,
  date: date,
  datetime: datetime,
  dayDiff: dayDiff,
  hourDiff: hourDiff,
  confDatePicker
};
