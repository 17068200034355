<template>
  <div class="card">
    <div class="card-header text-center">
      <TabSelector></TabSelector>
    </div>
    <div class="card-body">
      <div class="tab-content border-0">
        <TabPaneSimulation            :no="0"></TabPaneSimulation>
        <TabPaneRankingVersion        :no="1"></TabPaneRankingVersion>
        <TabPaneUserScoring           :no="2"></TabPaneUserScoring>
        <TabPaneCorporationScoring    :no="3"></TabPaneCorporationScoring>
        <TabPaneDealRanking           :no="4"></TabPaneDealRanking>
        <TabPaneDealClustering        :no="5"></TabPaneDealClustering>
        <TabPaneCommission            :no="6"></TabPaneCommission>
        <TabPaneCommissionClustering  :no="7"></TabPaneCommissionClustering>
      </div>
    </div>
  </div>
</template>

<script>
  import TabSelector                  from './TabSelector.vue';
  import TabPaneSimulation            from './Tab1Simulation/Main.vue';
  import TabPaneRankingVersion        from './Tab2RankingVersion/Main.vue';
  import TabPaneUserScoring           from './Tab3UserScoring/Main.vue';
  import TabPaneCorporationScoring    from './Tab4CorporationScoring/Main.vue';
  import TabPaneDealRanking           from './Tab5DealRaning/Main.vue';
  import TabPaneDealClustering        from './Tab6DealClustering/Main.vue';
  import TabPaneCommission            from './Tab7Commission/Main.vue';
  import TabPaneCommissionClustering  from './Tab8CommissionClustering/Main.vue';

  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import VeeValidate, { Validator } from 'vee-validate'
  import ja from '../../../../node_modules/vee-validate/dist/locale/ja'

  Validator.localize('ja', ja);
  Vue.use(VeeValidate, { locale: ja });

  export default  {
    name: 'App',
    components: {
      TabSelector,
      TabPaneRankingVersion,
      TabPaneUserScoring,
      TabPaneCorporationScoring,
      TabPaneDealRanking,
      TabPaneDealClustering,
      TabPaneCommission,
      TabPaneCommissionClustering,
      TabPaneSimulation,
    },
    created: function () {
      this.$store.commit('initRankingVersion', {
        id: document.ID,
        ranking_version                   : document.RANKING_VERSION,
        master_user_evidence_types        : document.MASTER_USER_EVIDENCE_TYPES,
        master_corporation_evidence_types : document.MASTER_CORPORATION_EVIDENCE_TYPES,
      });
      this.$store.commit('initUserScoreAndRank', {
        ranking_version:            document.RANKING_VERSION,
        master_user_evidence_types: document.MASTER_USER_EVIDENCE_TYPES,
      });
      this.$store.commit('initCorporationScoreAndRank', {
        ranking_version:                    document.RANKING_VERSION,
        master_corporation_evidence_types:  document.MASTER_CORPORATION_EVIDENCE_TYPES,
      });
      this.$store.commit('initDealRank', {
        ranking_version:  document.RANKING_VERSION,
      });
      this.$store.commit('initDealClustering', {
        ranking_version:  document.RANKING_VERSION,
      });
      this.$store.commit('initCommission', {
        ranking_version:                document.RANKING_VERSION,
        master_delivery_evidence_types: document.MASTER_DELIVERY_EVIDENCE_TYPES,
      });
      this.$store.commit('initCommissionRange', {
        ranking_version:  document.RANKING_VERSION,
      });
      this.$store.commit('initCommissionClustering', {
        ranking_version:  document.RANKING_VERSION,
      });
    }
  };
</script>
