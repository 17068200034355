<template>
  <li class="list-group-item pt-0 pb-0" :class="activeRow" v-if="tran._shown">
    <div class="row">
      <div class="col-12">
        <div class="row boxRowRecord">
          <div class="col form-check">
            <input type="checkbox" class="form-check-input" v-model="tran._checked" v-if="isAddMode" :disabled="!tran.isValid || !tran.targetable">
            <a :href="'/users/view/' + tran.User.ID" target="_blank">
              {{tran.User.FullName}}
              <span v-if="tran.User.IsDisabledOutcoming">(振込停止中)</span>
            </a>
          </div>

          <div class="col">
            {{tran.User.BankName}}
          </div>
          
          <template v-if="isViewMode">
            <div class="col">
              <template v-if="tran._failed">
                <span class="badge badge-danger">送金失敗</span>
              </template>
              <template v-else>
                <span class="badge badge-info">送金成功想定</span>
              </template>
            </div>
          </template>

          <div class="col" v-if="isAddMode">
            <template v-if="tran._targeted">
              <span class="badge badge-success">処理対象</span>
            </template>
            <template v-else>
              <span class="badge badge-secondary">対象外</span>
            </template>
          </div>
          <div class="col">
            {{fmt.datetime(tran.CreatedAt)}}
            {{ '(' + fmt.dayDiff(tran.CreatedAt) + '日)' }}
          </div>
          <div class="col"  v-if="containsInstantTransferRecords">
            <template v-if="tran._instantTransferAppliedAt">
              {{fmt.datetime(tran._instantTransferAppliedAt)}}
              {{ '(' + fmt.hourDiff(tran._instantTransferAppliedAt) + '時間)' }}
            </template>
            <template v-else>
              -
            </template>
          </div>
          <div class="col">
            <span class="badge" :class="type.LabelClass" v-for="type in tran.Types">{{type.Type}}</span>
          </div>
          <div class="col">
            <template v-if="tran.Collectionable">
              <template v-if="tran.CollectionableDeadline">
                <span class="badge badge-danger">あり(期日到来)</span>
              </template>
              <template v-else-if="tran.Collectionable15DaysDeadline">
                <span class="badge badge-warning">あり(15日以内期日)</span>
              </template>
              <template v-else-if="tran.Collectionable && !(tran.CollectionableDeadline || tran.Collectionable15DaysDeadline)">
                <span class="badge badge-info">あり</span>
              </template>
              <template v-else>
                <span class="badge badge-secondary">なし</span>
              </template>
            </template>
            <template v-else>
              <span class="badge badge-secondary">なし</span>
            </template>
          </div>
          <div class="col">
            {{fmt.currency(calcTransactionAmount(tran))}}
          </div>
          <template v-if="isViewMode">
            <div class="col">{{showSendingDate}}</div>
          </template>
          <template v-if="isViewMode">
            <div
              v-html="showMasterGanbTransferStatusTitle"
              class="col" />
          </template>
          <div class="col-1 boxDetailButton">
            <button type="button" @click="toggle" class="btn btn-secondary btn-sm btn-block">
              <span 
                :class="{ 'cui-chevron-bottom' : !openDetail, 'cui-chevron-top' : openDetail }"
              ></span>
              {{!openDetail ? "詳細" : "閉じる"}}
            </button>
          </div>
        </div>
      </div>
      <RowDetail :tran="tran" :openDetail="openDetail"></RowDetail>
    </div>
  </li>
</template>

<style scoped>
.boxRowRecord > .col {
  padding-top:.25rem;
}
.boxDetailButton {
  padding-right:0;
  padding-top:.25rem;
  padding-bottom:.25rem;
}
.border-2 {
  border:2px solid;
}
.bg-open {
  background-color:#e9f7fc!important;
}
.bg-open-targeted {
  background-color:#ddf2e4!important;
}
</style>

<script>
  import moment from 'moment'
  moment.locale('ja');
  import { mapGetters } from 'vuex'
  import RowDetail from './RowDetail.vue';
  import fmt from '../util.js'
  export default {
    name: 'Row',
    props: [
      'tran',
      'index',
    ],
    computed: {
      ...mapGetters([
        'calcTransactionAmount',
        'isAddMode',
        'isViewMode',
        'containsInstantTransferRecords',
      ]),
      activeRow() {
        return {
          'bg-light' : !this.openDetail && this.index % 2 === 0,
          'bg-open' : this.openDetail && !this.tran._targeted,
          'bg-open-targeted' : this.tran._targeted,
          'border-primary' : this.openDetail && !this.tran._targeted, 
          'border-success' : this.openDetail && this.tran._targeted, 
          'border-2' : this.openDetail,
        };
      },
      showMasterGanbTransferStatusTitle() {
        return this.outcomingRecord.MasterGanbTransferStatusID !== 0 ? this.getGanbTransferContent : '-';
      },
      getGanbTransferContent() {
        let htlmElement = '';
        switch(this.outcomingRecord.MasterGanbTransferStatusID) {
          case this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFERRING:
          case this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_API_ERROR:
          case this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_FAILED:
          case this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFERED:
            if (moment(this.outcomingRecord.GanbTransferRequestedAt).format('YYYY/MM/DD') !== '0001/01/01') {
              htlmElement = this.getHTMLForTransferGanbInfo;
            } else {
              htlmElement = this.getHTMLForOnlyApplyNo;
            }
            break;
          case this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_WHITE_LIST_ERROR:
            htlmElement = this.getHTMLForAddWhiteListError;
            break;
          default:
            htlmElement = this.getHTMLForOnlyGanbTransferStatusTitle;
        }

        return htlmElement;
      },
      getHTMLForOnlyApplyNo() {
        return `<span style="word-break: break-all;">申請番号：${this.outcomingRecord.GanbTransferApplyNo}</span>`;
      },
      getHTMLForOnlyGanbTransferStatusTitle() {
        return `<span style="word-break: break-all;">${this.outcomingRecord.MasterGanbTransferStatus.Title}</span>`;
      },
      getHTMLForAddWhiteListError() {
        let htmlText = `<span style="word-break: break-all;">${this.outcomingRecord.MasterGanbTransferStatus.Title}</span>`;
        if (this.outcomingRecord.GanbTransferRequestError !== '') {
          htmlText = `<a
                  class="cursor-text"
                  href="javascript:void(0)"
                  data-toggle="tooltip"
                  data-html="true" 
                  data-placement="top"
                  title="<div class='text-left'>
                    エラー情報:<br>
                    ${this.outcomingRecord.GanbTransferRequestError}
                  </div>">${this.outcomingRecord.MasterGanbTransferStatus.Title}</a>`;
        }

        return htmlText;
      },
      getHTMLForTransferGanbInfo() {
        return `<a class="cursor-text"
                  href="javascript:void(0)"
                  data-toggle="tooltip"
                  data-html="true"
                  data-placement="top"
                  title="<div class='text-left'>
                    ${this.showGanbTransferRequestedAt}<br>
                    ${this.showGanbTransferExecutedAt}
                    ${this.showGanbTransferApplyNo}
                    ${this.showGanbTransferError}
                  </div>">${this.outcomingRecord.MasterGanbTransferStatus.Title}</a>`;
      },
      showGanbTransferRequestedAt() {
        return moment(this.outcomingRecord.GanbTransferRequestedAt).format('YYYY/MM/DD') === '0001/01/01' ? '依頼日時: -' :
          `依頼日時: ${moment(this.outcomingRecord.GanbTransferRequestedAt).format('YYYY/MM/DD HH:mm:ss')}`;
      },
      showGanbTransferExecutedAt() {
        return moment(this.outcomingRecord.GanbTransferExecutedAt).format('YYYY/MM/DD') === '0001/01/01' ? '実行日時: -' :
          `実行日時: ${moment(this.outcomingRecord.GanbTransferExecutedAt).format('YYYY/MM/DD HH:mm:ss')}`;
      },
      showGanbTransferApplyNo() {
        return this.isDisplayableApplyNo ? `<br>申請番号: ${this.outcomingRecord.GanbTransferApplyNo}` : '';
      },
      showGanbTransferError() {
        let htmlText = '';

        if (this.outcomingRecord.GanbTransferRequestError !== '') {
          htmlText = `<br>振り込みエラー： ${this.outcomingRecord.GanbTransferRequestError}`;
        } else if (this.outcomingRecord.MasterGanbTransferStatusID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_FAILED) {
          htmlText = '<br>振り込みエラー： 銀行管理画面を確認してください';
        }

        return htmlText;
      },
      isDisplayableApplyNo() {
        return (this.outcomingRecord.GanbTransferApplyNo !== ''
          && (this.outcomingRecord.MasterGanbTransferStatus.ID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFERED
          || this.outcomingRecord.MasterGanbTransferStatus.ID === this.masterGanbTransferStatus.MASTER_GANB_TRANSFER_STATUS_TRANSFER_FAILED));
      },
      showSendingDate() {
        return moment(this.outcomingRecord.SendingDate).format('YYYY/MM/DD') === '0001/01/01' ? '-' :
          moment(this.outcomingRecord.SendingDate).format('YYYY/MM/DD');
      },
    },
    components: {
      RowDetail,
    },
    methods: {
      toggle() {
        this.openDetail = this.openDetail ? false: true;
      },
    },
    created() {
      if (this.isViewMode && this.$store.state.outcoming) {
        this.outcomingRecord = this.$store.state.outcoming.OutcomingRecords.find(OutRec => OutRec.ID === this.tran.OutcomingRecordID);
      }
    },
    data() {
      return {
        openDetail : false,
        fmt: fmt,
        outcomingRecord: null,
        masterGanbTransferStatus: this.$store.state.masterGanbTransferStatus,
      };
    },
  };
</script>
