<template>
  <div class="form-check">
    <label class="form-check-label">
      <input class="form-check-input" type="checkbox" v-bind:checked="checked" :disabled="disabled" v-on:change="$emit('change', $event.target.checked)">
        <span v-if="!isCheckEvidence"> {{ label }} が <span class="text-info font-weight-bold">{{ correct }}</span> か？ </span>
        <span v-else> {{ label }} </span>
    </label>
    <div v-if="masterLinkingServiceID || autoCheckEnabled">
      (自動チェック:
      <span class="badge" :class="{ 'badge-success' : isMatched, 'badge-danger' : !isMatched }">
        {{ isMatched ? 'マッチ' : 'マッチせず' }}
      </span>)
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckListItem',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    label: String,
    correct: String,
    checked: Boolean,
    disabled: Boolean,
    isMatched: Boolean,
    isCheckEvidence: Boolean,
    masterLinkingServiceID: Number,
    autoCheckEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
};
</script>
