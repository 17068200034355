<template>
  <div class="card border-secondary">
    <div class="card-header bg-secondary border-light d-flex">
      <h5 class="f14 mr-auto b">
        対象データ
      </h5>
      <div>
        <a
          href="#"
          class="badge badge-light"
          @click="onResetFilter">
          フィルターリセット
        </a>
      </div>
    </div>
    <div class="card-body bg-light border-secondary">
      <template v-if="checkedAll">
        <template v-if="cntSelectedTransactions > 0">
          選択された{{ cntCheckedTransactions }}件のデータを
          <button
            class="btn btn-sm btn-success"
            @click="onClickSelectionsTargeted">
            処理対象
          </button>
          <button
            class="btn btn-sm btn-secondary"
            @click="onClickSelectionsUntargeted">
            対象外
          </button>に変更
          <template v-if="cntRecords != cntCheckedTransactions">
            <span class="text-danger">
              （選択されない一部のユーザーは口座番号の桁数に問題があり対象に選択できません）
            </span>
          </template>
        </template>
        <template v-else>
          <span class="text-danger">
            （すべてのユーザーが口座番号の桁数に問題があり対象に選択できません）
          </span>
        </template>
      </template>
    </div>
    <ul class="list-group">
      <li class="list-group-item bg-light border-secondary rounded-0 border-left-0 border-right-0">
        <div class="row">
          <div class="col form-check">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="checkedAll"
              v-if="isAddMode">
            <span class="b">氏名</span>
          </div>

          <div class="col">
            <span class="b">メインバンク</span>
          </div>

          <template v-if="isViewMode">
            <div class="col">
              <span class="b">ステータス</span>
            </div>
          </template>

          <div
            class="col form-check"
            v-if="isAddMode">
            <span class="b">対象/対象外</span>
            <div>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondTargeted">
                <span class="badge badge-success">処理対象</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondUntargeted">
                <span class="badge badge-secondary">対象外</span>
              </label>
            </div>
          </div>
          <div class="col">
            <span class="b">発生日時(経過)</span>
          </div>
          <div class="col" v-if="containsInstantTransferRecords">
            <span class="b">今すぐ振替申請日時(経過)</span>
          </div>
          <div class="col">
            <span class="b">発生タイプ</span>
            <div>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondIncoming">
                <span class="badge badge-primary">入金</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondQuickPay">
                <span class="badge badge-warning">即日払い</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondRetryFail">
                <span class="badge badge-secondary">送金失敗分再送金</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondRetryStop">
                <span class="badge badge-secondary">凍結分再送金</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondInstantTransferFee">
                <span class="badge badge-secondary">今すぐ振替手数料</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondCommissionCashBack">
                <span class="badge badge-success">手数料キャッシュバック</span>
              </label>
            </div>
          </div>
          <div class="col">
            <span class="b">回収予定</span>
            <div>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondDeadlineHasCome">
                <span class="badge badge-danger">あり(期日到来)</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondDeadline15Days">
                <span class="badge badge-warning">あり(15日以内期日)</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondDeadlineIsComming">
                <span class="badge badge-info">あり</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondDeadlineNone">
                <span class="badge badge-secondary">なし</span>
              </label>
            </div>
          </div>
          <div class="col">
            <span class="b">送金合計</span>
            <div v-if="isAddMode">
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondIncomingAmountMoreThanTwoMillionYen">
                <span class="badge">(個別) 200万円以上</span>
              </label>
              <label class="d-block">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="filters.checkedCondIncomingAmountLessThanTwoMillionYen">
                <span class="badge">(個別) 0円以上200万円未満</span>
              </label>
            </div>
          </div>
          <template v-if="isViewMode">
            <div class="col">
              <span class="b">送金予定日</span>
            </div>
          </template>
          <template v-if="isViewMode">
            <div class="col">
              <span class="b">振込状態</span>
            </div>
          </template>
          <div class="col-1">
            <span class="b">詳細</span>
            <div v-if="isAddMode">
              <label
                v-for="(corporationBankAccount, key) in corporationBankAccountFilters"
                class="d-block"
                :key="key"
                :index="key">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="checkedBankAccountFilters[key]">
                <span class="badge">{{ corporationBankAccount.Corporation.Name }}</span>
              </label>
            </div>
          </div>
        </div>
      </li>
      <template v-if="cntRecords > 0">
        <Row
          v-for="(tran, index) in transactions"
          :key="tran.ID"
          :tran="tran"
          :index="index" />
      </template>
      <template v-else>
        <li class="list-group-item text-center p-3">
          マッチするレコードが存在しません
        </li>
      </template>
    </ul>
    <!-- ##### FOOTER FOR ADD MODE ##### -->
    <template v-if="isAddMode && cntRecords > 0">
      <div class="card-footer bg-light p-3 text-center border-top-0">
        <p>
          対象{{ cntTargeted }}人、合計{{ fmt.currency(amountTargeted) }}の送金データが作成されます
        </p>
        <div class="my-2">
          <template
            v-if="isCollectTargeted">
            <span class="text-muted b">回収予定日：</span>
            <Datepicker
              v-model="collectingDate"
              :format="confDatePicker.format"
              :language="confDatePicker.language"
              name="datepicker" />
          </template>
          <span class="text-muted b">送金予定日：</span>
          <Datepicker
            v-model="sendingDate"
            :format="confDatePicker.format"
            :language="confDatePicker.language"
            name="datepicker" />
        </div>
        <button
          class="btn btn-lg btn-primary"
          :disabled="cntTargeted === 0 || this.$store.state.isProcessingApiRequest"
          @click="onClickSave">
          {{ this.$store.state.isProcessingApiRequest ? '送信中...' : '送金データを作成する' }}
        </button>
      </div>
    </template>
    <!-- ##### FOOTER FOR VIEW MODE ##### -->
    <template v-else-if="isViewMode && isAllowTransferRequestOutcoming">
      <div class="card-footer bg-light p-3 text-center border-top-0">
        <template v-if="this.$store.state.isMasterOutcomingStatusFixed">
          <div class="my-2">
            <template v-if="hasCollection">
              <span class="text-muted b">回収予定日：</span>
              <Datepicker
                v-model="collectingDate"
                :format="confDatePicker.format"
                :language="confDatePicker.language"
                name="datepicker" />
            </template>
            <span class="text-muted b">送金予定日：</span>
            <Datepicker
              v-model="sendingDate"
              :format="confDatePicker.format"
              :language="confDatePicker.language"
              name="datepicker" />
            <button
              class="btn btn-sm btn-primary"
              @click="onClickUpdateOutcoming"
              :disabled="updateOutcoming">
              {{ updateOutcoming ? '送信中..' : '更新' }}
            </button>
            <div
              v-if="isSendingDatePast"
              class="alert alert-danger text-center b mt-2">
              <span
                class="cui-warning"
                aria-hidden="true" />
              「送金予定日」が過去の日付です、本日このデータを送金する場合は送金予定日を更新してから
              <span v-if="this.$store.state.isGanbAPIConnected">振込依頼</span>
              <span v-else>総振データをDL</span>
              して下さい
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.vdp-datepicker, .vdp-datepicker div {
  display:inline-block;
}
label {
  margin-bottom:0;
  cursor:pointer;
}
.inputCollectedDate {
  position: relative !important;
  margin-left: 0 !important;
}
</style>

<script>
import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import { mapGetters } from 'vuex';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import Row from './Row.vue';
import fmt from '../util.js';
import ja from '../../../../node_modules/vee-validate/dist/locale/ja';

moment.locale('ja');

Validator.localize('ja', ja);
Vue.use(VeeValidate, { locale: ja });

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'transactions',
      'cntCheckedTransactions',
      'cntSelectedTransactions',
      'isCollectTargeted',
      'cntTargeted',
      'amountTargeted',
      'isAddMode',
      'isViewMode',
      'getSendingValue',
      'containsInstantTransferRecords',
    ]),
    cntRecords() {
      const tc = this.$store.getters.transactions.filter((t) => t._shown);
      return tc.length;
    },
    isSendingDatePast() {
      const today = new Date();
      return moment(this.sendingDate).add('days', 1).isBefore(today);
    },
    hasCollection() {
      return this.$store.getters.transactions.some(
        transaction => transaction.TransactionRecords
                && transaction.TransactionRecords.some(record => record.CollectionID > 0)
      );
    }
  },
  watch: {
    checkedAll(newVal) {
      if (newVal) {
        this.$store.commit('checkByMaxRecords', { maxRecords: document.ACCOUNT_NUMBER_PER_REQUEST });
      } else {
        this.$store.commit('uncheckAll');
      }
    },
    checkedBankAccountFilters(val) {
      if (val.some(v => v === true)) {
        if (this.filters.checkedCondIncoming && this.filters.checkedCondDeadlineNone) {
          this.applyFilter();
        }
        if (!this.filters.checkedCondIncoming) {
          this.filters.checkedCondIncoming = true;
        }

        if (!this.filters.checkedCondDeadlineNone) {
          this.filters.checkedCondDeadlineNone = true;
        }
      } else {
        this.applyFilter();
      }
    },
    'filters.checkedCondTargeted': function () { this.applyFilter(); },
    'filters.checkedCondUntargeted': function () { this.applyFilter(); },
    'filters.checkedCondIncoming': function (newVal) {
      if (!newVal) {
        this.$store.commit('uncheckAll');
        this.filters.checkedCondIncomingAmountLessThanTwoMillionYen = false;
        this.filters.checkedCondIncomingAmountMoreThanTwoMillionYen = false;
        for (let i = 0; i < this.checkedBankAccountFilters.length; i++) {
          this.checkedBankAccountFilters[i] = false;
        }
      } else {
        this.applyFilter();
      }
    },
    'filters.checkedCondQuickPay':           function () { this.applyFilter(); },
    'filters.checkedCondRetryFail':          function () { this.applyFilter(); },
    'filters.checkedCondRetryStop':          function () { this.applyFilter(); },
    'filters.checkedCondInstantTransferFee': function () { this.applyFilter(); },
    'filters.checkedCondDeadlineHasCome':    function () { this.applyFilter(); },
    'filters.checkedCondDeadline15Days':     function () { this.applyFilter(); },
    'filters.checkedCondDeadlineIsComming':  function () { this.applyFilter(); },
    'filters.checkedCondDeadlineNone':       function (newVal) {
      if (!newVal) {
        this.$store.commit('uncheckAll');
        this.filters.checkedCondIncomingAmountLessThanTwoMillionYen = false;
        this.filters.checkedCondIncomingAmountMoreThanTwoMillionYen = false;
        for (let i = 0; i < this.checkedBankAccountFilters.length; i++) {
          this.checkedBankAccountFilters[i] = false;
        }
      } else {
        this.applyFilter();
      }
    },
    'filters.checkedCondCommissionCashBack': function () { this.applyFilter(); },
    'filters.checkedCondIncomingAmountMoreThanTwoMillionYen': function (newVal) {
      if (newVal) {
        if (this.filters.checkedCondIncoming && this.filters.checkedCondDeadlineNone) {
          this.applyFilter();
        }
        
        if (!this.filters.checkedCondIncoming) {
          this.filters.checkedCondIncoming = true;
        }

        if (!this.filters.checkedCondDeadlineNone) {
          this.filters.checkedCondDeadlineNone = true;
        }
      } else {
        this.applyFilter();
      }
    },
    'filters.checkedCondIncomingAmountLessThanTwoMillionYen': function (newVal) {
      if (newVal) {
        if (this.filters.checkedCondIncoming && this.filters.checkedCondDeadlineNone) {
          this.applyFilter();
        }
        
         if (!this.filters.checkedCondIncoming) {
          this.filters.checkedCondIncoming = true;
         }

         if (!this.filters.checkedCondDeadlineNone) {
          this.filters.checkedCondDeadlineNone = true;
         }
      } else {
        this.applyFilter();
      }
    },
  },
  components: {
    Datepicker,
    Row
  },
  methods: {
    onClickUpdateOutcoming() {
      if (!this.checkSendingDate()) {
        return;
      }

      this.updateOutcoming = true;
      const updateOutcomingData = {};
      // 送金予定日が設定されている場合は送金予定日を更新する
      if (this.sendingDate) {
        updateOutcomingData.sending_date = moment(this.sendingDate).format('YYYY-MM-DD');
      }
      // 回収予定日が設定されている場合は回収予定日を更新する
      if (this.collectingDate && this.hasCollection) {
        updateOutcomingData.collecting_date = moment(this.collectingDate).format('YYYY-MM-DD');
      }

      this.$store.dispatch('updateOutcomingInfo', {
        id: this.$store.state.outcoming.ID,
        data: updateOutcomingData,
        onSuccess: () => {
          this.updateOutcoming = false;
          alert('更新が完了しました');
        }
      });
    },
    applyFilter() {
      this.checkedAll = false;
      this.$store.commit('uncheckAll');
      this.$store.commit('applyFilter');
    },
    onResetFilter() {
      this.$store.commit('uncheckAll');
      this.$store.commit('resetFilter');
      this.checkedAll = false;
      this.$store.commit('updateCheckAllTransactionRecord', { checkedAll: true });
    },
    onClickSelectionsTargeted() {
      this.checkedAll = false;
      this.$store.commit('makeSelectionsTargeted', { getters: this.$store.getters });
    },
    onClickSelectionsUntargeted() {
      this.checkedAll = false;
      this.$store.commit('makeSelectionsUntargeted');
    },
    checkSendingDate() {
      // check sending date is valid
      const today = new Date();
      const isInvalid = moment(this.sendingDate).add('days', 1).isBefore(today);
      if (!this.sendingDate || isInvalid) {
        alert('送金予定日が不適切です、再度ご確認下さい');
        return false;
      }
      return true;
    },
    onClickSave() {
      if (!this.checkSendingDate()) {
        return;
      }
      // last confirmation for this procedure
      if (confirm('送金データを作成してよろしいですか？\nこの操作は元に戻すことが出来ません')) {
        this.$store.commit('setProcessingApiRequest', true);
        const data = {
          sending_date: moment(this.sendingDate).format('YYYY-MM-DD')
        };
        data.collecting_date = null;
        if (this.$store.getters.isCollectTargeted) {
          data.collecting_date = moment(this.collectingDate).format('YYYY-MM-DD');
        }
        console.log('onClickSave');
        console.log(this.$store.getters);
        console.log(data);
        this.$store.dispatch('save', {
          data
        });
      }
    },
    onClickUpdateSendingDate() {
      if (!this.checkSendingDate()) {
        return;
      }
      this.updatingSendingDate = true;
      this.$store.dispatch('updateSendingDate', {
        id: this.$store.state.outcoming.ID,
        data: {
          sending_date: moment(this.sendingDate).format('YYYY-MM-DD')
        },
        onSuccess: (res) => {
          this.updatingSendingDate = false;
          alert('更新が完了しました');
        }
      });
    }
  },
  mounted() {
    // date time rangepicker
    const datePickerInput = $('input.calDateTimePicker');

    // Listen event "hide.daterangepicker"
    datePickerInput.on('hide.daterangepicker', (event, picker) => {
      this.collecingDate = picker.startDate.format('YYYY-MM-DD HH:mm');
    });
  },
  created() {
    if (this.isViewMode) {
      this.isAllowTransferRequestOutcoming = this.$store.state.isAllowTransferRequestOutcoming;
    }
  },
  data() {
    const sd = this.$store.state.outcoming.SendingDate;
    const st = this.$store.state.outcoming.SentDate;
    const cd = this.$store.state.outcoming.CollectingDate;
    const msd = moment(sd).format('YYYY/MM/DD');
    const mst = moment(st).format('YYYY/MM/DD HH:mm:ss');
    const mcd = moment(cd).format('YYYY/MM/DD');
    console.log('data');
    console.log(this.$store.getters);
    return {
      // util function
      fmt: fmt,

      // Status for the list manipulation
      checkedAll: false,
      filters: this.$store.state.filters,
      bankAccount: this.$store.state.bankAccount,
      corporationBankAccountFilters: this.$store.state.corporationBankAccountFilters,
      checkedBankAccountFilters: this.$store.state.checkedBankAccountFilters,

      // Timestamps
      sendingDate: msd === '0001/01/01' ? '-' : msd,
      sentDate: mst === '0001/01/01 09:18:59' ? '-' : mst,
      collectingDate: mcd === '0001/01/01' ? moment().format('YYYY/MM/DD') : mcd,

      // Config for Datepicker comopnet
      confDatePicker: {
        format: 'yyyy-MM-dd',
        language: {
          language: 'Japanese',
          months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          days: ['日', '月', '火', '水', '木', '金', '土'],
          rtl: false,
          ymd: 'yyyy-MM-dd',
          yearSuffix: '年'
        }
      },
      updateOutcoming: false,
      isAllowTransferRequestOutcoming: false
    };
  }
};
</script>
