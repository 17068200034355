<template>
  <div class="card mb-1" :class="{ 'border-warning' : escalated }">
    <ToggleHeader 
      :name="no + '. 代替条件の承諾待ち'" 
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-3">
        <div class="row">
          <div class="col text-right">
            代替条件ご案内日時：
          </div>
          <div class="col">
            {{sentDealConditionDay || '-'}}
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            取引ご承諾期限：
          </div>
          <div class="col">
            {{approveDealDay || '-'}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader      from '../../ApplicationView/components/parts/ToggleHeader.vue';
  import CheckAndEscalate  from '../../ApplicationView/components/parts/CheckAndEscalate.vue';

  export default {
    name: 'WatingForApproval',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    computed: {
      ...mapGetters([
        'isActive',
      ]),
    },
    methods: {
      onClickChecked () {
        this.$store.dispatch('updateProcess', {
          'data' : {
            'process': this.process,
            'method': 'checked',
          },
        });
      },
      onClickEscalation (data) {
        this.$store.dispatch('updateProcess', data);
      },
    },
    data() {
      return {
        'process'               : this.$options.name,
        'escalated'             : this.$store.getters.isEscalated(this.$options.name),
        'sentDealConditionDay'  : '',
        'approveDealDay'        : '',
      };
    },
  };
</script>
