import Vue from 'vue';
import App from './components/App.vue';
export default (function () {
    new Vue({
        el: '#app',
        components: {
            App: App,
        },
    });
});
