<template>
  <td class="align-middle" v-bind:style="{'background-color': bg_color}">
    <select class="form-control" v-model="selected">
      <option 
        v-for="commission in commissions" 
        :value="commission.id" 
        :key="commission.key"
        :disabled="disabled"
        >{{ commission.name }}</option>
    </select>
  </td>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'CommissionClusteringCell',
    props: [
      'commission_range',
      'deal_rank',
      'commissions',
      'commission_clustering',
    ],
    watch: {
      selected: function(a, b) { 
        this.bg_color = this.$store.state.commission_color_map[a]
      },
    },
    computed: {
      selected: {
        get() { return this.commission_clustering.commission_id },
        set(value) { 
          this.$store.commit('updateCommissionClustering', {
            'key': this.commission_clustering.key,
            'commission_id': value,
          });
        },
      },
      ...mapGetters([
        'disabled',
      ]),
    },
    data() {
      let commission_id = this.commission_clustering.commission_id;
      let commission_color_map = this.$store.state.commission_color_map;
      return { 
        'bg_color' : commission_color_map[commission_id],
      }
    },
  };
</script>
