// ---------------------------------
// Resolve dependencies
// ---------------------------------
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// Vue / Vuex
import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "./store";
// Axios
import * as Axios from 'axios';
var AXIOS = Axios.default.create({});
// Moment.js
import moment from 'moment';
moment.locale('ja');
var ON_UPLOADED_CHECK = 1;
var ON_COMPLETE = 2;
var ON_REJECTED = 3;
var ON_UNNECESSARY = 4;
var ON_PERMANENT_REJECTED = 5;
var TelInterview = /** @class */ (function (_super) {
    __extends(TelInterview, _super);
    function TelInterview() {
        // ----------------------------------------
        // state
        // ----------------------------------------
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.id = 0;
        _this.bookedDatetime = '';
        _this.interviewType = '';
        _this.approvedOrRejected = '';
        _this.adminUserId = 0;
        _this.judgeNote = '';
        return _this;
    }
    // ----------------------------------------
    // mutation
    // ----------------------------------------
    TelInterview.prototype.setApprovedOrRejected = function (judge) {
        if (judge != 'approved' && judge != 'rejected' && judge != 'permanentRejected') {
            judge = '';
        }
        this.approvedOrRejected = judge;
    };
    TelInterview.prototype.loadData = function (data) {
        this.id = data.ID;
        this.adminUserId = data.TelInterviewAdminUserID;
        this.judgeNote = data.TelInterviewNote;
        var s = '';
        if (data.MasterUserEvidenceStatusID == ON_COMPLETE) {
            s = 'approved';
        }
        if (data.MasterUserEvidenceStatusID == ON_REJECTED) {
            s = 'rejected';
        }
        if (data.MasterUserEvidenceStatusID == ON_PERMANENT_REJECTED) {
            s = 'permanentRejected';
        }
        this.approvedOrRejected = s;
        this.interviewType = '通常通話';
        if (data.MasterUserEvidenceTypeID == 21) {
            this.interviewType = 'ビデオ通話';
        }
        this.bookedDatetime = moment(data.TelInterviewDatetime).format("YYYY/MM/DD HH:mm:ss");
    };
    TelInterview.prototype.setAdminUserId = function (id) {
        this.adminUserId = id;
    };
    TelInterview.prototype.setJudgeNote = function (note) {
        this.judgeNote = note;
    };
    // ----------------------------------------
    // actions
    // ----------------------------------------
    TelInterview.prototype.update = function () {
        var params = new URLSearchParams();
        params.append('data', JSON.stringify({
            'process': 'TelInterview',
            'method': 'update',
            'approved_or_rejected': this.approvedOrRejected,
            'admin_user_id': this.adminUserId,
            'judge_note': this.judgeNote,
        }));
        AXIOS.post('/apiInternal/updateUserEvidenceProcess/' + this.id, params)
            .then(function (res) {
            location.reload();
        })
            .catch(function (error) {
            alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
            console.log(error);
        });
    };
    __decorate([
        Mutation
    ], TelInterview.prototype, "setApprovedOrRejected", null);
    __decorate([
        Mutation
    ], TelInterview.prototype, "loadData", null);
    __decorate([
        Mutation
    ], TelInterview.prototype, "setAdminUserId", null);
    __decorate([
        Mutation
    ], TelInterview.prototype, "setJudgeNote", null);
    __decorate([
        Action({})
    ], TelInterview.prototype, "update", null);
    TelInterview = __decorate([
        Module({ dynamic: true, store: store, name: "tel_interview", namespaced: true })
    ], TelInterview);
    return TelInterview;
}(VuexModule));
export var telInterviewModule = getModule(TelInterview);
