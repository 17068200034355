<template>
  <div ref="ace" class="ace"></div>
</template>

<script>
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-textmate';

export default {
  name: 'Ace',
  props: {
    content: String,
  },
  data() {
    return {
      editor: Object,
    };
  },
  mounted() {
    this.editor = ace.edit(this.$refs.ace);
    this.editor.session.setMode('ace/mode/yaml');
    this.editor.setTheme('ace/theme/textmate');
    if (this.content) {
      this.editor.session.setValue(this.content);
    }
    this.editor.on('change', () => {
      this.$emit('content-changed', this.editor.getValue());
    });
  },
};
</script>

<style>
.ace {
  position: relative !important;
  border: 1px solid lightgray;
  margin: auto;
  height: 100%;
  width: 100%;
}

.ace_editor * {
  font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace !important;
}
</style>
