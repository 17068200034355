var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
moment.locale('ja');
var APPROVED_BTN_ID = 'approveBtnJudgeCamp';
var DECLINED_BTN_ID = 'declineBtnJudgeCamp';
var cbxSelector = '[id*="-dealCheckBox"]';
var checkedCond = ':checked';
var campaigns = [];
var initCampaignSelectBox = function () {
    var urlParams = new URLSearchParams(window.location.search);
    var isIncludeExpired = Boolean(JSON.parse(urlParams.get('include_expired') ? '1' : '0'));
    var selectedCampID = Number(JSON.parse(urlParams.get('campaign_id') || '0'));
    var searchedCampaign = campaigns.find(function (campaign) { return campaign.ID === selectedCampID; });
    if (searchedCampaign) {
        isIncludeExpired = (!moment().tz('Asia/Tokyo').isBetween(moment(searchedCampaign.StartedAt).tz('Asia/Tokyo'), moment(searchedCampaign.EndedAt).tz('Asia/Tokyo'))) ? true : isIncludeExpired;
    }
    loadCampaignSelectBox(isIncludeExpired, selectedCampID);
    var cbxIncludeExpired = document.querySelector('#include_expired');
    if (!cbxIncludeExpired)
        return;
    cbxIncludeExpired.checked = isIncludeExpired;
    cbxIncludeExpired.addEventListener('change', function (e) {
        loadCampaignSelectBox(e.target.checked, selectedCampID);
    });
};
var loadCampaignSelectBox = function (isIncludeExpired, selectedCampID) {
    var campaigns = document.CAMPAIGNS;
    var selectbox = document.querySelector('#campaignSelectBox');
    if (!selectbox)
        return;
    selectbox.innerHTML = '';
    var nullOption = document.createElement('OPTION');
    nullOption.innerHTML = '-';
    nullOption.value = '';
    selectbox.appendChild(nullOption);
    if (!campaigns)
        return;
    campaigns.forEach(function (campaign) {
        var option = document.createElement('OPTION');
        option.value = campaign.ID;
        if (selectedCampID == campaign.ID) {
            option.selected = true;
        }
        var prevText = '';
        if (!moment().tz('Asia/Tokyo').isBetween(moment(campaign.StartedAt).tz('Asia/Tokyo'), moment(campaign.EndedAt).tz('Asia/Tokyo'))) {
            if (isIncludeExpired) {
                prevText = (moment().tz('Asia/Tokyo').isBefore(moment(campaign.StartedAt).tz('Asia/Tokyo')))
                    ? '(未来)' : '(期限切れ)';
                option.innerHTML = prevText + campaign.Title;
                selectbox.appendChild(option);
            }
        }
        else {
            option.innerHTML = prevText + campaign.Title;
            selectbox.appendChild(option);
        }
    });
};
var initToggleCtrlBtns = function () {
    var isDisabled = document.querySelectorAll("" + cbxSelector + checkedCond).length == 0;
    Array.from(document.getElementsByClassName('btn-campaign-deal-ctrl')).forEach(function (e) {
        e.disabled = isDisabled;
    });
};
var checkCbxSelectedByOther = function (e) {
    if (!e.target.checked)
        return;
    var elm = e;
    var elmID = e.target.id;
    var dealID = elmID.split('-')[2];
    var dealCheckBoxs = Array.from(document.querySelectorAll("" + cbxSelector + checkedCond));
    dealCheckBoxs.forEach(function (checkBox) {
        var selectedDealID = (checkBox.id).split('-')[2];
        if (elmID != checkBox.id && dealID == selectedDealID) {
            alert('他キャンペーンに選ばれています。');
            elm.target.checked = false;
        }
    });
};
var addEventForCbxes = function () {
    document.querySelectorAll("" + cbxSelector).forEach(function (element) {
        element.addEventListener('change', function (e) {
            initToggleCtrlBtns();
            checkCbxSelectedByOther(e);
        });
    });
};
var getSelectedData = function () {
    var selectedDatas = [];
    document.querySelectorAll("" + cbxSelector + checkedCond).forEach(function (checkBox) {
        var _a = checkBox.id.split('-'), userIDStr = _a[0], campaignIDStr = _a[1], dealIDStr = _a[2];
        var userID = parseInt(userIDStr, 10);
        var dealID = parseInt(dealIDStr, 10);
        var campaignID = parseInt(campaignIDStr, 10);
        var amountInput = document.getElementById(campaignID + "-" + dealID + "-amount");
        var amount = amountInput ? parseInt(amountInput.value, 10) : 0;
        selectedDatas.push({
            userID: userID,
            campaignID: campaignID,
            dealID: dealID,
            amount: amount
        });
    });
    return selectedDatas;
};
var addEventForCtrlBtn = function (btnID) {
    var btnEl = document.getElementById(btnID);
    if (!btnEl)
        return;
    btnEl.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, submitCorpDeals(btnID)];
    }); }); });
};
var submitCorpDeals = function (btnID) { return __awaiter(void 0, void 0, void 0, function () {
    var btnEl, selectedData, params, data, url, reasonTxt, errMsgEl, res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                btnEl = document.getElementById(btnID);
                if (!btnEl)
                    return [2 /*return*/];
                selectedData = getSelectedData();
                if (selectedData.length === 0)
                    return [2 /*return*/];
                params = new URLSearchParams();
                data = {};
                url = '/apiInternal/approveCampaignDeal';
                switch (btnID) {
                    case APPROVED_BTN_ID:
                        data.judge_params = selectedData;
                        break;
                    case DECLINED_BTN_ID:
                        reasonTxt = document.getElementById('judgeCampDeclineReasonTxt').value;
                        errMsgEl = document.getElementById('declineReasonErrMsg');
                        if (!reasonTxt) {
                            errMsgEl.classList.add('text-danger');
                            errMsgEl.classList.remove('d-none');
                            return [2 /*return*/];
                        }
                        data.judge_params = selectedData;
                        data.decline_reason = reasonTxt;
                        url = '/apiInternal/declineCampaignDeal';
                        break;
                }
                params.append('data', JSON.stringify(data));
                if (!confirm('データを一括更新します、よろしいですか？'))
                    return [2 /*return*/];
                btnEl.disabled = true;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post(url, params)];
            case 2:
                res = _a.sent();
                if (res.data.status === 200) {
                    location.reload();
                    window.scrollTo(0, 0);
                    return [2 /*return*/];
                }
                btnEl.disabled = false;
                alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                return [2 /*return*/];
            case 3:
                err_1 = _a.sent();
                alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
                btnEl.disabled = false;
                throw err_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export default (function () {
    campaigns = document.CAMPAIGNS;
    initCampaignSelectBox();
    addEventForCbxes();
    addEventForCtrlBtn(APPROVED_BTN_ID);
    addEventForCtrlBtn(DECLINED_BTN_ID);
});
