import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';

export default function dateRangePicker () {
    window.moment = require('moment');
    $('input.dateRangePicker').daterangepicker({
        autoUpdateInput: false,
        alwaysShowCalendars:true,
        locale: {
            applyLabel: '適用',
            cancelLabel: 'リセット',
            customRangeLabel: '範囲指定',
            format: 'YYYY-MM-DD',
        },
        ranges: {
            // TODO :「本日(Today)」の日付を定義するとカレンダーを開いた際にデフォルトでactiveクラスが付く(選択していないため値はフォームに反映されない)
            // カレンダー表示時は何も選択されていないためactiveクラスは付与されないのが良いと考える
            '本日': [moment(), moment()],
            '直近7日': [moment().subtract(6,'days'), moment()],
            '今月': [moment().startOf('month'), moment().endOf('month')],
            '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
    });
    $('input.dateRangePicker').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD') + ' 〜 ' + picker.endDate.format('YYYY-MM-DD'));
    });
    $('input.dateRangePicker').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });

    // single daterangepicker
    $('input.calDatePicker').daterangepicker({
        singleDatePicker: true,
        autoInput:true,
        locale: {
            format: 'YYYY-MM-DD',
        },
    });

    // date time rangepicker
    $('input.calDateTimePicker').daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: false,
        singleDatePicker: true,
        autoInput: true,
        locale: {
            format: 'YYYY-MM-DD HH:mm',
        },
    });

    if (document.URL.match("/search")) {
        $('input.calDatePicker').val('');
    }
};
