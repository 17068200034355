<template>
  <div>
    <label>{{ heading }}</label>
    <pre class="bg-secondary p-3 border border-dark rounded">
{{ fullName }} 様

いつもフリーナンスをご利用いただき、誠にありがとうございます。
この度はフリーナンスの会員登録にお申し込みいただきまして、
誠にありがとうございました。

お客様のお申込みを審査させていただきました結果、
以下の理由により会員登録を見送らせていただくこととなりました。

-------------------------------------------

{{ content }}

-------------------------------------------

お客様のご要望にお応えできませんでしたことにお詫びを申し上げます。
なお恐れ入りますが審査の基準については個別にお答えすることは
致しかねますのでご了承ください。

フリーナンスをご利用いただきありがとうございました。
お客様の今後の活躍をお祈りしております。
    </pre>
    <br />
  </div>
</template>

<script>
  export default {
    name: 'EmailPreviewTemplateForDeclined',
    props: [
      'fullName',
      'content',
      'heading'
    ],

  };
</script>
