import Vue        from 'vue';
import Vuex       from 'vuex'
import mutations  from './mutations'
import getters    from './getters'
import actions    from '../api/actions'

Vue.use(Vuex);

const state = {
  user: {},
  deal: {},
  current_proposal_application_amount: 0,
  admin_users: [],
  login_user_id : 0,
  examination_items: {},
  evidence_examination_items: {},
  corp_evidence_type_id: 0,
  user_evidence_type_id: 0
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
});
