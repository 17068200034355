<template>
  <div class="card mb-1">
    <ToggleHeader 
      :name="'※GMO-PG与信チェック (' + pg_insurance_status_title + ')'"
      :process="process"
      :target="'#collapseStep'+no"
      :success=true
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-3">
        <select
          :name="'selected'"
          class="form-control"
          v-model="selected"
          :disabled="!isActive(process)"
        >
        <option
          v-for="selected in pg_insurance_options[pg_insurance_status_id]"
          :value="selected.id"
          :key="selected.id"
        >{{ selected.name }}</option>
        </select>
      </li>
      <CheckAndEscalate
        :no="no"
        :process="process"
        :lbl_check="'保存'"
        :can_escalate=false
        :skip_confirmation=true
        v-on:onClickChecked="onClickChecked"
      ></CheckAndEscalate>
    </ul>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader      from '../../ApplicationView/components/parts/ToggleHeader.vue';
  import CheckAndEscalate  from '../../ApplicationView/components/parts/CheckAndEscalate.vue';

  export default {
    name: 'GMOPGCheck',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    computed: {
      ...mapGetters([
        'isActive',
      ]),
    },
    methods: {
      onClickChecked () {
        this.$store.dispatch('updateProcess', {
          'data' : {
            'process': this.process,
            'method': 'checked',
            'master_pg_insurance_status_id' : this.selected,
            'updated_at': this.$store.state.deal.UpdatedAt,
          },
        });
      },
    },
    data() {
      let deal = this.$store.state.deal;
      let pgInsuranceStatusId = deal.MasterPgInsuranceStatus.ID;
      // Because select box is active only when the PG insurance status ID is 1 or 4
      let pgInsuranceOptions = {
        1: [{
              id : 3,
              name : '審査依頼する',
            },{
              id : 2,
              name : '審査依頼しない',
            },{
              id : 7,
              name : '保証依頼する',
            }],
        4: [{
              id : 7,
              name : '保証依頼する',
            },{
              id : 5,
              name : '審査通過だが、保証依頼しない',
            },{
              id : 6,
              name : '審査落ち',
          }]
      };
      return {
        'process'                   : this.$options.name,
        'pg_insurance_status_title' : deal.MasterPgInsuranceStatus.Title,
        'pg_insurance_status_id'    : pgInsuranceStatusId,
        'pg_insurance_options'      : pgInsuranceOptions,
        'selected'                  : pgInsuranceOptions[pgInsuranceStatusId] ? pgInsuranceOptions[pgInsuranceStatusId][0].id : null,
      };
    },
  };
</script>
