<template>
  <div class="card mb-1" :class="{ 'border-warning' : isEscalated(process) }">
    <ToggleHeader
      :name="no + '. 出金データ作成'"
      :process="process"
      :target="'#collapseStep'+no"
    ></ToggleHeader>
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-3">
        <div class="row">
          <div class="col text-right">
            出金データ作成日:
          </div>
          <div class="col">
            <template v-if="outcomingDate === '-'">
              {{outcomingDate}}
            </template>
            <template v-else>
              <a :href="'/outcomings/view/'+outcomingId">
                {{outcomingDate}}
              </a>
            </template>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-outline-danger btn-sm mt-2"
            :disabled="!isActive(process) || outcomingDate !== '-' || disable_btn"
            @click="onClickRevertInvoiceEvidenceCheck">
            <span class="cui-reload"></span>
            "審査中"に戻す
          </button>
        </div>
      </li>

    </ul>
  </div>

</template>

<script>

  import { mapGetters } from 'vuex'
  import ToggleHeader      from '../../ApplicationView/components/parts/ToggleHeader.vue';
  import CheckAndEscalate  from '../../ApplicationView/components/parts/CheckAndEscalate.vue';

  export default {
    name: 'WatingForPayment',
    props: [
      'no',
    ],
    components: {
      ToggleHeader,
      CheckAndEscalate,
    },
    computed: {
      ...mapGetters([
        'isActive',
        'isEscalated',
        'outcomingDate',
        'outcomingId',
      ]),
    },
    methods: {
      onClickChecked () {
        this.$store.dispatch('updateProcess', {
          'data' : {
            'process': this.process,
            'method': 'checked',
          },
        });
      },
      onClickEscalation (data) {
        this.$store.dispatch('updateProcess', data);
      },
      onClickRevertInvoiceEvidenceCheck () {
        if (confirm('ステータスを「審査中」に戻してもよろしいですか？')) {
          this.disable_btn = true;
          this.$store.dispatch('updateProcess', {
            'data' : {
              'process': this.process,
              'method': 'revert',
            },
          });
        }
      },
    },
    data() {
      return {
        'process' : this.$options.name,
        'disable_btn' : false,
      };
    },
  };
</script>
