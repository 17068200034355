import axios from 'axios';

export default {
  // Ajax request of serialized table-jorm params
  updateProcess({ commit, state }, opt) {

    function serialize (data) {
      const params = new URLSearchParams();
      params.append('data', JSON.stringify(data));
      return params;
    }

    function onSuccess (res) {
      if (res.data.status == 400) {
        alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい'+'\n'+res.data.message);
      }
      if (opt.onSuccess) {
        opt.onSuccess(res);
        return;
      }
      if (res.data.status !== 200) {
        onError(res);
        return;
      }
      location.reload();
    }

    function onError (err) {
      if (opt.onError) {
        opt.onError(err);
        return;
      }
      console.error(err);
      alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
    }

    axios.post('/apiInternal/updateApplicationProcess/' + state.application.ID, serialize(opt.data))
      .then((res) => onSuccess(res))
      .catch(onError);

  },

  // Ajax request of nexway request
  trackNexwayPostalStatus({ commit, state }, cb) {
    function onSuccess (res) {
      if (res.status === 200 && res.data.status === 200) {
        cb(res.data.result);
      } else {
        alert(res.data.message);
      }
    }

    function onError (err) {
      console.error(err);
      alert('エラーが発生しました、問題が解決しない場合システム管理者までお問い合わせ下さい');
    }

    axios.get('/apiInternal/trackNexwayPostalStatus/' + state.application.user_id)
      .then((res) => onSuccess(res))
      .catch(onError);
  },
};
