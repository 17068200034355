<template>
  <div class="tab-pane" :id="'tab'+no" role="tabpanel"
    :class="{'active show': isSelectedTab('tab'+no)}"
    >
    <div class="row">
      <div class="col-md-12">
        <div class="card m-0">
          <div class="card-header">手数料クラスタリング設定</div>
          <div class="table-responsive">
            <table class="table m-0">
              <tr class="table-secondary">
                <th style="border:none;"></th>
                <th style="border:none;"></th>
                <th class="text-center"  style="border:none;" :colspan="cols.length">取引格付け</th>
              </tr>
              <tr class="table-secondary">
                <th class="border-top-0 align-middle" :rowspan="rows.length+1" style="width:70px;position:relative;">
                  <div style="position:absolute;left:-30px;top:50%;transform:rotate(-90deg); width:120px;">
                    手数料レンジ
                  </div>
                </th>
                <th class="border-top-0"></th>
                <th v-for="col in cols" class="border-top-0 text-center">{{ col.name }}</th>
              </tr>
              <tr v-for="row in rows">
                <th class="table-secondary align-middle">
                  {{ row.col_name }}
                </th>
                <CommissionClusteringCell
                  v-for="cell in row.cells"
                  :key="cell.deal_rank.key"
                  :commission_range="row.commission_range"
                  :deal_rank="cell.deal_rank"
                  :commissions="commissions"
                  :commission_clustering="cell.commission_clustering"
                  >
                </CommissionClusteringCell>
              </tr>
            </table>
          </div>
          <div class="card-footer text-center">
            <button 
              class="btn btn-primary" 
              style="width:200px;" 
              v-on:click="saveCommissionClustering"
              :disabled="disabled"
              >保存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import CommissionClusteringCell from './CommissionClusteringCell.vue';

  export default {
    name: 'TabPaneCommissionClustering',
    props: [
      'no',
    ],
    computed: {
      ...mapGetters([
        'isSelectedTab',
        'disabled',
      ])
    },
    components: {
      CommissionClusteringCell,
    },
    data() {
      const commission_ranges = this.$store.state.commission_ranges;
      const deal_ranks = this.$store.state.deal_ranks;
      const commissions = this.$store.state.commissions;
      
      let cols = [];
      deal_ranks.forEach((deal_rank, i) => {
        cols.push(deal_rank)
      });
      
      let rows = [];
      commission_ranges.forEach((commission_range, i) => {
        let min = commission_range.min_price;
        let max = commission_range.max_price;
        let row = {
          col_name : ((min === 0) ? '〜' : min.toLocaleString() + '円〜')
            + ((max === 0) ? '' : max.toLocaleString() + '円'),
          commission_rage: commission_range,
          cells: [],
        };
        deal_ranks.forEach((deal_rank, i) => {
          row.cells.push({
            deal_rank: deal_rank,
            commission_clustering: this.$store.getters.findCommissionClustering(deal_rank.id,commission_range.id),
          })
        });
        rows.push(row);
      });
            
      return {
        cols: cols,
        rows: rows,
        commissions: commissions,
      }
    },
    methods: {
      'saveCommissionClustering' : function () {
        if (!this.$store.getters.isFormAllValid('commission_clusterings')) {
          alert("入力内容をご確認下さい");
          return;
        }
        this.$store.dispatch('saveTblForm', {
          'url' : '/apiInternal/saveCommissionClusterings/' + this.$store.state.ranking_version_id,
          'target' : this.$store.state.commission_clusterings,
          'redirect' : location.pathname + '#tab7',
        });
      },
    },
  };
</script>
