<template>
  <!-- below Trustdockリリース後 -->
  <StickyScroller v-if="isEkyc">
    <Milestone :color="'info'" :label="'申込日時: ' + createdDate" />
    <ArrowDown />
    <WaitingAdditionalFile :no="1" />
    <ArrowDown />
    <TrustDockCheck :no="2" />
    <ArrowDown />
    <JudgeTrustdock :no="3" />
    <ArrowDown />
    <Complete :no="4" />
  </StickyScroller>
  <!-- below Trustdockリリース前 -->
  <StickyScroller v-else-if="!isEkyc">
    <Milestone :color="'info'" :label="'申込日時: ' + createdDate" />
    <ArrowDown />
    <WaitingAdditionalFile :no="1" />
    <ArrowDown />
    <RegistrationCheck :no="2" />
    <ArrowDown />
    <AntiSocialCheck :no="3" />
    <ArrowDown />
    <Judge :no="4" />
    <ArrowDown />
    <Postal :no="5" />
    <ArrowDown />
    <Milestone :color="'info'" :label="'確認コード入力日: ' + activatedDate" />
    <ArrowDown />
    <Complete :no="6" />
  </StickyScroller>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import ja from '../../../../node_modules/vee-validate/dist/locale/ja';

import Milestone from '../../../components/Milestone.vue';
import ArrowDown from '../../../components/ArrowDown.vue';
import StickyScroller from '../../../components/StickyScroller.vue';
import TrustDockCheck from './Step0TrustDockCheck.vue';
import WaitingAdditionalFile from './Step1WaitingAdditionalFile.vue';
import RegistrationCheck from './Step2RegistrationCheck.vue';
import AntiSocialCheck from './Step3AntiSocialCheck.vue';
import Judge from './Step5Judge.vue';
import Postal from './Step6Postal.vue';
import Complete from './Step7Complete.vue';
import JudgeTrustdock from './Step8JudgeTrustdock.vue';

Validator.localize('ja', ja);
Vue.use(VeeValidate, { locale: ja });

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'isEkyc',
      'isActive',
      'isEscalated',
      'createdDate',
      'activatedDate'
    ])
  },
  components: {
    ArrowDown,
    StickyScroller,
    TrustDockCheck,
    JudgeTrustdock,
    WaitingAdditionalFile,
    RegistrationCheck,
    AntiSocialCheck,
    Judge,
    Postal,
    Complete,
    Milestone
  },
  created() {
    this.$store.commit('initApplication', {
      application: document.APPLICATION,
      user: document.USER,
      admin_users: document.ADMIN_USERS,
      login_user_id: document.LOGIN_USER_ID,
      application_same_possibilities: document.APPLICATION_SAME_POSSIBILITIES,
    });
  }
};
</script>
