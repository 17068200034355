<template>
  <div class="card mb-1" :class="{ 'border-warning' : isEscalated(process) }">
    <ToggleHeader
      :name="no + '. 受付'"
      :process="process"
      :target="'#collapseStep'+no" />
    <ul :id="'collapseStep'+no" class="list-group list-group-flush collapse" :class="{ 'show' : isActive(process) }">
      <li class="list-group-item p-1 pt-3 pb-3">
        <ul class="m-0 pl-4">
          <li>即日払いの受付を行ったら、保存ボタンを押してください。</li>
        </ul>
      </li>
      <CheckAndEscalate
        :no="no"
        :process="process"
        :lbl_check="'保存'"
        :can_escalate="false"
        :skip_confirmation="true"
        @onClickChecked="onClickChecked" />
    </ul>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ToggleHeader from '../../ApplicationView/components/parts/ToggleHeader.vue';
import CheckAndEscalate from '../../ApplicationView/components/parts/CheckAndEscalate.vue';

export default {
  name: 'Reception',
  props: [
    'no'
  ],
  components: {
    ToggleHeader,
    CheckAndEscalate
  },
  computed: {
    ...mapGetters([
      'isActive',
      'isEscalated'
    ])
  },
  methods: {
    onClickChecked() {
      this.$store.dispatch('updateProcess', {
        data: {
          process: this.process,
          method: 'checked'
        }
      });
    }
  },
  data() {
    return {
      process: this.$options.name
    };
  }
};
</script>
