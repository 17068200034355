var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ToggleHeader from './parts/ToggleHeader.vue';
import CheckAndEscalate from './parts/CheckAndEscalate.vue';
import ExpirationDateInput from './parts/ExpirationDateInput.vue';
import { mainModule } from '../store/main';
var UploadedUserEvidenceCheck = /** @class */ (function (_super) {
    __extends(UploadedUserEvidenceCheck, _super);
    function UploadedUserEvidenceCheck() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processId = 'UploadedUserEvidenceCheck';
        _this.nowUploading = false;
        _this.expirationDate = '';
        return _this;
    }
    UploadedUserEvidenceCheck.prototype.created = function () {
    };
    UploadedUserEvidenceCheck.prototype.onClickChecked = function (e) {
        mainModule.update({
            data: {
                process: this.process,
                method: 'checked',
                approved_or_rejected: e.escalation_approved_or_rejected,
                updated_at: mainModule.user_evidence.UpdatedAt
            }
        });
    };
    UploadedUserEvidenceCheck.prototype.onClickEscalation = function (data) {
        data.data.updated_at = mainModule.user_evidence.UpdatedAt;
        mainModule.update(data);
    };
    Object.defineProperty(UploadedUserEvidenceCheck.prototype, "process", {
        get: function () {
            return this.processId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadedUserEvidenceCheck.prototype, "active", {
        get: function () {
            return mainModule.isActive(this.processId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadedUserEvidenceCheck.prototype, "necessaryReason", {
        get: function () {
            return mainModule.user_evidence.MasterUserEvidenceNecessaryReason.Title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], UploadedUserEvidenceCheck.prototype, "no", void 0);
    UploadedUserEvidenceCheck = __decorate([
        Component({
            components: {
                ToggleHeader: ToggleHeader,
                CheckAndEscalate: CheckAndEscalate,
                ExpirationDateInput: ExpirationDateInput
            }
        })
    ], UploadedUserEvidenceCheck);
    return UploadedUserEvidenceCheck;
}(Vue));
export default UploadedUserEvidenceCheck;
